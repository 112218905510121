import { MuiThemeProvider } from '@material-ui/core';
import { theme } from './theme';
import MainRouter from './routes/router';
import { createBrowserHistory } from "history";
import { Provider } from 'react-redux';
import { store } from './store/reducer';


function App() {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <MainRouter history={createBrowserHistory()}/>
      </MuiThemeProvider>
    </Provider>
  );
}

export default App;
