import React, { useEffect, useState } from "react"
import { private_server } from "../../config"
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetSelectedFormData, onGetUserCatalogs, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../shared/utility"
import queryString from 'query-string'
import { generic_errors } from "../../texts/eng/genericErrors"
import catalogs from "../../texts/eng/catalogs"

const useEnquiriesView = ({ language, history, is_auth, content }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const [total, setTotal] = useState(0)
    const [search, setSearch] = useState(null)
    const [filterValue, setFilterValue] = useState(0)
    const [tableFilter, setTableFilter] = useState({ limit: 10, offset: 0, order_by: 'id_artwork_purchase_request', order: 1 })
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    //DATA

    const [users, setUsers] = useState([])
    const [artworks, setArtworks] = useState([])
    const [enquiries, setEnquiries] = useState([])
    const [enquiry, setEnquiry] = useState(null)
    const [enquiryID, setEnquiryID] = useState(null)
    const [add_modal, setAddModal] = useState(false)
    const [edit_modal, setEditModal] = useState(false)

    useEffect(() => {
        if (is_auth)
            actions.onInitModule()
    }, [is_auth])

    useEffect(() => {
        let origin = add_modal ? null : enquiry
        let _form = JSON.parse(JSON.stringify(formData))
        _form = onInitForm(_form, origin, content.form)
        _form.consultant_id.options = [...users]
        // _form.artwork_id.options = [...artworks]
        setForm(_form)
    }, [users, artworks, add_modal, edit_modal])

    useEffect(() => {
        if (is_auth)
            actions.onFilterEnquiries()
    }, [is_auth, tableFilter])

    useEffect(() => {
        if (is_auth)
            actions.onResetTableFilter()
    }, [is_auth, filterValue])

    const actions = {
        onInitModule: async () => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)

                let filter = {
                    OR: [
                        { field: "user_type_id", operator: "=", value: 1 },
                        { field: "user_type_id", operator: "=", value: 2 }
                    ]
                }
                
                filter = encodeURIComponent(JSON.stringify(filter))
                let _users = await private_server.get(`user/all/?filter=${filter}`)
                _users = _users.data.data.users
                setUsers(onGetUserCatalogs(_users))

                let _artworks = await private_server.get(`artwork/all`)
                _artworks = _artworks.data.data.artworks
                setArtworks(_artworks)
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        },
        onResetTableFilter: () => setTableFilter({ limit: 10, offset: 0, order_by: 'id_artwork_purchase_request', order: 1 }),
        onCloseError: () => setError(null),
        onUpdateTableFilter: (data) => setTableFilter(data),
        onUpdateFilterValue: (id) => setFilterValue(id),
        onOpenAddModal: () => setAddModal(true),
        onCloseAddModal: () => setAddModal(false),
        onChange: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if (error) setError(null)
            setForm(temp)
        },
        onSearchEnquirie: (data) => {
            let temp = { ...form }
            temp.search = { ...data }
            if (error) setError(null)
            setForm(temp)
            setSearch(data.value)
        },
        onFilterEnquiries: async () => {
            try {
                setSending(true)
                let filter = { AND: [] }
                if (filterValue > 0) filter.AND.push({ field: "artwork_purchase_request_status_id", operator: "=", value: filterValue })
                if (search && search.length > 0) filter.AND.push({ field: "name", operator: "LIKE", value: `%%${search}%%` })
                let _filter = encodeURIComponent(JSON.stringify(filter))
                let params = filter.AND.length > 0 ? `?${queryString.stringify(tableFilter)}&filter=${_filter}` : `?${queryString.stringify(tableFilter)}`
                const _enquiries = await private_server.get(`artworkpurchaserequest/all${params}`)
                setEnquiries([..._enquiries.data.data.artwork_purchase_requests])
                setTotal(_enquiries.data.data.count)
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onSelectEnquirie: (_enquiry) => {
            setEnquiry(_enquiry)
            setEnquiryID(_enquiry.id_artwork_purchase_request)
            setEditModal(true)
        },
        onCloseEditModal: () => setEditModal(false),
        onAddEnquirie: async () => {
            try {
                setSending(true)

                const errors = isFormValid(form)

                // Error handler
                if (errors && errors.length) {
                    const _form = onSetErrorsToForm(form, errors)
                    setForm(_form)
                    setError(generic_errors[language].INCOMPLETE_FORM)
                    setSending(false)
                    return
                }

                const data2send = onGetSelectedFormData(form, enquiries)

                await private_server.post(`artworkpurchaserequest`, data2send)
                let params = `?${queryString.stringify(tableFilter)}`
                const _enquiries = await private_server.get(`/artworkpurchaserequest/all${params}`)
                setEnquiries(_enquiries.data.data.artwork_purchase_requests)
                setTotal(_enquiries.data.data.count)
                setFilterValue(0)
                setTableFilter({ limit: 10, offset: 0, order_by: 'id_artwork_purchase_request', order: 1 })

                setError(null)
                setAddModal(false)
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onUpdateEnquiry: async () => {
            try {
                setSending(true)

                const errors = isFormValid(form)

                // Error handler
                if (errors && errors.length) {
                    const _form = onSetErrorsToForm(form, errors)
                    setForm(_form)
                    setError(generic_errors[language].INCOMPLETE_FORM)
                    setSending(false)
                    return
                }

                const data2send = onGetSelectedFormData(form, enquiries)
                console.log(data2send)

                await private_server.patch(`artworkpurchaserequest/${enquiryID}`, data2send)
                let params = `?${queryString.stringify(tableFilter)}`
                const _enquiries = await private_server.get(`/artworkpurchaserequest/all${params}`)
                setEnquiries(_enquiries.data.data.artwork_purchase_requests)
                setTotal(_enquiries.data.data.count)
                setFilterValue(0)
                setTableFilter({ limit: 10, offset: 0, order_by: 'id_artwork_purchase_request', order: 1 })

                setError(null)
                setEditModal(false)
            } catch (error) {
                setError(onGetErrorMessage(error))
                console.log(sending)
            }
            setSending(false)
        },
    }

    const system = { loading, sending, error }
    const view_data = { total, filterValue, enquiries, form, tableFilter, add_modal, edit_modal, artworks }

    return { system, view_data, actions }
}

export default useEnquiriesView

const formData = {
    search: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'search',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    artwork_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'artwork_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none'
        }
    },
    name: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    email: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'email'
        }
    },
    consultant_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        image: true,
        config: {
            id: 'consultant_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none'
        }
    },
    date: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'date',
            type: 'date',
            fullWidth: true,
        },
        rules: {
            type: 'date'
        }
    },
    artwork_purchase_request_status_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        icon: 'square',
        options: [...catalogs.application_statuses],
        config: {
            id: 'artwork_purchase_request_status_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none'
        }
    },
    comments: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'comments',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 4
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    }
}