import React, { useState } from 'react';
import { withStyles, Grid, Icon, IconButton, LinearProgress, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import ActionBar from './ActionBar';
import SearchBar from './SearchBar';
import StatusData from '../../../../../components/Structure/DisplayData/StatusData';
import { catalogs } from '../../../../../texts/eng/catalogs';
import AvatarPicture from '../../../../../components/Structure/DisplayData/AvatarPicture';
import StarIcon from '@material-ui/icons/Star';
import SVGIcon from '../../../../../components/Structure/DisplayData/SVGIcon';
import ActionTopBar from '../../../../../components/Structure/Table/ActionTopBar';

const CustomizedTableRow = withStyles((theme) => ({
	root: {
		background: 'white',
		boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.07)',
		borderRadius: 15,
		height: 90,
		'& td:first-child': {
			borderRadius: '15px 0px 0px 15px',
		},
		'& td:last-child': {
			borderRadius: '0px 15px 15px 0px',
		},
		'&:hover': {
			cursor: 'pointer',
			background: grey[100],
		},
	},
}))(TableRow);

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 40,
	},
	addIcon: {
		background: 'black',
		borderRadius: '100%',
		color: 'white',
		'&:hover': {
			background: theme.palette.primary.main,
		},
	},
	paper: {
		marginTop: 4,
		borderRadius: 8,
	},
	cellHeader: {
		paddingTop: 16,
		paddingBottom: 16,
		borderBottom: 'none',
	},
	cell: {
		borderBottom: 'none',
		position: 'relative',
	},
	text: {
		fontWeight: 500,
		fontSize: 14,
	},
	country: {
		width: 'fit-content',
		margin: 'auto',
		fontWeight: 500,
		fontSize: 14,
		border: '1px solid black',
		borderRadius: 4,
		padding: '0px 10px',
	},
	button: {
		background: theme.palette.primary.main,
		padding: '6px 12px',
		borderRadius: 32,
		color: 'white',
		'&:hover': {
			background: theme.palette.primary.dark,
		},
	},
	toolbar: {
		marginBottom: 16,
	},
	actions: {
		marginTop: 16,
	},
	table: {
		//minWidth: 1100,
		borderSpacing: '0px 3px',
		borderCollapse: 'separate',
	},
	tableWrapper: {
		overflowX: 'auto',
	},
	nodata: {
		marginTop: 40,
	},
	colorTag: {
		borderRadius: '15px 0px 0px 15px',
		position: 'absolute',
		width: 8,
		height: 90,
		top: 0,
		left: 0,
	},
	startIcon: {
		fill: theme.palette.secondary.main,
	},
    nft_button: {
        background: 'transparent',
        width: 32,
        height: 32,
        margin: 'auto',
        position: 'relative',
        boxSizing: 'border-box',
        borderRadius: '100%',
        padding: 0,
        zIndex: 1,
        '&:hover': {
            opacity: 0.5
        }
    },
}));

const SimpleTable = (props) => {
	const classes = useStyles();

	const {
		data,
		header,
		total,
		search,
		onChangeSearch,
		onAddRegister,
		tableFilter,
		onUpdateTableFilter,
		loading,
		onRemoveItem,
		noData,
		onVisitUrl,
		content,
		status,
		onChangeStatus,
		catalog_filter,
	} = props;


	return (
		<div className={classes.root}>
			{loading ? (
				<Grid container justifyContent='center'>
					<Grid item xs={12}>
						<LinearProgress color='secondary' />
					</Grid>
				</Grid>
			) : null}
			<div className={classes.toolbar}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
							<Grid item>
								<ActionTopBar filter={catalog_filter} filterValue={status} onChangeFilter={onChangeStatus}></ActionTopBar>
							</Grid>
							<Grid item xs />
							<Grid item>
								<SearchBar value={search} onChange={onChangeSearch} />
							</Grid>
							<Grid item>
								<IconButton className={classes.addIcon} onClick={onAddRegister}>
									<Icon>add</Icon>
								</IconButton>{' '}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
			{data && data.length > 0 ? (
				<>
					<div className={classes.tableWrapper}>
						<Table className={classes.table}>
							{header ? (
								<TableHead>
									<TableRow>
										{header.map((item) => {
											return (
												<TableCell padding={'normal'} className={classes.cellHeader} key={item.id.toString()} align={item.align}>
													<Typography variant='body2'>{item.label}</Typography>
												</TableCell>
											);
										})}
									</TableRow>
								</TableHead>
							) : null}
							<TableBody>
								{data.map((item, key) => {
									const { artist_status_id, id_magazine_artist, image, artist, artist_discipline, country_abbreviation, membership_type, artist_new, artist_featured, url } = item;
									const color = catalogs.artist_statuses.find(type => type.value === artist_status_id).color
									const newData = catalogs.sponsorship.find((n) => n.id === artist_new);

									return (
										<CustomizedTableRow key={key.toString()}>
											<TableCell className={classes.cell} align='center' onClick={() => onVisitUrl(url, 'artists')}>
												<div className={classes.colorTag} style={{ background: color }}></div>
												<Typography className={classes.text}>{id_magazine_artist}</Typography>
											</TableCell>
											<TableCell className={classes.cell} onClick={() => onVisitUrl(url, 'artists')}>
												<Grid container alignItems='center' spacing={2}>
													<Grid item>
														<AvatarPicture src={image} size={42} />
													</Grid>
													<Grid item xs>
														<Typography className={classes.text}>{artist}</Typography>
													</Grid>
												</Grid>
											</TableCell>
											<TableCell className={classes.cell} onClick={() => onVisitUrl(url, 'artists')}>
												<Typography className={classes.text}>{artist_discipline}</Typography>
											</TableCell>
											<TableCell className={classes.cell} align='center' onClick={() => onVisitUrl(url, 'artists')}>
												<Typography className={classes.country}>{country_abbreviation}</Typography>
											</TableCell>
											<TableCell className={classes.cell} onClick={() => onVisitUrl(url, 'artists')}>{membership_type ? membership_type.toUpperCase() : '-'}</TableCell>
											<TableCell className={classes.cell} align='center' onClick={() => onVisitUrl(url, 'artists')}>
												{newData && <StatusData icon={'square'} data={newData.label} color={newData.color} size={10} style={{ color: 'black', fontWeight: 500, fontSize: 14 }} />}
											</TableCell>
											<TableCell className={classes.cell} align='center' onClick={() => onVisitUrl(url, 'artists')}>
												{artist_featured ? <StarIcon classes={{ root: classes.startIcon }} /> : null}
											</TableCell>
											{onRemoveItem && (
												<TableCell className={classes.cell} >
													<IconButton
														onClick={() => onRemoveItem('artists', id_magazine_artist)}
														component='span'
													>
														<SVGIcon src={content.delete} />
													</IconButton>
												</TableCell>
											)}
										</CustomizedTableRow>
									);
								})}
							</TableBody>
						</Table>
					</div>
					<div className={classes.actions}>
						<ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter} />
					</div>
				</>
			) : null}
			{data.length === 0 && !loading ? (
				<div className={classes.nodata}>
					<Grid container justifyContent='center' alignItems='center' direction='column'>
						<Grid item>
							<Typography>{noData}</Typography>
						</Grid>
					</Grid>
				</div>
			) : null}
		</div>
	);
};

export default SimpleTable;
