export const translations = {
    title: 'Sponsors',
    noData: 'No sponsors to show.',
    filter: [
        { id: -1, value: -1, label: 'All' },
        { id: 1, value: 1, label: 'Active' },
        { id: 0, value: 0, label: 'Inactive' },
    ],
    form: {
        search: {
            placeholder: 'Search'
        },
        name: {
            label: 'Name',
            placeholder: 'Name',
            helperText: 'Name is required'
        },
        description: {
            label: 'Description',
            placeholder: 'Description',
            helperText: 'Incorrect field'
        },
        enabled: {
            label: 'Status',
            placeholder: 'Status',
            helperText: 'Select a valid option'
        },
    },
    header: [
        { id: 1, label: 'ID', align: 'center' },
        { id: 2, label: 'Name' },
        { id: 3, label: 'Status' },
    ],
    modals: {
        add_modal: {
            title: 'Add sponsor',
            save_button: 'Add'
        },
        edit_modal: {
            title: 'Edit sponsor',
            save_button: 'Save'
        }
    }
}

export default translations