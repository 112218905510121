import { Grid, Typography, IconButton, Icon, useTheme } from '@material-ui/core';
import SimpleModal from '../../../../../components/Modals/SimpleModal';
import RoundedButton from '../../../../../components/Actions/RoundedButton';
import LineAutocompleteForm from '../../../../../components/Forms/LineAutocompleteForm';
import LineSelectForm from '../../../../../components/Forms/LineSelectForm';
import { useState } from 'react';
import SwipableArtworks from '../components/SwipableArtworks';

const EditMagazineApplicationModal = ({ open, form, onChange, onSubmit, onClose, content, artworks, onAddArtwork, onDeleteArtwork, setArtistArtworks }) => {
	const theme = useTheme()
    const [artworkModal, setArtworkModal] = useState(false)

	const onSelectArtist = async(data) =>{
		if (data.value) await setArtistArtworks(data.value);
		onChange(data)
	}

	const onSaveArtwork = async() =>{
		await onAddArtwork()
		setArtworkModal(false)
	}

    return (
			<>
				<AddArtworkModal
					content={content.add_artwork}
					data={form.artwork_id}
					open={artworkModal}
					onClose={() => setArtworkModal(false)}
					onChange={onChange}
					onSave={onSaveArtwork}
				/>
				<SimpleModal maxWidth='xs' padding='medium' open={open} onClose={onClose} borderRadius={5}>
					<Grid container alignItems='center' spacing={4}>
						<Grid item xs={12}>
							<Typography variant='h6'>{content.title}</Typography>
						</Grid>
						<Grid item xs={12}>
							<LineAutocompleteForm data={form.user_id} onChange={onSelectArtist} />
						</Grid>
						<Grid item container spacing={2} alignItems='center' xs={12}>
							<Grid item>
								<Typography variant='body2'>{content.artworks}</Typography>
							</Grid>
							<Grid item>
								<IconButton onClick={()=> setArtworkModal(true)} style={{ color: 'white', background: theme.palette.secondary.main, width: 14, height: 14 }}>
									<Icon style={{ fontSize: 18 }}>add</Icon>
								</IconButton>
							</Grid>
							<Grid item xs={12}>
								<SwipableArtworks artworks={artworks} content={content} onDeleteArtwork={onDeleteArtwork} />
							</Grid>
						</Grid>
						<Grid item xs={5}>
							<LineSelectForm data={form.payment_status_id} onChange={onChange} />
						</Grid>
						<Grid item xs={2} />
						<Grid item xs={5}>
							<LineSelectForm data={form.application_status_id} onChange={onChange} />
						</Grid>
						{/* <Grid item xs={12}>
                    <LineInputForm data={form.comments} onChange={onChange} />
                </Grid> */}
						<Grid item xs></Grid>
						<Grid item container justifyContent='center'>
							<RoundedButton size='large' onClick={() => onSubmit('magazine')}>
								{content.save_button}
							</RoundedButton>
						</Grid>
					</Grid>
				</SimpleModal>
			</>
		);
};

const AddArtworkModal = ({open, onClose, onSave, content, data, onChange}) =>{
    return(
        <SimpleModal maxWidth='xs' open={open} onClose={onClose} borderRadius={5}>
			<Grid container alignItems='center' spacing={4}>
				<Grid item xs={12}>
					<Typography variant='h6'>{content.title}</Typography>
				</Grid>
				<Grid item xs={12}>
					<LineAutocompleteForm data={data} onChange={onChange} />
				</Grid>
				<Grid item xs></Grid>
				<Grid item container justifyContent='center'>
					<RoundedButton size='large' onClick={onSave}>
						{content.save_button}
					</RoundedButton>
				</Grid>
			</Grid>
		</SimpleModal>
    )
}

export default EditMagazineApplicationModal;
