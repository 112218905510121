import React, { useState, useEffect } from 'react'
import { isFormValid, onGetErrorMessage, onGetFormData, onGetUserCatalogs, onInitForm, onSetErrorsToForm } from '../../shared/utility'
import queryString from 'query-string'
import { private_server } from '../../config'
import general_tranlations from '../../texts/eng/general-translations'

const useArtworksView = ({ is_auth, history, content, search_params, onUpdateSearchParams }) => {

    //Check if its the last page visited and has a saved search and filter
	let savedSearch = null
	let savedFilterValue = 0
	if(search_params && search_params.last_visited)
		if(search_params.last_visited === 'artworks'){
			savedSearch = search_params.search ? search_params.search : null;
			savedFilterValue = search_params.filter_value ? search_params.filter_value : 0;
		}

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [search, setSearch] = useState(savedSearch)
    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({ limit: 12, offset: 0, order_by: 'id_artwork', order: 0 })
    const [filterValue, setFilterValue] = useState(savedFilterValue)
    const [artworks, setArtworks] = useState([])
    const [artists, setArtists] = useState([])

    //MODAL
    const [add_modal, setAddModal] = useState(false)

    const errors_txt = general_tranlations.errors

    useEffect(() => {
        if (is_auth)
            actions.onInitModule()
    }, [is_auth])

    useEffect(() => {
        if (is_auth)
            actions.onFilterArtwork()
    }, [is_auth, tableFilter])

    useEffect(() => {
        if (is_auth)
            actions.onResetTableFilter()
    }, [is_auth, filterValue])

    const actions = {
        onInitModule: async() => {
            window.scrollTo(0, 0)
            let _form = JSON.parse(JSON.stringify(formData))
            _form = onInitForm(_form, null, content.form)

            let _artists = await private_server.get(`artist/all`)
            _artists = _artists.data.data.artists
            setArtists(_artists)

            _form.artist_id.options = onGetUserCatalogs(_artists)

            if(savedSearch)
				_form.search.value = savedSearch

            setForm(_form)
            setError(null)
        },
        onResetTableFilter: () => setTableFilter({ limit: 12, offset: 0, order_by: 'id_artwork', order: 0 }),
        onUpdateTableFilter: (data) => setTableFilter(data),
        onCloseSuccess: () => setSuccess(false),
        onCloseError: () => setError(null),
        onSearchArtwork: (data) => {
            let temp = { ...form }
            temp.search = { ...data }
            if (error) setError(null)
            setForm(temp)
            setSearch(data.value)
        },
        onUpdateFilterValue: (id) => setFilterValue(id),
        onFilterArtwork: async () => {
            setLoading(true)
            try {
                //setSending(true)
                let filter = {}
                if (filterValue > 0) filter.AND = [{ field: "artwork_status_id", operator: "=", value: filterValue }]
                if (search && search.length > 0)
                    if (filterValue > 0)
                        filter.AND.push({
                            OR: [
                                { field: "title", operator: "LIKE", value: `%%${search}%%` },
                                { field: "artist", operator: "LIKE", value: `%%${search}%%` },
                                // { field: "artist_professional_name", operator: "LIKE", value: `%%${search}%%` },
                            ]
                        })
                    else
                        filter.OR = [
                            { field: "title", operator: "LIKE", value: `%%${search}%%` },
                            { field: "artist", operator: "LIKE", value: `%%${search}%%` },
                            // { field: "artist_professional_name", operator: "LIKE", value: `%%${search}%%` },
                        ]

                let _filter = encodeURIComponent(JSON.stringify(filter))
                let isValidFilter = Object.keys(filter).length !== 0 || Object.getPrototypeOf(filter) !== Object.prototype
                let params = isValidFilter ? `?${queryString.stringify(tableFilter)}&filter=${_filter}` : `?${queryString.stringify(tableFilter)}`

                const _artworks = await private_server.get(`artwork/all${params}`)
                setArtworks([..._artworks.data.data.artworks])
                setTotal(_artworks.data.data.count)
                setLoading(false)
            } catch (error) {
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
            //setSending(false)
        },
        onVisitUrl: (url) => {
            if (url && url.length > 0){
                onUpdateSearchParams({search: search, filter_value: filterValue, last_visited: 'artworks'})
                history.push(`artworks/${url}`)
            }
        },
        onOpenAddModal: () => setAddModal(true),
        onCloseAddModal: () => setAddModal(false),
        onChange: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if (error) setError(null)
            setForm(temp)
        },
        onSaveArtwork: async () => {
            const errors = isFormValid(form)
            if (errors && errors.length) {
				console.log(errors);
				const _form = onSetErrorsToForm(form, errors);
				setForm(_form);
				setError(errors_txt.incomplete_form);
				return;
			}

            try {
                setSending(true)

                const data2send = onGetFormData(form)
                data2send.artwork_status_id = 1
                delete data2send.search

                let _artwork = await private_server.post(`artwork/`, data2send)
                _artwork = _artwork.data.data.artwork
                setArtworks([...artworks, _artwork])

                setError(null)
                setAddModal(false)
                setSending(false)

                history.push(`artworks/${_artwork.url}`)
            } catch (error) {
                console.log(error)
                setSending(false)
                setError(onGetErrorMessage(error))
            }
        }
    }

    const system = { loading, sending, success, error }
    const view_data = { form, total, tableFilter, filterValue, artworks, add_modal }

    return { system, view_data, actions }
}

export default useArtworksView

const formData = {
    search: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'search',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    artist_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        image: true,
        config: {
            id: 'artist_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none'
        }
    },
    title: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'title',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    dimensions: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'dimensions',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    technique: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'technique',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    price: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'price',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'numeric',
            min: 0
        }
    },
    description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 4
        },
        rules: {
            type: 'none',
        }
    },
    date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'date',
            type: 'date',
            fullWidth: true,
        },
        rules: { type: 'date' }
    },
    sold: {
        value: 0,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sold'
        }
    },
    nft: {
        value: 0,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'nft'
        }
    },
    external_url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'external_url',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
}