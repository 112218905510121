import React from 'react'
import { withStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { grey } from '@material-ui/core/colors'

const CustomizedSelect = withStyles({
    root: {
        fontSize: 20,
        fontWeight: 700,
        color: grey[800],
        textTransform: 'uppercase',
        textDecoration: 'none',
        minHeight: 0
    },
})(Select)

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    subtitle: {
        fontSize: 24,
        fontWeight: 700,
        color: grey[800],
        textTransform: 'uppercase'
    },
    menuPaper: {
        marginTop: 40,
        textTransform: 'uppercase',
    },
    itemRoot: {
        color: grey[800],
        fontSize: 14,
        fontWeight: 600
    },
    itemSelected: {
        color: theme.palette.secondary.main,
        backgroundColor: 'white !important'
    }
}))

const SelectFilter = ({ value, options, onChange }) => {
    const classes = useStyles()

    return (
        <div>
            <FormControl className={classes.formControl}>
                <CustomizedSelect
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                    disableUnderline 
                    MenuProps={{
                        classes: {
                            paper: classes.menuPaper,
                            list: classes.menuList
                        }
                    }}
                >
                    {options.map((option, key) => (
                        <MenuItem
                            value={option.value}
                            key={`selectfilter-${key.toString()}`}
                            classes={{
                                root: classes.itemRoot,
                                selected: classes.itemSelected
                            }}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </CustomizedSelect>
            </FormControl>
        </div >
    )
}

export default SelectFilter