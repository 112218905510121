import { makeStyles } from "@material-ui/core"
import AddButton from "../../../../../components/Actions/AddButton"


const AddCard = ({ onClick }) => {

    const classes = useStyles()

    return (
        <div className={classes.root} onClick={onClick}>
            <AddButton />
        </div>
    )

}

export default AddCard

const useStyles = makeStyles(theme => ({
    root: {
        background: '#E3E3E3',
        borderRadius: 5,
        boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.07)',
        minHeight: 400,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        transition: '0.3s',
        '&:hover':{
            cursor:'pointer',
            background:'rgba(0,0,0,0.3)'
        }
    },
}))