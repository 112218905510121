
export const translations = {
    title:'Detalle de producto',
    menu:[{id:1, label:'Información'}, {id:2, label:'Estadísticas'}],
    product_info:{
        general_info:{
            title:'Editar',
            code:'Código',
            location:'Ubicación',
            description:'Descripción',
            brand:'Marca',
            sale_group:'Grupo de compra',
            quantity:{
                title:'Cantidad',
                current:'Actual',
                min:'Min',
                max:'Máx'
            },
            price:{
                title:'Precio'
            },
            fiscal_info:{
                title:'Información fiscal',
                unity:'Unidad',
                code:'Código'
            },
            modal:{
                button:'Guardar'
            },
            
        },
        equivalences:{
            title:'Equivalencias',
            code:'Código',
            description:'Descripción',
            button:'Agregar'
        },
        complementary:{
            title:'Complementarios',
            code:'Código',
            description:'Descripción',
            button:'Agregar'
        },
        
    }
    
}

export default translations