import React, { useEffect, useState } from "react"
import { CircularProgress, Grid, IconButton, makeStyles, Paper, useMediaQuery } from '@material-ui/core'
import { useTheme } from "@material-ui/styles"
import { grey } from "@material-ui/core/colors"
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import SwipeableViews from 'react-swipeable-views'
import GenericImage from '../../../../../components/Structure/DisplayData/GenericImage'
import { autoPlay } from 'react-swipeable-views-utils'
import CloseIcon from '@material-ui/icons/Close';
import cx from 'classnames'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const styles = makeStyles(theme => ({
    root: {
        //paddingLeft: 100,
        //paddingRight: 100,
        paddingTop: 40,
        paddingBottom: 80,
        [theme.breakpoints.down('sm')]: {
            //padding: 32
        },
    },
    content: {
        padding: '60px 0px'
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        backgroundColor: grey[600]
    },
    button: {
        background: theme.palette.secondary.main,
        color: 'white',
        '&:hover': {
            background: theme.palette.secondary.dark,
        }
    }
}))

const SwipableArtworks = ({ artworks, content, onDeleteArtwork }) => {

    const classes = styles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('xs'))
    const [current, setCurrent] = useState(1)
    const [subArtworks, setSubArtworks] = useState([])

    useEffect(() => {
        if (artworks && artworks.length > 0) {
            let arr = []
            let rows = Math.ceil(artworks.length / 2)
            for (let i = 0; i < rows; i++) {
                arr[i] = []
                for (let j = 0; j < 2; j++) {
                    arr[i][j] = artworks[i * rows + j]
                }
            }
            setSubArtworks(arr)
        } else
            setSubArtworks([])
    }, [artworks])
    
    return (

        <Grid container justifyContent='center' alignItems='center' spacing={8}>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems='center' spacing={1}>
                    {!mobile && current !== 1 &&
                        <Grid item>
                            <IconButton disabled={current == 1} onClick={() => setCurrent(current - 1)} className={classes.button}>
                                <NavigateBeforeIcon />
                            </IconButton>
                        </Grid>
                    }
                    <Grid item xs>
                        <AutoPlaySwipeableViews
                            slideStyle={{ overflow: 'hidden' }}
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={current - 1}
                            onChangeIndex={(value) => setCurrent(value + 1)}
                            enableMouseEvents
                            interval={16000}
                        >
                            {subArtworks.map((batch, key) => (
                                <div key={key} style={{ padding: 5 }}>
                                    <Grid container spacing={2}>
                                        {batch.map(artwork => {
                                            if (artwork)
                                                return (
                                                    <Grid item xs={6}>
                                                        <ArtworkCard {...artwork} content={content} noImage={content.noImage}
                                                            onDelete={onDeleteArtwork} />
                                                    </Grid>
                                                )
                                        })}
                                    </Grid>
                                </div>
                            ))}
                        </AutoPlaySwipeableViews>
                    </Grid>
                    {mobile && current !== 1 &&
                        <Grid item>
                            <IconButton disabled={current === 1} onClick={() => setCurrent(current - 1)} className={classes.button}>
                                <NavigateBeforeIcon />
                            </IconButton>
                        </Grid>
                    }
                    {current < Math.ceil(artworks.length / 2) &&
                        <Grid item>
                            <IconButton disabled={current === artworks.length} onClick={() => setCurrent(current + 1)} className={classes.button}>
                                <NavigateNextIcon />
                            </IconButton>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SwipableArtworks

const imageCardStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        borderRadius: 10,
        position: 'relative',
        backgroundColor: 'transparent',
        boxSizing: 'border-box',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    overlay: {
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        position: 'absolute',
        cursor: 'pointer',
        background: 'rgba(0,0,0,0.6)',
        visibility: 'hidden',
        borderRadius: 10
    },
    overlayShow: {
        visibility: 'inherit'
    },
    deleteIcon: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        color: 'white',
        transform: 'translate(-50%,-50%)',
        //  mixBlendMode: 'unset',
        background: 'transparent',
        height: 'fit-content',
        mixBlendMode: 'difference',
        right: 0,
        padding: 10,
    },
    title: {
        color: 'white',
        fontSize: 16,
        fontWeight: 400
    },
    image: {
        height: '100%',
        objectFit: 'cover',
        borderRadius: 10
    },
    loading: {
        position: 'absolute',
        top: '50%',
        left: 0,
        zIndex: 3,
        transform: 'translateY(-50%)'
        //background:'red'
    },
}))

const ArtworkCard = ({ id_exhibition_application_artwork, artwork_web_image, artwork_mobile_image, content, onDelete }) => {

    const classes = imageCardStyles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('xs'))

    const [hover, setHover] = useState(false)

    const selectedImage = mobile ? artwork_mobile_image : artwork_web_image

    return (
        <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => onDelete(id_exhibition_application_artwork)}
            className={classes.root}
        >
            <GenericImage src={selectedImage} />
            <div className={cx({
                [classes.overlay]: true,
                [classes.overlayShow]: hover
            })}>
                <IconButton className={classes.deleteIcon}>
                    <CloseIcon />
                </IconButton>
            </div>
        </div >
    )
}
