import React from 'react'
import { translations as translations_esp } from '../../texts/eng/masterclasses-translations'
import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import LoadingContainer from '../../components/Structure/Layouts/LoadingContainer'
import TitleContainer from '../../components/Structure/Layouts/TitleContainer'
import ActionTopBar from '../../components/Structure/Table/ActionTopBar'
import useMasterclassesView from './useMasterclassesView'
import { CONFIG_SETTINGS } from '../../config'
import { isValidDocument, onGetDateFormat } from '../../shared/utility'
import { catalogs } from '../../texts/eng/catalogs'
import StopIcon from '@material-ui/icons/Stop'
import { grey } from '@material-ui/core/colors'
import SVGIcon from '../../components/Structure/DisplayData/SVGIcon'
import AddMasterclassModal from './modals/AddMasterclassModal'
import { connect } from 'react-redux'
import moment from 'moment'
import cx from 'classnames'
import ActionBar from './components/ActionBar'
import { actionTypes } from '../../store/actions'

const styles = makeStyles(theme => ({
    root: {
        padding: 80,
        paddingBottom: 0,
    },
    content: {
        marginTop: 30
    },
    noMasterclass: {
        marginTop: 100
    },
    noMasterclassTitle: {
        fontSize: 24,
        fontWeight: 700,
        color: '#494949'
    },
    noMasterclassSubtitle: {
        fontSize: 16,
        color: '#7F7F7F'
    }
}))

const MasterclassesView = ({ is_auth, user, history, search_params, onUpdateSearchParams }) => {

    const classes = styles()
    const content = translations_esp

    const { system, view_data, actions } = useMasterclassesView({
        is_auth, user, history, content, search_params, onUpdateSearchParams
    })


    return (
        <TitleContainer title={content.title}>
            <AddMasterclassModal
                open={view_data.add_modal}
                form={view_data.form}
                onChange={actions.onChange}
                onSubmit={actions.onSaveMasterclass}
                onClose={actions.onCloseAddModal}
                content={content}
            />
            <ActionTopBar
                filter={[...content.filter, ...catalogs.masterclass_statuses]}
                filterValue={view_data.filterValue}
                onChangeFilter={actions.onUpdateFilterValue}
                search
                searchData={view_data.form.search}
                onSearch={actions.onSearchMasterclass}
                onUpdateSearch={actions.onResetTableFilter}
            //add
            //onAdd={actions.onOpenAddModal}
            />
            <LoadingContainer
                loading={system.loading}
                sending={system.sending}
                success={system.success}
                error={system.error}
                onCloseSuccess={actions.onCloseSuccess}
                onCloseError={actions.onCloseError}
            >
                <div className={classes.content}>
                    <Grid container alignItems='stretch' spacing={4}>
                        {view_data.masterclasses.length > 0 ?
                            view_data.masterclasses.map((masterclass, key) => (
                                <Grid item xs={12} sm={6} lg={4} key={`masterclass-${key.toString()}`}>
                                    <MasterclassCard masterclass={masterclass} content={content} onClick={actions.onVisitUrl} />
                                </Grid>
                            ))
                            :
                            <Grid item xs={12}>
                                <div className={classes.noMasterclass}>
                                    <Grid container justifyContent='center' spacing={3}>
                                        <Grid item>
                                            <SVGIcon src={content.noMasterclass.icon} size={64} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography align='center' className={classes.noMasterclassTitle}>
                                                {content.noMasterclass.title}
                                            </Typography>
                                            <Typography align='center' className={classes.noMasterclassSubtitle}>
                                                {content.noMasterclass.subtitle}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        }
                    </Grid>
                    {view_data.masterclasses.length > 0 &&
                        <div className={classes.actions}>
                            <ActionBar total={view_data.total} tableFilter={view_data.tableFilter} onUpdateTableFilter={actions.onUpdateTableFilter} />
                        </div>
                    }
                </div>
            </LoadingContainer>
        </TitleContainer>
    )
}

const stylesCard = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        //height: "calc(100% * (4/3))",
        maxWidth: 400,
        borderRadius: 5,
        padding: 0,
        boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.07)',
        '&:hover': {
            //boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        }
    },
    cardActionArea: {
        position: 'relative',
        padding: 10,
        width: '100%',
        height: '100%',
        cursor: 'inherit',
        '& span': {
            '&:hover': {
                //backgroundColor: 'rgba(0,0,0,0.1)',
                //cursor: 'pointer'
            }
        }
    },
    media: {
        width: '100%',
        background: 'white',
        paddingTop: "56.25%",
        borderRadius: 2,
        display: 'block',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        border: '1px solid #EAEAEA'
    },
    mediaNoImage: {
        backgroundSize: '40%',
        backgroundRepeat: 'no-repeat'
    },
    cardContent: {
        padding: 0,
        paddingTop: 16,
        paddingBottom: 16,
        background: 'transparent',
        position: 'relative',
        '&:hover': {
            background: 'white'
        }
    },
    typeButton: {
        width: 'fit-content',
        borderRadius: 4,
        padding: '5px 20px',
        background: 'black',
        color: 'white',
        fontSize: 12,
        textTransform: 'uppercase'
    },
    statusContainer: {
        display: 'inline-flex',
        alignItems: 'center'
    },
    status: {
        fontSize: 14,
        fontWeight: 600,
        color: grey[800]
    },
    text: {
        fontSize: 14,
        fontWeight: 400,
        color: grey[500],
        '& span': {
            fontSize: 14,
            fontWeight: 600,
            color: grey[800],
        }
    },
    smallText: {
        fontSize: 12,
        color: grey[800],
        '& span': {
            fontWeight: 600,
            fontSize: 16,
        }
    },
    paidContent: {
        position: 'absolute',
        top: 0,
        right: 5,
        padding: 15,
    },
    paid: {
        fontSize: 24,
        fontWeight: 600,
        color: 'white'
    }
}))

const MasterclassCard = ({ masterclass, content, onClick }) => {

    const classes = stylesCard()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    const { title, masterclas_category, date, duration, masterclass_status_id,
        paid, url, web_image, mobile_image } = masterclass

    const _image = mobile ? mobile_image : web_image
    const isValid = isValidDocument(_image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${_image}` : content.noImage

    const masterclassStatus = catalogs.masterclass_statuses.find(masterclass => masterclass.id === masterclass_status_id)
    const _date = date ? onGetDateFormat(date, 'DD MMM YYYY') : null
    const _duration = duration ? moment.utc(duration * 1000) : null

    return (
        <Card className={classes.root}> {/*onClick={() => onClick(url)}>*/}
            <CardActionArea className={classes.cardActionArea}>
                <CardMedia
                    className={cx({
                        [classes.media]: true,
                        [classes.mediaNoImage]: !isValid
                    })}
                    image={selectedImage}
                />
                <CardContent className={classes.cardContent}>
                    <Grid container alignItems='center' spacing={2}>
                        <Grid item xs>
                            <div color='black' size='small'>
                                <Typography className={classes.typeButton}>
                                    {masterclas_category}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className={classes.statusContainer}>
                                <Typography className={classes.status}>
                                    {masterclassStatus.label}
                                </Typography>
                                <StopIcon style={{ width: 15, height: 15, color: masterclassStatus.color }} />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.text}>
                                <span>{content.data.title}:</span> {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.text}>
                                <span>{content.data.start_date}: </span>
                                {_date ? _date : '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.smallText} align='right'>
                                {_duration.format('HH') !== '00' &&
                                    <>
                                        <span> {_duration.format('HH')}</span> {content.time.hours}
                                    </>
                                }
                                {_duration.format('mm') !== '00' &&
                                    <>
                                        <span> {_duration.format('mm')}</span> {content.time.minutes}
                                    </>
                                }
                                {_duration.format('ss') !== '00' &&
                                    <>
                                        <span> {_duration.format('ss')}</span> {content.time.seconds}
                                    </>
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                {Boolean(paid) &&
                    <div className={classes.paidContent}>
                        <Typography className={classes.paid}>
                            $
                        </Typography>
                    </div>
                }
            </CardActionArea>
        </Card >
    )
}


const mapStateToProps = (state) => {
    return {
        is_auth: state.is_auth,
        user: state.user,
		search_params: state.search_params
    }
}

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateSearchParams: (search_params) => dispatch({ type: actionTypes.SYS_SET_SEARCH_PARAMS, search_params }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterclassesView)