import React from 'react'
import { Icon, TextField, withStyles } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { ruleValidation } from './customFunctions'
import AvatarPicture from '../Structure/DisplayData/AvatarPicture';
import { grey } from '@material-ui/core/colors';

const CssTextField = withStyles(theme => ({
  root: {
    //color: 'white',
    borderBottom: `0.5px solid #6f6c6c`,
    '& label': {
      width: '100%'
    },
    '& label.Mui-focused': {
      //color: theme.palette.secondary.main,
      //color: 'white'
    },
    '& .MuiFormLabel-root': {
      //color: '#6f6c6c', 
    },
    '& .MuiInput-underline:before': {
      borderBottom: `0.5px solid #6f6c6c`
    },
    '& .MuiInput-underline:after': {
      borderBottom: `0.5px solid ${theme.palette.secondary.main}`,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 12,
    },
    '& .MuiInputBase-input': {
      //color: 'white',
    }
  },
}), { withTheme: true })(TextField);

const LineAutocompleteForm = props => {

  const { data, onChange } = props

  const onInnerChange = (e, item) => {
    let temp = { ...data }
    if (item) {
      temp.value = item.value
      temp.isValid = true
    } else {
      temp.value = null
      temp.isValid = data.rules ? false : true
    }
    temp.isVisited = true
    onChange(temp)
  }

  const { config, value, image, icon, isValid, isVisited, isRequired, options } = data

  const isError = (isVisited && !isValid) || (isVisited && isRequired && !isValid)
  let interConfig = { ...config }
  if (!isError) interConfig.helperText = ''

  const _options = options ? options : []
  let selectedValue = null
  if (value !== null && value !== undefined) {
    const singleEl = options.find(el => el.value === value)
    selectedValue = singleEl ? singleEl : selectedValue
  }

  return (
    <div>
      <Autocomplete
        value={selectedValue}
        error={isError.toString()}
        onChange={onInnerChange}
       // onInputChange={onInputChange}
        renderInput={params =>
          <CssTextField
            {...params}
            error={isError} {...interConfig}
            label={interConfig.placeholder}
            helperText={interConfig.helperText}
          />
        }
        options={_options}
        getOptionLabel={option => option.label}
        renderOption={(option, state) =>
          <div style={{ display: 'inline-flex', alignItems: 'center' }}>
            {image &&
              <div style={{ marginRight: 10 }}>
                <AvatarPicture src={option.image} size={32} />
              </div>
            }
            {icon &&
              <Icon
                style={{
                  marginRight: 10,
                  fontSize: 12,
                  color: option.color ? option.color : grey[300],
                }}
              >
                {icon}
              </Icon>
            }
            {option.label}
          </div>
        }
      />
    </div>
  )
}

export default LineAutocompleteForm