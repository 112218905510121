import { useEffect, useRef, useState } from "react"
import axios from 'axios'
import { private_server } from "../../../../../../config"
import { isFormValid, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm, onSetValueInForm, updateAuthorizationHeader } from "../../../../../../shared/utility"
import { generic_errors } from "../../../../../../texts/esp/genericErrors"


const useAddBannerModal = ({ open, origin, language, content, onCompletedBanners }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))


    const [img_view, setImgView] = useState(1)
    const [text_color, setTextColor] = useState('black')
    const [text_color_hex, setTextColorHex] = useState('black')
    const [back_color, setBackColor] = useState('red')
    const [back_color_hex, setBackColorHex] = useState('red')
    const [enable, setEnable] = useState(false)

    const [data_image, setDataImage] = useState(null)
    const [file, setFile] = useState(null)
    const [crop_image, setCropImage] = useState(null)
    //MOBILE
    const [file_mobile, setFileMobile] = useState(null)
    const [crop_image_mobile, setCropImageMobile] = useState(null)

    const [upload_image, setUploadImage] = useState(false)


    useEffect(() => {
        if (!open) {
            actions.onInitModule()
        }
    }, [open])

    const actions = {
        onInitModule: async () => {
            setForm(JSON.parse(JSON.stringify(form_data)))
            let _form = onInitForm(form_data, null, content.form)
            setForm(_form)
            setDataImage(null)
            setFile(null)
            setFileMobile(null)
            setCropImage(null)
            setCropImageMobile(null)
            setEnable(false)
            setError(null)
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if (error) setError(null)
            setForm(temp)
        },
        onChangeImgView: (view) => {
            setImgView(view)
        },
        onChangeTextColor: (value) => {
            setTextColor(value)
            setTextColorHex(value.css.backgroundColor)
        },
        onChangeBackColor: (value) => {
            setBackColor(value)
            setBackColorHex(value.css.backgroundColor)
        },
        onChangeModal: (value) => {
            setUploadImage(value)
        },
        onChangeEnable: (event) => {
            setEnable(event.target.checked)
        },
        onUpdateDataImage: (data, _file, crop) => {
            setDataImage(data)
            if (img_view === 1) {
                setFile(_file)
                setCropImage(crop)
            } else {
                setFileMobile(_file)
                setCropImageMobile(crop)
            }
        },
        onSubmit: async () => {

            const errors = isFormValid(form)
            // Error handler
            if (errors && errors.length) {
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            // Clean data
            const data2send = onGetFormData(form)
            data2send.enabled = enable ? 1 : 0
            data2send.text_color = text_color_hex
            data2send.background_color = back_color_hex
            data2send.banner_type_id = 1
            console.log(data2send)

            console.log(data_image)

            try {
                setSending(true)
                updateAuthorizationHeader(private_server)
                const request = await private_server.post(`/banner`, data2send)
                console.log(request)
                const id = request.data.data.banner.id_banner
                if (data_image && file) {
                    const image_req = await private_server.post(`/banner/${id}/image`, data_image)
                    console.log(image_req)
                    const options = { headers: { 'Content-Type': file.type } }
                    delete axios.defaults.headers.common["Authorization"]
                    await axios.put(image_req.data.data.url, file, options);
                }
                onCompletedBanners()
                setError(null)
                setSending(false)

            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error)
                if (_error.details) {
                    console.log('Actualizar datos')
                    const _error_keys = Object.keys(_error.details).map(item => item)
                    const _form = onSetErrorsToForm(form, _error_keys)
                    console.log(_form)
                    setForm(_form)
                }
                setError(_error ? _error.message : null)
            }

        }
    }

    const system = { loading, sending, error, }
    const view_data_desktop = { text_color, text_color_hex, back_color, back_color_hex, enable, file, crop_image }
    const view_data_mobile = {
        text_color, text_color_hex, back_color, back_color_hex, enable,
        file: file_mobile,
        crop_image: crop_image_mobile
    }

    return { system, form, actions, img_view, view_data_desktop, view_data_mobile, upload_image }
}


export default useAddBannerModal

const form_data = {
    date: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'date',
            type: 'date',
            fullWidth: true,
        },
        rules: { type: 'date' }
    },
    title: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'title',
            type: 'text',
            fullWidth: true,
        },
        rules: { type: 'distance', min: 1, max: 50 }
    },
    subtitle: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'subtitle',
            type: 'text',
            fullWidth: true,
        },
        rules: { type: 'distance' }
    },
    description: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 4
        },
        rules: { type: 'distance' }
    },
    web_link: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'web_link',
            type: 'text',
            fullWidth: true,
        },
        rules: { type: 'distance' }
    },
    app_link: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'app_link',
            type: 'text',
            fullWidth: true,
        },
        rules: { type: 'distance' }
    },

}