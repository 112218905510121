import React, { useEffect, useState } from "react"
import { private_server } from "../../config"
import { isFormValid, onGetErrorMessage, onGetSelectedFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../shared/utility"
import queryString from 'query-string'
import { generic_errors } from "../../texts/eng/genericErrors"
import catalogs from "../../texts/eng/catalogs"

const useSponsorsView = ({ language, history, is_auth, content }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const [total, setTotal] = useState(0)
    const [search, setSearch] = useState(null)
    const [filterValue, setFilterValue] = useState(-1)
    const [tableFilter, setTableFilter] = useState({ limit: 10, offset: 0, order_by: 'id_sponsor', order: 0 })
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    //DATA
    const [sponsors, setSponsors] = useState([])
    const [sponsor, setSponsor] = useState(null)
    const [sponsorID, setSponsorID] = useState(null)
    const [add_modal, setAddModal] = useState(false)
    const [edit_modal, setEditModal] = useState(false)

    useEffect(() => {
        if (is_auth)
            actions.onInitModule()
    }, [is_auth])

    useEffect(() => {
        if (is_auth)
            actions.onFilterSponsors()
    }, [is_auth, tableFilter])

    useEffect(() => {
        if (is_auth)
            actions.onResetTableFilter()
    }, [is_auth, filterValue])

    const actions = {
        onInitModule: () => {
            let _form = JSON.parse(JSON.stringify(formData))
            _form = onInitForm(_form, null, content.form)
            setForm(_form)
        },
        onResetTableFilter: () => setTableFilter({ limit: 10, offset: 0, order_by: 'id_sponsor', order: 0 }),
        onCloseError: () => setError(null),
        onUpdateTableFilter: (data) => setTableFilter(data),
        onUpdateFilterValue: (id) => setFilterValue(id),
        onOpenAddModal: () => {
            let _form = JSON.parse(JSON.stringify(formData))
            _form = onInitForm(_form, null, content.form)
            setForm(_form)
            setAddModal(true)
        },
        onCloseAddModal: () => setAddModal(false),
        onChange: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if (error) setError(null)
            setForm(temp)
        },
        onSearchSponsor: async (data) => {
            let temp = { ...form }
            temp.search = { ...data }
            if (error) setError(null)
            setForm(temp)
            setSearch(data.value)
        },
        onFilterSponsors: async () => {
            try {
                setSending(true)
                let filter = { AND: [] }
                if (filterValue > -1) filter.AND.push({ field: "enabled", operator: "=", value: filterValue })
                if (search && search.length > 0) filter.AND.push({ field: "name", operator: "LIKE", value: `%%${search}%%` })
                let _filter = encodeURIComponent(JSON.stringify(filter))
                let params = filter.AND.length > 0 ? `?${queryString.stringify(tableFilter)}&filter=${_filter}` : `?${queryString.stringify(tableFilter)}`
                const _sponsors = await private_server.get(`sponsor/all${params}`)
                setSponsors([..._sponsors.data.data.sponsors])
                setTotal(_sponsors.data.data.count)
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onSelectSponsor: (_sponsor) => {
            setSponsor(_sponsor)
            setSponsorID(_sponsor.id_sponsor)
            const _form = onInitForm(form, _sponsor, content.form)
            setForm(_form)
            setEditModal(true)
        },
        onCloseEditModal: () => setEditModal(false),
        onAddSponsor: async () => {
            try {
                setSending(true)

                const errors = isFormValid(form)

                // Error handler
                if (errors && errors.length) {
                    const _form = onSetErrorsToForm(form, errors)
                    setForm(_form)
                    setError(generic_errors[language].INCOMPLETE_FORM)
                    setSending(false)
                    return
                }

                const data2send = onGetSelectedFormData(form, sponsors)

                await private_server.post(`sponsor`, data2send)

                actions.onResetTableFilter()

                setError(null)
                setAddModal(false)
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onUpdateSponsor: async () => {
            try {
                setSending(true)

                const errors = isFormValid(form)

                // Error handler
                if (errors && errors.length) {
                    const _form = onSetErrorsToForm(form, errors)
                    setForm(_form)
                    setError(generic_errors[language].INCOMPLETE_FORM)
                    setSending(false)
                    return
                }

                const data2send = onGetSelectedFormData(form, sponsors)

                await private_server.patch(`sponsor/${sponsorID}`, data2send)
                setEditModal(false)
                setError(null)
                setSending(false)
                actions.onResetTableFilter()
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
    }

    const system = { loading, sending, error }
    const view_data = { total, filterValue, sponsors, form, tableFilter, add_modal, edit_modal }

    return { system, view_data, actions }
}

export default useSponsorsView

const formData = {
    search: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'search',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 4
        },
        rules: {
            type: 'none'
        }
    },
    enabled: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options: [...catalogs.sponsor_statuses],
        config: {
            id: 'enabled',
            type: 'select',
            fullWidth: true,
        },
        rules: { type: 'select' }
    },
}