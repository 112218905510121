import React, { useEffect } from 'react'
import { Grid, makeStyles, Switch, Typography } from '@material-ui/core'
import CustomDropzone from './CustomDropzone'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import SimpleModal from '../../../../components/Modals/SimpleModal'
import RoundedButton from '../../../../components/Actions/RoundedButton'
import usePictureModal from './usePictureModal'

const PictureModal = ({ id, open, content, onClose, onUpdateArtist }) => {

    const classes = useStyles()
    //mobile
    const { loading, src, actions } = usePictureModal({ id, onUpdateArtist })

    useEffect(() => {
        if (open) actions.onResetComponent()
    }, [open])

    return (
        <SimpleModal open={open} onClose={onClose}>
            <Grid container alignItems='center' spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h6'>{content.title}</Typography>
                </Grid>
                {/*  <Grid item xs></Grid>
                <Grid item>
                    <Typography variant='body2' >{content.mobile}</Typography>
                </Grid>
                <Grid item>
                    <Switch value={mobile} onChange={actions.onUpdateMobile} />
              </Grid>*/}
                <Grid item xs={12}>
                    <CustomDropzone onDropFile={actions.onDropFile} placeholder={content.placeholder} />
                </Grid>
                {src ? (
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant='subtitle1' >{content.image_selected}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.react_drop}>
                                    <img src={src} className={classes.image} />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <Grid container justifyContent='center'>
                        <Grid item>
                            <RoundedButton onClick={actions.onSubmit} loading={loading}>{content.save_button}</RoundedButton>
                        </Grid>
                    </Grid>
                    {/*<ActionModalBar error={error} loading={loading} btnLabel='Guardar' onDelete={onDelete} onSubmit={onSubmit}/>*/}
                </Grid>

            </Grid>
        </SimpleModal>
    )
}

const useStyles = makeStyles(theme => ({
    react_drop: {
        width: '100%',
        height: 300,
        // overflowY: 'auto'
    },
    react_drop_container: {

    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    }
}))

export default PictureModal