import { amber, blue, green, grey, orange, purple, red } from "@material-ui/core/colors";

// Social media icons
import FB from '../../assets/icons/facebookNoFill.svg'
import IG from '../../assets/icons/instagramNoFill.svg'
import TW from '../../assets/icons/twitterNoFill.svg'
import TT from '../../assets/icons/tiktok.svg'
import PT from '../../assets/icons/pinterest.svg'
import YT from '../../assets/icons/youtube.svg'
import WE from '../../assets/icons/emblemweb.svg'
import OP from '../../assets/icons/openseaNoFill.svg'
import VM from '../../assets/icons/vimeo.svg'

export const catalogs = {
    social_medias: [
        { id: 'facebook', label: 'Facebook', placeholder: 'Facebook', icon: FB },
        { id: 'twitter', label: 'Twitter', placeholder: 'Twitter', icon: TW },
        { id: 'instagram', label: 'Instagram', placeholder: 'Instagram', icon: IG },
        { id: 'tiktok', label: 'Tiktok', placeholder: 'Tiktok', icon: TT },
        { id: 'pinterest', label: 'Pinterest', placeholder: 'Pinterest', icon: PT },
        { id: 'youtube', label: 'Youtube', placeholder: 'Youtube', icon: YT },
        { id: 'vimeo', label: 'Vimeo', placeholder: 'Vimeo', icon: VM },
        { id: 'opensea', label: 'Opensea', placeholder: 'Opensea', icon: OP },
        { id: 'web', label: 'Web', placeholder: 'Web', icon: WE },
    ],
    user_genders: [
        { id: 1, value: 1, label: "Male" },
        { id: 2, value: 2, label: "Female" },
        { id: 3, value: 3, label: "Other" },
        { id: 4, value: 4, label: "I'd rather not say" }
    ],
    user_types: [
        { id: 1, value: 1, label: "Administrator", color: green[500] },
        { id: 2, value: 2, label: "Editor", color: red[700] },
        { id: 3, value: 3, label: "Artist", color: purple[500] },
        { id: 4, value: 4, label: "Gallery", color: blue[500] },
        { id: 5, value: 5, label: "Collector", color: orange[500] },
        { id: 6, value: 6, label: "Art Lover", color: '#BD8B40' }
    ],
    artist_levels: [
        { id: 1, value: 1, label: "Emergent" },
        { id: 2, value: 2, label: "Rising" },
        { id: 3, value: 3, label: "Consolidated" },
        { id: 4, value: 4, label: "Exponent" }
    ],
    artwork_statuses: [
        { id: 1, value: 1, label: 'Created', color: orange[500] },
        { id: 2, value: 2, label: 'Approved', color: green[500] },
        { id: 3, value: 3, label: 'Rejected', color: red[700] },
        { id: 4, value: 4, label: 'Published', color: purple[500] },
        { id: 5, value: 5, label: 'Archived', color: blue[500] },
    ],
    sponsorship: [
        { id: 0, value: 0, label: "No", color: red[500] },
        { id: 1, value: 1, label: "Yes", color: green[500] },
    ],
    sponsor_statuses: [
        { id: 0, value: 0, label: "Inactive", color: red[500] },
        { id: 1, value: 1, label: "Active", color: green[500] },
    ],
    payment_statuses: [
        { id: 1, value: 1, label: "Pending", color: orange[500] },
        { id: 2, value: 2, label: "Opened", color: green[500] },
        { id: 3, value: 3, label: "Closed", color: red[500] },
        { id: 4, value: 4, label: "Acepted", color: purple[500] },
        { id: 5, value: 5, label: "Paused", color: blue[500] },
        { id: 6, value: 6, label: "Archived", color: grey[500] }
    ],
    application_statuses: [
        { id: 1, value: 1, label: "Pending", color: orange[500] },
        { id: 2, value: 2, label: "Opened", color: green[500] },
        { id: 3, value: 3, label: "Closed", color: red[500] },
        { id: 4, value: 4, label: "Acepted", color: purple[500] },
        { id: 5, value: 5, label: "Paused", color: blue[500] },
        { id: 6, value: 6, label: "Archived", color: grey[500] }
    ],
    artist_statuses: [
        { id: 1, value: 1, label: "Applied", color: blue[500] },
        { id: 2, value: 2, label: "Accepted", color: green[500] },
        { id: 3, value: 3, label: "Published", color: orange[500] },
        { id: 4, value: 4, label: "Rejected", color: red[500] },
        { id: 5, value: 5, label: "Request More Info", color: purple[500] }
    ],
    artist_applications_statuses: [
        { id: 1, value: 1, label: "Applied", color: blue[500] },
        { id: 5, value: 5, label: "Request More Info", color: purple[500] }
    ],
    exhibition_statuses: [
        { id: 1, value: 1, label: "Created", color: orange[500] },
        { id: 2, value: 2, label: "Opened", color: green[500] },
        { id: 3, value: 3, label: "Closed", color: red[500] },
        { id: 4, value: 4, label: "Published", color: purple[500] },
        { id: 5, value: 5, label: "Paused", color: blue[500] },
        { id: 6, value: 6, label: "Archived", color: grey[500] }
    ],
    magazine_statuses: [
        { id: 1, value: 1, label: "Created", color: orange[500] },
        { id: 2, value: 2, label: "Opened", color: green[500] },
        { id: 3, value: 3, label: "Closed", color: red[500] },
        { id: 4, value: 4, label: "Published", color: purple[500] },
        { id: 5, value: 5, label: "Paused", color: blue[500] },
        { id: 6, value: 6, label: "Archived", color: grey[500] }
    ],
    masterclass_statuses: [
        { id: 1, value: 1, label: "Created", color: orange[500] },
        { id: 2, value: 2, label: "Approved", color: green[500] },
        { id: 3, value: 3, label: "Rejected", color: red[500] },
        { id: 4, value: 4, label: "Published", color: purple[500] },
        { id: 5, value: 5, label: "Archived", color: blue[500] }
    ],
    exhibition_types: [
        { id: 1, value: 1, label: "Individual" },
        { id: 2, value: 2, label: "Private" },
        { id: 3, value: 3, label: "Open Call" }
    ],
    magazine_types: [
        { id: 1, value: 1, label: "Individual" },
        { id: 2, value: 2, label: "Collective" },
    ],
    countries: [
        { id: 1, value: 1, label: "Mexico", abbreviation: "MEX", mobile_code: "52" },
        { id: 2, value: 2, label: "Albania", abbreviation: "ALB", mobile_code: "355" },
        { id: 3, value: 3, label: "Algeria", abbreviation: "DZA", mobile_code: "213" },
        { id: 4, value: 4, label: "American Samoa", abbreviation: "ASM", mobile_code: "1" },
        { id: 5, value: 5, label: "Andorra", abbreviation: "AND", mobile_code: "376" },
        { id: 6, value: 6, label: "Angola", abbreviation: "AGO", mobile_code: "244" },
        { id: 7, value: 7, label: "Anguilla", abbreviation: "AIA", mobile_code: "1" },
        { id: 8, value: 8, label: "Antigua and Barbuda", abbreviation: "ATG", mobile_code: "1" },
        { id: 9, value: 9, label: "Argentina", abbreviation: "ARG", mobile_code: "54" },
        { id: 10, value: 10, label: "Armenia", abbreviation: "ARM", mobile_code: "374" },
        { id: 11, value: 11, label: "Aruba", abbreviation: "ABW", mobile_code: "297" },
        { id: 12, value: 12, label: "Australia", abbreviation: "AUS", mobile_code: "61" },
        { id: 13, value: 13, label: "Australian External Territories", abbreviation: "", mobile_code: "672" },
        { id: 14, value: 14, label: "Austria", abbreviation: "AUT", mobile_code: "43" },
        { id: 15, value: 15, label: "Azerbaijan", abbreviation: "AZE", mobile_code: "994" },
        { id: 16, value: 16, label: "Bahamas", abbreviation: "BHS", mobile_code: "1" },
        { id: 17, value: 17, label: "Bahrain", abbreviation: "BHR", mobile_code: "973" },
        { id: 18, value: 18, label: "Bangladesh", abbreviation: "BGD", mobile_code: "880" },
        { id: 19, value: 19, label: "Barbados", abbreviation: "BRB", mobile_code: "1" },
        { id: 20, value: 20, label: "Belarus", abbreviation: "BLR", mobile_code: "375" },
        { id: 21, value: 21, label: "Belgium", abbreviation: "BEL", mobile_code: "32" },
        { id: 22, value: 22, label: "Belize", abbreviation: "BLZ", mobile_code: "501" },
        { id: 23, value: 23, label: "Benin", abbreviation: "BEN", mobile_code: "229" },
        { id: 24, value: 24, label: "Bermuda", abbreviation: "BMU", mobile_code: "1" },
        { id: 25, value: 25, label: "Bhutan", abbreviation: "BTN", mobile_code: "975" },
        { id: 26, value: 26, label: "Bolivia (Plurinational State of)", abbreviation: "", mobile_code: "591" },
        { id: 27, value: 27, label: "Bonaire, Sint Eustatius and Saba", abbreviation: "", mobile_code: "599" },
        { id: 28, value: 28, label: "Bosnia and Herzegovina", abbreviation: "BIH", mobile_code: "387" },
        { id: 29, value: 29, label: "Botswana", abbreviation: "BWA", mobile_code: "267" },
        { id: 30, value: 30, label: "Brazil", abbreviation: "BRA", mobile_code: "55" },
        { id: 31, value: 31, label: "British Virgin Islands", abbreviation: "VGB", mobile_code: "1" },
        { id: 32, value: 32, label: "Brunei Darussalam", abbreviation: "", mobile_code: "673" },
        { id: 33, value: 33, label: "Bulgaria", abbreviation: "BGR", mobile_code: "359" },
        { id: 34, value: 34, label: "Burkina Faso", abbreviation: "BFA", mobile_code: "226" },
        { id: 35, value: 35, label: "Burundi", abbreviation: "BDI", mobile_code: "257" },
        { id: 36, value: 36, label: "Cambodia", abbreviation: "KHM", mobile_code: "855" },
        { id: 37, value: 37, label: "Cameroon", abbreviation: "CMR", mobile_code: "237" },
        { id: 38, value: 38, label: "Canada", abbreviation: "CAN", mobile_code: "1" },
        { id: 39, value: 39, label: "Cape Verde", abbreviation: "CPV", mobile_code: "238" },
        { id: 40, value: 40, label: "Cayman Islands", abbreviation: "CYM", mobile_code: "1" },
        { id: 41, value: 41, label: "Central African Rep.", abbreviation: "", mobile_code: "236" },
        { id: 42, value: 42, label: "Chad", abbreviation: "TCD", mobile_code: "235" },
        { id: 43, value: 43, label: "Chile", abbreviation: "CHL", mobile_code: "56" },
        { id: 44, value: 44, label: "China", abbreviation: "CHN", mobile_code: "86" },
        { id: 45, value: 45, label: "Colombia", abbreviation: "COL", mobile_code: "57" },
        { id: 46, value: 46, label: "Comoros", abbreviation: "COM", mobile_code: "269" },
        { id: 47, value: 47, label: "Congo", abbreviation: "", mobile_code: "242" },
        { id: 48, value: 48, label: "Cook Islands", abbreviation: "COK", mobile_code: "682" },
        { id: 49, value: 49, label: "Costa Rica", abbreviation: "CRI", mobile_code: "506" },
        { id: 50, value: 50, label: "Côte d'Ivoire", abbreviation: "", mobile_code: "225" },
        { id: 51, value: 51, label: "Croatia", abbreviation: "HRV", mobile_code: "385" },
        { id: 52, value: 52, label: "Cuba", abbreviation: "CUB", mobile_code: "53" },
        { id: 53, value: 53, label: "Curaçao", abbreviation: "CUW", mobile_code: "599" },
        { id: 54, value: 54, label: "Cyprus", abbreviation: "CYP", mobile_code: "357" },
        { id: 55, value: 55, label: "Czech Rep.", abbreviation: "", mobile_code: "420" },
        { id: 56, value: 56, label: "Dem. People's Rep. of Korea", abbreviation: "", mobile_code: "850" },
        { id: 57, value: 57, label: "Dem. Rep. of the Congo", abbreviation: "", mobile_code: "243" },
        { id: 58, value: 58, label: "Denmark", abbreviation: "DNK", mobile_code: "45" },
        { id: 59, value: 59, label: "Diego Garcia", abbreviation: "", mobile_code: "246" },
        { id: 60, value: 60, label: "Djibouti", abbreviation: "DJI", mobile_code: "253" },
        { id: 61, value: 61, label: "Dominica", abbreviation: "DMA", mobile_code: "1" },
        { id: 62, value: 62, label: "Dominican Rep.", abbreviation: "", mobile_code: "1" },
        { id: 63, value: 63, label: "Ecuador", abbreviation: "ECU", mobile_code: "593" },
        { id: 64, value: 64, label: "Egypt", abbreviation: "EGY", mobile_code: "20" },
        { id: 65, value: 65, label: "El Salvador", abbreviation: "SLV", mobile_code: "503" },
        { id: 66, value: 66, label: "Equatorial Guinea", abbreviation: "GNQ", mobile_code: "240" },
        { id: 67, value: 67, label: "Eritrea", abbreviation: "ERI", mobile_code: "291" },
        { id: 68, value: 68, label: "Estonia", abbreviation: "EST", mobile_code: "372" },
        { id: 69, value: 69, label: "Ethiopia", abbreviation: "ETH", mobile_code: "251" },
        { id: 70, value: 70, label: "Falkland Islands (Malvinas)", abbreviation: "", mobile_code: "500" },
        { id: 71, value: 71, label: "Faroe Islands", abbreviation: "FRO", mobile_code: "298" },
        { id: 72, value: 72, label: "Fiji", abbreviation: "FJI", mobile_code: "679" },
        { id: 73, value: 73, label: "Finland", abbreviation: "FIN", mobile_code: "358" },
        { id: 74, value: 74, label: "France", abbreviation: "FRA", mobile_code: "33" },
        { id: 75, value: 75, label: "French Dep. and Territories in the Indian Ocean", abbreviation: "", mobile_code: "262" },
        { id: 76, value: 76, label: "French Guiana", abbreviation: "GUF", mobile_code: "594" },
        { id: 77, value: 77, label: "French Polynesia", abbreviation: "PYF", mobile_code: "689" },
        { id: 78, value: 78, label: "Gabon", abbreviation: "GAB", mobile_code: "241" },
        { id: 79, value: 79, label: "Gambia", abbreviation: "GMB", mobile_code: "220" },
        { id: 80, value: 80, label: "Georgia", abbreviation: "GEO", mobile_code: "995" },
        { id: 81, value: 81, label: "Germany", abbreviation: "DEU", mobile_code: "49" },
        { id: 82, value: 82, label: "Ghana", abbreviation: "GHA", mobile_code: "233" },
        { id: 83, value: 83, label: "Gibraltar", abbreviation: "GIB", mobile_code: "350" },
        { id: 84, value: 84, label: "Global Mobile Satellite System (GMSS), shared", abbreviation: "", mobile_code: "881" },
        { id: 85, value: 85, label: "Greece", abbreviation: "GRC", mobile_code: "30" },
        { id: 86, value: 86, label: "Greenland", abbreviation: "GRL", mobile_code: "299" },
        { id: 87, value: 87, label: "Grenada", abbreviation: "GRD", mobile_code: "1" },
        { id: 88, value: 88, label: "Group of countries, shared code", abbreviation: "", mobile_code: "388" },
        { id: 89, value: 89, label: "Guadeloupe", abbreviation: "GLP", mobile_code: "590" },
        { id: 90, value: 90, label: "Guam", abbreviation: "GUM", mobile_code: "1" },
        { id: 91, value: 91, label: "Guatemala", abbreviation: "GTM", mobile_code: "502" },
        { id: 92, value: 92, label: "Guinea", abbreviation: "GIN", mobile_code: "224" },
        { id: 93, value: 93, label: "Guinea-Bissau", abbreviation: "GNB", mobile_code: "245" },
        { id: 94, value: 94, label: "Guyana", abbreviation: "GUY", mobile_code: "592" },
        { id: 95, value: 95, label: "Haiti", abbreviation: "HTI", mobile_code: "509" },
        { id: 96, value: 96, label: "Honduras", abbreviation: "HND", mobile_code: "504" },
        { id: 97, value: 97, label: "Hong Kong, China", abbreviation: "", mobile_code: "852" },
        { id: 98, value: 98, label: "Hungary", abbreviation: "HUN", mobile_code: "36" },
        { id: 99, value: 99, label: "Iceland", abbreviation: "ISL", mobile_code: "354" },
        { id: 100, value: 100, label: "India", abbreviation: "IND", mobile_code: "91" },
        { id: 101, value: 101, label: "Indonesia", abbreviation: "IDN", mobile_code: "62" },
        { id: 102, value: 102, label: "Iran (Islamic Republic of)", abbreviation: "", mobile_code: "98" },
        { id: 103, value: 103, label: "Iraq", abbreviation: "IRQ", mobile_code: "964" },
        { id: 104, value: 104, label: "Ireland", abbreviation: "IRL", mobile_code: "353" },
        { id: 105, value: 105, label: "Israel", abbreviation: "ISR", mobile_code: "972" },
        { id: 106, value: 106, label: "Italy", abbreviation: "ITA", mobile_code: "39" },
        { id: 107, value: 107, label: "Jamaica", abbreviation: "JAM", mobile_code: "1" },
        { id: 108, value: 108, label: "Japan", abbreviation: "JPN", mobile_code: "81" },
        { id: 109, value: 109, label: "Jordan", abbreviation: "JOR", mobile_code: "962" },
        { id: 110, value: 110, label: "Kazakhstan", abbreviation: "KAZ", mobile_code: "7" },
        { id: 111, value: 111, label: "Kenya", abbreviation: "KEN", mobile_code: "254" },
        { id: 112, value: 112, label: "Kiribati", abbreviation: "KIR", mobile_code: "686" },
        { id: 113, value: 113, label: "Korea (Rep. of)", abbreviation: "", mobile_code: "82" },
        { id: 114, value: 114, label: "Kuwait", abbreviation: "KWT", mobile_code: "965" },
        { id: 115, value: 115, label: "Kyrgyzstan", abbreviation: "KGZ", mobile_code: "996" },
        { id: 116, value: 116, label: "Lao P.D.R.", abbreviation: "", mobile_code: "856" },
        { id: 117, value: 117, label: "Latvia", abbreviation: "LVA", mobile_code: "371" },
        { id: 118, value: 118, label: "Lebanon", abbreviation: "LBN", mobile_code: "961" },
        { id: 119, value: 119, label: "Lesotho", abbreviation: "LSO", mobile_code: "266" },
        { id: 120, value: 120, label: "Liberia", abbreviation: "LBR", mobile_code: "231" },
        { id: 121, value: 121, label: "Libya", abbreviation: "LBY", mobile_code: "218" },
        { id: 122, value: 122, label: "Liechtenstein", abbreviation: "LIE", mobile_code: "423" },
        { id: 123, value: 123, label: "Lithuania", abbreviation: "LTU", mobile_code: "370" },
        { id: 124, value: 124, label: "Luxembourg", abbreviation: "LUX", mobile_code: "352" },
        { id: 125, value: 125, label: "Macao, China", abbreviation: "", mobile_code: "853" },
        { id: 126, value: 126, label: "Madagascar", abbreviation: "MDG", mobile_code: "261" },
        { id: 127, value: 127, label: "Malawi", abbreviation: "MWI", mobile_code: "265" },
        { id: 128, value: 128, label: "Malaysia", abbreviation: "MYS", mobile_code: "60" },
        { id: 129, value: 129, label: "Maldives", abbreviation: "MDV", mobile_code: "960" },
        { id: 130, value: 130, label: "Mali", abbreviation: "MLI", mobile_code: "223" },
        { id: 131, value: 131, label: "Malta", abbreviation: "MLT", mobile_code: "356" },
        { id: 132, value: 132, label: "Marshall Islands", abbreviation: "MHL", mobile_code: "692" },
        { id: 133, value: 133, label: "Martinique", abbreviation: "MTQ", mobile_code: "596" },
        { id: 134, value: 134, label: "Mauritania", abbreviation: "MRT", mobile_code: "222" },
        { id: 135, value: 135, label: "Mauritius", abbreviation: "MUS", mobile_code: "230" },
        { id: 137, value: 137, label: "Micronesia", abbreviation: "FSM", mobile_code: "691" },
        { id: 138, value: 138, label: "Moldova (Republic of)", abbreviation: "", mobile_code: "373" },
        { id: 139, value: 139, label: "Monaco", abbreviation: "MCO", mobile_code: "377" },
        { id: 140, value: 140, label: "Mongolia", abbreviation: "MNG", mobile_code: "976" },
        { id: 141, value: 141, label: "Montenegro", abbreviation: "MNE", mobile_code: "382" },
        { id: 142, value: 142, label: "Montserrat", abbreviation: "MSR", mobile_code: "1" },
        { id: 143, value: 143, label: "Morocco", abbreviation: "MAR", mobile_code: "212" },
        { id: 144, value: 144, label: "Mozambique", abbreviation: "MOZ", mobile_code: "258" },
        { id: 145, value: 145, label: "Myanmar", abbreviation: "MMR", mobile_code: "95" },
        { id: 146, value: 146, label: "Namibia", abbreviation: "NAM", mobile_code: "264" },
        { id: 147, value: 147, label: "Nauru", abbreviation: "NRU", mobile_code: "674" },
        { id: 148, value: 148, label: "Nepal", abbreviation: "NPL", mobile_code: "977" },
        { id: 149, value: 149, label: "Netherlands", abbreviation: "NLD", mobile_code: "31" },
        { id: 150, value: 150, label: "New Caledonia", abbreviation: "NCL", mobile_code: "687" },
        { id: 151, value: 151, label: "New Zealand", abbreviation: "NZL", mobile_code: "64" },
        { id: 152, value: 152, label: "Nicaragua", abbreviation: "NIC", mobile_code: "505" },
        { id: 153, value: 153, label: "Niger", abbreviation: "NER", mobile_code: "227" },
        { id: 154, value: 154, label: "Nigeria", abbreviation: "NGA", mobile_code: "234" },
        { id: 155, value: 155, label: "Niue", abbreviation: "NIU", mobile_code: "683" },
        { id: 156, value: 156, label: "Northern Marianas", abbreviation: "", mobile_code: "1" },
        { id: 157, value: 157, label: "Norway", abbreviation: "NOR", mobile_code: "47" },
        { id: 158, value: 158, label: "Oman", abbreviation: "OMN", mobile_code: "968" },
        { id: 159, value: 159, label: "Pakistan", abbreviation: "PAK", mobile_code: "92" },
        { id: 160, value: 160, label: "Palau", abbreviation: "PLW", mobile_code: "680" },
        { id: 161, value: 161, label: "Panama", abbreviation: "PAN", mobile_code: "507" },
        { id: 162, value: 162, label: "Papua New Guinea", abbreviation: "PNG", mobile_code: "675" },
        { id: 163, value: 163, label: "Paraguay", abbreviation: "PRY", mobile_code: "595" },
        { id: 164, value: 164, label: "Peru", abbreviation: "PER", mobile_code: "51" },
        { id: 165, value: 165, label: "Philippines", abbreviation: "PHL", mobile_code: "63" },
        { id: 166, value: 166, label: "Poland", abbreviation: "POL", mobile_code: "48" },
        { id: 167, value: 167, label: "Portugal", abbreviation: "PRT", mobile_code: "351" },
        { id: 168, value: 168, label: "Puerto Rico", abbreviation: "PRI", mobile_code: "1" },
        { id: 169, value: 169, label: "Qatar", abbreviation: "QAT", mobile_code: "974" },
        { id: 170, value: 170, label: "Romania", abbreviation: "ROU", mobile_code: "40" },
        { id: 171, value: 171, label: "Russian Federation", abbreviation: "", mobile_code: "7" },
        { id: 172, value: 172, label: "Rwanda", abbreviation: "RWA", mobile_code: "250" },
        { id: 173, value: 173, label: "Saint Helena, Ascension and Tristan da Cunha", abbreviation: "", mobile_code: "247" },
        { id: 174, value: 174, label: "Saint Helena, Ascension and Tristan da Cunha", abbreviation: "", mobile_code: "290" },
        { id: 175, value: 175, label: "Saint Kitts and Nevis", abbreviation: "KNA", mobile_code: "1" },
        { id: 176, value: 176, label: "Saint Lucia", abbreviation: "LCA", mobile_code: "1" },
        { id: 177, value: 177, label: "Saint Pierre and Miquelon", abbreviation: "SPM", mobile_code: "508" },
        { id: 178, value: 178, label: "Saint Vincent and the Grenadines", abbreviation: "VCT", mobile_code: "1" },
        { id: 179, value: 179, label: "Samoa", abbreviation: "WSM", mobile_code: "685" },
        { id: 180, value: 180, label: "San Marino", abbreviation: "SMR", mobile_code: "378" },
        { id: 181, value: 181, label: "Sao Tome and Principe", abbreviation: "STP", mobile_code: "239" },
        { id: 182, value: 182, label: "Saudi Arabia", abbreviation: "SAU", mobile_code: "966" },
        { id: 183, value: 183, label: "Senegal", abbreviation: "SEN", mobile_code: "221" },
        { id: 184, value: 184, label: "Serbia", abbreviation: "SRB", mobile_code: "381" },
        { id: 185, value: 185, label: "Seychelles", abbreviation: "SYC", mobile_code: "248" },
        { id: 186, value: 186, label: "Sierra Leone", abbreviation: "SLE", mobile_code: "232" },
        { id: 187, value: 187, label: "Singapore", abbreviation: "SGP", mobile_code: "65" },
        { id: 188, value: 188, label: "Sint Maarten (Dutch part)", abbreviation: "", mobile_code: "1" },
        { id: 189, value: 189, label: "Slovakia", abbreviation: "SVK", mobile_code: "421" },
        { id: 190, value: 190, label: "Slovenia", abbreviation: "SVN", mobile_code: "386" },
        { id: 191, value: 191, label: "Solomon Islands", abbreviation: "SLB", mobile_code: "677" },
        { id: 192, value: 192, label: "Somalia", abbreviation: "SOM", mobile_code: "252" },
        { id: 193, value: 193, label: "South Africa", abbreviation: "ZAF", mobile_code: "27" },
        { id: 194, value: 194, label: "South Sudan", abbreviation: "SSD", mobile_code: "211" },
        { id: 195, value: 195, label: "Spain", abbreviation: "ESP", mobile_code: "34" },
        { id: 196, value: 196, label: "Sri Lanka", abbreviation: "LKA", mobile_code: "94" },
        { id: 197, value: 197, label: "Sudan", abbreviation: "SDN", mobile_code: "249" },
        { id: 198, value: 198, label: "Suriname", abbreviation: "", mobile_code: "597" },
        { id: 199, value: 199, label: "Swaziland", abbreviation: "SWZ", mobile_code: "268" },
        { id: 200, value: 200, label: "Sweden", abbreviation: "SWE", mobile_code: "46" },
        { id: 201, value: 201, label: "Switzerland", abbreviation: "CHE", mobile_code: "41" },
        { id: 202, value: 202, label: "Syrian Arab Republic", abbreviation: "", mobile_code: "963" },
        { id: 203, value: 203, label: "Taiwan, China", abbreviation: "", mobile_code: "886" },
        { id: 204, value: 204, label: "Tajikistan", abbreviation: "TJK", mobile_code: "992" },
        { id: 205, value: 205, label: "Tanzania", abbreviation: "TZA", mobile_code: "255" },
        { id: 206, value: 206, label: "Thailand", abbreviation: "THA", mobile_code: "66" },
        { id: 207, value: 207, label: "The Former Yugoslav Republic of Macedonia", abbreviation: "", mobile_code: "389" },
        { id: 208, value: 208, label: "Timor-Leste", abbreviation: "", mobile_code: "670" },
        { id: 209, value: 209, label: "Togo", abbreviation: "TGO", mobile_code: "228" },
        { id: 210, value: 210, label: "Tokelau", abbreviation: "TKL", mobile_code: "690" },
        { id: 211, value: 211, label: "Tonga", abbreviation: "TON", mobile_code: "676" },
        { id: 212, value: 212, label: "Trinidad and Tobago", abbreviation: "TTO", mobile_code: "1" },
        { id: 213, value: 213, label: "Tunisia", abbreviation: "TUN", mobile_code: "216" },
        { id: 214, value: 214, label: "Turkey", abbreviation: "TUR", mobile_code: "90" },
        { id: 215, value: 215, label: "Turkmenistan", abbreviation: "TKM", mobile_code: "993" },
        { id: 216, value: 216, label: "Turks and Caicos Islands", abbreviation: "TCA", mobile_code: "1" },
        { id: 217, value: 217, label: "Tuvalu", abbreviation: "TUV", mobile_code: "688" },
        { id: 218, value: 218, label: "Uganda", abbreviation: "UGA", mobile_code: "256" },
        { id: 219, value: 219, label: "Ukraine", abbreviation: "UKR", mobile_code: "380" },
        { id: 220, value: 220, label: "United Arab Emirates", abbreviation: "ARE", mobile_code: "971" },
        { id: 221, value: 221, label: "United Kingdom", abbreviation: "GBR", mobile_code: "44" },
        { id: 222, value: 222, label: "United States", abbreviation: "USA", mobile_code: "1" },
        { id: 223, value: 223, label: "United States Virgin Islands", abbreviation: "", mobile_code: "1" },
        { id: 224, value: 224, label: "Uruguay", abbreviation: "URY", mobile_code: "598" },
        { id: 225, value: 225, label: "Uzbekistan", abbreviation: "UZB", mobile_code: "998" },
        { id: 226, value: 226, label: "Vanuatu", abbreviation: "VUT", mobile_code: "678" },
        { id: 227, value: 227, label: "Vatican", abbreviation: "VAT", mobile_code: "379" },
        { id: 228, value: 228, label: "Vatican", abbreviation: "VAT", mobile_code: "39" },
        { id: 229, value: 229, label: "Venezuela (Bolivarian Republic of)", abbreviation: "", mobile_code: "58" },
        { id: 230, value: 230, label: "Viet Nam", abbreviation: "", mobile_code: "84" },
        { id: 231, value: 231, label: "Wallis and Futuna", abbreviation: "WLF", mobile_code: "681" },
        { id: 232, value: 232, label: "Yemen", abbreviation: "YEM", mobile_code: "967" },
        { id: 233, value: 233, label: "Zambia", abbreviation: "ZMB", mobile_code: "260" },
        { id: 234, value: 234, label: "Zimbabwe", abbreviation: "ZWE", mobile_code: "263" }
    ],
    country_codes: [
        { id: 1, value: "+1", label: "+1" },
        { id: 7, value: "+7", label: "+7" },
        { id: 20, value: "+20", label: "+20" },
        { id: 27, value: "+27", label: "+27" },
        { id: 30, value: "+30", label: "+30" },
        { id: 31, value: "+31", label: "+31" },
        { id: 32, value: "+32", label: "+32" },
        { id: 33, value: "+33", label: "+33" },
        { id: 34, value: "+34", label: "+34" },
        { id: 36, value: "+36", label: "+36" },
        { id: 39, value: "+39", label: "+39" },
        { id: 40, value: "+40", label: "+40" },
        { id: 41, value: "+41", label: "+41" },
        { id: 43, value: "+43", label: "+43" },
        { id: 44, value: "+44", label: "+44" },
        { id: 45, value: "+45", label: "+45" },
        { id: 46, value: "+46", label: "+46" },
        { id: 47, value: "+47", label: "+47" },
        { id: 48, value: "+48", label: "+48" },
        { id: 49, value: "+49", label: "+49" },
        { id: 51, value: "+51", label: "+51" },
        { id: 52, value: "+52", label: "+52" },
        { id: 53, value: "+53", label: "+53" },
        { id: 54, value: "+54", label: "+54" },
        { id: 55, value: "+55", label: "+55" },
        { id: 56, value: "+56", label: "+56" },
        { id: 57, value: "+57", label: "+57" },
        { id: 58, value: "+58", label: "+58" },
        { id: 60, value: "+60", label: "+60" },
        { id: 61, value: "+61", label: "+61" },
        { id: 62, value: "+62", label: "+62" },
        { id: 63, value: "+63", label: "+63" },
        { id: 64, value: "+64", label: "+64" },
        { id: 65, value: "+65", label: "+65" },
        { id: 66, value: "+66", label: "+66" },
        { id: 81, value: "+81", label: "+81" },
        { id: 82, value: "+82", label: "+82" },
        { id: 84, value: "+84", label: "+84" },
        { id: 86, value: "+86", label: "+86" },
        { id: 90, value: "+90", label: "+90" },
        { id: 91, value: "+91", label: "+91" },
        { id: 92, value: "+92", label: "+92" },
        { id: 93, value: "+93", label: "+93" },
        { id: 94, value: "+94", label: "+94" },
        { id: 95, value: "+95", label: "+95" },
        { id: 98, value: "+98", label: "+98" },
        { id: 211, value: "+211", label: "+211" },
        { id: 212, value: "+212", label: "+212" },
        { id: 213, value: "+213", label: "+213" },
        { id: 216, value: "+216", label: "+216" },
        { id: 218, value: "+218", label: "+218" },
        { id: 220, value: "+220", label: "+220" },
        { id: 221, value: "+221", label: "+221" },
        { id: 222, value: "+222", label: "+222" },
        { id: 223, value: "+223", label: "+223" },
        { id: 224, value: "+224", label: "+224" },
        { id: 225, value: "+225", label: "+225" },
        { id: 226, value: "+226", label: "+226" },
        { id: 227, value: "+227", label: "+227" },
        { id: 228, value: "+228", label: "+228" },
        { id: 229, value: "+229", label: "+229" },
        { id: 230, value: "+230", label: "+230" },
        { id: 231, value: "+231", label: "+231" },
        { id: 232, value: "+232", label: "+232" },
        { id: 233, value: "+233", label: "+233" },
        { id: 234, value: "+234", label: "+234" },
        { id: 235, value: "+235", label: "+235" },
        { id: 236, value: "+236", label: "+236" },
        { id: 237, value: "+237", label: "+237" },
        { id: 238, value: "+238", label: "+238" },
        { id: 239, value: "+239", label: "+239" },
        { id: 240, value: "+240", label: "+240" },
        { id: 241, value: "+241", label: "+241" },
        { id: 242, value: "+242", label: "+242" },
        { id: 243, value: "+243", label: "+243" },
        { id: 244, value: "+244", label: "+244" },
        { id: 245, value: "+245", label: "+245" },
        { id: 246, value: "+246", label: "+246" },
        { id: 248, value: "+248", label: "+248" },
        { id: 249, value: "+249", label: "+249" },
        { id: 250, value: "+250", label: "+250" },
        { id: 251, value: "+251", label: "+251" },
        { id: 252, value: "+252", label: "+252" },
        { id: 253, value: "+253", label: "+253" },
        { id: 254, value: "+254", label: "+254" },
        { id: 255, value: "+255", label: "+255" },
        { id: 256, value: "+256", label: "+256" },
        { id: 257, value: "+257", label: "+257" },
        { id: 258, value: "+258", label: "+258" },
        { id: 260, value: "+260", label: "+260" },
        { id: 261, value: "+261", label: "+261" },
        { id: 262, value: "+262", label: "+262" },
        { id: 263, value: "+263", label: "+263" },
        { id: 264, value: "+264", label: "+264" },
        { id: 265, value: "+265", label: "+265" },
        { id: 266, value: "+266", label: "+266" },
        { id: 267, value: "+267", label: "+267" },
        { id: 268, value: "+268", label: "+268" },
        { id: 269, value: "+269", label: "+269" },
        { id: 290, value: "+290", label: "+290" },
        { id: 291, value: "+291", label: "+291" },
        { id: 297, value: "+297", label: "+297" },
        { id: 298, value: "+298", label: "+298" },
        { id: 299, value: "+299", label: "+299" },
        { id: 350, value: "+350", label: "+350" },
        { id: 351, value: "+351", label: "+351" },
        { id: 352, value: "+352", label: "+352" },
        { id: 353, value: "+353", label: "+353" },
        { id: 354, value: "+354", label: "+354" },
        { id: 355, value: "+355", label: "+355" },
        { id: 356, value: "+356", label: "+356" },
        { id: 357, value: "+357", label: "+357" },
        { id: 358, value: "+358", label: "+358" },
        { id: 359, value: "+359", label: "+359" },
        { id: 370, value: "+370", label: "+370" },
        { id: 371, value: "+371", label: "+371" },
        { id: 372, value: "+372", label: "+372" },
        { id: 373, value: "+373", label: "+373" },
        { id: 374, value: "+374", label: "+374" },
        { id: 375, value: "+375", label: "+375" },
        { id: 376, value: "+376", label: "+376" },
        { id: 377, value: "+377", label: "+377" },
        { id: 378, value: "+378", label: "+378" },
        { id: 380, value: "+380", label: "+380" },
        { id: 381, value: "+381", label: "+381" },
        { id: 382, value: "+382", label: "+382" },
        { id: 385, value: "+385", label: "+385" },
        { id: 386, value: "+386", label: "+386" },
        { id: 387, value: "+387", label: "+387" },
        { id: 389, value: "+389", label: "+389" },
        { id: 420, value: "+420", label: "+420" },
        { id: 421, value: "+421", label: "+421" },
        { id: 423, value: "+423", label: "+423" },
        { id: 500, value: "+500", label: "+500" },
        { id: 501, value: "+501", label: "+501" },
        { id: 502, value: "+502", label: "+502" },
        { id: 503, value: "+503", label: "+503" },
        { id: 504, value: "+504", label: "+504" },
        { id: 505, value: "+505", label: "+505" },
        { id: 506, value: "+506", label: "+506" },
        { id: 507, value: "+507", label: "+507" },
        { id: 508, value: "+508", label: "+508" },
        { id: 509, value: "+509", label: "+509" },
        { id: 590, value: "+590", label: "+590" },
        { id: 591, value: "+591", label: "+591" },
        { id: 592, value: "+592", label: "+592" },
        { id: 593, value: "+593", label: "+593" },
        { id: 594, value: "+594", label: "+594" },
        { id: 595, value: "+595", label: "+595" },
        { id: 596, value: "+596", label: "+596" },
        { id: 597, value: "+597", label: "+597" },
        { id: 598, value: "+598", label: "+598" },
        { id: 599, value: "+599", label: "+599" },
        { id: 670, value: "+670", label: "+670" },
        { id: 672, value: "+672", label: "+672" },
        { id: 673, value: "+673", label: "+673" },
        { id: 674, value: "+674", label: "+674" },
        { id: 675, value: "+675", label: "+675" },
        { id: 676, value: "+676", label: "+676" },
        { id: 677, value: "+677", label: "+677" },
        { id: 678, value: "+678", label: "+678" },
        { id: 679, value: "+679", label: "+679" },
        { id: 680, value: "+680", label: "+680" },
        { id: 681, value: "+681", label: "+681" },
        { id: 682, value: "+682", label: "+682" },
        { id: 683, value: "+683", label: "+683" },
        { id: 685, value: "+685", label: "+685" },
        { id: 686, value: "+686", label: "+686" },
        { id: 687, value: "+687", label: "+687" },
        { id: 688, value: "+688", label: "+688" },
        { id: 689, value: "+689", label: "+689" },
        { id: 690, value: "+690", label: "+690" },
        { id: 691, value: "+691", label: "+691" },
        { id: 692, value: "+692", label: "+692" },
        { id: 767, value: "+767", label: "+767" },
        { id: 850, value: "+850", label: "+850" },
        { id: 852, value: "+852", label: "+852" },
        { id: 853, value: "+853", label: "+853" },
        { id: 855, value: "+855", label: "+855" },
        { id: 856, value: "+856", label: "+856" },
        { id: 868, value: "+868", label: "+868" },
        { id: 876, value: "+876", label: "+876" },
        { id: 880, value: "+880", label: "+880" },
        { id: 886, value: "+886", label: "+886" },
        { id: 960, value: "+960", label: "+960" },
        { id: 961, value: "+961", label: "+961" },
        { id: 962, value: "+962", label: "+962" },
        { id: 963, value: "+963", label: "+963" },
        { id: 964, value: "+964", label: "+964" },
        { id: 965, value: "+965", label: "+965" },
        { id: 966, value: "+966", label: "+966" },
        { id: 967, value: "+967", label: "+967" },
        { id: 968, value: "+968", label: "+968" },
        { id: 970, value: "+970", label: "+970" },
        { id: 971, value: "+971", label: "+971" },
        { id: 972, value: "+972", label: "+972" },
        { id: 973, value: "+973", label: "+973" },
        { id: 974, value: "+974", label: "+974" },
        { id: 975, value: "+975", label: "+975" },
        { id: 976, value: "+976", label: "+976" },
        { id: 977, value: "+977", label: "+977" },
        { id: 992, value: "+992", label: "+992" },
        { id: 993, value: "+993", label: "+993" },
        { id: 994, value: "+994", label: "+994" },
        { id: 995, value: "+995", label: "+995" },
        { id: 996, value: "+996", label: "+996" },
        { id: 998, value: "+998", label: "+998" },
        { id: 1246, value: "+1246", label: "+1246" },
        { id: 1264, value: "+1264", label: "+1264" },
        { id: 1268, value: "+1268", label: "+1268" },
        { id: 1284, value: "+1284", label: "+1284" },
        { id: 1340, value: "+1340", label: "+1340" },
        { id: 1441, value: "+1441", label: "+1441" },
        { id: 1473, value: "+1473", label: "+1473" },
        { id: 1664, value: "+1664", label: "+1664" },
        { id: 1684, value: "+1684", label: "+1684" },
        { id: 1721, value: "+1721", label: "+1721" },
        { id: 1758, value: "+1758", label: "+1758" },
        { id: 1784, value: "+1784", label: "+1784" },
        { id: 1869, value: "+1869", label: "+1869" },
        { id: 4779, value: "+4779", label: "+4779" },
        { id: 5997, value: "+5997", label: "+5997" },
        { id: 1242, value: "+1 242", label: "+1 242" },
        { id: 61891, value: "+61 891", label: "+61 891" },
        { id: 1345, value: "+1 345", label: "+1 345" },
        { id: 1670, value: "+1 670", label: "+1 670" },
        { id: 1649, value: "+1 649", label: "+1 649" },
    ]
}

export const countries = [
    { id: 1, value: 1, label: "Mexico", abbreviation: "MEX", mobile_code: "52" },
    { id: 2, value: 2, label: "Albania", abbreviation: "ALB", mobile_code: "355" },
    { id: 3, value: 3, label: "Algeria", abbreviation: "DZA", mobile_code: "213" },
    { id: 4, value: 4, label: "American Samoa", abbreviation: "ASM", mobile_code: "1" },
    { id: 5, value: 5, label: "Andorra", abbreviation: "AND", mobile_code: "376" },
    { id: 6, value: 6, label: "Angola", abbreviation: "AGO", mobile_code: "244" },
    { id: 7, value: 7, label: "Anguilla", abbreviation: "AIA", mobile_code: "1" },
    { id: 8, value: 8, label: "Antigua and Barbuda", abbreviation: "ATG", mobile_code: "1" },
    { id: 9, value: 9, label: "Argentina", abbreviation: "ARG", mobile_code: "54" },
    { id: 10, value: 10, label: "Armenia", abbreviation: "ARM", mobile_code: "374" },
    { id: 11, value: 11, label: "Aruba", abbreviation: "ABW", mobile_code: "297" },
    { id: 12, value: 12, label: "Australia", abbreviation: "AUS", mobile_code: "61" },
    { id: 13, value: 13, label: "Australian External Territories", abbreviation: "", mobile_code: "672" },
    { id: 14, value: 14, label: "Austria", abbreviation: "AUT", mobile_code: "43" },
    { id: 15, value: 15, label: "Azerbaijan", abbreviation: "AZE", mobile_code: "994" },
    { id: 16, value: 16, label: "Bahamas", abbreviation: "BHS", mobile_code: "1" },
    { id: 17, value: 17, label: "Bahrain", abbreviation: "BHR", mobile_code: "973" },
    { id: 18, value: 18, label: "Bangladesh", abbreviation: "BGD", mobile_code: "880" },
    { id: 19, value: 19, label: "Barbados", abbreviation: "BRB", mobile_code: "1" },
    { id: 20, value: 20, label: "Belarus", abbreviation: "BLR", mobile_code: "375" },
    { id: 21, value: 21, label: "Belgium", abbreviation: "BEL", mobile_code: "32" },
    { id: 22, value: 22, label: "Belize", abbreviation: "BLZ", mobile_code: "501" },
    { id: 23, value: 23, label: "Benin", abbreviation: "BEN", mobile_code: "229" },
    { id: 24, value: 24, label: "Bermuda", abbreviation: "BMU", mobile_code: "1" },
    { id: 25, value: 25, label: "Bhutan", abbreviation: "BTN", mobile_code: "975" },
    { id: 26, value: 26, label: "Bolivia (Plurinational State of)", abbreviation: "", mobile_code: "591" },
    { id: 27, value: 27, label: "Bonaire, Sint Eustatius and Saba", abbreviation: "", mobile_code: "599" },
    { id: 28, value: 28, label: "Bosnia and Herzegovina", abbreviation: "BIH", mobile_code: "387" },
    { id: 29, value: 29, label: "Botswana", abbreviation: "BWA", mobile_code: "267" },
    { id: 30, value: 30, label: "Brazil", abbreviation: "BRA", mobile_code: "55" },
    { id: 31, value: 31, label: "British Virgin Islands", abbreviation: "VGB", mobile_code: "1" },
    { id: 32, value: 32, label: "Brunei Darussalam", abbreviation: "", mobile_code: "673" },
    { id: 33, value: 33, label: "Bulgaria", abbreviation: "BGR", mobile_code: "359" },
    { id: 34, value: 34, label: "Burkina Faso", abbreviation: "BFA", mobile_code: "226" },
    { id: 35, value: 35, label: "Burundi", abbreviation: "BDI", mobile_code: "257" },
    { id: 36, value: 36, label: "Cambodia", abbreviation: "KHM", mobile_code: "855" },
    { id: 37, value: 37, label: "Cameroon", abbreviation: "CMR", mobile_code: "237" },
    { id: 38, value: 38, label: "Canada", abbreviation: "CAN", mobile_code: "1" },
    { id: 39, value: 39, label: "Cape Verde", abbreviation: "CPV", mobile_code: "238" },
    { id: 40, value: 40, label: "Cayman Islands", abbreviation: "CYM", mobile_code: "1" },
    { id: 41, value: 41, label: "Central African Rep.", abbreviation: "", mobile_code: "236" },
    { id: 42, value: 42, label: "Chad", abbreviation: "TCD", mobile_code: "235" },
    { id: 43, value: 43, label: "Chile", abbreviation: "CHL", mobile_code: "56" },
    { id: 44, value: 44, label: "China", abbreviation: "CHN", mobile_code: "86" },
    { id: 45, value: 45, label: "Colombia", abbreviation: "COL", mobile_code: "57" },
    { id: 46, value: 46, label: "Comoros", abbreviation: "COM", mobile_code: "269" },
    { id: 47, value: 47, label: "Congo", abbreviation: "", mobile_code: "242" },
    { id: 48, value: 48, label: "Cook Islands", abbreviation: "COK", mobile_code: "682" },
    { id: 49, value: 49, label: "Costa Rica", abbreviation: "CRI", mobile_code: "506" },
    { id: 50, value: 50, label: "Côte d'Ivoire", abbreviation: "", mobile_code: "225" },
    { id: 51, value: 51, label: "Croatia", abbreviation: "HRV", mobile_code: "385" },
    { id: 52, value: 52, label: "Cuba", abbreviation: "CUB", mobile_code: "53" },
    { id: 53, value: 53, label: "Curaçao", abbreviation: "CUW", mobile_code: "599" },
    { id: 54, value: 54, label: "Cyprus", abbreviation: "CYP", mobile_code: "357" },
    { id: 55, value: 55, label: "Czech Rep.", abbreviation: "", mobile_code: "420" },
    { id: 56, value: 56, label: "Dem. People's Rep. of Korea", abbreviation: "", mobile_code: "850" },
    { id: 57, value: 57, label: "Dem. Rep. of the Congo", abbreviation: "", mobile_code: "243" },
    { id: 58, value: 58, label: "Denmark", abbreviation: "DNK", mobile_code: "45" },
    { id: 59, value: 59, label: "Diego Garcia", abbreviation: "", mobile_code: "246" },
    { id: 60, value: 60, label: "Djibouti", abbreviation: "DJI", mobile_code: "253" },
    { id: 61, value: 61, label: "Dominica", abbreviation: "DMA", mobile_code: "1" },
    { id: 62, value: 62, label: "Dominican Rep.", abbreviation: "", mobile_code: "1" },
    { id: 63, value: 63, label: "Ecuador", abbreviation: "ECU", mobile_code: "593" },
    { id: 64, value: 64, label: "Egypt", abbreviation: "EGY", mobile_code: "20" },
    { id: 65, value: 65, label: "El Salvador", abbreviation: "SLV", mobile_code: "503" },
    { id: 66, value: 66, label: "Equatorial Guinea", abbreviation: "GNQ", mobile_code: "240" },
    { id: 67, value: 67, label: "Eritrea", abbreviation: "ERI", mobile_code: "291" },
    { id: 68, value: 68, label: "Estonia", abbreviation: "EST", mobile_code: "372" },
    { id: 69, value: 69, label: "Ethiopia", abbreviation: "ETH", mobile_code: "251" },
    { id: 70, value: 70, label: "Falkland Islands (Malvinas)", abbreviation: "", mobile_code: "500" },
    { id: 71, value: 71, label: "Faroe Islands", abbreviation: "FRO", mobile_code: "298" },
    { id: 72, value: 72, label: "Fiji", abbreviation: "FJI", mobile_code: "679" },
    { id: 73, value: 73, label: "Finland", abbreviation: "FIN", mobile_code: "358" },
    { id: 74, value: 74, label: "France", abbreviation: "FRA", mobile_code: "33" },
    { id: 75, value: 75, label: "French Dep. and Territories in the Indian Ocean", abbreviation: "", mobile_code: "262" },
    { id: 76, value: 76, label: "French Guiana", abbreviation: "GUF", mobile_code: "594" },
    { id: 77, value: 77, label: "French Polynesia", abbreviation: "PYF", mobile_code: "689" },
    { id: 78, value: 78, label: "Gabon", abbreviation: "GAB", mobile_code: "241" },
    { id: 79, value: 79, label: "Gambia", abbreviation: "GMB", mobile_code: "220" },
    { id: 80, value: 80, label: "Georgia", abbreviation: "GEO", mobile_code: "995" },
    { id: 81, value: 81, label: "Germany", abbreviation: "DEU", mobile_code: "49" },
    { id: 82, value: 82, label: "Ghana", abbreviation: "GHA", mobile_code: "233" },
    { id: 83, value: 83, label: "Gibraltar", abbreviation: "GIB", mobile_code: "350" },
    { id: 84, value: 84, label: "Global Mobile Satellite System (GMSS), shared", abbreviation: "", mobile_code: "881" },
    { id: 85, value: 85, label: "Greece", abbreviation: "GRC", mobile_code: "30" },
    { id: 86, value: 86, label: "Greenland", abbreviation: "GRL", mobile_code: "299" },
    { id: 87, value: 87, label: "Grenada", abbreviation: "GRD", mobile_code: "1" },
    { id: 88, value: 88, label: "Group of countries, shared code", abbreviation: "", mobile_code: "388" },
    { id: 89, value: 89, label: "Guadeloupe", abbreviation: "GLP", mobile_code: "590" },
    { id: 90, value: 90, label: "Guam", abbreviation: "GUM", mobile_code: "1" },
    { id: 91, value: 91, label: "Guatemala", abbreviation: "GTM", mobile_code: "502" },
    { id: 92, value: 92, label: "Guinea", abbreviation: "GIN", mobile_code: "224" },
    { id: 93, value: 93, label: "Guinea-Bissau", abbreviation: "GNB", mobile_code: "245" },
    { id: 94, value: 94, label: "Guyana", abbreviation: "GUY", mobile_code: "592" },
    { id: 95, value: 95, label: "Haiti", abbreviation: "HTI", mobile_code: "509" },
    { id: 96, value: 96, label: "Honduras", abbreviation: "HND", mobile_code: "504" },
    { id: 97, value: 97, label: "Hong Kong, China", abbreviation: "", mobile_code: "852" },
    { id: 98, value: 98, label: "Hungary", abbreviation: "HUN", mobile_code: "36" },
    { id: 99, value: 99, label: "Iceland", abbreviation: "ISL", mobile_code: "354" },
    { id: 100, value: 100, label: "India", abbreviation: "IND", mobile_code: "91" },
    { id: 101, value: 101, label: "Indonesia", abbreviation: "IDN", mobile_code: "62" },
    { id: 102, value: 102, label: "Iran (Islamic Republic of)", abbreviation: "", mobile_code: "98" },
    { id: 103, value: 103, label: "Iraq", abbreviation: "IRQ", mobile_code: "964" },
    { id: 104, value: 104, label: "Ireland", abbreviation: "IRL", mobile_code: "353" },
    { id: 105, value: 105, label: "Israel", abbreviation: "ISR", mobile_code: "972" },
    { id: 106, value: 106, label: "Italy", abbreviation: "ITA", mobile_code: "39" },
    { id: 107, value: 107, label: "Jamaica", abbreviation: "JAM", mobile_code: "1" },
    { id: 108, value: 108, label: "Japan", abbreviation: "JPN", mobile_code: "81" },
    { id: 109, value: 109, label: "Jordan", abbreviation: "JOR", mobile_code: "962" },
    { id: 110, value: 110, label: "Kazakhstan", abbreviation: "KAZ", mobile_code: "7" },
    { id: 111, value: 111, label: "Kenya", abbreviation: "KEN", mobile_code: "254" },
    { id: 112, value: 112, label: "Kiribati", abbreviation: "KIR", mobile_code: "686" },
    { id: 113, value: 113, label: "Korea (Rep. of)", abbreviation: "", mobile_code: "82" },
    { id: 114, value: 114, label: "Kuwait", abbreviation: "KWT", mobile_code: "965" },
    { id: 115, value: 115, label: "Kyrgyzstan", abbreviation: "KGZ", mobile_code: "996" },
    { id: 116, value: 116, label: "Lao P.D.R.", abbreviation: "", mobile_code: "856" },
    { id: 117, value: 117, label: "Latvia", abbreviation: "LVA", mobile_code: "371" },
    { id: 118, value: 118, label: "Lebanon", abbreviation: "LBN", mobile_code: "961" },
    { id: 119, value: 119, label: "Lesotho", abbreviation: "LSO", mobile_code: "266" },
    { id: 120, value: 120, label: "Liberia", abbreviation: "LBR", mobile_code: "231" },
    { id: 121, value: 121, label: "Libya", abbreviation: "LBY", mobile_code: "218" },
    { id: 122, value: 122, label: "Liechtenstein", abbreviation: "LIE", mobile_code: "423" },
    { id: 123, value: 123, label: "Lithuania", abbreviation: "LTU", mobile_code: "370" },
    { id: 124, value: 124, label: "Luxembourg", abbreviation: "LUX", mobile_code: "352" },
    { id: 125, value: 125, label: "Macao, China", abbreviation: "", mobile_code: "853" },
    { id: 126, value: 126, label: "Madagascar", abbreviation: "MDG", mobile_code: "261" },
    { id: 127, value: 127, label: "Malawi", abbreviation: "MWI", mobile_code: "265" },
    { id: 128, value: 128, label: "Malaysia", abbreviation: "MYS", mobile_code: "60" },
    { id: 129, value: 129, label: "Maldives", abbreviation: "MDV", mobile_code: "960" },
    { id: 130, value: 130, label: "Mali", abbreviation: "MLI", mobile_code: "223" },
    { id: 131, value: 131, label: "Malta", abbreviation: "MLT", mobile_code: "356" },
    { id: 132, value: 132, label: "Marshall Islands", abbreviation: "MHL", mobile_code: "692" },
    { id: 133, value: 133, label: "Martinique", abbreviation: "MTQ", mobile_code: "596" },
    { id: 134, value: 134, label: "Mauritania", abbreviation: "MRT", mobile_code: "222" },
    { id: 135, value: 135, label: "Mauritius", abbreviation: "MUS", mobile_code: "230" },
    { id: 137, value: 137, label: "Micronesia", abbreviation: "FSM", mobile_code: "691" },
    { id: 138, value: 138, label: "Moldova (Republic of)", abbreviation: "", mobile_code: "373" },
    { id: 139, value: 139, label: "Monaco", abbreviation: "MCO", mobile_code: "377" },
    { id: 140, value: 140, label: "Mongolia", abbreviation: "MNG", mobile_code: "976" },
    { id: 141, value: 141, label: "Montenegro", abbreviation: "MNE", mobile_code: "382" },
    { id: 142, value: 142, label: "Montserrat", abbreviation: "MSR", mobile_code: "1" },
    { id: 143, value: 143, label: "Morocco", abbreviation: "MAR", mobile_code: "212" },
    { id: 144, value: 144, label: "Mozambique", abbreviation: "MOZ", mobile_code: "258" },
    { id: 145, value: 145, label: "Myanmar", abbreviation: "MMR", mobile_code: "95" },
    { id: 146, value: 146, label: "Namibia", abbreviation: "NAM", mobile_code: "264" },
    { id: 147, value: 147, label: "Nauru", abbreviation: "NRU", mobile_code: "674" },
    { id: 148, value: 148, label: "Nepal", abbreviation: "NPL", mobile_code: "977" },
    { id: 149, value: 149, label: "Netherlands", abbreviation: "NLD", mobile_code: "31" },
    { id: 150, value: 150, label: "New Caledonia", abbreviation: "NCL", mobile_code: "687" },
    { id: 151, value: 151, label: "New Zealand", abbreviation: "NZL", mobile_code: "64" },
    { id: 152, value: 152, label: "Nicaragua", abbreviation: "NIC", mobile_code: "505" },
    { id: 153, value: 153, label: "Niger", abbreviation: "NER", mobile_code: "227" },
    { id: 154, value: 154, label: "Nigeria", abbreviation: "NGA", mobile_code: "234" },
    { id: 155, value: 155, label: "Niue", abbreviation: "NIU", mobile_code: "683" },
    { id: 156, value: 156, label: "Northern Marianas", abbreviation: "", mobile_code: "1" },
    { id: 157, value: 157, label: "Norway", abbreviation: "NOR", mobile_code: "47" },
    { id: 158, value: 158, label: "Oman", abbreviation: "OMN", mobile_code: "968" },
    { id: 159, value: 159, label: "Pakistan", abbreviation: "PAK", mobile_code: "92" },
    { id: 160, value: 160, label: "Palau", abbreviation: "PLW", mobile_code: "680" },
    { id: 161, value: 161, label: "Panama", abbreviation: "PAN", mobile_code: "507" },
    { id: 162, value: 162, label: "Papua New Guinea", abbreviation: "PNG", mobile_code: "675" },
    { id: 163, value: 163, label: "Paraguay", abbreviation: "PRY", mobile_code: "595" },
    { id: 164, value: 164, label: "Peru", abbreviation: "PER", mobile_code: "51" },
    { id: 165, value: 165, label: "Philippines", abbreviation: "PHL", mobile_code: "63" },
    { id: 166, value: 166, label: "Poland", abbreviation: "POL", mobile_code: "48" },
    { id: 167, value: 167, label: "Portugal", abbreviation: "PRT", mobile_code: "351" },
    { id: 168, value: 168, label: "Puerto Rico", abbreviation: "PRI", mobile_code: "1" },
    { id: 169, value: 169, label: "Qatar", abbreviation: "QAT", mobile_code: "974" },
    { id: 170, value: 170, label: "Romania", abbreviation: "ROU", mobile_code: "40" },
    { id: 171, value: 171, label: "Russian Federation", abbreviation: "", mobile_code: "7" },
    { id: 172, value: 172, label: "Rwanda", abbreviation: "RWA", mobile_code: "250" },
    { id: 173, value: 173, label: "Saint Helena, Ascension and Tristan da Cunha", abbreviation: "", mobile_code: "247" },
    { id: 174, value: 174, label: "Saint Helena, Ascension and Tristan da Cunha", abbreviation: "", mobile_code: "290" },
    { id: 175, value: 175, label: "Saint Kitts and Nevis", abbreviation: "KNA", mobile_code: "1" },
    { id: 176, value: 176, label: "Saint Lucia", abbreviation: "LCA", mobile_code: "1" },
    { id: 177, value: 177, label: "Saint Pierre and Miquelon", abbreviation: "SPM", mobile_code: "508" },
    { id: 178, value: 178, label: "Saint Vincent and the Grenadines", abbreviation: "VCT", mobile_code: "1" },
    { id: 179, value: 179, label: "Samoa", abbreviation: "WSM", mobile_code: "685" },
    { id: 180, value: 180, label: "San Marino", abbreviation: "SMR", mobile_code: "378" },
    { id: 181, value: 181, label: "Sao Tome and Principe", abbreviation: "STP", mobile_code: "239" },
    { id: 182, value: 182, label: "Saudi Arabia", abbreviation: "SAU", mobile_code: "966" },
    { id: 183, value: 183, label: "Senegal", abbreviation: "SEN", mobile_code: "221" },
    { id: 184, value: 184, label: "Serbia", abbreviation: "SRB", mobile_code: "381" },
    { id: 185, value: 185, label: "Seychelles", abbreviation: "SYC", mobile_code: "248" },
    { id: 186, value: 186, label: "Sierra Leone", abbreviation: "SLE", mobile_code: "232" },
    { id: 187, value: 187, label: "Singapore", abbreviation: "SGP", mobile_code: "65" },
    { id: 188, value: 188, label: "Sint Maarten (Dutch part)", abbreviation: "", mobile_code: "1" },
    { id: 189, value: 189, label: "Slovakia", abbreviation: "SVK", mobile_code: "421" },
    { id: 190, value: 190, label: "Slovenia", abbreviation: "SVN", mobile_code: "386" },
    { id: 191, value: 191, label: "Solomon Islands", abbreviation: "SLB", mobile_code: "677" },
    { id: 192, value: 192, label: "Somalia", abbreviation: "SOM", mobile_code: "252" },
    { id: 193, value: 193, label: "South Africa", abbreviation: "ZAF", mobile_code: "27" },
    { id: 194, value: 194, label: "South Sudan", abbreviation: "SSD", mobile_code: "211" },
    { id: 195, value: 195, label: "Spain", abbreviation: "ESP", mobile_code: "34" },
    { id: 196, value: 196, label: "Sri Lanka", abbreviation: "LKA", mobile_code: "94" },
    { id: 197, value: 197, label: "Sudan", abbreviation: "SDN", mobile_code: "249" },
    { id: 198, value: 198, label: "Suriname", abbreviation: "", mobile_code: "597" },
    { id: 199, value: 199, label: "Swaziland", abbreviation: "SWZ", mobile_code: "268" },
    { id: 200, value: 200, label: "Sweden", abbreviation: "SWE", mobile_code: "46" },
    { id: 201, value: 201, label: "Switzerland", abbreviation: "CHE", mobile_code: "41" },
    { id: 202, value: 202, label: "Syrian Arab Republic", abbreviation: "", mobile_code: "963" },
    { id: 203, value: 203, label: "Taiwan, China", abbreviation: "", mobile_code: "886" },
    { id: 204, value: 204, label: "Tajikistan", abbreviation: "TJK", mobile_code: "992" },
    { id: 205, value: 205, label: "Tanzania", abbreviation: "TZA", mobile_code: "255" },
    { id: 206, value: 206, label: "Thailand", abbreviation: "THA", mobile_code: "66" },
    { id: 207, value: 207, label: "The Former Yugoslav Republic of Macedonia", abbreviation: "", mobile_code: "389" },
    { id: 208, value: 208, label: "Timor-Leste", abbreviation: "", mobile_code: "670" },
    { id: 209, value: 209, label: "Togo", abbreviation: "TGO", mobile_code: "228" },
    { id: 210, value: 210, label: "Tokelau", abbreviation: "TKL", mobile_code: "690" },
    { id: 211, value: 211, label: "Tonga", abbreviation: "TON", mobile_code: "676" },
    { id: 212, value: 212, label: "Trinidad and Tobago", abbreviation: "TTO", mobile_code: "1" },
    { id: 213, value: 213, label: "Tunisia", abbreviation: "TUN", mobile_code: "216" },
    { id: 214, value: 214, label: "Turkey", abbreviation: "TUR", mobile_code: "90" },
    { id: 215, value: 215, label: "Turkmenistan", abbreviation: "TKM", mobile_code: "993" },
    { id: 216, value: 216, label: "Turks and Caicos Islands", abbreviation: "TCA", mobile_code: "1" },
    { id: 217, value: 217, label: "Tuvalu", abbreviation: "TUV", mobile_code: "688" },
    { id: 218, value: 218, label: "Uganda", abbreviation: "UGA", mobile_code: "256" },
    { id: 219, value: 219, label: "Ukraine", abbreviation: "UKR", mobile_code: "380" },
    { id: 220, value: 220, label: "United Arab Emirates", abbreviation: "ARE", mobile_code: "971" },
    { id: 221, value: 221, label: "United Kingdom", abbreviation: "GBR", mobile_code: "44" },
    { id: 222, value: 222, label: "United States", abbreviation: "USA", mobile_code: "1" },
    { id: 223, value: 223, label: "United States Virgin Islands", abbreviation: "", mobile_code: "1" },
    { id: 224, value: 224, label: "Uruguay", abbreviation: "URY", mobile_code: "598" },
    { id: 225, value: 225, label: "Uzbekistan", abbreviation: "UZB", mobile_code: "998" },
    { id: 226, value: 226, label: "Vanuatu", abbreviation: "VUT", mobile_code: "678" },
    { id: 227, value: 227, label: "Vatican", abbreviation: "VAT", mobile_code: "379" },
    { id: 228, value: 228, label: "Vatican", abbreviation: "VAT", mobile_code: "39" },
    { id: 229, value: 229, label: "Venezuela (Bolivarian Republic of)", abbreviation: "", mobile_code: "58" },
    { id: 230, value: 230, label: "Viet Nam", abbreviation: "", mobile_code: "84" },
    { id: 231, value: 231, label: "Wallis and Futuna", abbreviation: "WLF", mobile_code: "681" },
    { id: 232, value: 232, label: "Yemen", abbreviation: "YEM", mobile_code: "967" },
    { id: 233, value: 233, label: "Zambia", abbreviation: "ZMB", mobile_code: "260" },
    { id: 234, value: 234, label: "Zimbabwe", abbreviation: "ZWE", mobile_code: "263" }
]

export default catalogs