import React from 'react'
import { translations as translations_esp } from '../../texts/eng/artworks-translations'
import { Card, CardActionArea, CardContent, CardMedia, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import LoadingContainer from '../../components/Structure/Layouts/LoadingContainer'
import TitleContainer from '../../components/Structure/Layouts/TitleContainer'
import ActionTopBar from '../../components/Structure/Table/ActionTopBar'
import useArtworksView from './useArtworksView'
import { CONFIG_SETTINGS } from '../../config'
import { isValidDocument, onGetCurrencyValue } from '../../shared/utility'
import RoundedButton from '../../components/Actions/RoundedButton'
import { catalogs } from '../../texts/eng/catalogs'
import StopIcon from '@material-ui/icons/Stop'
import { grey, red } from '@material-ui/core/colors'
import SVGIcon from '../../components/Structure/DisplayData/SVGIcon'
import cx from 'classnames'
import AddArtworkModal from './modals/AddArtworkModal'
import { connect } from 'react-redux'
import ActionBar from './components/ActionBar'
import { actionTypes } from '../../store/actions'

const styles = makeStyles(theme => ({
    root: {
        padding: 80,
        paddingBottom: 0
    },
    content: {
        marginTop: 30
    },
    noArtworks: {
        marginTop: 100
    },
    noArtworksTitle: {
        fontSize: 24,
        fontWeight: 700,
        color: '#494949'
    },
    noArtworksSubtitle: {
        fontSize: 16,
        color: '#7F7F7F'
    }
}))

const ArtworksView = ({ is_auth, history, search_params, onUpdateSearchParams }) => {

    const classes = styles()
    const content = translations_esp

    const { system, view_data, actions } = useArtworksView({
        is_auth, history, content, search_params, onUpdateSearchParams
    })


    return (
		<TitleContainer title={content.title}>
			<AddArtworkModal
				open={view_data.add_modal}
				form={view_data.form}
				onChange={actions.onChange}
				onSubmit={actions.onSaveArtwork}
				onClose={actions.onCloseAddModal}
				content={content}
			/>
			<ActionTopBar
				filter={[...content.filter, ...catalogs.artwork_statuses]}
				filterValue={view_data.filterValue}
				onChangeFilter={actions.onUpdateFilterValue}
				search
				searchData={view_data.form.search}
				onSearch={actions.onSearchArtwork}
				onUpdateSearch={actions.onResetTableFilter}
				add
				onAdd={actions.onOpenAddModal}
			/>
			<LoadingContainer
				loading={system.loading}
				sending={system.sending}
				success={system.success}
				error={system.error}
				onCloseSuccess={actions.onCloseSuccess}
				onCloseError={actions.onCloseError}
			>
				<div className={classes.content}>
					<Grid container alignItems='stretch' spacing={4}>
						{view_data.artworks.length > 0 ? (
							view_data.artworks.map((artwork, key) => (
								<Grid item xs={12} sm={4} lg={3} key={`artwork-item-${key.toString()}`}>
									<ArtworkCard artwork={artwork} content={content} onClick={actions.onVisitUrl} />
								</Grid>
							))
						) : (
							<Grid item xs={12}>
								<div className={classes.noArtworks}>
									<Grid container justifyContent='center' spacing={3}>
										<Grid item>
											<SVGIcon src={content.noArtworks.icon} size={64} />
										</Grid>
										<Grid item xs={12}>
											<Typography align='center' className={classes.noArtworksTitle}>
												{content.noArtworks.title}
											</Typography>
											<Typography align='center' className={classes.noArtworksSubtitle}>
												{content.noArtworks.subtitle}
											</Typography>
										</Grid>
									</Grid>
								</div>
							</Grid>
						)}
					</Grid>
					{view_data.artworks.length > 0 && (
						<div className={classes.actions}>
							<ActionBar total={view_data.total} tableFilter={view_data.tableFilter} onUpdateTableFilter={actions.onUpdateTableFilter} />
						</div>
					)}
				</div>
			</LoadingContainer>
		</TitleContainer>
    );
}

const stylesCard = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        //height: "calc(100% * (4/3))",
        maxWidth: 400,
        borderRadius: 5,
        padding: 0,
        boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.07)',
        '&:hover': {
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        }
    },
    cardActionArea: {
        position: 'relative',
        padding: 10,
        //paddingBottom: 30,
        width: '100%',
        height: '100%',
        '& span': {
            '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.1)',
                cursor: 'pointer'
            }
        }
    },
    media: {
        width: '100%',
        background: 'white',
        paddingTop: "100%",
        borderRadius: 2,
        display: 'block',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        border: '1px solid #EAEAEA',
    },
    mediaNoImage: {
        backgroundSize: '70%',
        backgroundRepeat: 'no-repeat',
        border: '1px solid #EAEAEA',
    },
    cardContent: {
        padding: 0,
        paddingTop: 16,
        paddingBottom: 16,
        background: 'transparent',
        position: 'relative',
        '&:hover': {
            background: 'white'
        }
    },
    soldButton: {
        fontSize: 12,
        textTransform: 'uppercase'
    },
    statusContainer: {
        display: 'inline-flex',
        alignItems: 'center'
    },
    status: {
        fontSize: 14,
        fontWeight: 600,
        color: grey[800]
    },
    text: {
        fontSize: 14,
        fontWeight: 400,
        color: grey[500],
        '& span': {
            fontSize: 14,
            fontWeight: 600,
            color: grey[800],
        }
    },
    nft_button: {
        position: 'absolute',
        bottom: 5,
        right: 10,
        background: theme.palette.secondary.main,
        width: 32,
        height: 32,
        margin: 'auto',
        boxSizing: 'border-box',
        borderRadius: '100%',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    nft_button_grey: {
        background: grey[500],
        cursor: 'default',
        '&:hover': {
            background: grey[500],
        }
    },
    likesContent: {
        position: 'absolute',
        top: 0,
        right: 0,
        padding: 15,
        paddingTop: 20,
    },
    likes: {
        fontSize: 16,
        color: 'white',
        fontWeight: 600,
        position: 'absolute',
    }
}))

const ArtworkCard = ({ artwork, content, onClick }) => {

    const classes = stylesCard()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    const { sold, artwork_status_id, nft, price, likes, url, web_image, mobile_image } = artwork

    const _image = mobile ? mobile_image : web_image
    const isValid = isValidDocument(_image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${_image}` : content.noImage

    const artworkStatus = catalogs.artwork_statuses.find(artwork => artwork.id === artwork_status_id)

    return (
        <Card className={classes.root} onClick={() => onClick(url)}>
            <CardActionArea className={classes.cardActionArea}>
                <CardMedia
                    className={cx({
                        [classes.media]: true,
                        [classes.mediaNoImage]: !isValid
                    })}
                    image={selectedImage}
                />
                <CardContent className={classes.cardContent}>
                    <Grid container alignItems='center' spacing={2}>
                        <Grid item xs>
                            {Boolean(Number(sold)) &&
                                <RoundedButton color='black' size='small'>
                                    <Typography className={classes.soldButton}>
                                        {content.sold}
                                    </Typography>
                                </RoundedButton>
                            }
                        </Grid>
                        <Grid item>
                            <div className={classes.statusContainer}>
                                <Typography className={classes.status}>
                                    {artworkStatus.label}
                                </Typography>
                                <StopIcon style={{ width: 15, height: 15, color: artworkStatus.color }} />
                            </div>
                        </Grid>
                        {content.data.map((item, key) => (
                            <Grid item xs={12} key={`artworks-item-no-${key.toString()}`}>
                                <Typography className={classes.text}>
                                    <span>{item.label}:</span> {artwork[item.id]}
                                </Typography>
                            </Grid>
                        ))}
                        <Grid item xs>
                            <Typography className={classes.text}>
                                <span>{content.price}:</span> {onGetCurrencyValue(price ? price : '', 0, '€ ')}
                            </Typography>
                        </Grid>
                    </Grid>
                    {Boolean(Number(nft)) &&
                        <div className={classes.nft_button}>
                            <img src={content.nft} style={{width: 24, height: 24}} />
                        </div>
                    }
                </CardContent>
                <div className={classes.likesContent}>
                    <Grid container justifyContent='center'>
                        <Grid item container justifyContent='center' alignItems='center' style={{position: 'relative', opacity: '0.85'}}>
                            <SVGIcon src={content.like} size={38} color={theme.palette.secondary.light} />
                            <Typography className={classes.likes} align='center'>
                                {likes}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </CardActionArea>
        </Card>
    )
}


const mapStateToProps = (state) => {
    return {
        is_auth: state.is_auth,
		search_params: state.search_params
    }
}

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateSearchParams: (search_params) => dispatch({ type: actionTypes.SYS_SET_SEARCH_PARAMS, search_params }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtworksView)