import React from 'react';
import { alpha, Button, CircularProgress, makeStyles } from '@material-ui/core';
import cx from 'classnames'
import { red } from '@material-ui/core/colors';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.primary.main,
        fontFamily: theme.typography.fontFamily,
        fontSize: 18,
        textTransform: 'none',
        padding: '12px 28px',
        color: 'white',

        '&:hover': {
            background: theme.palette.primary.dark,
        },
        '&:disabled': {
            background: grey[300],
            color: grey[500],
            cursor: 'not-allowed'
        }
    },
    root_small: {
        padding: '5px 20px',
        fontSize: 16,
    },
    root_large: {
        padding: '5px 32px',
        //fontSize: 14,
    },
    progress: {
        color: 'white',
        marginLeft: 8
    },
    primary: {
        background: theme.palette.primary.main,
        '&:hover': { background: theme.palette.primary.dark }
    },
    secondary: {
        background: theme.palette.secondary.main,
        '&:hover': { background: theme.palette.secondary.dark }
    },
    red: {
        background: red[700],
        '&:hover': { background: red[900] }
    },
    grey: {
        background: grey[300],
        '&:hover': { background: grey[400] },
        color: grey[900],
    },
    white: {
        color: grey[900],
        background: 'white',
        '&:hover': { background: alpha('#fff', 0.66) }
    },
    transparent_white: {
        color: 'white',
        backgroundColor: 'rgba(255, 255, 255, 0.28)',
        borderRadius: 10,
        padding: '5px 20px',
        border: '2px solid white',
        '&:hover': { background: alpha('#fff', 0.66) }
    },
    transparent_white_square: {
        color: 'white',
        background: 'transparent',
        border: '1px solid white',
        '&:hover': {
            background: alpha('#fff', 0.66)
        }
    },
    white_square: {
        color: 'white',
        background: alpha('#fff', 0.3),
        border: '1px solid white',
        '&:hover': {
            background: alpha('#fff', 0.5)
        }
    },
    transparent_primary: {
        color: theme.palette.primary.main,
        background: 'transparent',
        border: `1px solid ${theme.palette.primary.main}`,
        '&:hover': {
            color: 'white',
            background: theme.palette.primary.dark,
        }
    },
    transparent_secondary: {
        color: theme.palette.secondary.main,
        background: 'transparent',
        border: `1px solid ${theme.palette.secondary.main}`,
        '&:hover': {
            color: 'white',
            background: theme.palette.secondary.dark,
        }
    },
    transparent: {
        color: 'white',
        background: 'transparent',
        borderRadius: 10,
        padding: '5px 20px',
        border: '1px solid white',
        '&:hover': { background: alpha('#fff', 0.66) }
    },
    black: {
        color: 'white',
        background: '#151515',
        '&:hover': { background: 'black' }
    }
}))

const RoundedButton = props => {

    const classes = useStyles()
    const { loading, children, color, onClick, fullWidth, disabled, size, startIcon, endIcon } = props

    return (
        <Button onClick={!loading ? onClick : undefined} fullWidth={fullWidth} disabled={disabled}
            endIcon={endIcon}
            startIcon={startIcon}
            {...props}
            color={'default'}
            className={cx({
                [classes.root]: true,
                [classes.root_small]: size === 'small',
                [classes.root_large]: size === 'large',
                [classes.secondary]: color === 'secondary',
                [classes.red]: color === 'red',
                [classes.white]: color === 'white',
                [classes.white_square]: color === 'white_square',
                [classes.black]: color === 'black',
                [classes.grey]: color === 'grey',
                [classes.transparent_primary]: color === 'transparent_primary',
                [classes.transparent_secondary]: color === 'transparent_secondary',
                [classes.transparent]: color === 'transparent',
                [classes.transparent_white]: color === 'transparent_white',
                [classes.transparent_white_square]: color === 'transparent_white_square',
            })}>
            {children}
            {loading ? (<CircularProgress size={24} className={classes.progress} />) : null}
        </Button>
    );
}

export default RoundedButton;