import React from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Divider, Grid, Typography } from '@material-ui/core'
import RoundedButton from '../../../components/Actions/RoundedButton'
import SimpleCard from '../../../components/Structure/Cards/SimpleCard'
import ReactHtmlParser from 'react-html-parser'

const styles = makeStyles(theme => ({
    root: {
        marginTop: 80
    },
    title: {
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        height: 2
    },
    label: {
        color: '#494949',
        fontSize: 16,
        fontWeight: 700,
    },
    content: {
        height: 'calc(100% - 24px)'
    },
    iframe: {
        '& iframe': {
            maxHeight: 400
        }
    },
    iframeCode: {
        background: 'rgba(189, 139, 64, 0.04)',
        padding: 10,
        overflowWrap: 'break-word',
        boxSizing: 'border-box',
        height: '100%'
    }
}))

const VirtualExhibitions = ({ data, content, submitButton, onEdit }) => {

    const classes = styles()
    const theme = useTheme()

    return (
        <div className={classes.root}>
            <Grid container justifyContent='flex-end' spacing={4}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.title}>
                                {content.title}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <RoundedButton
                        color='black'
                        size='small'
                        style={submitButton}
                        onClick={onEdit}
                    >
                        {content.button}
                    </RoundedButton>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={6}>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.label}>
                                {content.iframe}
                            </Typography>
                            <div className={classes.content}>
                                <SimpleCard fullHeight padding='small'>
                                    <div className={classes.iframeCode}>
                                        {data.iframe}
                                    </div>
                                </SimpleCard>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.label}>
                                {content.preview}
                            </Typography>
                            <div className={classes.iframe}>
                                {data.iframe && ReactHtmlParser(data.iframe)}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div >
    )
}

export default VirtualExhibitions