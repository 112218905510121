import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import WebsiteLayout from '../layouts/WebsiteLayout/WebsiteLayout';
import SignInView from '../layouts/SignInView/SignInView';

const MainRouter = ({ history }) => {
    return (
        <Router history={history}>
            <Switch>
                <Route path={'/signin'} component={SignInView} />
                <Route path={'/'} component={WebsiteLayout} />
                <Redirect to='/home' />
            </Switch>
        </Router>
    )
}

export default MainRouter