import React from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Divider, Grid, IconButton, Typography, useMediaQuery, withStyles } from '@material-ui/core'
import RoundedButton from '../../../components/Actions/RoundedButton'
import GenericImage from '../../../components/Structure/DisplayData/GenericImage'
import SimpleCard from '../../../components/Structure/Cards/SimpleCard'
import FilledDisplayData from '../../../components/Structure/DisplayData/FilledDisplayData'
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon'
import { grey } from '@material-ui/core/colors'

const styles = makeStyles(theme => ({
    root: {
        marginTop: 80
    },
    title: {
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        height: 2
    },
    deleteButton: {
        background: theme.palette.primary.main,
        padding: 8,
        '&:hover': {
            background: theme.palette.primary.dark,
        }
    },
}))

const Magazines = ({ data, content, form, files, submitButton, onEdit, onDelete,
    onUploadDocument, onDownloadDocument }) => {

    const classes = styles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.only('xs'))

    const labelID = 'magazines'

    return (
        <div className={classes.root}>
            <Grid container justifyContent='flex-end' spacing={4}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.title}>
                                {content.title}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <RoundedButton
                        color='black'
                        size='small'
                        style={submitButton}
                        onClick={() => onEdit(labelID)}
                    >
                        {content.button}
                    </RoundedButton>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={6}>
                        <Grid item xs={12} sm={5}>
                            <Grid container alignItems='center' spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className={classes.labelDoc}>
                                        {files.file.label}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <DocumentButton onClick={() => onUploadDocument(files.file)}>
                                                <SVGIcon src={content.fileData.upload.icon} size={18} />
                                            </DocumentButton>
                                        </Grid>
                                        <Grid item>
                                            <DocumentButton
                                                onClick={() => onDownloadDocument(files.file)}
                                                disabled={!files.file.status}
                                            >
                                                <SVGIcon src={content.fileData.download.icon} size={18} />
                                            </DocumentButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <SimpleCard fullHeight>
                                <Grid container justifyContent='flex-end' spacing={4}>
                                    <Grid item xs></Grid>
                                    <Grid item>
                                        <IconButton
                                            className={classes.deleteButton}
                                            onClick={() => onDelete(labelID)}
                                        >
                                            <SVGIcon src={content.delete} size={22} color='white' />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FilledDisplayData data={form.magazine} value={data.magazine} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FilledDisplayData data={form.magazine_description} value={data.magazine_description} />
                                    </Grid>
                                </Grid>
                            </SimpleCard>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Magazines

const DocumentButton = withStyles(theme => ({
    root: {
        background: 'black',
        padding: 10,
        margin: 'auto',
        display: 'flex',
        boxShadow: '0px 4.19564px 19.9293px rgba(0, 0, 0, 0.15)',
        '&:hover': {
            background: theme.palette.secondary.dark
        }
    },
    disabled: {
        color: 'white',
        backgroundColor: `${grey[500]} !important`
    }
}), { withTheme: true })(IconButton)

const RedDocumentButton = withStyles(theme => ({
    root: {
        background: theme.palette.primary.main,
        padding: 10,
        margin: 'auto',
        boxShadow: '0px 4.19564px 19.9293px rgba(0, 0, 0, 0.15)',
        display: 'flex',
        '&:hover': {
            background: theme.palette.primary.light
        }
    },
    disabled: {
        color: 'white',
        backgroundColor: `${grey[500]} !important`
    }
}), { withTheme: true })(IconButton)