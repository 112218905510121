
export const translations = {
    title:'Analíticos',
    menu:[{id:1, label:'General'}, {id:2, label:'Histórico'}],
    
        
    
    
}

export default translations