import React from 'react'
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import { onGetDateFormat } from '../../../../shared/utility'
import SimpleTable from './components/SimpleTable'

const styles = makeStyles(theme => ({
    root: {
        marginTop: 80
    },
    topTitle: {
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        height: 2
    },
    title: {
        color: '#494949',
        fontSize: 16,
        fontWeight: 700,
    },
    cellHeader: {
        paddingTop: 16,
        paddingBottom: 16,
        borderBottom: 'none',
    }
}))

const Artists = ({ content, data, tableFilter, onUpdateTableFilter, total, loading,
    onChangeSearch, onAddRegister, onRemoveItem, filter, onFilterStatus, catalog_filter  }) => {

    const classes = styles()
    const labelID = 'artists'

    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.topTitle}>
                                {content.title}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <SimpleTable
                        data={data}
                        tableFilter={tableFilter}
                        total={total}
                        noData={content.noData}
                        header={content.header}
                        loading={loading}
                        status={filter}
                        onUpdateTableFilter={(data) => onUpdateTableFilter(labelID, data)}
                        onChangeSearch={(data) => onChangeSearch(labelID, data)}
                        onAddRegister={() => onAddRegister(labelID)}
                        onRemoveItem={(id) => onRemoveItem(labelID, id)}
                        onChangeStatus={(data) => onFilterStatus(labelID, data)}
                        catalog_filter={catalog_filter}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

const stylesData = makeStyles(theme => ({
    label: {
        fontSize: 14,
        fontWeight: 600,
        color: '#A0A0A0'
    },
    text: {
        fontSize: 16,
        color: '#151515'
    }
}))

const DisplayData = ({ label, data, isDate, format }) => {

    const classes = stylesData()

    let formatData = data ? data : '-'
    if (isDate)
        formatData = format ? onGetDateFormat(formatData, format) : onGetDateFormat(formatData, 'DD MMM YYYY')

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography className={classes.label}>
                    {label}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.text}>
                    {formatData}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default Artists