import { Grid, Icon, Input, makeStyles, OutlinedInput, Typography } from '@material-ui/core';
import { useEffect, useRef, useState} from 'react';
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon';
import SimpleModal from '../../../components/Modals/SimpleModal';
import searchIcon from '../../../assets/icons/search.svg';
import { CONFIG_SETTINGS } from '../../../config';
import { isValidDocument } from '../../../shared/utility';
import noimage from '../../../assets/noimagegold.png'

const SelectArtworkModal = ({ open, onClose, onPick, artworks }) => {
	const classes = useStyles();

	const [artworks_array, setArtworks] = useState(artworks);
	const [search, setSearch] = useState('');

	const timerRef = useRef();

	const handleSearch = (e) => {
		setSearch(e.target.value);
	};

	const updateArtworks = () => {
		let _artworks = [];
		_artworks = artworks.filter((item) => item.title.toLowerCase().includes(search.toLowerCase()) || item.artist.toLowerCase().includes(search.toLowerCase()));
		setArtworks(_artworks);
	};

	const onStartCountDown = () => {
		if (timerRef.current) clearTimeout(timerRef.current);
		timerRef.current = setTimeout(updateArtworks, 300);
	};

	const onStopCountDown = () => {
		if (timerRef.current) clearTimeout(timerRef.current);
	};

	return (
		<SimpleModal padding='medium' open={open} onClose={onClose} maxWidth={'sm'}>
			<Typography variant='h6'>Pick an artwork</Typography>
			<div className={classes.input_container}>
				<OutlinedInput
					fullWidth
					className={classes.input}
					value={search}
					onChange={handleSearch}
					endAdornment={<SVGIcon src={searchIcon} size={22} color='grey' />}
					placeholder='Search by artwork or artist'
					onKeyUp={onStartCountDown}
					onKeyDown={onStopCountDown}
				/>
			</div>
			<Grid container className={classes.data_container} alignItems='flex-start'>
				<Grid item container xs={12} alignItems={'center'} className={classes.row_header}>
					<Grid item xs={1}></Grid>
					<Grid item xs={4} style={{ paddingLeft: 10 }}>
						<Typography className={classes.header} variant='subtitle1'>
							Artwork
						</Typography>
					</Grid>
					<Grid item xs={1} />
					<Grid item xs={5}>
						<Typography className={classes.header} variant='subtitle1'>
							Artist
						</Typography>
					</Grid>
				</Grid>
				{artworks_array.map((item) => {
					const isValid = isValidDocument(item.web_image);
					const src = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${item.web_image}` : noimage;
					return (
						<Grid item container xs={12} alignItems={'center'} key={item.id_artwork} className={classes.row} onClick={() => onPick(item.id_artwork, item.title)}>
							<Grid item xs={1}>
								<img src={src} className={classes.image} />
							</Grid>
							<Grid item xs={4} style={{ paddingLeft: 10 }}>
								<Typography className={classes.text}>{item.title}</Typography>
							</Grid>
							<Grid item xs={1} />
							<Grid item xs={5}>
								<Typography className={classes.text}>{item.artist}</Typography>
							</Grid>
						</Grid>
					);
				})}
			</Grid>
		</SimpleModal>
	);
};

const useStyles = makeStyles((theme) => ({
	input_container: {
		width: 354,
		margin: '45px auto',
	},
	input: {
		fontWeight: 600,
		fontSize: 16,
		background: '#FCFAF7',
	},
	text: {
		fontSize: 16,
		color: '#6D6D6D',
	},
	header: {
		fontSize: 18,
		color: '#6D6D6D',
	},
	data_container: {
		height: 400,
		overflowY: 'scroll',
		margin: '0px 15px',
		marginBottom: 20,
	},
	row: {
		minHeight: 70,
		borderBottom: '1px solid #DCDCDC',
		cursor: 'pointer',
		padding: '0px 10px',
		transition: 'all 0.2s',
		'&:hover': {
			background: 'lightgrey',
			transition: 'all 0.15s',
		},
	},
	row_header: {
		minHeight: 70,
		borderBottom: '1px solid #DCDCDC',
		padding: '0px 10px',
		transition: 'all 0.2s',
	},
	image: {
		height: 30,
		width: 30,
		borderRadius: '100%',
	},
}));

export default SelectArtworkModal;
