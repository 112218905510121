import shipping_icon from '../../assets/icons/shipping.svg'
import center_call_icon from '../../assets/icons/center-call.svg'
import offers_icon from '../../assets/icons/offers.svg'

import logo from '../../assets/logo_refaccionaria.png'


export const translations = {
    title:'Precio',
    menu:[{id:1, label:'Mayoreo'}, {id:2, label:'Menudeo'}],
    topbar:{
        logo:logo,
        button:'Cancelar'
    },
    wholesale:{
        cart:{
            title:'Carrito',
            subtotal:'Subtotal',
            taxes:'Impuestos',
            total:'Total',
            button:'Pagar'
        },
        equivalences:{
            title:'Equivalencias',
            code:'Código',
            description:'Descripción',
            button:'Agregar'
        },
        complementary:{
            title:'Complementarios',
            code:'Código',
            description:'Descripción',
            button:'Agregar'
        }
    }
    
}

export default translations