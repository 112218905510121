export const translations = {
    title: 'Enquiries',
    noData: 'No enquiries to show.',
    filter: [
        { id: 0, value: 0, label: 'All' },
    ],
    form: {
        search: {
            placeholder: 'Search'
        },
        artwork_id:{
            label: 'Artwork',
            placeholder: 'Artwork',
            helperText: 'Select an artwork'
        },
        name: {
            label: 'Name',
            placeholder: 'Name',
            helperText: 'Name is required'
        },
        email: {
            label: 'Email',
            placeholder: 'Email',
            helperText: 'Introduce a valid email'
        },
        consultant_id: {
            label: 'Asigned to',
            placeholder: 'Asigned to',
            helperText: 'Consultant is required'
        },
        date: {
            label: 'Next date',
            placeholder: 'Next date',
            helperText: 'Next date is required'
        },
        artwork_purchase_request_status_id: {
            label: 'Status',
            placeholder: 'Status',
        },
        comments:{
            label: 'Comments',
            placeholder: 'Comments',
        }
    },
    header: [
        { id: 1, label: 'ID', align: 'center' },
        { id: 2, label: 'Name' },
        { id: 3, label: 'Email' },
        { id: 4, label: 'Consultant' },
        { id: 5, label: 'Artwork' },
        { id: 6, label: 'Next date' },
        { id: 7, label: 'Status' }
    ],
    modals: {
        add_modal: {
            title: 'Add enquiry',
            save_button: 'Add'
        },
        edit_modal: {
            title: 'Edit enquiry',
            save_button: 'Save'
        }
    }
}

export default translations