import { useState, useEffect, useRef } from "react"
import { TOKEN_STATUSES } from "../../config"
import { onClearTokenSession, onGetCurrentUserProfile, onGetErrorMessage, onGetPrivateCurrentCart, onGetTokenFromMemory, onReAuthToken, onValidateToken } from "../../shared/utility"

const useWebsiteLayout = ({ is_auth, history, onUpdateUserData, onUpdateAuthStatus, onUpdateCart, search_params, onUpdateSearchParams }) => {
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [busy, setBusy] = useState(false)

    useEffect(() => {
        actions.onInitModule()
    }, [])

    // useInterval(() => {
    //     actions.onCheckTokenExpirationDate()
    // }, 30000)

    const actions = {
        onInitModule: async () => {
            try {
                // Get token
                setLoading(true)
                const session_data = onGetTokenFromMemory()
                const token_status = onValidateToken(session_data)
                let _user
                switch (token_status) {
                    case TOKEN_STATUSES.EXPIRED:
                        console.log('EXPIRED')
                        onClearTokenSession()
                        onUpdateUserData(null)
                        onUpdateAuthStatus(false)
                        history.push('/signin')
                    case TOKEN_STATUSES.NOT_VALID:
                        console.log('NOT VALID')
                        onClearTokenSession()
                        onUpdateUserData(null)
                        onUpdateAuthStatus(false)
                        history.push('/signin')
                        break;
                    case TOKEN_STATUSES.REAUTH:
                        console.log('Reauthenticate')
                        await onReAuthToken()
                        _user = await onGetCurrentUserProfile()
                        onUpdateUserData(_user)
                        onUpdateAuthStatus(true)
                        break;
                    default:
                        console.log('LOGGED')
                        _user = await onGetCurrentUserProfile()
                        onUpdateUserData(_user)
                        onUpdateAuthStatus(true)
                        break;
                }

            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        },
        onCheckTokenExpirationDate: async () => {
            if (busy || loading) return
            try {
                // Get token
                const session_data = onGetTokenFromMemory()
                const token_status = onValidateToken(session_data)
                let _user

                switch (token_status) {
                    case TOKEN_STATUSES.EXPIRED:
                        console.log('EXPIRED')
                        onClearTokenSession()
                        onUpdateUserData(null)
                        onUpdateAuthStatus(false)
                        history.push('/signin')
                    case TOKEN_STATUSES.NOT_VALID:
                        console.log('NOT VALID')
                        onClearTokenSession()
                        onUpdateUserData(null)
                        onUpdateAuthStatus(false)
                        history.push('/signin')
                        break;
                    case TOKEN_STATUSES.REAUTH:
                        console.log('Reauthenticate')
                        await onReAuthToken()
                        _user = await onGetCurrentUserProfile()
                        onUpdateUserData(_user)
                        onUpdateAuthStatus(true)
                        break;
                    default:
                        console.log('LOGGED')
                        //Nothing is logged and we already have the data (:
                        break;
                }
            } catch (error) {
                console.log(error)
            }
        },
        onItemSelected: async (url) => {
            const label = url.replace('/','')

            try {
                setSending(true)
                switch (url) {
                    case '/log-out':
                        await onClearTokenSession()
                        await onUpdateAuthStatus(false)
                        await onUpdateUserData(null)
                        history.push('/signin')
                        break;
                    default:
                        if(search_params && search_params.last_visited)
                            if(search_params.last_visited !== label)
                                onUpdateSearchParams(null)
                        history.push(url)
                        break;
                }
                setSending(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
    }

    return { loading, sending, error, actions }
}

export default useWebsiteLayout;

const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
