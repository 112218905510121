import React, { useState, useEffect } from 'react'
import { isFormValid, isValidDocument, onGetErrorMessage, onGetSelectedFormData, onGetUserCatalogs, onInitForm, onSetErrorsToForm } from '../../shared/utility'
import { CONFIG_SETTINGS, private_server } from '../../config'
import { generic_errors } from '../../texts/eng/genericErrors'
import queryString from 'query-string'
import catalogs from '../../texts/eng/catalogs'

const useArtworksDetailsView = ({ is_auth, language, history, match, content }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)

    const [artworkID, setArtworkID] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [status, setStatus] = useState(0)

    const [loadingEnquiries, setLoadingEnquiries] = useState(false)
    const [enquiries, setEnquiries] = useState([])
    const [tableFilter, setTableFilter] = useState({ limit: 10, offset: 0, order_by: 'id_artwork_purchase_request', order: 0 })
    const [total, setTotal] = useState(0)

    const [users, setUsers] = useState([])
    const [artists, setArtists] = useState([])
    const [artwork, setArtwork] = useState(null)
    const [artworkImages, setArtworkImages] = useState([])
    const [selectedArtworkImage, setSelectedArtworkImage] = useState(null)
    const [imageType, setImageType] = useState({ id: 'web_image', value: 1, label: 'web' })
    const [enquiry, setEnquiry] = useState(null)

    //MODALS
    const [artwork_image_modal, setArtworkImageModal] = useState(false)
    const [picture_modal, setPictureModal] = useState(false)
    const [add_modal, setAddModal] = useState(false)
    const [edit_modal, setEditModal] = useState(false)
    const [edit_enquiry_modal, setEditEnquiryModal] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
        if (is_auth)
            actions.initModule()
    }, [is_auth])

    useEffect(() => {
        if (artwork) {
            let _form = JSON.parse(JSON.stringify(formData))
            _form = onInitForm(_form, artwork, content.sections.general_info.form)

           _form.artist_id.options = onGetUserCatalogs(artists)

            setForm(_form)
        }
    }, [artwork, edit_modal])

    useEffect(() => {
        if (enquiry) {
            let _form = JSON.parse(JSON.stringify(formData))
            _form = onInitForm(_form, enquiry, content.sections.enquiries.form)
            _form.consultant_id.options = [...users]
            setForm(_form)
        }
    }, [enquiry, edit_enquiry_modal])

    useEffect(() => {
        if (is_auth)
            actions.onFilterEnquiries()
    }, [is_auth, tableFilter])

    const actions = {
        initModule: async () => {
            const artworkURL = match.params.url

            try {
                setLoading(true)

                let _users = await private_server.get(`user/all`)
                _users = _users.data.data.users
                setUsers(onGetUserCatalogs(_users))

                let _artists = await private_server.get(`artist/all`)
                _artists = _artists.data.data.artists
                setArtists(_artists)


                let _artwork = await private_server.get(`artwork/url?url=${artworkURL}`)
                _artwork = _artwork.data.data.artwork
                setArtwork({ ..._artwork })
                setStatus(_artwork.artwork_status_id)
                setArtworkID(_artwork.id_artwork)

                let _artworkImages = await private_server.get(`artworkimage/all/${_artwork.id_artwork}`)
                _artworkImages = _artworkImages.data.data.artwork_images

                let _gallery = []

                _artworkImages.map(artwork => {
                    const webImage = artwork ? artwork.web_image : null
                    const mobileImage = artwork ? artwork.mobile_image : null
                    const approved = artwork ? artwork.approved : null

                    let isValid = isValidDocument(webImage)
                    const selectedImageWeb = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${webImage}` : null
                    isValid = isValidDocument(mobileImage)
                    const selectedImageMob = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${mobileImage}` : null

                    _gallery.push({ id: artwork.id_artwork_image, web_image: selectedImageWeb, mobile_image: selectedImageMob, approved: approved })
                })

                setArtworkImages(_gallery)


                /* ENQUIRIES */
                let filter = {
                    AND: [
                        { field: "artwork_id", operator: "=", value: _artwork.id_artwork }
                    ]
                }
                filter = encodeURIComponent(JSON.stringify(filter))
                const _enquiries = await private_server.get(`artworkpurchaserequest/all?filter=${filter}`)
                setEnquiries([..._enquiries.data.data.artwork_purchase_requests])
                setTotal(_enquiries.data.data.count)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onUpdateTableFilter: (data) => setTableFilter(data),
        onFilterEnquiries: async () => {
            try {
                setLoadingEnquiries(true)
                let filter = {
                    AND: [
                        { field: "artwork_id", operator: "=", value: artworkID }
                    ]
                }
                filter = encodeURIComponent(JSON.stringify(filter))
                let params = `?${queryString.stringify(tableFilter)}&filter=${filter}`
                const _enquiries = await private_server.get(`artworkpurchaserequest/all${params}`)
                setEnquiries(_enquiries.data.data.artwork_purchase_requests)
                setTotal(_enquiries.data.data.count)
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setLoadingEnquiries(false)
        },
        onSelectEnquiry: (data) => {
            setEnquiry(data)
            actions.onOpenEnquiryModal()
        },
        onCloseSuccess: () => setSuccess(false),
        onCloseError: () => setError(null),
        onUpdateImageType: (step) => setImageType(step),
        onChange: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if (error) setError(null)
            setForm(temp)
        },
        onSetArtworkStatus: async (value) => {
            setStatus(value)
            try {
                setSending(true)

                const data2send = { artwork_status_id: value }

                let _artwork = await private_server.patch(`artwork/${artworkID}`, data2send)
                _artwork = _artwork.data.data.artwork
                setArtwork({ ..._artwork })

                setError(null)
                setSending(false)
            } catch (error) {
                console.log(error)
                setSending(false)
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onAddArtwork: () => {
            console.log('ADD C:')

        },
        onOpenPictureModal: () => setPictureModal(true),
        onClosePictureModal: () => setPictureModal(false),
        onOpenArtworkImageModal: (artwork) => {
            if (artwork && artwork.id)
                setSelectedArtworkImage(artwork)

            setArtworkImageModal(true)
        },
        onCloseArtworkImageModal: () => {
            setArtworkImageModal(false)
            setSelectedArtworkImage(null)
        },
        onOpenEditModal: () => setEditModal(true),
        onCloseEditModal: () => setEditModal(false),
        onOpenEnquiryModal: () => setEditEnquiryModal(true),
        onCloseEnquiryModal: () => setEditEnquiryModal(false),
        onDeleteArtwork: async () => {
            try {
                setSending(true)
                await private_server.delete(`artwork/${artworkID}`)
                history.push('/artworks')
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onSaveArtwork: async () => {
            setSending(true)
            try {
                const generalInfoKeys = Object.keys(content.sections.general_info.form)

                const errors = isFormValid(form, generalInfoKeys)

                // Error handler
                if (errors && errors.length) {
                    console.log(errors)
                    const _form = onSetErrorsToForm(form, errors)
                    setForm(_form)
                    setError(generic_errors[language].INCOMPLETE_FORM)
                    return
                }

                const data2send = onGetSelectedFormData(form, artwork)
                data2send['artwork_status_id'] = status

                let _artwork = await private_server.patch(`artwork/${artworkID}`, data2send)
                _artwork = _artwork.data.data.artwork
                setArtwork({ ..._artwork })

                setError(null)
                setEditModal(false)
                setSending(false)
            } catch (error) {
                console.log(error)
                setSending(false)
                setError(onGetErrorMessage(error))
            }
        },
        onUpdateArtwork: async () => {
            setSending(true)
            try {
                let _artwork = await private_server.get(`artwork/${artworkID}`)
                _artwork = _artwork.data.data.artwork
                setArtwork({ ..._artwork })

                let _form = JSON.parse(JSON.stringify(formData))

                Object.keys(content.sections).map(section => {
                    _form = onInitForm(_form, _artwork, content.sections[section].form)
                })

                setForm(_form)

                setError(null)
                setPictureModal(false)
                setSending(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setSending(false)
            }    
        },
        onClickSmallCard: async(key, prev_value) =>{
            try {
                setSending(true)

                const data2send = {}
                data2send[key] = prev_value === 0 ? 1 : 0

				let _artwork = await private_server.patch(`artwork/${artworkID}`, data2send);
				_artwork = _artwork.data.data.artwork;
				setArtwork({ ..._artwork });

                setError(null)
                setSending(false)
            } catch (error) {
                console.log(error)
                setSending(false)
                setError(onGetErrorMessage(error))
            }
        },
        onUpdateArtworkImages: async (artwork) => {
            try {
                let _prevGallery = [...artworkImages]
                let _artworkIndex = _prevGallery.findIndex((a => a.id === artwork.id))

                if (_artworkIndex !== -1)
                    _prevGallery[_artworkIndex] = { ...artwork }
                else if (_prevGallery.length < 12)
                    _prevGallery.push(artwork)

                //onSubmitArtwork
                setArtworkImages(_prevGallery)
                setArtworkImageModal(false)
                setSelectedArtworkImage(null)
                setSending(false)
            } catch (error) {
                console.log(error)
            }
        },
        onDeleteImage: async (artwork) => {
            try {
                setSending(true)
                let _gallery = [...artworkImages]
                const index = _gallery.indexOf(artwork)

                if (index > -1) {
                    await private_server.delete(`artworkimage/${artwork.id}`)
                    _gallery.splice(index, 1)
                }

                setArtworkImages(_gallery)
                setSending(false)
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
        },
        onSetApproved: async(id_image, value) =>{
            setSending(true)
            try {
                const data2send = {approved: value}

                const res = await private_server.patch(`artworkimage/${id_image}`, data2send)

                const _artwork = res.data.data.artwork_image

                const webImage = _artwork ? _artwork.web_image : null
                const mobileImage = _artwork ? _artwork.mobile_image : null

                let isValid = isValidDocument(webImage)
                    const selectedImageWeb = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${webImage}` : null
                    isValid = isValidDocument(mobileImage)
                    const selectedImageMob = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${mobileImage}` : null

                actions.onUpdateArtworkImages({ id: _artwork.id_artwork_image, web_image: selectedImageWeb, mobile_image: selectedImageMob, approved: _artwork.approved })

                setSending(false)
            } catch (error) {
                setError(onGetErrorMessage(error))
                setSending(false)
            }
        },
        onUpdateEnquiry: async () => {
            try {
                setSending(true)
                const enquiryKeys = Object.keys(content.sections.enquiries.form)

                const errors = isFormValid(form, enquiryKeys)
                console.log(errors)
                // Error handler
                if (errors && errors.length) {
                    const _form = onSetErrorsToForm(form, errors)
                    setForm(_form)
                    setError(generic_errors[language].INCOMPLETE_FORM)
                    setSending(false)
                    return
                }

                const data2send = onGetSelectedFormData(form, enquiryKeys)

                const enquiryID = enquiry.id_artwork_purchase_request
                await private_server.patch(`artworkpurchaserequest/${enquiryID}`, data2send)
                await actions.onFilterEnquiries()

                setEditEnquiryModal(false)
                setError(null)
                setEditModal(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
    }

    const system = { loading, sending, success, error }
    const view_data = {
        form, status, total, loadingEnquiries, tableFilter, enquiries,
        artwork, artworkID, artworkImages, selectedArtworkImage,
        artwork_image_modal, picture_modal, add_modal, edit_modal,
        edit_enquiry_modal, imageType
    }

    return { system, view_data, actions }
}

export default useArtworksDetailsView

const formData = {
    order: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'order',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    // quantity: {
    //     value: '',
    //     error: false,
    //     isVisited: false,
    //     isRequired: true,
    //     isValid: false,
    //     config: {
    //         id: 'quantity',
    //         type: 'text',
    //         fullWidth: true,
    //     },
    //     rules: {
    //         type: 'distance',
    //         min: 1,
    //         max: 200
    //     }
    // },
    artist_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        image: true,
        config: {
            id: 'artist_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none'
        }
    },
    title: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'title',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    dimensions: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'dimensions',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    technique: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'technique',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    url: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'url',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    price: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'price',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'numeric',
        }
    },
    description: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 4
        },
        rules: {
            type: 'distance',
            min: 0,
            max: 200
        }
    },
    date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'date',
            type: 'date',
            fullWidth: true,
        },
        rules: { type: 'date' }
    },
    quantity: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'quantity',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'numeric',
            min: 0
        }
    },
    sold: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sold'
        }
    },
    new: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'new'
        }
    },
    most_viewed: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'most_viewed'
        }
    },
    nft: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'nft'
        }
    },
    nft_sold: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'nft_sold'
        }
    },
    nft_price: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'nft_price',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'numeric',
            min: 0
        }
    },
    external_url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'external_url',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 0,
            max: 200
        }
    },
    /* ENQUIRY */
    consultant_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        image: true,
        config: {
            id: 'consultant_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none'
        }
    },
    artwork_purchase_request_status_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        icon: 'square',
        options: [...catalogs.application_statuses],
        config: {
            id: 'artwork_purchase_request_status_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none'
        }
    },
    comments: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'comments',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 4
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    }
}