import React, { useEffect, useState } from 'react';
import { Button, TextField, withStyles, makeStyles, InputLabel, Icon, Grid, Typography } from '@material-ui/core';
import { ruleValidation } from '../../../components/Forms/customFunctions';
import SelectArtworkModal from '../modals/SelectArtworkModal';
import { blueGrey, grey } from '@material-ui/core/colors';

const ArtworkPicker = (props) => {
	const { data, onChange, artworks} = props;

	const classes = useStyles();

	const [openModal, setOpenModal] = useState(false)
    const [title, setTitle] = useState('')

	useEffect(() => {
		if(value){
			const _title = artworks.find(item => item.id_artwork === value)
			setTitle(_title.title)
		}
	}, []);

	
	const onInnerChange = (id, title) => {
		let temp = { ...data };
		temp.value = id;
		temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
		temp.isVisited = true;
		onChange(temp);
        setTitle(title)
		setOpenModal(false)
	};

	const { config, value, isValid, isRequired, isVisited } = data;
	const isError = (isVisited && !isValid) || (isVisited && isRequired && !isValid);
	let interConfig = { ...config };
	if (!isError) interConfig.helperText = '';

	return (
		<>
			<SelectArtworkModal open={openModal} onClose={()=>setOpenModal(false)} artworks={artworks} onPick={onInnerChange}/>
			<InputLabel shrink htmlFor={interConfig.id}>
          		{interConfig.label}
        	</InputLabel>
            <Grid container className={classes.root} justifyContent='flex-start' onClick={()=>setOpenModal(true)} >
                <Grid item xs={6} style={{marginBottom: 2}}><Typography color='textSecondary' style={{fontWeight: 400, fontSize: 16}}>{title ? title : 'Select Artwork'}</Typography></Grid>
                <Grid item container justifyContent='flex-end' xs={6}><Icon style={{marginLeft: 'auto', color: grey[600]}}>arrow_drop_down</Icon></Grid>
            </Grid>
			{/* <Typography id={interConfig.id} className={classes.root} onClick={()=>setOpenModal(true)}>
				{value}
			</Typography> */}
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
        cursor: 'pointer',
        paddingTop: 10, 
        boxSizing: 'border-box',
        borderBottom: `2px solid ${grey[600]}`,
		'&:hover':{
            borderBottom: `3px solid ${grey[600]}`,
            boxSizing: 'border-box',
		}
	},
}));


export default ArtworkPicker;



