import React, { useState, useEffect } from 'react'
import { isFormValid, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm } from '../../shared/utility'
import queryString from 'query-string'
import { public_server, private_server } from '../../config'
import { generic_errors } from '../../texts/eng/genericErrors'
import catalogs from '../../texts/eng/catalogs'

const useMagazinesView = ({ is_auth, history, content, search_params, onUpdateSearchParams }) => {

    //Check if its the last page visited and has a saved search and filter
	let savedSearch = null
	let savedFilterValue = 0
	if(search_params && search_params.last_visited)
		if(search_params.last_visited === 'magazines'){
			savedSearch = search_params.search ? search_params.search : null;
			savedFilterValue = search_params.filter_value ? search_params.filter_value : 0;
		}

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [total, setTotal] = useState(0)
    const [search, setSearch] = useState(savedSearch)
    const [filterValue, setFilterValue] = useState(savedFilterValue)
    const [tableFilter, setTableFilter] = useState({ limit: 9, offset: 0, order_by: 'id_magazine', order: 0 })
    const [magazines, setMagazines] = useState([])

    //MODAL
    const [add_modal, setAddModal] = useState(false)

    const errors_txt = generic_errors.eng

    useEffect(() => {
        if (is_auth)
            actions.onInitModule()
    }, [is_auth])

    useEffect(() => {
        if (is_auth)
            actions.onFilterMagazine()
    }, [is_auth, tableFilter])

    useEffect(() => {
        if (is_auth)
            actions.onResetTableFilter()
    }, [is_auth, filterValue])


    const actions = {
        onInitModule: () => {
            window.scrollTo(0, 0)
            let _form = JSON.parse(JSON.stringify(formData))
            _form = onInitForm(_form, null, content.form)
            _form.magazine_type_id.options = catalogs.magazine_types

            if(savedSearch)
				_form.search.value = savedSearch

            setForm(_form)
        },
        onResetTableFilter: () => setTableFilter({ limit: 9, offset: 0, order_by: 'id_magazine', order: 0 }),
        onUpdateTableFilter: (data) => setTableFilter(data),
        onCloseSuccess: () => setSuccess(false),
        onCloseError: () => setError(null),
        onSearchMagazine: (data) => {
            let temp = { ...form }
            temp.search = { ...data }
            if (error) setError(null)
            setForm(temp)
            setSearch(data.value)
        },
        onUpdateFilterValue: (id) => setFilterValue(id),
        onFilterMagazine: async () => {
            try {
                setLoading(true)

                let filter = { AND: [] }
                if (filterValue > 0) filter.AND.push({ field: "magazine_status_id", operator: "=", value: filterValue })
                if (search && search.length > 0)
                    filter.AND.push({ field: "title", operator: "LIKE", value: `%%${search}%%` })
                let _filter = encodeURIComponent(JSON.stringify(filter))
                let params = filter.AND.length > 0 ? `?${queryString.stringify(tableFilter)}&filter=${_filter}` : `?${queryString.stringify(tableFilter)}`
                const _magazines = await private_server.get(`magazine/all${params}`)
                setMagazines([..._magazines.data.data.magazines])
                setTotal(_magazines.data.data.count)
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onVisitUrl: (url) => {
            if (url && url.length > 0){
                onUpdateSearchParams({search: search, filter_value: filterValue, last_visited: 'magazines'})
                history.push(`magazines/${url}`)
            }
        },
        onOpenAddModal: () => setAddModal(true),
        onCloseAddModal: () => setAddModal(false),
        onChange: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if (error) setError(null)
            setForm(temp)
        },
        onSaveMagazine: async () => {

            const errors = isFormValid(form)
            if (errors && errors.length) {
				console.log(errors);
				const _form = onSetErrorsToForm(form, errors);
				setForm(_form);
				setError(errors_txt.INCOMPLETE_FORM);
				return;
			}

            try {
                setSending(true)

                const data2send = onGetFormData(form)
                data2send.magazine_status_id = 1
                delete data2send.search

                let _magazine = await private_server.post(`magazine`, data2send)
                _magazine = _magazine.data.data.magazine
                setMagazines([...magazines, _magazine])

                setError(null)
                setAddModal(false)
                setSending(false)
                history.push(`magazines/${_magazine.url}`)

            } catch (error) {
                console.log(error)
                setSending(false)
                setError(onGetErrorMessage(error))
            }
        }
    }

    const system = { loading, sending, success, error }
    const view_data = { form, total, tableFilter, filterValue, magazines, add_modal }

    return { system, view_data, actions }
}

export default useMagazinesView

const formData = {
    search: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'search',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    title: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'title',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'url',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    magazine_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'magazine_type_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select'
        }
    },
}