import { Grid, makeStyles, Typography } from "@material-ui/core"
import AddCard from "./components/AddCard"
import BannerCard from "./components/BannerCard"
import AddBannerModal from "./modals/AddBannerModal/AddBannerModal"
import DeleteBannerModal from "./modals/DeleteBannerModal"
import EditBannerModal from "./modals/EditBannerModal/EditBannerModal"


const Banners = ({content, data, actions, modals, language, selected}) => {

    const classes = useStyles()

    //console.log(data)

    return(
        <div className={classes.root} >
            <AddBannerModal open={modals.add_banner} onClose={()=>actions.onChangeModalStatus('add_banner', false)} language={language} 
            onCompletedBanners={actions.onCompletedBanners} />
            <EditBannerModal open={modals.edit_banner} onClose={()=>actions.onChangeModalStatus('edit_banner', false)} language={language} 
             selected={selected} onCompletedBanners={actions.onCompletedBanners} />
            <DeleteBannerModal open={modals.delete_banner} onClose={()=>actions.onChangeModalStatus('delete_banner', false)} 
            language={language} selected={selected} onCompleted={actions.onCompletedBanners} />
            <div className={classes.title}><Typography variant='caption'>{content.title}</Typography></div>
            <Grid container spacing={5}>
                {data.map((item, index) =>{
                    return(
                        <Grid item md={4} sm={6} xs={12} key={index.toString()}>
                            <BannerCard {...item} content={content.card} onEdit={()=>actions.onEditBanner(item)} 
                            onDelete={()=>actions.onDeleteBanner(item)} />
                        </Grid>
                    )
                })}
                <Grid item md={4} sm={6} xs={12}>
                    <AddCard onClick={()=>actions.onChangeModalStatus('add_banner', true)} />
                </Grid>
            </Grid>
        </div>
    )

}

export default Banners

const useStyles = makeStyles(theme => ({
    root:{
        margin:'20px 0px',
        padding:'60px 0px',
        borderTop:'2px solid #CFCFCF',
        position:'relative'
    },
    title:{
        position:'absolute',
        padding:'0px 24px',
        background:'#FCFCFC',
        textTransform:'uppercase',
        left:'50%',
        top:-14,
        transform:'translateX(-50%)',
        letterSpacing:'0.16rem'
    }
}))