export const translations = {
    title: 'Contact',
    noData: 'No contacts to show.',
    filter: [
        { id: -1, value: -1, label: 'All' },
        { id: 0, value: 0, label: 'No contacted' },
        { id: 1, value: 1, label: 'Contacted' },
    ],
    form: {
        search: {
            placeholder: 'Search'
        },
        name: {
            placeholder: 'Name',
            helperText: 'Name is required'
        },
        email: {
            label: 'Email',
            placeholder: 'Email',
            helperText: 'Introduce a valid email'
        },
        country_code: {
            label: 'Code'
        },
        phone: {
            label: 'Phone',
            placeholder: 'Phone',
            helperText: 'Phone is required'
        },
        date: {
            label: 'Date',
            placeholder: 'Date',
            helperText: 'Date is required'
        },
        contacted: {
            label: 'Contacted',
            placeholder: 'Contacted',
        },
        contacted_at: {
            label: 'Contacted at',
            placeholder: 'Contacted at',
            helperText: 'Select a date'
        },
        message: {
            label: 'Message',
            placeholder: 'Message',
            helperText: 'Introduce a valid message'
        }
    },
    header: [
        { id: 1, label: 'ID', align: 'center' },
        { id: 2, label: 'Name' },
        { id: 3, label: 'Email' },
        { id: 4, label: 'Phone' },
        { id: 5, label: 'Date' },
        { id: 6, label: 'Contacted' }
    ],
    modals: {
        add_modal: {
            title: 'Add contact',
            save_button: 'Add'
        },
        edit_modal: {
            title: 'Edit contact',
            save_button: 'Save'
        }
    }
}
