import moment from 'moment';
import { useEffect, useState } from 'react';
import { private_server } from '../../config';
import {
	isFormValid,
	onGetArtistCatalogs,
	onGetArtworksCatalogs,
	onGetCatalogs,
	onGetErrorMessage,
	onGetExhibitionCatalogs,
	onGetFormData,
	onGetMagazineCatalogs,
	onGetUserCatalogs,
	onInitForm,
	onSetErrorsToForm,
	updateAuthorizationHeader,
} from '../../shared/utility';
import queryString from 'query-string';
import catalogs from '../../texts/eng/catalogs';
import { generic_errors } from '../../texts/eng/genericErrors';

const useApplicationsView = ({ language, content }) => {
	const [loading, setLoading] = useState(false);
	const [sending, setSending] = useState(false);
	const [error, setError] = useState(null);

	const [form, setForm] = useState({ ...formData });
	const [mainFilter, setMainFilter] = useState(1);
	const [total, setTotal] = useState({ magazine: 0, exhibition: 0, artist: 0 });
	const [filterValue, setFilterValue] = useState({ magazine: 0, exhibition: 0, artist: 0 });
	const [tableFilter, setTableFilter] = useState({
		artist: { limit: 10, offset: 0, order_by: 'id_user', order: 1 },
		magazine: { limit: 10, offset: 0, order_by: 'id_magazine_application', order: 1 },
		exhibition: { limit: 10, offset: 0, order_by: 'id_exhibition_application', order: 1 },
	});
	const [search, setSearch] = useState(null);

	//Artworks for edit modal (exhibition & magazine options)
    const [applicationArtworks, setApplicationArtworks] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)


	//DATA
	const [applications, setApplications] = useState({
		artist: [],
		magazine: [],
		exhibition: [],
	});

	const [add_modal, setAddModal] = useState({
		artist: false,
		exhibition: false,
		magazine: false,
	});
	const [edit_modal, setEditModal] = useState({
		artist: false,
		exhibition: false,
		magazine: false,
	});

	useEffect(() => {
		actions.onInitModule();
	}, []);

	useEffect(() => {
		actions.onFilterApplications();
	}, [tableFilter]);

	useEffect(() => {
		actions.onResetTableFilter();
	}, [mainFilter, filterValue, edit_modal]);

	const actions = {
		onInitModule: async () => {
			try {
				setLoading(true);
				updateAuthorizationHeader(private_server);

				let params = '';

				//Get artists
				params = `?${queryString.stringify(tableFilter.artist)}`;
				let _filter = JSON.stringify({
					OR: [
						{ field: 'artist_status_id', operator: '=', value: 1 },
						{ field: 'artist_status_id', operator: '=', value: 5 },
					],
				});
				_filter = encodeURIComponent(_filter);
				const _aapp = await private_server.get(`artist/all${params}&filter=${_filter}`);

				//Get magazine applications
				params = `?${queryString.stringify(tableFilter.magazine)}`;
				const _mapp = await private_server.get(`/magazineapplication/all${params}`);

				//Get exhibition applications
				params = `?${queryString.stringify(tableFilter.exhibition)}`;
				const _eapp = await private_server.get(`/exhibitionapplication/all${params}`);

				//Fill state
				setApplications({ magazine: _mapp.data.data.magazine_applications, exhibition: _eapp.data.data.exhibition_applications, artist: _aapp.data.data.artists });
				setTotal({ magazine: _mapp.data.data.count, exhibition: _eapp.data.data.count, artist: _aapp.data.data.count });

				// Init Form
				let _form = { ...formData };
				Object.keys(content.sections).map((section) => {
					if (content.sections[section].form) _form = onInitForm(_form, null, content.sections[section].form);
					if (content.sections[section].add_form) _form = onInitForm(_form, null, content.sections[section].add_form);
				});
				_form.search.config = { ..._form.search.config, ...content.search };

				//Fill catalogs
				_filter = JSON.stringify({
					OR: [
						{ field: 'exhibition_status_id', operator: '=', value: 1 },
						{ field: 'exhibition_status_id', operator: '=', value: 2 },
					],
				});
				_filter = encodeURIComponent(_filter);
				let _ecat = await private_server.get(`exhibition/all?filter=${_filter}`);
				_form.exhibition_id.options = onGetExhibitionCatalogs(_ecat.data.data.exhibitions);

				_filter = JSON.stringify({
					OR: [
						{ field: 'magazine_status_id', operator: '!=', value: 3 },
						{ field: 'magazine_status_id', operator: '!=', value: 4 },
					],
				});
				_filter = encodeURIComponent(_filter);
				let _mcat = await private_server.get(`magazine/all?filter=${_filter}`);
				_form.magazine_id.options = onGetMagazineCatalogs(_mcat.data.data.magazines);

				let _acat = await private_server.get(`artist/all`);
				_form.user_id.options = onGetUserCatalogs(_acat.data.data.artists);

				_form.gender_id.options = catalogs.user_genders

				setForm(_form);
			} catch (error) {
				console.log(error);
			}
			setLoading(false);
		},
		onChangeMainFilter: (value) => setMainFilter(value),
		onResetTableFilter: () =>
			setTableFilter({
				artist: { limit: 10, offset: 0, order_by: 'id_user', order: 1 },
				magazine: { limit: 10, offset: 0, order_by: 'id_magazine_application', order: 1 },
				exhibition: { limit: 10, offset: 0, order_by: 'id_exhibition_application', order: 1 },
			}),
		onSearch: async (data) => {
			try {
				// setSending(true)
				let temp = { ...form };
				temp.search = { ...data };
				if (error) setError(null);
				setForm(temp);
				setSearch(data.value);
			} catch (error) {
				setError(onGetErrorMessage(error));
			}
			//setSending(false)
		},
		onChangeAddModalStatus: (key, status) => {
			setForm({...formData});
			let _modals = { ...add_modal };
			_modals[key] = status;
			setAddModal(_modals);
		},
		onChangeEditModalStatus: (key, status) => {
			let _modals = { ...edit_modal };
			_modals[key] = status;
			setEditModal(_modals);
		},
		onChange: (data) => {
			let temp = { ...form };
			const id = data.config.id;
			temp[id] = { ...data };
			if (error) setError(null);
			setForm(temp);
		},
		onUpdateTableFilter: (data) => {
			switch (mainFilter) {
				case 1:
					setTableFilter({ ...tableFilter, artist: data });
					break;
				case 2:
					setTableFilter({ ...tableFilter, exhibition: data });
					break;
				case 3:
					setTableFilter({ ...tableFilter, magazine: data });
					break;

				default:
					break;
			}
		},
		onUpdateFilterValue: (data) => {
			switch (mainFilter) {
				case 1:
					setFilterValue({ ...filterValue, artist: data });
					break;
				case 2:
					setFilterValue({ ...filterValue, exhibition: data });
					break;
				case 3:
					setFilterValue({ ...filterValue, magazine: data });
					break;

				default:
					break;
			}
		},
		onFilterApplications: async () => {
			setLoading(true);
			try {
				setSending(true);
				let filter = {};
				let _filter = {};
				let isValidFilter = {};
				let params = '';

				switch (mainFilter) {
					case 1:
						if (filterValue.artist > 0) filter.AND = [{ field: 'artist_status_id', operator: '=', value: filterValue.artist }];
						else
							filter.OR = [
								{ field: 'artist_status_id', operator: '=', value: 1 },
								{ field: 'artist_status_id', operator: '=', value: 5 },
							];

						if (search && search.length > 0) {
							if (filterValue.artist > 0)
								filter.AND.push({
									OR: [
										{ field: 'first_name', operator: 'LIKE', value: `%%${search}%%` },
										{ field: 'last_name', operator: 'LIKE', value: `%%${search}%%` },
										{ field: 'professional_name', operator: 'LIKE', value: `%%${search}%%` },
									],
								});
							else
								filter = {
									AND: [
										{
											OR: [
												{ field: 'artist_status_id', operator: '=', value: 1 },
												{ field: 'artist_status_id', operator: '=', value: 5 },
											],
										},
										{
											OR: [
												{ field: 'first_name', operator: 'LIKE', value: `%%${search}%%` },
												{ field: 'last_name', operator: 'LIKE', value: `%%${search}%%` },
												{ field: 'professional_name', operator: 'LIKE', value: `%%${search}%%` },
											],
										},
									],
								};
						}

						_filter = encodeURIComponent(JSON.stringify(filter));
						isValidFilter = Object.keys(filter).length !== 0 || Object.getPrototypeOf(filter) !== Object.prototype;
						params = isValidFilter ? `?${queryString.stringify(tableFilter.artist)}&filter=${_filter}` : `?${queryString.stringify(tableFilter.artist)}`;
						const _aapp = await private_server.get(`artist/all${params}`);
						setApplications({ ...applications, artist: [..._aapp.data.data.artists] });
						setTotal({ ...total, artist: _aapp.data.data.count });
						break;
					case 2:
						if (filterValue.exhibition > 0) filter.AND = [{ field: 'application_status_id', operator: '=', value: filterValue.exhibition }];
						if (search && search.length > 0) {
							if (filterValue.exhibition > 0)
								filter.AND.push({
									OR: [
										{ field: 'exhibition', operator: 'LIKE', value: `%%${search}%%` },
										{ field: 'user', operator: 'LIKE', value: `%%${search}%%` },
									],
								});
							else
								filter.OR = [
									{ field: 'exhibition', operator: 'LIKE', value: `%%${search}%%` },
									{ field: 'user', operator: 'LIKE', value: `%%${search}%%` },
								];
						}

						_filter = encodeURIComponent(JSON.stringify(filter));
						isValidFilter = Object.keys(filter).length !== 0 || Object.getPrototypeOf(filter) !== Object.prototype;
						params = isValidFilter ? `?${queryString.stringify(tableFilter.exhibition)}&filter=${_filter}` : `?${queryString.stringify(tableFilter.exhibition)}`;
						const _eapp = await private_server.get(`exhibitionapplication/all${params}`);
						setApplications({ ...applications, exhibition: [..._eapp.data.data.exhibition_applications] });
						setTotal({ ...total, exhibition: _eapp.data.data.count });
						break;
					case 3:
						if (filterValue.magazine > 0) filter.AND = [{ field: 'application_status_id', operator: '=', value: filterValue.magazine }];
						if (search && search.length > 0) {
							if (filterValue.magazine > 0)
								filter.AND.push({
									OR: [
										{ field: 'magazine', operator: 'LIKE', value: `%%${search}%%` },
										{ field: 'user', operator: 'LIKE', value: `%%${search}%%` },
									],
								});
							else
								filter.OR = [
									{ field: 'magazine', operator: 'LIKE', value: `%%${search}%%` },
									{ field: 'user', operator: 'LIKE', value: `%%${search}%%` },
								];
						}

						_filter = encodeURIComponent(JSON.stringify(filter));
						isValidFilter = Object.keys(filter).length !== 0 || Object.getPrototypeOf(filter) !== Object.prototype;
						params = isValidFilter ? `?${queryString.stringify(tableFilter.magazine)}&filter=${_filter}` : `?${queryString.stringify(tableFilter.magazine)}`;
						const _mapp = await private_server.get(`magazineapplication/all${params}`);
						setApplications({ ...applications, magazine: [..._mapp.data.data.magazine_applications] });
						setTotal({ ...total, magazine: _mapp.data.data.count });
						break;

					default:
						break;
				}

				setLoading(false);
			} catch (error) {
				setError(onGetErrorMessage(error));
				console.log(error);
				setLoading(false);
			}
			setSending(false);
		},
		onAddRegister: async (type) => {
			try {
				setSending(true);

				const keys = Object.keys(content.sections[`${type}s`].add_form);
				const errors = isFormValid(form, keys);

				// Error handler
				if (errors && errors.length) {
					const _form = onSetErrorsToForm(form, errors);
					setForm(_form);
					setError(generic_errors[language].INCOMPLETE_FORM);
					setSending(false);
					return;
				}

				const data2send = onGetFormData(form, keys);

				switch (type) {
					case 'artist':
						data2send.artist_status_id = 1
						let _artist = await private_server.post(`artist`, data2send)
						_artist = _artist.data.data.artist
						setApplications({...applications, artist: [...applications.artist, _artist] })
						break;
					case 'exhibition':
						console.log(data2send)
						let _eapp = await private_server.post(`exhibitionapplication/`, data2send);
						console.log(_eapp)
						_eapp = _eapp.data.data.exhibitionapplication;
						setApplications({ ...applications, exhibition: [...applications.exhibition, _eapp ] });

						break;
					case 'magazine':
						let _mapp = await private_server.post(`magazineapplication/`, data2send);
						_mapp = _mapp.data.data.magazineapplication;
						setApplications({ ...applications, magazine: [ ...applications.magazine, _mapp ] });
				}
				actions.onChangeAddModalStatus(type, false)
				setError(null);
				setSending(false);
				actions.onResetTableFilter()
			} catch (error) {
				console.log(error);
				setSending(false);
				setError(onGetErrorMessage(error));
			}
		},
		onInitEditForm: async (type, data) => {
            try {
                /* INIT FORM */
                let _form = {...formData}
                _form = onInitForm(_form, data, content.sections[`${type}s`].form)
                _form.user_id.options = form.user_id.options
				_form.payment_status_id.options = catalogs.payment_statuses
				_form.application_status_id.options = catalogs.application_statuses

                setSelectedItem({ type: type, data: data }) //For artworks applications

                let _filter = JSON.stringify({
					AND: [
						{ field: 'artist_id', operator: "=", value: _form.user_id.value }]
				})
				_filter = encodeURIComponent(_filter)

				let _artworks = await private_server.get(`artwork/all?filter=${_filter}`)
				_artworks = _artworks.data.data.artworks
				_form.artwork_id.options = onGetArtworksCatalogs(_artworks)

                switch (type) {
                    case 'exhibition':
                        _filter = JSON.stringify({
                            AND: [
                                { field: 'exhibition_application_id', operator: "=", value: data.id_exhibition_application }]
                        })
                        _filter = encodeURIComponent(_filter)

                        let exhibitionArtworks = await private_server.get(`exhibitionapplicationartwork/all?filter=${_filter}`)
                        setApplicationArtworks(exhibitionArtworks.data.data.exhibition_application_artworks)

                        break
                    case 'magazine':
                        _filter = JSON.stringify({
                            AND: [
                                { field: 'magazine_application_id', operator: "=", value: data.id_magazine_application }]
                        })
                        _filter = encodeURIComponent(_filter)

                        let magazineArtworks = await private_server.get(`magazineapplicationartwork/all?filter=${_filter}`)
                        setApplicationArtworks(magazineArtworks.data.data.magazine_application_artworks)

                        break
                }
				setForm(_form)
				actions.onChangeEditModalStatus(type, true)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
        },
		updateArtistArtworks: async(id_artist) =>{
			try {
                let _filter = JSON.stringify({
					AND: [
						{ field: 'artist_id', operator: "=", value: id_artist }]
				})
				_filter = encodeURIComponent(_filter)

				let _artworks = await private_server.get(`artwork/all?filter=${_filter}`)
				_artworks = _artworks.data.data.artworks
				
				let  _form = {...form}
				_form.artwork_id.options = onGetArtworksCatalogs(_artworks)
				setForm(_form)

            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
		},
		onEditRegister: async (type) => {
            try {
                setSending(true)

                const keys = Object.keys(content.sections[`${type}s`].form)
                const errors = isFormValid(form, keys)

                // Error handler
                if (errors && errors.length) {
                    const _form = onSetErrorsToForm(form, errors)
                    setForm(_form)
                    setError(generic_errors[language].INCOMPLETE_FORM)
                    setSending(false)
                    return
                }

                const data2send = onGetFormData(form, keys)

                switch (type) {
                    case 'exhibition':
						console.log(data2send)
                        await private_server.patch(`exhibitionapplication/${selectedItem.data.id_exhibition_application}`, data2send)
                        break
                    case 'magazine':
						console.log(data2send)
                        await private_server.patch(`magazineapplication/${selectedItem.data.id_magazine_application}`, data2send)
                        break

                }

                setError(null)
                actions.onChangeEditModalStatus(type, false)
                await actions.onFilterApplications()
                setSending(false)
            } catch (error) {
                console.log(error)
                setSending(false)
                setError(onGetErrorMessage(error))
            }
        },

		/* APPLICATION ARTWORKS */
        onAddArtwork: async () => {
            try {
                setSending(true)
                //applicationArtwork
                let data = null
                let path = null

                switch (selectedItem.type) {
                    case 'exhibition':
                        data = {
                            exhibition_application_id: selectedItem.data.id_exhibition_application,
                            artwork_id: form.artwork_id.value,
                        }
                        path = 'exhibitionapplicationartwork'

                        break
                    case 'magazine':
                        data = {
                            magazine_application_id: selectedItem.data.id_magazine_application,
                            artwork_id: form.artwork_id.value,
                        }
                        path = 'magazineapplicationartwork'

                        break
                }
                await private_server.post(path, data)
                await actions.onInitEditForm(selectedItem.type, selectedItem.data)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
		onDeleteArtwork: async (id) => {
            try {
                setSending(true)
                let path = null
                switch (selectedItem.type) {
                    case 'exhibition':
                        path = 'exhibitionapplicationartwork'
                        break
                    case 'magazine':
                        path = 'magazineapplicationartwork'
                        break
                }

                await private_server.delete(`${path}/${id}`)
                await actions.onInitEditForm(selectedItem.type, selectedItem.data)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },


 
	}

	const system = { loading, sending, error };
	const view_data = { mainFilter, applications, search, tableFilter, total, form, filterValue, applicationArtworks };
	const modals = { add_modal, edit_modal };

	return { system, modals, view_data, actions };
};

const formData = {
	search: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: false,
		isValid: false,
		config: {
			id: 'search',
			type: 'text',
			fullWidth: true,
		},
		rules: {
			type: 'distance',
			min: 1,
			max: 200,
		},
	},
	// ARTIST
	email: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'email',
			type: 'email',
			fullWidth: true,
		},
		rules: {
			type: 'email'
		},
	},
	password: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'password',
			type: 'password',
			fullWidth: true,
		},
		rules: {
			type: 'distance',
			min: 8,
			max: 16,
		},
	},
	first_name: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'first_name',
			type: 'text',
			fullWidth: true,
		},
		rules: {
			type: 'distance',
			min: 1,
			max: 200,
		},
	},
	last_name: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: false,
		isValid: false,
		config: {
			id: 'last_name',
			type: 'text',
			fullWidth: true,
		},
		rules: {
			type: 'distance',
			min: 1,
			max: 200,
		},
	},
	gender_id: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'gender_id',
			type: 'select',
			fullWidth: true,
		},
		rules: {
			type: 'select'
		},
	},
	/* EXHIBITION DATA */
	exhibition_id: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		options: [],
		image: true,
		config: {
			id: 'exhibition_id',
			type: 'select',
			fullWidth: true,
		},
		rules: {
			type: 'none',
		},
	},
	user_id: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		options: [],
		image: true,
		config: {
			id: 'user_id',
			type: 'select',
			fullWidth: true,
		},
		rules: {
			type: 'none',
		},
	},
	payment_status_id: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		icon: 'square',
		options: [...catalogs.payment_statuses],
		config: {
			id: 'payment_status_id',
			type: 'select',
			fullWidth: true,
		},
		rules: {
			type: 'none',
		},
	},
	application_status_id: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		icon: 'square',
		options: [...catalogs.application_statuses],
		config: {
			id: 'application_status_id',
			type: 'select',
			fullWidth: true,
		},
		rules: {
			type: 'none',
		},
	},
	artwork_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        image: true,
        config: {
            id: 'artwork_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none'
        }
    },
	/* MAGAZINE DATA */
	magazine_id: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		options: [],
		image: true,
		config: {
			id: 'magazine_id',
			type: 'select',
			fullWidth: true,
		},
		rules: {
			type: 'none',
		},
	},


};

export default useApplicationsView;
