import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import LineInputForm from '../../../components/Forms/LineInputForm'
import SimpleModal from '../../../components/Modals/SimpleModal'
import RoundedButton from '../../../components/Actions/RoundedButton'
import LineSelectForm from '../../../components/Forms/LineSelectForm'
import CountryCodeButton from '../../../components/Forms/CountryCodeButton'

const ChangePasswordModal = ({ open, form, onChange, onSubmit, onClose, content, isUserProfile }) => {

    return (
			<SimpleModal maxWidth='xs' open={open} onClose={onClose}>
				<Grid container alignItems='center' spacing={4}>
					<Grid item xs={12} sm={12}>
						<Typography variant='h6'>{content.title}</Typography>
					</Grid>
					{isUserProfile && (
						<Grid item xs={12} sm={12}>
							<LineInputForm data={form.previous_password} onChange={onChange} />
						</Grid>
					)}
					<Grid item xs={12} sm={12}>
						<LineInputForm data={form.new_password} onChange={onChange} />
					</Grid>
					<Grid item xs={12} sm={12}>
						<LineInputForm data={form.new_password_confirm} onChange={onChange} />
					</Grid>
					<Grid item xs></Grid>
					<Grid item>
						<RoundedButton size='small' onClick={onSubmit}>
							{content.save_button}
						</RoundedButton>
					</Grid>
				</Grid>
			</SimpleModal>
		);
}

export default ChangePasswordModal