import { Grid, IconButton, Typography, useMediaQuery, withStyles } from '@material-ui/core'
import { green, grey, red } from '@material-ui/core/colors'
import { makeStyles, useTheme } from '@material-ui/styles'
import React from 'react'
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon'
import SimpleCard from '../../../components/Structure/Cards/SimpleCard'
import FilledDisplayData from '../../../components/Structure/DisplayData/FilledDisplayData'
import FilterSelect from '../../../components/Forms/FilterSelect'
import RoundedButton from '../../../components/Actions/RoundedButton'
import { catalogs } from '../../../texts/eng/catalogs'
import ImageEdition from '../../../components/Structure/DisplayData/ImageEdition'
import cx from 'classnames'
import ButtonsSelect from '../../../components/Forms/ButtonsSelect'

const styles = makeStyles(theme => ({
    root: {

    },
    topBar: {
        [theme.breakpoints.up('md')]: {
            marginTop: -50
        }
    },
    content: {
     //   marginTop: 30
    },
    deleteButton: {
        background: theme.palette.primary.main,
        padding: 8,
        '&:hover': {
            background: theme.palette.primary.dark,
        }
    },
    topData: {
        top: 0,
        right: 0,
        position: 'absolute',
        display: 'inline-flex'
    },
    referenceCode: {
        marginTop: 10,
        marginRight: 10,
        textTransform: 'uppercase',
        fontSize: 12,
        color: '#A6A6A6',
        '& span': {
            fontSize: 14,
            color: theme.palette.secondary.main
        }
    },
    marginBottom: {
        marginBottom: 20
    },
    sideImage: {
        height: '100%',
        display: 'grid',
        alignItems: 'stretch',
    },
    tabContainer: {
        width: '100%',
        boxSizing: 'border-box'
    },
    labelTab: {
        width: '100%',
        boxSizing: 'border-box',
        fontSize: 16,
        fontWeight: 600,
        fontFamily: theme.typography.fontFamily,
        textTransform: 'uppercase',
        letterSpacing: '0.165em',
        padding: '4px 0px',
        marginBottom: 10,
        cursor: 'pointer',
        color: '#858585',
    },
    labelTabSelected: {
        color: theme.palette.secondary.main,
        borderBottom: `solid 2px ${theme.palette.secondary.main}`,

    },
    labelDoc: {
        color: '#A0A0A0',
        fontSize: 12,
        fontWeight: 700,
    },
    title: {
        color: grey[800],
        fontSize: 20,
        fontWeight: 600
    },
    exhibitionType: {
        width: '100%',
        padding: 8,
        border: `1px solid ${grey[500]}`,
        borderRadius: 10,
        boxSizing: 'border-box'
    },
    exhibitionTypeSelected: {
        background: theme.palette.secondary.main,
        border: `1px solid ${theme.palette.secondary.main}`,
    },
    exhibitionTypeLabel: {
        color: grey[600],
        fontSize: 12,
    },
    exhibitionTypeLabelSelected: {
        color: 'white',
        fontWeight: 600
    }
}))

const GeneralInfo = ({ exhibition, status, imageType, form, files, content, onEditPicture,
    onOpenEditModal, onUpdateImageType, onSetExhibitionStatus, submitButton,
    onDeleteExhibition, onRemoveDocument, onUploadDocument, onDownloadDocument }) => {

    const classes = styles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.only('xs'))

    const image = exhibition ? exhibition[imageType.id] : null

    return (
        <div className={classes.root}>
            <div className={classes.topBar}>
                <Grid container justifyContent='flex-end' alignItems='center' spacing={2}>
                    <Grid item>
                        <FilterSelect
                            value={status}
                            menuFilter={catalogs.exhibition_statuses}
                            onFilter={onSetExhibitionStatus}
                        //disabled
                        />
                    </Grid>
                    <Grid item>
                        <RoundedButton
                            color='black'
                            size='small'
                            style={submitButton}
                            onClick={onOpenEditModal}
                        >
                            {content.edit_button}
                        </RoundedButton>
                    </Grid>
                    <Grid item>
                        <IconButton
                            className={classes.deleteButton}
                            onClick={onDeleteExhibition}
                        >
                            <SVGIcon src={content.delete} size={22} color='white' />
                        </IconButton>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.tabContainer}>
                <Grid container spacing={2}>
                    {content.image_options.map((tab, key) => (
                        <Grid item key={`exhibition-image-type-item-${key.toString()}`}>
                            <div onClick={() => onUpdateImageType(tab)}
                                className={cx({
                                    [classes.labelTab]: true,
                                    [classes.labelTabSelected]: imageType.value === tab.value
                                })}>
                                {tab.label}
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </div>
            <div className={classes.content}>
                <Grid container alignItems='stretch' spacing={8}>
                    <Grid item xs={12}>
                        <Grid container alignItems='stretch' spacing={4}>
                            <Grid item xs={12} md={5}>
                                <div className={classes.sideImage}>
                                    <ImageEdition src={image} onClick={() => onEditPicture('image')} />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <div style={{ position: 'relative' }}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <Typography className={classes.title}>
                                                {exhibition.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container justifyContent='space-between' spacing={2}>
                                                {catalogs.exhibition_types.map((type, key) => {
                                                    const selected = exhibition.exhibition_type_id === type.id

                                                    return (
                                                        <Grid item key={`exhibition-type-item-${key.toString()}`} xs={12} sm>
                                                            <div className={cx({
                                                                [classes.exhibitionType]: true,
                                                                [classes.exhibitionTypeSelected]: selected
                                                            })}>
                                                                <Typography
                                                                    align='center'
                                                                    className={cx({
                                                                        [classes.exhibitionTypeLabel]: true,
                                                                        [classes.exhibitionTypeLabelSelected]: selected
                                                                    })}
                                                                >
                                                                    {type.label}
                                                                </Typography>
                                                            </div>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SimpleCard fullHeight>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={5}>
                                                        <FilledDisplayData
                                                            data={form.artworks}
                                                            value={exhibition.min_artwork_number}
                                                            value2={exhibition.max_artwork_number}
                                                            unit1={form.min_artwork_number.config.unit}
                                                            unit2={form.max_artwork_number.config.unit}
                                                        />
                                                    </Grid>
                                                    <Grid item xs></Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Grid container alignItems='center' spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Typography className={classes.labelDoc}>
                                                                    {files.brochure.label}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item>
                                                                        <DocumentButton onClick={() => onUploadDocument(files.brochure)}>
                                                                            <SVGIcon src={content.fileData.upload.icon} size={18} />
                                                                        </DocumentButton>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <DocumentButton
                                                                            onClick={() => onDownloadDocument(files.brochure)}
                                                                            disabled={!files.brochure.status}
                                                                        >
                                                                            <SVGIcon src={content.fileData.download.icon} size={18} />
                                                                        </DocumentButton>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <RedDocumentButton
                                                                            disabled={!files.brochure.status}
                                                                            onClick={() => onRemoveDocument(files.brochure)}
                                                                        >
                                                                            <SVGIcon src={content.fileData.remove.icon} size={18} />
                                                                        </RedDocumentButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FilledDisplayData data={form.short_description} value={exhibition.short_description} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FilledDisplayData data={form.medium_description} value={exhibition.medium_description} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FilledDisplayData data={form.description} value={exhibition.description} />
                                                    </Grid>
                                                </Grid>
                                            </SimpleCard>
                                        </Grid>
                                    </Grid>
                                    <div className={classes.topData}>
                                        {exhibition.featured > 0 &&
                                            <SVGIcon src={content.featuredIcon} color={theme.palette.secondary.main} size={32} />
                                        }
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent='center' spacing={4}>
                            <Grid item>
                                <SimpleCard padding='small'>
                                    <FilledDisplayData data={form.created_by} value={exhibition.creator} />
                                </SimpleCard>
                            </Grid>
                            <Grid item>
                                <SimpleCard padding='small'>
                                    <FilledDisplayData data={form.updated_by} value={exhibition.updater} />
                                </SimpleCard>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid >
            </div>
        </div >
    )
}

const SmallCard = ({ icon, label, value, secondaryColor, primaryColor }) => {

    const _primaryColor = primaryColor ? primaryColor : green[500]
    const _secondaryColor = secondaryColor ? secondaryColor : red[500]
    const styleElement = { fontSize: 15, color: value ? _primaryColor : _secondaryColor }

    return (
        <SimpleCard padding='small'>
            <Grid container justifyContent='center' alignItems='center' spacing={1}>
                <Grid item>
                    {React.cloneElement(icon, { style: styleElement })}
                </Grid>
                <Grid item>
                    <Typography style={{ color: '#636363', fontSize: 16, fontWeight: 600 }}>
                        {label}
                    </Typography>
                </Grid>
            </Grid>
        </SimpleCard>
    )
}

const DocumentButton = withStyles(theme => ({
    root: {
        background: 'black',
        padding: 10,
        margin: 'auto',
        display: 'flex',
        boxShadow: '0px 4.19564px 19.9293px rgba(0, 0, 0, 0.15)',
        '&:hover': {
            background: theme.palette.secondary.dark
        }
    },
    disabled: {
        color: 'white',
        backgroundColor: `${grey[500]} !important`
    }
}), { withTheme: true })(IconButton)

const RedDocumentButton = withStyles(theme => ({
    root: {
        background: theme.palette.primary.main,
        padding: 10,
        margin: 'auto',
        boxShadow: '0px 4.19564px 19.9293px rgba(0, 0, 0, 0.15)',
        display: 'flex',
        '&:hover': {
            background: theme.palette.primary.light
        }
    },
    disabled: {
        color: 'white',
        backgroundColor: `${grey[500]} !important`
    }
}), { withTheme: true })(IconButton)

export default GeneralInfo