import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import ActionModalBar from '../../../components/Actions/ActionModalBar'
import SimpleModal from '../../../components/Modals/SimpleModal'
import InputFile from '../../../components/Forms/InputFile'

const DocumentModal = ({ content, loading, error, open, onClose, onUpdateDocument }) => {

    const [file, setFile] = useState(null)

    useEffect(() => {
        if (!open)
            setFile(null)
    }, [open])

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h6' color='primary'>{content.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputFile id='file' file={file} label={content.label}
                        onChange={(file) => setFile(file)} onDelete={() => setFile(null)} />
                </Grid>
                <Grid item xs={12}>
                    <ActionModalBar btnLabel={content.button} loading={loading} error={error} onSubmit={() => onUpdateDocument(file)} />
                </Grid>
            </Grid>
        </SimpleModal>
    );
}

export default DocumentModal;