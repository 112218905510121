import nftIcon from '../../assets/icons/NFT.svg'
import likeIcon from '../../assets/icons/full_heart.svg'
import trashIcon from '../../assets/icons/trash.svg'
import editIcon from '../../assets/icons/edit.svg'

import viewsIcon from '../../assets/icons/views.svg'
import exhibitionsIcon from '../../assets/icons/exhibitions_filled.svg'
import timeIcon from '../../assets/icons/wall-clock-white-clockhands.svg'
import enquiresIcon from '../../assets/icons/file_filled.svg'
import StopIcon from '@material-ui/icons/Stop'


export const translations = {
    title: 'Artwork',
    detail: 'Detail',
    add_button: 'Add',
    edit_button: 'Edit',
    save_button: 'Save',
    nft: nftIcon,
    like: likeIcon,
    delete: trashIcon,
    sections: {
        general_info: {
            editIcon: editIcon,
            image_options: [
                { id: 'web_image', value: 1, label: 'web' },
                { id: 'mobile_image', value: 2, label: 'mobile' },
            ],
            form: {
                order: {
                    label: 'Order',
                    placeholder: 'Order',
                    helperText: 'Field must be numeric'
                },
                quantity: {
                    label: 'Quantity',
                    placeholder: 'Quantity',
                    helperText: 'Field must be numeric'
                },
                artist_id: {
                    label: 'Artist',
                    placeholder: 'Artist',
                    helperText: 'Select a valid option'
                },
                title: {
                    label: 'Title',
                    placeholder: 'Title',
                    helperText: 'Field required'
                },
                dimensions: {
                    label: 'Dimensions',
                    placeholder: 'Dimensions',
                    helperText: 'Field required'
                },
                technique: {
                    label: 'Technique',
                    placeholder: 'Technique',
                    helperText: 'Field required'
                },
                url: {
                    label: 'URL',
                    placeholder: 'URL',
                    helperText: 'Field required'
                },
                price: {
                    label: 'Price',
                    placeholder: 'Price',
                    helperText: 'Field must be numeric'
                },
                description: {
                    label: 'Description',
                    placeholder: 'Description',
                    helperText: 'Field must be between 0 and 200 characters long'
                },
                date: {
                    label: 'Date',
                    helperText: 'Choose a valid date'
                },
                // quantity: {
                //     label: 'Quantity',
                //     placeholder: 'Quantity',
                //     helperText: 'Field must be numeric'
                // },
                sold: {
                    label: 'Sold'
                },
                new: {
                    label: 'New'
                },
                most_viewed: {
                    label: 'Most viewed'
                },
                nft: {
                    label: 'NFT'
                },
                nft_sold: {
                    label: 'NFT Sold'
                },
                nft_price: {
                    label: 'NFT Price',
                    placeholder: 'NFT Price',
                    helperText: 'Field must be numeric'
                },
                external_url: {
                    label: 'NFT Link',
                    placeholder: 'NFT Link',
                    helperText: 'Field must be between 0 and 200 characters long'
                },
            },
            smallCards: [
                {
                    id: 'sold',
                    label: 'Sold',
                    icon: <StopIcon />
                },
                {
                    id: 'new',
                    label: 'New',
                    icon: <StopIcon />
                },
                {
                    id: 'most_viewed',
                    label: 'Most viewed',
                    icon: <StopIcon />,
                    //  primaryColor: '#BD8B40',
                    // secondaryColor: grey[500]
                }
            ]
        },
        additional_images: {
            title: 'ADDITIONAL IMAGES',
            deleteIcon: trashIcon,
            approved: 'Approved:'
        },
        statistics: {
            title: 'STATISTICS',
            data: [
                {
                    id: 'likes',
                    label: 'Likes',
                    icon: likeIcon
                },
                {
                    id: 'views',
                    label: 'Views',
                    icon: viewsIcon
                },
                {
                    id: 'purchase_requested',
                    label: 'Exhibitions',
                    icon: exhibitionsIcon
                },
                {
                    id: 'time',
                    label: 'Time',
                    icon: timeIcon,
                    unit: 'mins'
                },
                {
                    id: 'enquires',
                    label: 'Enquires',
                    icon: enquiresIcon
                }
            ]
        },
        enquiries: {
            title: 'Enquiries',
            noData: 'No enquiries to show.',
            header: [
                { id: 1, label: 'ID', align: 'center' },
                { id: 2, label: 'Name' },
                { id: 3, label: 'Email' },
                { id: 4, label: 'Consultant' },
                { id: 5, label: 'Next date' },
                { id: 6, label: 'Status' }
            ],
            form: {
                consultant_id: {
                    label: 'Asigned to',
                    placeholder: 'Asigned to',
                    helperText: 'Consultant is required'
                },
                artwork_purchase_request_status_id: {
                    label: 'Status',
                    placeholder: 'Status',
                },
                comments: {
                    label: 'Description',
                    placeholder: 'Description',
                }
            }
        }
    },
    modals: {
        picture: {
            title: 'Update Picture',
            placeholder: '',
            image_selected: '',
            save_button: 'Save',
            mobile: 'Save for mobile view'
        },
        artwork_image_modal: {
            title: 'Update Additional Image',
            placeholder: '',
            image_selected: '',
            save_button: 'Save',
            mobile: 'Save for mobile view',
        },
        edit_artwork:{
            save_button: 'Save',
        },
        edit_enquiry:{
            title:'Edit enquiry',
            save_button: 'Save',
        }
    }
}

export default translations