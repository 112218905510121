import React, { useState } from 'react'
import { Card, CardActionArea, CardContent, CardMedia, Divider, Grid, Icon, IconButton, LinearProgress, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { CONFIG_SETTINGS } from '../../../../config'
import { isValidDocument, onGetCurrencyValue } from '../../../../shared/utility'
import RoundedButton from '../../../../components/Actions/RoundedButton'
import { grey } from '@material-ui/core/colors'
import SVGIcon from '../../../../components/Structure/DisplayData/SVGIcon'
import cx from 'classnames'
import SearchBar from './components/SearchBar'
import ActionBar from './components/ActionBar'

const styles = makeStyles(theme => ({
    root: {
        marginTop: 80
    },
    topTitle: {
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        height: 2
    },
    content: {
        margin: '30px 0px'
    },
    nodata: {
        marginTop: 40
    },
    addIcon: {
        background: 'black',
        borderRadius: '100%',
        color: 'white',
        '&:hover': {
            background: theme.palette.primary.main,
        }
    },
}))

const Artworks = ({ content, data, tableFilter, search, total, loading, onVisitUrl, onUpdateTableFilter,
    onRemoveItem, onAddRegister, onChangeSearch }) => {

    const classes = styles()
    const labelID = 'artworks'

    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.topTitle}>
                                {content.title}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                <div className={classes.toolbar}>
                        <Grid container spacing={2} >
                            <Grid item xs={12}>
                                <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                                    <Grid item xs />
                                    <Grid item><SearchBar value={search} onChange={(data) => onChangeSearch(labelID, data)} /></Grid>
                                    <Grid item> <IconButton className={classes.addIcon} color='primary' onClick={() => onAddRegister(labelID)}><Icon>add</Icon></IconButton> </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </div>
                </Grid>
                {data.length > 0 ? <Grid item xs={12}>
                    {loading ? (
                        <Grid container justifyContent='center'>
                            <Grid item xs={12}><LinearProgress color='secondary' /></Grid>
                        </Grid>
                    ) : null}
                    <div className={classes.content}>
                        <Grid container alignItems='stretch' spacing={4}>
                            {data.map((artwork, key) => (
                                <Grid item xs={12} sm={4} lg={3} key={`exhibition-artwork-${key.toString()}`}>
                                    <ArtworkCard
                                        artwork={artwork}
                                        content={content}
                                        onClick={onVisitUrl}
                                        onDelete={(id) => onRemoveItem(labelID, id)}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                    <div className={classes.actions}>
                        <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={(data) => onUpdateTableFilter(labelID, data)} />
                    </div>
                </Grid> :
                    <Grid item xs={12}>
                        <div className={classes.nodata}>
                            <Grid container justifyContent='center' alignItems='center' direction='column'>
                                <Grid item>
                                    <Typography>{content.noData}</Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                }
            </Grid>
        </div>
    )
}

const stylesCard = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        //height: "calc(100% * (4/3))",
        maxWidth: 400,
        borderRadius: 5,
        padding: 0,
        boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.07)',
        '&:hover': {
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        }
    },
    cardActionArea: {
        position: 'relative',
        padding: 10,
        paddingBottom: 30,
        width: '100%',
        height: '100%',
    },
    media: {
        width: '100%',
        background: 'white',
        paddingTop: "100%",
        borderRadius: 2,
        display: 'block',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        border: '1px solid #EAEAEA'
    },
    mediaNoImage: {
        backgroundSize: '70%',
        backgroundRepeat: 'no-repeat'
    },
    cardContent: {
        padding: 0,
        paddingTop: 16,
        paddingBottom: 16,
        background: 'transparent',
        position: 'relative',
        '&:hover': {
            background: 'white'
        }
    },
    nftButton: {
        position: 'absolute',
        bottom: -16,
        right: 10
    },
    soldButton: {
        fontSize: 12,
        textTransform: 'uppercase'
    },
    statusContainer: {
        display: 'inline-flex',
        alignItems: 'center'
    },
    status: {
        fontSize: 14,
        fontWeight: 600,
        color: grey[800]
    },
    text: {
        fontSize: 14,
        fontWeight: 400,
        color: grey[500],
        '& span': {
            fontSize: 14,
            fontWeight: 600,
            color: grey[800],
        }
    },
    nft_button: {
        background: 'transparent',
        width: 32,
        height: 32,
        margin: 'auto',
        position: 'relative',
        boxSizing: 'border-box',
        borderRadius: '100%',
        padding: 0,
        zIndex: 1,
        '&:hover': {
            background: theme.palette.primary.main,
        }
    },
}))

const ArtworkCard = ({ artwork, content, onClick, onDelete }) => {

    const classes = stylesCard()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    const { id_exhibition_artwork, artwork_sold, artwork_status_id, artwork_price, artwork_url,
        artwork_web_image, artwork_mobile_image } = artwork

    const [hover, setHover] = useState(false)

    const _image = mobile ? artwork_mobile_image : artwork_web_image
    const isValid = isValidDocument(_image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${_image}` : content.noImage

    //   const artworkStatus = catalogs.artwork_statuses.find(artwork => artwork.id === artwork_status_id)

    return (
        <Card className={classes.root} onClick={!hover ? () => onClick(artwork_url) : null}>
            <CardActionArea className={classes.cardActionArea}>
                <CardMedia
                    className={cx({
                        [classes.media]: true,
                        [classes.mediaNoImage]: !isValid
                    })}
                    image={selectedImage}
                />
                <CardContent className={classes.cardContent}>
                    <Grid container alignItems='center' spacing={2}>
                        <Grid item xs>
                            {Boolean(Number(artwork_sold)) &&
                                <RoundedButton color='black' size='small'>
                                    <Typography className={classes.soldButton}>
                                        {content.sold}
                                    </Typography>
                                </RoundedButton>
                            }
                        </Grid>
                        {/*<Grid item>
                            <div className={classes.statusContainer}>
                                <Typography className={classes.status}>
                                    {artworkStatus.label}
                                </Typography>
                                <StopIcon style={{ width: 15, height: 15, color: artworkStatus.color }} />
                            </div>
                        </Grid>*/}
                        {content.data.map((item, key) => (
                            <Grid item xs={12} key={`artwork-card-title-exhibition}${key.toString()}`}>
                                <Typography className={classes.text}>
                                    <span>{item.label}:</span> {artwork[item.id]}
                                </Typography>
                            </Grid>
                        ))}
                        <Grid item xs>
                            <Typography className={classes.text}>
                                <span>{content.price}:</span> {onGetCurrencyValue(artwork_price ? artwork_price : '', 0, '€ ')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <div className={classes.nftButton}>
                        <IconButton
                            classes={{ root: classes.nft_button }}
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                            onClick={() => onDelete(id_exhibition_artwork)}
                            component="span"
                        >
                            {hover ?
                                <SVGIcon src={content.deleteH} size={24} /> :
                                <SVGIcon src={content.delete} />}
                        </IconButton>
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default Artworks