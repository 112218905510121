import React, { useEffect, useState } from 'react';
import { AppBar, Divider, SwipeableDrawer, Grid, IconButton, List, ListItem, ListItemText, makeStyles, Toolbar, useMediaQuery, useScrollTrigger, useTheme, Collapse, Typography, Avatar } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import general_translations from '../../../texts/eng/general-translations'
import cx from 'classnames'
import { CONFIG_SETTINGS, DASHBOARD_SETTINGS, private_server } from '../../../config';
import { actionTypes } from '../../../store/actions';
import { isValidDocument, onClearMemory, onGetErrorMessage, onGetFullname } from '../../../shared/utility';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import RoundedButton from '../../../components/Actions/RoundedButton';
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon';
import { ExpandLess } from '@material-ui/icons';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: '12px 20px',
        margin: 'auto',
        boxSizing: 'border-box',
        position: 'relative',
        paddingLeft: 125
        /* [theme.breakpoints.only('lg')]: {
             width: 1200,
         },
         [theme.breakpoints.down('md')]: {
             width: '100%',
             padding: '12px 0px',
             //height:300
         },
         [theme.breakpoints.down('md')]: {
             width: '100%',
             padding: '0px 16px'
         },*/
    },
    root_mobile: {
        paddingLeft: 0
    },
    menuButton: {
        marginLeft: 20
    },
    button: {
        background: 'white',
        color: 'black',
        '&:hover': {
            background: 'white',
        }
    },
    appbar: {
        background: 'white',
        boxShadow: DASHBOARD_SETTINGS.BOXSHADOW,
        height: 76,
        color: 'grey',
    },
    appbar_mono: {
        background: 'transparent',
        boxShadow: 'none',
        color: 'grey',
    },
    drawer: {
        color: 'white',
        backgroundColor: 'black'
    },
    list: {
        width: 250,
    },
    logoContainer: {
        position: 'relative'
    },
    logo: {
        width: 45,
        height: 'auto',
        position: 'fixed',
        left: 70,
        '&:hover': {
            cursor: 'pointer'
        },
    },
    searchbar: {
        position: 'absolute',
        top: 24,
        left: '50%',
        transform: 'translateX(-50%)'
    },
    subtitle: {
        paddingLeft: theme.spacing(4),
        background: grey[300],
        color: 'black'
    },
    avatar: {
        position: 'absolute',
        right: 20
    },
    applicationMessage: {
        width: '100%',
        padding: 10
    },
    applicationTitle: {
        color: theme.palette.secondary.main,
        fontSize: 12,
        fontWeight: 600
    },
    userName: {
        color: 'black',
        fontSize: 14,
        fontWeight: 600
    },
    userType: {
        color: '#B4B4B4',
        fontSize: 12,
        fontWeight: 600
    },
}))

const Topbar = ({ is_auth, user, history, showSidebar, sidebar_drawer, onItemSelected, 
    onShowApplicationForm, onUpdateSidebarDrawer }) => {

    const classes = useStyles()
    const theme = useTheme()

    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))
    const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 100 })

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const content = general_translations.topbar
    const isValid = user ? isValidDocument(user.image) : false
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${user.image}` : content.noimage

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null)
    }

    const openSideBar = async () => {
        await onUpdateSidebarDrawer(!sidebar_drawer)
    }

    const onSelect = (url)=>{
        handleClose()
        onItemSelected(url)
    }

    return (
        <AppBar position="fixed" className={classes.appbar}>
            <Toolbar className={cx({
                [classes.root]: true,
                [classes.root_mobile]: ismobile || !showSidebar
            })}>
                <IconButton
                    onClick={openSideBar}
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                >
                    <MenuIcon />
                </IconButton>
                <div className={classes.avatar}>
                    {is_auth &&
                        <div>
                            <Grid container alignItems='center'>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography align='right' className={classes.userName}>
                                                {onGetFullname(user.first_name, user.last_name)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography align='right' className={classes.userType}>
                                                {user.user_type}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleMenu}
                                        color="secondary"
                                        style={{ paddingTop: 0, paddingBottom: 0 }}
                                    >
                                        <Avatar src={selectedImage} />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        
                                        getContentAnchorEl={null}
                                        elevation={0}
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        open={open}
                                        onClose={handleClose}
                                    >
                                        {content.profile_menu.map((item, key) => (
                                            <MenuItem
                                                key={`kuvik-pro-topbar-item-${key.toString()}`}
                                                onClick={item.id !== 'profile' ? () => onSelect(item.url) : () => onSelect(`${item.url}/${user.id_user}`)}
                                            >
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Grid>
                            </Grid>
                        </div>
                    }
                </div>
            </Toolbar>
        </AppBar >
    );
}

const styles_link = makeStyles(theme => ({
    root: {
        color: 'white',
        textDecoration: 'initial',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'initial',
            color: theme.palette.secondary.light,
            fontWeight: 500
        }
    },
    selected: {
        borderBottom: '1px solid white',
        paddingBottom: 5,
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    dark: {
        //color: 'black',
        mixBlendMode: 'difference',
        '&:hover': {
            mixBlendMode: 'unset',
            color: theme.palette.secondary.main,
        }
    }
}))

const LinkItem = ({ selected, dark, history, url, title, subtitles }) => {

    const classes = styles_link()

    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const sendToUrl = (url, event) => {
        console.log(event)
        if (url) {
            history.push(url)
            handleClose()
        } else
            handleClick(event)
    }

    let _subtitles = null

    if (subtitles)
        _subtitles = (
            <Menu
                keepMounted
                id="simple-menu"
                anchorEl={anchorEl}
                elevation={10}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                {subtitles.map((item, key) => (
                    <MenuItem onClick={() => sendToUrl(item.url)} key={`kuvik-pro-topbar-submenuitem-${key.toString()}`}>
                        {item.title}
                    </MenuItem>
                ))}
            </Menu>
        )

    return (
        <React.Fragment>
            <Link
                className={cx({
                    [classes.root]: true,
                    [classes.dark]: dark,
                    [classes.selected]: selected
                })}
                onClick={(event) => sendToUrl(url, event)}
                onMouseEnter={handleClick}
            >
                {title}
            </Link>
            {_subtitles}
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        is_auth: state.is_auth,
        signup_drawer: state.signup_drawer,
        sidebar_drawer: state.drawer_status
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({ type: actionTypes.AUTH_UPDATE_USER, user }),
        onUpdateSidebarDrawer: (drawer_status) => dispatch({ type: actionTypes.SYS_UPDATE_DRAWER_STATUS, drawer_status })
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Topbar));
