import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import HomeView from '../views/Home/HomeView'
import ApplicationsView from '../views/Applications/ApplicationsView'
import ArtworksView from '../views/ArtworksView/ArtworksView'
import ArtworksDetailsView from '../views/ArtworksDetailsView/ArtworksDetailsView'
import ExhibitionsView from '../views/ExhibitionsView/ExhibitionsView'
import MagazinesView from '../views/MagazinesView/MagazinesView'
import MasterclassesView from '../views/MasterclassesView/MasterclassesView'
import ArtistsView from '../views/ArtistsView/ArtistsView'
import ArtistProfileView from '../views/ArtistProfileView/ArtistProfileView'
import ExhibitionDetailsView from '../views/ExhibitionDetailsView/ExhibitionDetailsView'
import MagazineDetailsView from '../views/MagazineDetailsView/MagazineDetailsView'
import ContactView from '../views/ContactView/ContactView'
import NewsletterView from '../views/NewsletterView/NewsletterView'
import EnquiriesView from '../views/EnquiriesView/EnquiriesView'
import UsersView from '../views/UsersView/UsersView'
import UserProfileView from '../views/UserProfileView/UserProfileView'
import SponsorsView from '../views/SponsorsView/SponsorsView'

const DashboardRouter = props => {
    return(
        <Switch>
            <Route exact path='/home' component={HomeView} />
            <Route exact path='/applications' component={ApplicationsView} />
            <Route exact path='/users' component={UsersView} />
            <Route exact path='/sponsors' component={SponsorsView} />
            <Route exact path='/users/:id' component={UserProfileView} />
            <Route exact path='/artists' component={ArtistsView} />
            <Route exact path='/artists/:url' component={ArtistProfileView} />
            <Route exact path='/artworks' component={ArtworksView} />
            <Route exact path='/artworks/:url' component={ArtworksDetailsView} />
            <Route exact path='/exhibitions' component={ExhibitionsView} />
            <Route exact path='/exhibitions/:url' component={ExhibitionDetailsView} />
            <Route exact path='/magazines' component={MagazinesView} />
            <Route exact path='/magazines/:url' component={MagazineDetailsView} />
            <Route exact path='/masterclasses' component={MasterclassesView} />
            <Route exact path='/contact' component={ContactView} />
            <Route exact path='/newsletters' component={NewsletterView} />
            <Route exact path='/enquiries' component={EnquiriesView} />
            <Redirect to='/home' />
        </Switch>
    )
}

export default DashboardRouter

