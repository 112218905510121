import { connect } from 'react-redux'
import React from 'react'
import { translations as translations_esp } from '../../texts/eng/magazine-details-translations'
import { Grid, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import LoadingContainer from '../../components/Structure/Layouts/LoadingContainer'
import TitleContainer from '../../components/Structure/Layouts/TitleContainer'
import useMagazineDetailsView from './useMagazineDetailsView'
import GeneralInfo from './sections/GeneralInfo'
import EditMagazineInfoModal from './modals/EditMagazineInfoModal'
import PictureModal from './modals/PictureModal/PictureModal'
import EditMagazineModal from './modals/EditMagazineModal'
import Dates from './sections/Dates'
import DocumentModal from './modals/DocumentModal'
import Applications from './sections/Applications/Applications'
import Sponsors from './sections/Sponsors/Sponsors'
import AddArtworkModal from './modals/AddArtworkModal'
import Artworks from './sections/Artworks/Artworks'
import Artists from './sections/Artists/Artists'
import Code from './sections/Code'
import Magazines from './sections/Magazines'
import EditVideoModal from './modals/EditVideoModal'
import EditDatesModal from './modals/EditDatesModal'
import AddArtistModal from './modals/AddArtistModal'
import AddApplicationModal from './modals/AddApplicationModal'
import catalogs from '../../texts/eng/catalogs'
import AddSponsorModal from './modals/AddSponsorModal'
import EditIframeModal from './modals/EditIframeModal'

const styles = makeStyles(theme => ({
    root: {
        padding: 80,
        paddingBottom: 0,
    },
}))

const submitButton = {
    fontSize: 14,
    fontWeight: 700,
    padding: '5px 20px',
    textTransform: 'uppercase'
}

const MagazineDetailsView = ({ is_auth, history, language, match, user }) => {

    const classes = styles()
    const content = translations_esp

    const { system, view_data, actions } = useMagazineDetailsView({
        language, is_auth, history, match, content
    })


    return (
        <LoadingContainer
            loading={system.loading}
            sending={system.sending}
            success={system.success}
            error={system.error}
            onCloseSuccess={actions.onCloseSuccess}
            onCloseError={actions.onCloseError}
        >
            <TitleContainer title={content.title} detail={content.detail}>
                <PictureModal
                    id={view_data.magazineID}
                    open={view_data.picture_modal}
                    name={view_data.imageType}
                    content={content.modals.picture}
                    onClose={actions.onClosePictureModal}
                    onUpdateMagazine={actions.onUpdateMagazine}
                    onDeleteMagazine={actions.onDeleteMagazine}
                />
                <DocumentModal
                    content={content.modals.documents}
                    open={view_data.document_modal}
                    onUpdateDocument={actions.onUploadDocument}
                    loading={system.sending}
                    error={system.error}
                    onClose={actions.onCloseDocumentModal}
                />
                <EditMagazineInfoModal
                    open={view_data.edit_modal}
                    form={view_data.form}
                    onChange={actions.onChange}
                    onSubmit={actions.onSaveMagazine}
                    onClose={actions.onCloseEditModal}
                    content={content}
                />
                <EditMagazineModal
                    open={view_data.edit_magazine_modal}
                    form={view_data.form}
                    onChange={actions.onChange}
                    onSubmit={actions.onSaveMagazine}
                    onClose={actions.onCloseEditMagazineModal}
                    content={content}
                />
                <EditVideoModal
                    open={view_data.edit_video_modal}
                    form={view_data.form}
                    onChange={actions.onChange}
                    onSubmit={actions.onSaveMagazine}
                    onClose={actions.onCloseEditVideoModal}
                    content={content}
                />
                <EditDatesModal
                    open={view_data.edit_date_modal}
                    form={view_data.form}
                    onChange={actions.onChange}
                    onSubmit={actions.onSaveMagazine}
                    onClose={actions.onCloseEditDateModal}
                    content={content}
                />
                <AddArtworkModal
                    open={view_data.add_modal.artworks}
                    form={view_data.form}
                    onChange={actions.onChange}
                    onSubmit={actions.onAddItem}
                    onClose={() => actions.onCloseAddModal('artworks')}
                    content={content.modals.add_modal}
                />
                 <AddArtistModal
                    open={view_data.add_modal.artists}
                    form={view_data.form}
                    onChange={actions.onChange}
                    onSubmit={actions.onAddItem}
                    onClose={() => actions.onCloseAddModal('artists')}
                    content={content.modals.add_modal}
                />
                 <AddSponsorModal
                    open={view_data.add_modal.sponsors}
                    form={view_data.form}
                    onChange={actions.onChange}
                    onSubmit={actions.onAddItem}
                    onClose={() => actions.onCloseAddModal('sponsors')}
                    content={content.modals.add_modal}
                />
                 <AddApplicationModal
                    open={view_data.add_modal.applications}
                    form={view_data.form}
                    onChange={actions.onChange}
                    onSubmit={actions.onAddItem}
                    onClose={() => actions.onCloseAddModal('applications')}
                    content={content.modals.add_modal}
                />
                <EditIframeModal
                    open={view_data.edit_iframe_modal}
                    form={view_data.form}
                    onChange={actions.onChange}
                    onSubmit={actions.onSaveMagazine}
                    onClose={actions.onCloseEditIframeModal}
                    content={content.modals.iframe}
                />
                <Grid container spacing={4}>
                    {view_data.magazine &&
                        <>
                            <Grid item xs={12}>
                                <GeneralInfo
                                    imageType={view_data.imageType}
                                    status={view_data.status}
                                    magazine={view_data.magazine}
                                    form={view_data.form}
                                    files={view_data.files}
                                    submitButton={submitButton}
                                    content={content.sections.general_info}
                                    onOpenEditModal={actions.onOpenEditModal}
                                    onEditPicture={actions.onOpenPictureModal}
                                    onSetMagazineStatus={actions.onSetMagazineStatus}
                                    onUpdateImageType={actions.onUpdateImageType}
                                    onRemoveDocument={actions.onRemoveDocument}
                                    onUploadDocument={actions.onOpenDocumentModal}
                                    onDownloadDocument={actions.onDownloadDocument}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Dates
                                    submitButton={submitButton}
                                    magazine={view_data.magazine}
                                    form={view_data.form}
                                    content={content.sections.dates}
                                    onOpenEditModal={actions.onOpenEditDateModal}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Applications
                                    content={content.sections.applications}
                                    data={view_data.applications}
                                    tableFilter={view_data.tableFilter.applications}
                                    search={view_data.search.applications}
                                    onUpdateTableFilter={actions.onUpdateTableFilter}
                                    total={view_data.total.applications}
                                    loading={view_data.loadingTable.applications}
                                    filter={view_data.filters.applications}
                                    onAddRegister={actions.onOpenAddModal}
                                    onChangeSearch={actions.onUpdateSearch}
                                    onFilterStatus={actions.onUpdateFilter}
                                    catalog_filter={[...content.filter, ...catalogs.application_statuses]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Sponsors
                                    content={content.sections.sponsors}
                                    data={view_data.sponsors}
                                    tableFilter={view_data.tableFilter.sponsors}
                                    total={view_data.total.sponsors}
                                    loading={view_data.loadingTable.sponsors}
                                    onUpdateTableFilter={actions.onUpdateTableFilter}
                                    onRemoveItem={actions.onDeleteItem}
                                    search={view_data.search.sponsors}
                                    onChangeSearch={actions.onUpdateSearch}
                                    onAddSponsor={actions.onOpenAddModal}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Artworks
                                    content={content.sections.artworks}
                                    data={view_data.artworks}
                                    tableFilter={view_data.tableFilter.artworks}
                                    search={view_data.search.artworks}
                                    total={view_data.total.artworks}
                                    loading={view_data.loadingTable.artworks}
                                    onVisitUrl={actions.onVisitUrl}
                                    onUpdateTableFilter={actions.onUpdateTableFilter}
                                    onRemoveItem={actions.onDeleteItem}
                                    onAddRegister={actions.onOpenAddModal}
                                    onChangeSearch={actions.onUpdateSearch}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Artists
                                    content={content.sections.artists}
                                    data={view_data.artists}
                                    tableFilter={view_data.tableFilter.artists}
                                    search={view_data.search.artists}
                                    total={view_data.total.artists}
                                    loading={view_data.loadingTable.artists}
                                    filter={view_data.filters.artists}
                                    onUpdateTableFilter={actions.onUpdateTableFilter}
                                    onRemoveItem={actions.onDeleteItem}
                                    onAddRegister={actions.onOpenAddModal}
                                    onChangeSearch={actions.onUpdateSearch}
                                    onVisitUrl={actions.onVisitUrl}
                                    onFilterStatus={actions.onUpdateFilter}
                                    catalog_filter={[...content.filter, ...catalogs.artist_statuses]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Code
                                    submitButton={submitButton}
                                    data={view_data.magazine}
                                    form={view_data.form}
                                    content={content.sections.code}
                                    onEdit={actions.onOpenEditIframeModal}
                                />
                            </Grid>
                            {/*<Grid item xs={12}>
                                <Magazines
                                    submitButton={submitButton}
                                    data={view_data.magazine}
                                    form={view_data.form}
                                    content={content.sections.documents}
                                    onEdit={actions.onOpenEditMagazineModal}
                                    onDelete={actions.onDeleteItem}
                                />
                    </Grid>*/}
                        </>
                    }
                </Grid>
            </TitleContainer>
        </LoadingContainer>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        is_auth: state.is_auth
    }
}

export default connect(mapStateToProps, null)(MagazineDetailsView)