import React, { useState, useEffect, useRef } from 'react'
import { Grid, IconButton, makeStyles, MenuItem, Paper, Select, SvgIcon } from '@material-ui/core'
import arrowIcon from '../../../assets/icons/arrow_down.svg'
import searchIcon from '../../../assets/icons/search.svg'
import SVGIcon from '../DisplayData/SVGIcon'
import LineInputForm from '../../Forms/LineInputForm'
import AddIcon from '@material-ui/icons/Add'
import { grey } from '@material-ui/core/colors'
import FilterSelect from '../../Forms/FilterSelect'

const styles = makeStyles(theme => ({
    addButton: {
        width: 36,
        height: 36,
        background: 'black',
        color: 'white',
        margin: 'auto',
        boxSizing: 'border-box',
        '&:hover': {
            background: theme.palette.secondary.main
        }
    },
    addIcon: {
    },
}))


const ActionTopBar = ({ filter, filterValue, onChangeFilter, search, searchData, onSearch, add, onAdd, onUpdateSearch }) => {

    const classes = styles()

    const timerRef = useRef()

    const onStartCountDown = () => {
        if(timerRef.current) clearTimeout(timerRef.current)
        timerRef.current = setTimeout(onUpdateSearch, 300);
    }

    const onStopCountDown = () => {
        if(timerRef.current) clearTimeout(timerRef.current)
    }

    return (
        <Grid container alignItems='center' spacing={2}>
            {filter &&
                <Grid item>
                    <FilterSelect
                        value={filterValue}
                        onFilter={onChangeFilter}
                        menuFilter={filter}
                    />
                </Grid>
            }
            {
                search &&
                <>
                    <Grid item xs></Grid>
                    <Grid item>
                        <LineInputForm
                            endAdornment={<SVGIcon src={searchIcon} size={20} color='grey' />}
                            data={searchData}
                            onChange={onSearch}
                            onKeyUp={onStartCountDown}
                            onKeyDown={onStopCountDown}
                        />
                    </Grid>
                </>
            }
            {
                add &&
                <Grid item>
                    <IconButton className={classes.addButton} onClick={onAdd}>
                        <AddIcon className={classes.addIcon} />
                    </IconButton>
                </Grid>
            }
        </Grid >
    )
}

export default ActionTopBar