import React from 'react'
import { Card, makeStyles } from '@material-ui/core'
import cx from 'classnames'
import { grey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        background: 'white',
        borderRadius: 5,
        boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.07)',
        padding: 24,
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            padding: 16,
        }
    },
    clickable: {
        '&:hover': {
            cursor: 'pointer',
            background: grey[100]
        }
    }
}))

const SimpleCard = ({ children, padding, fullHeight, onClick, border, style }) => {
    const classes = useStyles()

    let styles = {}
    if (padding === 'small') styles = { ...styles, padding: 12 }
    if (fullHeight) styles = { ...styles, height: '100%', boxSizing: 'border-box' }
    if (border) styles = { ...styles, borderRadius: border }
    if (style) styles = { ...styles, ...style }

    return (
        <Card className={cx({
            [classes.root]: true,
            [classes.clickable]: onClick ? true : false
        })} style={styles} onClick={onClick} >
            {children}
        </Card>
    )
}

export default SimpleCard