import React from 'react'
import { Grid, Icon, InputBase, makeStyles, withStyles } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'
import SVGIcon from '../../../../../components/Structure/DisplayData/SVGIcon'
import searchIcon from '../../../../../assets/icons/search.svg'


const useStyles = makeStyles(theme => ({
    root: {
        //background: 'white',
        //boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
        //borderRadius: 32,
        padding: '8px 16px',
        borderBottom: '1px solid grey'
    },
    icon: {
        color: blueGrey[500],

    }
}))

const SearchBar = props => {
    const classes = useStyles()
    const { value, onChange } = props

    const onInnerChange = (e) => {
        onChange(e.target.value)
    }

    return (
        <div className={classes.root}>
            <Grid container alignItems='center' spacing={1} wrap='nowrap'>
                <Grid item>
                    <InputBase value={value} placeholder='Search...' onChange={onInnerChange} />
                </Grid>
                <Grid item>
                    <SVGIcon src={searchIcon} size={20} color='grey' />
                </Grid>
            </Grid>
        </div>
    )
}

export default SearchBar