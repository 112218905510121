import React, { useEffect, useState } from 'react';
//import { onGetErrorMessage, onGetFileExtension, onGetImageURL2Blob } from '../../../../shared/utility';
import system_translations from '../../../../../../texts/system_translations';
import PictureModal from '../../../../../../components/Modals/PictureModal';
import { onGetErrorMessage, onGetFileExtension, onGetImageURL2Blob } from '../../../../../../shared/utility';
import { private_server } from '../../../../../../config';



const ChangeImageModal = (props) => {

    const {id, open, onClose, onCompleted, language, type, onUpdateDataImage} = props
    const content = system_translations[language].modals.upload_image_modal

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(!open){
            setError(null)
        }
    }, [open])

    const onSubmit = async(file, src) => {
        if(!file){
            setError(content.error_msg)
            return
        }
        setError(null)
        try {
            setLoading(true)
            //console.log(file)
            const _file = await onGetImageURL2Blob(file)
            console.log(file)
            let data
            if(type === 'web'){data = {name:"web_image", ext:onGetFileExtension(_file.name)}}
            else{data = {name:"mobile_image", ext:onGetFileExtension(_file.name)}}
            console.log(data)
            onUpdateDataImage(data, _file, file)
            //await private_server.post(`/banner/${id}/image`, data)
            //await request_upload_image(id, _file, {type:onGetFileExtension(_file.name)})
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setLoading(false)
    }

    return ( 
        <PictureModal open={open} onClose={onClose} loading={loading} error={error} language={language}
            title={content.title} btnLabel={content.button} onChangeFile={onSubmit} aspect_ratio={16/9} />
     );
}
 
export default ChangeImageModal;