import noImage from '../../assets/noimagegold.png'
import featuredIcon from '../../assets/icons/featured.svg'

import noExhibitions from '../../assets/icons/exhibitions.svg'

export const translations = {
    title: 'Exhibitions',
    noExhibitions: {
        title: 'No exhibitions to show',
        subtitle: 'Exhibitions will show here.',
        icon: noExhibitions
    },
    filter: [
        { id: 'all', value: 0, label: 'All' }
    ],
    form: {
        search: {
            placeholder: 'Search by title...'
        },
        title: {
            label: 'Title',
            placeholder: 'Title',
            helperText: 'Required field'
        },
        url: {
            label: 'URL',
            placeholder: 'URL',
            helperText: 'Required field'
        },
        exhibition_type_id: {
            label: 'Type',
            placeholder: 'Type',
            helperText: 'Select a valid option'
        },
        
    },
    add_button: 'Add',
    add_title: 'Add Exhibition',
    noImage: noImage,
    noContent: 'There are not coincidences with the filters selected.',
    data: {
        title:'Title',
        start_date: 'Date',
        artworks: 'Artworks'
    },
    featured: featuredIcon
}