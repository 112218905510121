import React from 'react'
import SimpleTable from './components/SimpleTable'

const ArtistApplications = props => {

    const { content, applications, search, onChangeSearch, total, tableFilter,
    loading, onAddRegister, onPreDeleteRegister, onUpdateTableFilter, onSelectItem } = props

    return (
        <SimpleTable
            search={search}
            onChangeSearch={onChangeSearch}
            onAddRegister={onAddRegister}
            onSelectedItem={onSelectItem}
            data={applications}
            tableFilter={tableFilter}
            onUpdateTableFilter={onUpdateTableFilter}
            total={total}
            loading={loading}
            onRemoveItem={onPreDeleteRegister}
            noData={content.noData}
            header={content.header}
        />
    )
}

export default ArtistApplications