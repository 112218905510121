import { Grid } from '@material-ui/core'
import React from 'react'
import SwitchForm from '../../../components/Forms/SwitchForm'
import LineInputForm from '../../../components/Forms/LineInputForm'
import SimpleModal from '../../../components/Modals/SimpleModal'
import RoundedButton from '../../../components/Actions/RoundedButton'
import LineSelectForm from '../../../components/Forms/LineSelectForm'
import { catalogs } from '../../../texts/eng/catalogs'
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon'

const EditSocialMediasModal = ({ open, form, onChange, onSubmit, onClose, content }) => {
    const total = catalogs.social_medias.length
    
    return (
        <SimpleModal maxWidth='sm' open={open} onClose={onClose}>
            <Grid container alignItems='center' spacing={4}>
                {catalogs.social_medias.map((media, key) => {
                    const icon = <SVGIcon size={20} src={media.icon} color='black' style={{ marginRight: 8 }} />
                    const smSize = total === key + 1 ? 12 : 6

                    return (
                        <Grid item xs={12} sm={smSize} key={`social-media-input-${key.toString()}`}>
                            <LineInputForm
                                startAdornment={icon}
                                data={form[media.id]}
                                onChange={onChange}
                            />
                        </Grid>
                    )
                })}
                <Grid item xs></Grid>
                <Grid item>
                    <RoundedButton size='small' onClick={onSubmit}>
                        {content.save_button}
                    </RoundedButton>
                </Grid>
            </Grid>
        </SimpleModal>
    )
}

export default EditSocialMediasModal