import React from 'react'
import { withStyles, Grid, Icon, IconButton, LinearProgress, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import ActionBar from './ActionBar'
import StatusData from '../../../components/Structure/DisplayData/StatusData'
import { catalogs } from '../../../texts/eng/catalogs'
import AvatarPicture from '../../../components/Structure/DisplayData/AvatarPicture'
import StarIcon from '@material-ui/icons/Star'

const CustomizedTableRow = withStyles((theme) => ({
    root: {
        background: 'white',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.07)',
        borderRadius: 15,
        height: 90,
        '& td:first-child': {
            borderRadius: '15px 0px 0px 15px',
        },
        '& td:last-child': {
            borderRadius: '0px 15px 15px 0px',
        },
        '&:hover': {
            cursor: 'pointer',
            background: grey[100]
        }
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 40
    },
    addIcon: {
        background: theme.palette.secondary.main,
        borderRadius: '100%',
        color: 'white',
        '&:hover': {
            background: theme.palette.secondary.dark,
        }
    },
    paper: {
        marginTop: 4,
        borderRadius: 8,
    },
    cellHeader: {
        paddingTop: 16,
        paddingBottom: 16,
        borderBottom: 'none',
    },
    cell: {
        borderBottom: 'none',
        position: 'relative',
    },
    text: {
        fontWeight: 500,
        fontSize: 14
    },
    country: {
        width: 'fit-content',
        margin: 'auto',
        fontWeight: 500,
        fontSize: 14,
        border: '1px solid black',
        borderRadius: 4,
        padding: '0px 10px'
    },
    button: {
        background: theme.palette.primary.main,
        padding: '6px 12px',
        borderRadius: 32,
        color: 'white',
        '&:hover': {
            background: theme.palette.primary.dark,
        }
    },
    toolbar: {
        marginBottom: 16
    },
    actions: {
        marginTop: 16
    },
    table: {
        //minWidth: 1100,
        borderSpacing: '0px 3px',
        borderCollapse: 'separate'
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    nodata: {
        marginTop: 40
    },
    colorTag: {
        borderRadius: '15px 0px 0px 15px',
        position: 'absolute',
        width: 8,
        height: '100%',
        top: 0,
        left: 0
    },
    startIcon: {
        fill: theme.palette.secondary.main
    },
    noArtists: {
        marginTop: 100
    },
    noArtistsTitle: {
        fontSize: 24,
        fontWeight: 700,
        color: '#494949'
    },
    noArtistsSubtitle: {
        fontSize: 16,
        color: '#7F7F7F'
    }
}))

const SimpleTable = props => {

    const classes = useStyles()

    const { data, header, total,
        tableFilter, onUpdateTableFilter, onSelectedItem, loading,
        onRemoveItem, noData } = props
    return (
        <div className={classes.root}>
            {loading ? (
                <Grid container justifyContent='center'>
                    <Grid item xs={12}><LinearProgress color='secondary' /></Grid>
                </Grid>
            ) : null}
            {data && data.length > 0 ? (
                <div className={classes.tableWrapper}>
                    <Table className={classes.table}>
                        {header ? (
                            <TableHead>
                                <TableRow>
                                    {header.map(item => {
                                        return (
                                            <TableCell padding={'normal'} className={classes.cellHeader} key={item.id.toString()} align={item.align}>
                                                <Typography variant='body2' >{item.label}</Typography>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                        ) : null}
                        <TableBody>
                            {data.map((item, key) => {

                                const { artist_status_id, id_user, image, first_name, last_name, discipline, country_abbreviation, sponsorship, featured } = item
                                const color = catalogs.artist_statuses.find(status => status.value === artist_status_id).color
                                const sponsorData = catalogs.sponsorship.find(sponsor => sponsor.id === sponsorship)

                                return (
                                    <CustomizedTableRow key={key.toString()}>
                                        <TableCell className={classes.cell} onClick={() => onSelectedItem(item)} align='center'>
                                            <div className={classes.colorTag} style={{ background: color }}></div>
                                            <Typography className={classes.text}>{id_user}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}>
                                            <Grid container alignItems='center' spacing={2}>
                                                <Grid item>
                                                    <AvatarPicture src={image} size={42} />
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography className={classes.text}>{first_name} {last_name}</Typography>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography className={classes.text}>{discipline}</Typography></TableCell>
                                        <TableCell className={classes.cell} onClick={() => onSelectedItem(item)} align='center'><Typography className={classes.country}>{country_abbreviation}</Typography></TableCell>
                                        <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}>
                                            {sponsorData &&
                                                <StatusData
                                                    icon={'square'}
                                                    data={sponsorData.label}
                                                    color={sponsorData.color}
                                                    size={10}
                                                    style={{ color: 'black', fontWeight: 500, fontSize: 14 }}
                                                />
                                            }
                                        </TableCell>
                                        <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}>
                                            {sponsorData &&
                                                <StatusData
                                                    icon={'square'}
                                                    data={sponsorData.label}
                                                    color={sponsorData.color}
                                                    size={10}
                                                    style={{ color: 'black', fontWeight: 500, fontSize: 14 }}
                                                />
                                            }
                                        </TableCell>
                                        <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}>
                                            {featured ? <StarIcon style={{marginLeft: 24}} classes={{ root: classes.startIcon }} /> : null}
                                        </TableCell>
                                        {onRemoveItem && <TableCell className={classes.cell} onClick={() => onRemoveItem(id_user)}><IconButton size='small'><Icon>delete</Icon></IconButton></TableCell>}
                                    </CustomizedTableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </div>

            ) : null}

            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter} />
            </div> : null}
            {data.length === 0 && !loading ? (
                <div className={classes.nodata}>
                    <Grid item xs={12}>
                        <div className={classes.noArtists}>
                            <Grid container justifyContent='center' spacing={3}>
                                <Grid item>
                                    <img src={noData.image} style={{width:64, height:64, objectFit:'contain'}} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography align='center' className={classes.noArtistsTitle}>
                                        {noData.title}
                                    </Typography>
                                    <Typography align='center' className={classes.noArtistsSubtitle}>
                                        {noData.subtitle}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </div>

            ) : null}

        </div>
    )
}

export default SimpleTable