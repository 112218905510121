import StopIcon from '@material-ui/icons/Stop'
import StarIcon from '@material-ui/icons/Star'
import { grey } from '@material-ui/core/colors'

import noImage from '../../assets/noimagegold.png'
import nftIcon from '../../assets/icons/NFT.svg'
import likeIcon from '../../assets/icons/full_heart.svg'
import trashIcon from '../../assets/icons/trash.svg'

import viewsIcon from '../../assets/icons/views.svg'
import exhibitionsIcon from '../../assets/icons/exhibitions_filled.svg'
import timeIcon from '../../assets/icons/wall-clock-white-clockhands.svg'
import enquiresIcon from '../../assets/icons/file_filled.svg'
import featuredIcon from '../../assets/icons/featured.svg'

import uploadIcon from '../../assets/icons/upload.svg'
import downloadIcon from '../../assets/icons/download.svg'
import removeIcon from '../../assets/icons/remove.svg'

export const translations = {
    title: 'Artist',
    detail: 'Profile',
    nft: nftIcon,
    like: likeIcon,
    delete: trashIcon,
    save_button: 'Save',
    words: 'words',
    characters: 'characters',
    sections: {
        general_info: {
            add_button: 'Add',
            edit_button: 'Edit',
            referenceCode: 'refcode',
            featuredIcon: featuredIcon,
            form: {
                referenced_by: {
                    label: 'Refered by',
                    placeholder: 'Refered by'
                },
                approved_by: {
                    label: 'Approved by',
                    placeholder: 'Approved by'
                },
                first_name: {
                    label: 'First name',
                    placeholder: 'First name',
                },
                last_name: {
                    label: 'Last name',
                    placeholder: 'Last name',
                },
                professional_name: {
                    label: 'Professional name',
                    placeholder: 'Professional name',
                },
                gender_id: {
                    label: 'Gender',
                    placeholder: 'Gender',
                },
                country_id: {
                    label: 'Country',
                    placeholder: 'Country',
                },
                discipline_id: {
                    label: 'Discipline',
                    placeholder: 'Discipline',
                },
                birth_date: {
                    label: 'Birth Date',
                    //placeholder: 'Discipline',
                },
                country_code: {
                    label: 'Code',
                },
                mobile: {
                    label: 'Mobile',
                    placeholder: 'Mobile'
                },
                email: {
                    label: 'Email',
                    placeholder: 'Email'
                },
                url: {
                    label: 'Username',
                    placeholder: 'Username'
                },
                artist_level_id:{
                    label: 'Level',
                    placeholder: 'Level'
                },
                order:{
                    label: 'Order',
                    placeholder: 'Order'
                }
            },
            smallCards: [
                {
                    id: 'enabled',
                    label: 'Enabled',
                    icon: <StopIcon />
                },
                {
                    id: 'new',
                    label: 'New',
                    icon: <StopIcon />
                },
                {
                    id: 'featured',
                    label: 'Featured',
                    icon: <StarIcon />,
                    primaryColor: '#BD8B40',
                    secondaryColor: grey[500]
                }
            ]
        },
        social_medias: {
            title: 'Social media',
            button: 'Edit',
            form: {
                facebook: {
                    label: 'Facebook',
                    placeholder: 'Facebook',
                },
                twitter: {
                    label: 'Twitter',
                    placeholder: 'Twitter',
                },
                instagram: {
                    label: 'Instagram',
                    placeholder: 'Instagram',
                },
                tiktok: {
                    label: 'Tiktok',
                    placeholder: 'Tiktok',
                },
                pinterest: {
                    label: 'Pinterest',
                    placeholder: 'Pinterest',
                },
                youtube: {
                    label: 'Youtube',
                    placeholder: 'Youtube',
                },
                vimeo: {
                    label: 'Vimeo',
                    placeholder: 'Vimeo',
                },
                opensea: {
                    label: 'Opensea',
                    placeholder: 'Opensea',
                },
                web: {
                    label: 'Web',
                    placeholder: 'Web',
                },
            },
        },
        about: {
            title: 'About',
            button: 'Edit',
            form: {
                tweet: {
                    label: 'Motto',
                    placeholder: 'Motivational Tweet',
                    helperText: 'Write less than 200 characters please'
                },
                biography: {
                    label: 'Biography',
                    placeholder: 'Biography',
                    words: true,
                    helperText: 'Write less than 250 words please'
                },
                statement: {
                    label: 'Statement',
                    placeholder: 'Statement',
                    words: true,
                    helperText: 'Write less than 250 words please'
                },
                why_you: {
                    label: 'Why choosing you?',
                    placeholder: 'Why choosing you?',
                    words: true,
                    helperText: 'Write less than 200 words please'
                }
            },
        },
        documents: {
            title: 'Documents',
            button: 'Edit',
            files: {
                cv: {
                    label: 'CV'
                },
                portfolio: {
                    label: 'Portfolio'
                },
                other_documents: {
                    label: 'Other'
                }
            },
            fileData: {
                upload: {
                    icon: uploadIcon,
                    label: 'Upload'
                },
                download: {
                    icon: downloadIcon,
                    label: 'Download'
                },
                remove: {
                    icon: removeIcon,
                    label: 'Remove'
                }
            }
        },
        images: {
            title: 'Images',
            deleteIcon: trashIcon,
            bannerImage: 'Background image',
            mainImage: 'Main professional image',
            professionalImages: 'Professional images',
            main: 'Select as main',
            noImage: noImage,
        },
        application_artworks:{
            title:'Application artworks',
            noImage: noImage,
        },
        membership: {
            title: 'Membership',
            form: {
                id: {
                    placeholder: 'ID',
                    helperText: 'ID'
                },
                suscription_date: {
                    placeholder: 'Suscription date',
                    helperText: 'Suscription date',
                    isDate: true
                },
                renewal_date: {
                    placeholder: 'Renewal date',
                    helperText: 'Renewal date',
                    isDate: true
                },
                cancelation_date: {
                    placeholder: 'Cancellation date',
                    helperText: 'Cancellation date',
                    isDate: true
                },
                status: {
                    placeholder: 'Status',
                    helperText: 'Status'
                },
            }
        },
        payments: {
            title: 'Payments',
        }
    },
    modals: {
        picture: {
            title: 'Update Picture',
            placeholder: '',
            image_selected: '',
            save_button: 'Save',
            mobile: 'Save for mobile view'
        },
        professional_image: {
            title: 'Update Additional Image',
            placeholder: '',
            image_selected: '',
            save_button: 'Save',
            //mobile: 'Save for mobile view',
        },
        documents: {
            title: 'Upload document',
            label: 'Select the document',
            button: 'Save'
        }
    }
}