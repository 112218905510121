import React from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Divider, Grid, IconButton, Typography, useMediaQuery } from '@material-ui/core'
import RoundedButton from '../../../components/Actions/RoundedButton'
import GenericImage from '../../../components/Structure/DisplayData/GenericImage'
import SimpleCard from '../../../components/Structure/Cards/SimpleCard'
import FilledDisplayData from '../../../components/Structure/DisplayData/FilledDisplayData'
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon'

const styles = makeStyles(theme => ({
    root: {
        marginTop: 80
    },
    title: {
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        height: 2
    },
    deleteButton: {
        background: theme.palette.primary.main,
        padding: 8,
        '&:hover': {
            background: theme.palette.primary.dark,
        }
    },
}))

const Magazines = ({ data, content, form, submitButton, onEdit, onDelete }) => {

    const classes = styles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.only('xs'))

    const labelID = 'magazines'
    let selectedImage = data ? (ismobile ? data.magzine_mobile_image : data.magazine_web_image) : null

    return (
        <div className={classes.root}>
            <Grid container justifyContent='flex-end' spacing={4}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.title}>
                                {content.title}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <RoundedButton
                        color='black'
                        size='small'
                        style={submitButton}
                        onClick={() => onEdit(labelID)}
                    >
                        {content.button}
                    </RoundedButton>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={6}>
                        <Grid item xs={12} sm={5}>
                            <GenericImage src={selectedImage} />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <SimpleCard fullHeight>
                                <Grid container justifyContent='flex-end' spacing={4}>
                                    <Grid item xs></Grid>
                                    <Grid item>
                                        <IconButton
                                            className={classes.deleteButton}
                                            onClick={() => onDelete(labelID)}
                                        >
                                            <SVGIcon src={content.delete} size={22} color='white' />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FilledDisplayData data={form.magazine} value={data.magazine} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FilledDisplayData data={form.magazine_description} value={data.magazine_description} />
                                    </Grid>
                                </Grid>
                            </SimpleCard>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Magazines