import React, { useState } from 'react';
import { Avatar, alpha, Grid, Icon, makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { CONFIG_SETTINGS } from '../../../config';
import { isValidDocument } from '../../../shared/utility';
import EditButton from '../../Actions/EditButton';
import nouser from '../../../assets/icons/profile.svg'

const AvatarPicture = ({ src, onChangePicture, can_edit, size }) => {
    const classes = useStyles()
    const [hover, setHover] = useState(false)

    let selectedImage = nouser

    let size_styles = { width: 160, height: 160 }
    if (size) size_styles = { width: size, height: size }

    if (src) {
        const isValid = isValidDocument(src)
        selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${src}` : nouser
    }

    return (
        <div className={classes.avatarContainer} style={size_styles}>
            <Avatar src={selectedImage} alt='' className={classes.image} style={size_styles} />
            {can_edit ?
                <div className={classes.edit_button_container}>
                    <EditButton size='small' onClick={onChangePicture} />
                </div> : null}
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    image: {
        width: 160,
        height: 160,
        border: '1px solid #D6D6D6'
    },
    container: {
        padding: 32,
        borderRadius: 16,
        border: `1px solid ${grey[300]}`
    },
    avatarContainer: {
        width: 160,
        height: 160,
        position: 'relative'
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        background: alpha('#000', 0.5),
        zIndex: 2,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    picture: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    edit_button_container: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        zIndex: 5,
        transform: 'translate(-50%, -25%)'
    }
}))

export default AvatarPicture;