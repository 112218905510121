import { Grid, Select, Typography, MenuItem } from '@material-ui/core'
import React, {useState} from 'react'
import LineInputForm from '../../../components/Forms/LineInputForm'
import SimpleModal from '../../../components/Modals/SimpleModal'
import RoundedButton from '../../../components/Actions/RoundedButton'
import LineSelectForm from '../../../components/Forms/LineSelectForm'
import ArtworkPicker from '../components/ArtworkPicker'

const AddEnquirieModal = ({ open, form, onChange, onSubmit, onClose, content, artworks }) => {
    const [artworkModal, setArtworkModal] = useState(false)

    return (
        <>
        <SimpleModal maxWidth='xs' open={open} onClose={onClose} borderRadius={5}>
            <Grid container alignItems='flex-end' spacing={4}>
                <Grid item xs={12}>
                    <Typography style={{ fontSize: 18, fontWeight: 700 }}>{content.title}</Typography>
                </Grid>
                <Grid item container xs={12}>
                    {/* <LineSelectForm data={form.artwork_id} onChange={onChange} /> */}
                    <ArtworkPicker data={form.artwork_id} onChange={onChange} artworks={artworks}/>
                </Grid>
                <Grid item xs={12}>
                    <LineInputForm data={form.name} onChange={onChange} />
                </Grid>
                <Grid item xs={12}>
                    <LineInputForm data={form.email} onChange={onChange} />
                </Grid>
                <Grid item xs={12}>
                    <LineSelectForm data={form.consultant_id} onChange={onChange} />
                </Grid>
                <Grid item xs={12}>
                    <LineInputForm data={form.date} onChange={onChange} />
                </Grid>
                <Grid item xs={12}>
                    <LineSelectForm
                        data={form.artwork_purchase_request_status_id}
                        onChange={onChange}
                        disableFuture
                    />
                </Grid>
                <Grid item xs={12}>
                    <LineInputForm data={form.comments} onChange={onChange} />
                </Grid>
                <Grid item xs></Grid>
                <Grid item>
                    <RoundedButton size='small' onClick={onSubmit}>
                        {content.save_button}
                    </RoundedButton>
                </Grid>
            </Grid>
        </SimpleModal></>
    )
}

export default AddEnquirieModal