import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import SwitchForm from '../../../components/Forms/SwitchForm'
import LineInputForm from '../../../components/Forms/LineInputForm'
import SimpleModal from '../../../components/Modals/SimpleModal'
import RoundedButton from '../../../components/Actions/RoundedButton'
import LineSelectForm from '../../../components/Forms/LineSelectForm'

const AddSponsorModal = ({ open, form, onChange, onSubmit, onClose, content }) => {

    return (
        <SimpleModal maxWidth='xs' open={open} onClose={onClose} borderRadius={5}>
            <Grid container alignItems='flex-end' spacing={4}>
            <Grid item xs={12}>
                    <Typography style={{ fontSize: 18, fontWeight: 700 }}>{content.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <LineInputForm data={form.name} onChange={onChange} />
                </Grid>
                <Grid item xs={12}>
                    <LineInputForm data={form.description} onChange={onChange} />
                </Grid>
                <Grid item xs={12}>
                    <LineSelectForm data={form.enabled} onChange={onChange} />
                </Grid>
                <Grid item>
                    <RoundedButton size='small' onClick={onSubmit}>
                        {content.save_button}
                    </RoundedButton>
                </Grid>
            </Grid>
        </SimpleModal>
    )
}

export default AddSponsorModal