import React, { useState } from 'react';
import { makeStyles, Fade } from '@material-ui/core';
import noimage from '../../assets/noimagegold.png'
import { CONFIG_SETTINGS } from '../../config';
import AddButton from '../Actions/AddButton'
import EditButton from '../Actions/EditButton';
import cx from 'classnames'

const image_ratio = {
    '1/1': '100%',
    '16/9': '56.25%',
    '4/3': '75%',
    '2/3': '150%',
    '5/4': '80%',
    '3/4': '133%',
    '4/5': '125%'
}

const ImageContainer = ({ src, size, noborder, ratio, onUpload, onEdit, src_crop, variant, hover, fullpath }) => {

    const classes = useStyles()
    const [error, setError] = useState(true)

    const onImageLoad = (e) => setError(false)
    const onError = (e) => setError(true)

    let imgSrc = null

    if(!fullpath)
        imgSrc = src ? `${CONFIG_SETTINGS.S3_SERVER_URL}${src}` : null
    else
        imgSrc = src ? src : null

    const _ratio = ratio ? ratio : '1/1'

    let image_height = image_ratio[_ratio]

    return (
			<div
				style={{ paddingTop: image_height }}
				className={cx({
					[classes.image_container]: true,
					[classes.hover]: hover,
				})}
			>
				{onUpload ? (
					<div className={classes.upload_container}>
						<AddButton onClick={onUpload} />
					</div>
				) : null}
				{onEdit ? (
					<div className={classes.edit_container}>
						<EditButton variant='modal' onClick={onEdit} />
					</div>
				) : null}
				{src_crop ? (
					<div className={classes.src_crop} onClick={onUpload}>
						<img alt='' src={src_crop} className={classes.image} />
					</div>
				) : null}
				{error ? (
					<div className={classes.image_wrapper}>
						<img src={noimage} alt='' className={classes.image} style={{ padding: 32, boxSizing: 'border-box', objectFit: 'contain' }} />
					</div>
				) : null}
				<Fade in={!error}>
					<div className={classes.image_wrapper} style={noborder ? {borderWidth: 0} : {}}>
						{imgSrc ? (
							<img alt='' src={imgSrc} className={classes.image} style={{ objectFit: variant ? variant : 'contain' }} onLoad={onImageLoad} onError={onError} />
						) : (
							<img src={noimage} alt='' className={classes.image} style={{ padding: 32, boxSizing: 'border-box', objectFit: 'contain' }} />
						)}
					</div>
				</Fade>
			</div>
		);
}

const useStyles = makeStyles(theme => ({
    image_container: {
        position: 'relative',
        margin: 'auto',
        width: '100%',
        paddingTop: '100%',
        boxSizing: 'border-box',
        background: 'white',
        transition: '0.3s',
    },
    hover:{
        '&:hover': {
            cursor: 'pointer',
            filter: 'brightness(0.7)'
        }
    },
    image_wrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        border: '1px solid #EAEAEA',
        boxSizing: 'border-box',
    },
    image: {
        width: '100%',
        height: '100%',
    },
    svg_container: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        padding: 8,
        boxSizing: 'border-box'
    },
    upload_container: {
        width: '100%',
        height: '100%',
        background: '#C9C9C9',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    edit_container: {
        position: 'absolute',
        top: 12,
        right: 12,
        zIndex: 5
    },
    src_crop: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        //background:'red',
        zIndex: 7,
        cursor: 'pointer'
    }
}))

export default ImageContainer;