import { Icon, IconButton, makeStyles } from "@material-ui/core"


const AddButton = ({size, onClick}) =>{

    const classes = useStyles()

    return(
        <IconButton onClick={onClick} size={size ? size : 'medium'} className={classes.root}><Icon >add</Icon></IconButton>
    )

}

export default AddButton

const useStyles = makeStyles(theme => ({
    root:{
        background:theme.palette.secondary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.secondary.main
        }
    }
    
}))