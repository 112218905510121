import { Icon, IconButton, makeStyles } from "@material-ui/core"


const DeleteButton = ({size, onClick, card}) => {

    const classes = useStyles()

    return(
        <IconButton onClick={onClick} size={size ? size : 'medium'} className={card ? classes.button_card : classes.button}><Icon>delete</Icon></IconButton>
    )

}

export default DeleteButton

const useStyles = makeStyles(theme => ({
    button:{
        background:theme.palette.primary.dark,
        padding:6,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark
        }
    },
    button_card:{
        boxShadow:'0px 5px 7px rgba(0, 0, 0, 0.12)',
        color:theme.palette.primary.main,
        padding:8
    }
    
}))