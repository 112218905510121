import noArtists from '../../assets/icons/artist.svg'

export const translations = {
    title: 'Artists',
    noData: 'No artists to show.',
    noArtists: {
        title: 'No artists to show',
        subtitle: 'Artists will show here.',
        image: noArtists
    },
    filter: [
        { id: 'all', value: 0, label: 'All' }
    ],
    form: {
        search: {
            placeholder: 'Search'
        },
        email:{
            label: 'Email',
            placeholder: 'Email',
            helperText: 'Insert a valid email',
        },
        password:{
            label: 'Password',
            placeholder: 'Password',
            helperText: 'Insert a valid password',
        },
        first_name:{
            label: 'First name',
            placeholder: 'First Name',
            helperText: 'Required field',
        },
        last_name:{
            label: 'Last name',
            placeholder: 'Last Name',
            helperText: 'Incorrect field',
        },
        professional_name:{
            label: 'Professional name',
            placeholder: 'Professional name',
            helperText: 'Incorrect field',
        },
        gender_id:{
            label: 'Gender',
            helperText: 'Select a valid option',
        },
    },
    header: [
        { id: 1, label: 'ID', align: 'center' },
        { id: 2, label: 'Artist' },
        { id: 3, label: 'Discipline' },
        { id: 4, label: 'Nationality', align: 'center' },
        { id: 5, label: 'Member' },
        { id: 6, label: 'Sponsored' },
        { id: 7, label: 'Featured' },
    ],
    modals:{
        add:{
            title: 'Add Artist',
            button: 'Add'
        }
    }

}

export default translations