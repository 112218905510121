import { createTheme } from "@material-ui/core/styles";
import { blueGrey, lightBlue } from "@material-ui/core/colors";

const montserrat = {
	fontFamily: 'Montserrat',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	src: `url('./fonts/Montserrat-VariableFont_wght.ttf')`,
	unicodeRange: 'U+30-39',
};

export const theme = createTheme({
  palette: {
    primary: { main: '#6D1A12', light: '#a14738', dark: '#3f0000' },  //#2196f3 //4466f2
    secondary: { main: '#BD8B40', light: '#BD8B40', dark: '#895e12' }, //#1A237E //0847a7
    tertiary: { main: '#96AE76' },
    layout: { background: '#F1F1F2' },
    text: {
      primary: blueGrey[900],
      secondary: blueGrey[500],
    }
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    },
    '@font-face': [montserrat],
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Raleway',
      'Montserrat',
      'Work Sans',
    ].join(','),
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: '4.5rem',
        fontWeight: 500
      },
      h2: {
        fontSize: '3.75rem',
      },
      h3: {
        fontSize: '3rem',
        fontWeight: 600
      },
      h4: {
        fontWeight: 600,
        fontSize: '2.250rem',
      },
      h5: {
        fontSize: '1.875rem',
        fontWeight: 600
      },
      h6: {
        fontSize: '1.5rem',
        fontWeight: 700,
        //fontWeight:'bold'
      },
      subtitle1: {
        fontSize: '1.250rem',
        fontWeight: 600,
      },
      subtitle2: {
        fontSize: '1rem',
        fontWeight: 700,
      },
      body1: {
        fontSize: '1.125rem',
        //fontWeight:500,
      },
      body2: {
        fontSize: '1rem',
        fontWeight: 700,
      },
      caption: {
        fontSize: '0.875rem',
        fontWeight: 600,
      },
      button: {
        fontSize: '1.125rem'
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [montserrat],
      },
    },
  }
});