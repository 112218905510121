import React, { useEffect, useState } from 'react';
import axios from 'axios';
import system_translations from '../../../../../../texts/system_translations';
import PictureModal from '../../../../../../components/Modals/PictureModal';
import { onGetErrorMessage, onGetFileExtension, onGetImageURL2Blob } from '../../../../../../shared/utility';
import { private_server } from '../../../../../../config';



const ChangeImageModal = (props) => {

    const {id, open, onClose, onCompleted, language, type, id_image} = props
    const content = system_translations[language].modals.change_image_modal

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(!open){
            setError(null)
        }
    }, [open])

    const onSubmit = async(file) => {
        if(!file){
            setError(content.error_msg)
            return
        }
        setError(null)
        try {
            setLoading(true)
            //console.log(file)
            const _file = await onGetImageURL2Blob(file)
            console.log(file)
            let data
            if(type === 'web'){data = {name:`web_image_${id_image}`, ext:onGetFileExtension(_file.name)}}
            else{data = {name:`mobile_image_${id_image}`, ext:onGetFileExtension(_file.name)}}
            console.log(data)
            const response = await private_server.post(`/homesection/${id}/image`, data)
            console.log(response)
            const options = {headers: {'Content-Type': _file.type}}
            delete axios.defaults.headers.common["Authorization"]
            await axios.put(response.data.data.url, _file, options);
            onCompleted()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setLoading(false)
    }

    const onDelete = async() => {
        try {
            setError(null)
            setLoading(true)
            let data
            /* if(type === 'web'){data = {name:"web_image"}}
            else{data = {name:"mobile_image"}} */
            if(type === 'web'){data = '?name=web_image'}
            else{data = '?name=mobile_image'}
            //console.log('hola')
            await private_server.delete(`/banner/${id}/image${data}`)
            onCompleted()
        } catch (error) {
            console.log(error)
            //setError(onGetErrorMessage(error))
        }
        setLoading(false)
        
    }

    return ( 
        <PictureModal open={open} onClose={onClose} loading={loading} error={error} language={language}
            title={content.title} btnLabel={content.button} onChangeFile={onSubmit} aspect_ratio={2/3}  onDeleteFile={onDelete} />
     );
}
 
export default ChangeImageModal;