import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import cx from 'classnames'


const ImagesMenu = (props) => {

    const {selected, onChange, menu} = props

    return ( 
        <div>
            <Grid container spacing={2}>
                {menu.map(item => {
                    return <Grid item><CustomButton number={item.number} onClick={() => onChange(item.id)} selected={item.id === selected}>{item.label}</CustomButton></Grid>
                })}
            </Grid>
        </div>
     );
}
 
export default ImagesMenu;

const useButtonStyles = makeStyles(theme => ({
    root:{position:'relative'},
    normal:{
        color:'#8F8F8F',
        textTransform:'uppercase',
        padding:'4px 12px',
        cursor:'pointer'
    },
    selected:{
        color:theme.palette.primary.main,
        borderBottom:`3px solid ${theme.palette.primary.main}`
    }
}))

const CustomButton = props => {

    const classes = useButtonStyles()
    const {children, selected, onClick, number} = props

    return(
        <div className={cx({
            [classes.normal]:true,
            [classes.selected]:selected
        })} onClick={onClick}>
            <Typography style={{fontWeight:500, fontSize:'1rem', letterSpacing:'0.165em'}} >{children}</Typography>
        </div>
    )
}


  