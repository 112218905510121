import React, { useEffect, useState } from "react"
import { Card, CardActionArea, CardContent, CardMedia, Divider, Grid, FormControlLabel, makeStyles, Typography, useMediaQuery, TextField, CardActions, IconButton } from '@material-ui/core'
import { useTheme, withStyles } from "@material-ui/styles"
import { grey } from "@material-ui/core/colors"
import cx from 'classnames'
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "./Carousel.css"
import { isValidDocument, onGetDateFormat } from "../../../../shared/utility"
import { CONFIG_SETTINGS } from "../../../../config"

const TextFieldDate = withStyles(theme => ({
    root: {
        color: theme.palette.secondary.main,
        '& svg': {
            color: theme.palette.secondary.main,
        },
        '& input': {
            color: theme.palette.secondary.main,
        },
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        },
        '& .MuiInput-underline:before': {
            borderBottom: '0px',
        },
        '& .MuiInput-underline:after': {
            borderBottom: '0px'
        },
    },
}))(TextField);

const styles = makeStyles(theme => ({
    root: {
        width: '100%',
        minHeight: '50vh',
        paddingTop: 80,
        paddingBottom: 80,
        boxSizing: 'border-box'
    },
    topTitle: {
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        height: 2
    },
    content: {
        paddingTop: 20,
        paddingBottom: 80,
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        // backgroundColor: grey[600]
        height: 2
    },
    galleryTitle: {
        fontSize: 28,
        fontWeight: 600,
    },
    image: {
        width: '100%',
        height: 'auto'
    },
    date: {
        fontSize: 16,
        fontWeight: 500,
        '& span': {
            fontSize: 24,
            fontWeight: 400,
        }
    },
    description: {
        color: '#A5A5A5',
        fontSize: 16,
        fontWeight: 600,
        [theme.breakpoints.up('md')]: {
            lineHeight: '1.8em'
        }
    },
    carouselContainer: {
        //marginTop: -100
    },
    featuredButton: {
        color: theme.palette.secondary.main,
        background: 'transparent',
        borderRadius: 4,
        border: `1px solid ${theme.palette.secondary.main}`,
        padding: '6px 28px 6px 10px',
        '&:hover': {
            color: 'white',
            background: `${theme.palette.secondary.dark}80`,
        },
    },
    featuredButtonActive: {
        color: 'white',
        background: theme.palette.secondary.main,
    },
    noContent: {
        fontSize: 16,
        fontWeight: 300
    },
    filterButton: {
        background: 'rgba(189, 139, 64, 0.14)',
        padding: 10,
        boxSizing: 'border-box',
        borderRadius: '100%',
        border: `1px solid ${theme.palette.secondary.main}`,
        '&:hover': {
            background: `${theme.palette.secondary.dark}50`,
        }
    },
    filterLabel: {
        fontSize: 18,
        fontWeight: 600
    }
}))

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 3,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1000 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1000, min: 700 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 700, min: 0 },
        items: 1,
    }
}

const CustomArrow = ({ onClick, side }) => (
    <button
        onClick={onClick}
        aria-label="Go to next slide"
        className={
            `react-multiple-carousel__arrow
                react-multiple-carousel__arrow--${side}
                carousel_arrow 
                carousel_arrow-${side}`
        }
    />
)

const ApplicationArtworks = ({ history, content, data, onClick }) => {

    const classes = styles()
    const theme = useTheme()
    const mobileXS = useMediaQuery(theme.breakpoints.only('xs'))
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))
    const deviceType = mobileXS ? 'mobileXS' : 'mobile'

    //const [filter, setFilter] = useState(1)
    const [currentSlide, setCurrentSlide] = useState(1)

    const handleClick = (side) => {
        let increment = side === 'right' ? 1 : -1
        setCurrentSlide(prev => {
            //console.log('PREV', prev)
            //console.log(side)
            //console.log('NEW VALUE', (prev + increment) % data.length,  increment > 0 ? 1 : data.length-1)
            //console.log('total',data.length)
            //   console.log(prev + increment, (prev + increment) % data.length)
            if (prev + increment < 0)
                return increment > 0 ? 1 : data.length-1
            else
                return (prev + increment) % data.length
        })
        console.log('CURRENT', currentSlide)
    }

    return (
        <div className={classes.root}>
            <Grid container alignItems='center' spacing={3}>
                <Grid item xs>
                    <Divider className={classes.divider} />
                </Grid>
                <Grid item>
                    <Typography className={classes.topTitle} align='right'>
                        {content.title}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Divider className={classes.divider} />
                </Grid>
            </Grid>
            {data && data.length > 0 ?
                <div className={classes.carouselContainer}>
                    <Carousel
                        responsive={responsive}
                        ssr
                        infinite
                        autoPlay={false}
                        //autoPlaySpeed={8000}
                        keyBoardControl
                        //centerMode={!mobile}
                        //customTransition="transform 300ms ease-in-out"
                        itemClass="carouselItem"
                        deviceType={deviceType}
                        removeArrowOnDeviceType={["mobileXS"]}

                        afterChange={(previousSlide, { currentSlide, onMove }) => {
                            //console.log('SLIDES',previousSlide, currentSlide)
                            let side = previousSlide < currentSlide ? 'right' : 'left'
                            if(currentSlide === 0 && previousSlide > 1)
                                side = 'right'
                            handleClick(side);
                        }}
                        customLeftArrow={<CustomArrow side='left' />}
                        customRightArrow={<CustomArrow side='right' />}
                    >
                        {data.map((item, key) => {
                            return (
                                <CarouselItem
                                    key={key.toString()}
                                    current={currentSlide === key}
                                    {...item}
                                    content={content}
                                    onClick={onClick}
                                />
                            )
                        })}
                    </Carousel>
                </div>
                :
                <Typography className={classes.noContent} align='center'>
                    {content.noContent}
                </Typography>
            }
        </div>
    )
}

export default ApplicationArtworks

const styles_carousel = makeStyles(theme => ({
    root: {
        marginTop: 64,
        marginBottom: 64,
        width: '80%',
        margin: 'auto',
        transition: 'all 0.2s ease-in',
        boxShadow: 'none',
        paddingTop: '15%',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            width: '100%',
            boxSizing: 'border-box',
        },
        '&:hover': {
            cursor: 'pointer'
        }
    },
    current: {
        //paddingTop: 0,
        width: '80%',
        transition: 'all 0.2s ease-in',
    },
    cardRoot: {
        background: 'transparent',
        margin: 'auto',
        boxShadow: 'none',
    },
    cardActionArea: {
        background: 'white',
        //pointerEvents: 'none',
        position: 'relative',
        borderRadius: 5,
        border: '1px solid #EAEAEA',
    },
    cardContent: {
        display: 'none',
        width: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        padding: 32,
        boxSizing: 'border-box'
    },
    showCardContent: {
        display: 'block'
    },
    media: {
        width: '100%',
        paddingTop: "100%",
        borderRadius: 5,
        display: 'block',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
    title: {
        width: '100%',
        fontSize: 18,
        fontWeight: 600,
        marginBottom: 15,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        }
    },
    description: {
        color: grey[400],
        fontSize: 14,
        fontWeight: 400,
        letterSpacing: '0.025em'
    },
}))

const CarouselItem = ({ current, title, web_image, mobile_image, content, onClick, url }) => {

    const classes = styles_carousel()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [hover, setHover] = useState(false)

    const image = mobile ? mobile_image : web_image
    const isValid = isValidDocument(image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${image}` : content.noImage

    return (
        <div
            className={cx({
                [classes.root]: true,
                [classes.current]: !mobile && current
            })}
        >
            <Card className={classes.cardRoot} onClick={() => onClick(url)}>
                <CardActions style={{ background: 'transparent', padding: 0, paddingTop: 10 }}>
                    <Typography align='center' className={classes.title}>
                        {title}
                    </Typography>
                </CardActions>
                <CardActionArea
                    className={classes.cardActionArea}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}>
                    <CardMedia
                        className={classes.media}
                        image={selectedImage}
                        title={"Magazine Individuals"}
                        style={{ transform: `scale(${isValid ? 1 : 0.7})` }}
                    />
                </CardActionArea>
            </Card>
        </div >
    )
}