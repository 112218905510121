import React from 'react'
import { withStyles, Grid, LinearProgress, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography, IconButton, Icon } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import ActionBar from './ActionBar'
import StatusData from '../../../../../components/Structure/DisplayData/StatusData'
import { catalogs } from '../../../../../texts/eng/catalogs'
import AvatarPicture from '../../../../../components/Structure/DisplayData/AvatarPicture'
import SVGIcon from '../../../../../components/Structure/DisplayData/SVGIcon'
import deleteIcon from '../../../../../assets/icons/delete_trash.svg'
import SearchBar from '../components/SearchBar'

const CustomizedTableRow = withStyles((theme) => ({
    root: {
        background: 'white',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.07)',
        borderRadius: 15,
        height: 90,
        '& td:first-child': {
            borderRadius: '15px 0px 0px 15px',
        },
        '& td:last-child': {
            borderRadius: '0px 15px 15px 0px',
        },
        // '&:hover': {
        //     cursor: 'pointer',
        //     background: grey[100]
        // }
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 40
    },
    addIcon: {
        background: 'black',
        borderRadius: '100%',
        color: 'white',
        '&:hover': {
            background: theme.palette.primary.main,
        }
    },
    paper: {
        marginTop: 4,
        borderRadius: 8,
    },
    cellHeader: {
        paddingTop: 16,
        paddingBottom: 16,
        borderBottom: 'none',
    },
    cell: {
        borderBottom: 'none',
        position: 'relative',
    },
    text: {
        fontWeight: 500,
        fontSize: 14
    },
    country: {
        width: 'fit-content',
        margin: 'auto',
        fontWeight: 500,
        fontSize: 14,
        border: '1px solid black',
        borderRadius: 4,
        padding: '0px 10px'
    },
    button: {
        background: theme.palette.primary.main,
        padding: '6px 12px',
        borderRadius: 32,
        color: 'white',
        '&:hover': {
            background: theme.palette.primary.dark,
        }
    },
    toolbar: {
        marginBottom: 16
    },
    actions: {
        marginTop: 16
    },
    table: {
        //minWidth: 1100,
        borderSpacing: '0px 3px',
        borderCollapse: 'separate'
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    nodata: {
        marginTop: 40
    },
    colorTag: {
        borderRadius: '15px 0px 0px 15px',
        position: 'absolute',
        width: 8,
        height: 90,
        top: 0,
        left: 0
    },
    startIcon: {
        fill: theme.palette.secondary.main
    }
}))

const SimpleTable = props => {

    const classes = useStyles()

    const { data, header, total, tableFilter, onUpdateTableFilter, onSelectedItem,
        onRemoveItem, loading, noData, search, onChangeSearch, onAddSponsor } = props

    return (
        <div className={classes.root}>
            {loading ? (
                <Grid container justifyContent='center'>
                    <Grid item xs={12}><LinearProgress color='secondary' /></Grid>
                </Grid>
            ) : null}
            <div className={classes.toolbar}>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                            {/* <Grid item>
								<ActionTopBar filter={catalog_filter} filterValue={status} onChangeFilter={onChangeStatus}></ActionTopBar>
							</Grid> */}
                            <Grid item xs />
                            <Grid item><SearchBar value={search} onChange={onChangeSearch} /></Grid>
                            <Grid item> <IconButton className={classes.addIcon} onClick={onAddSponsor}><Icon>add</Icon></IconButton> </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            {data && data.length > 0 ?
                <>
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item => {
                                            return (
                                                <TableCell padding={'normal'} className={classes.cellHeader} key={item.id.toString()} align={item.align}>
                                                    <Typography variant='body2' >{item.label}</Typography>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ) : null}
                            <TableBody>
                                {data.map((item, key) => {

                                    const { id_exhibition_sponsor, sponsor, sponsor_logo, enabled } = item
                                    const statusData = catalogs.sponsor_statuses.find(status => status.id === enabled)

                                    return (
                                        <CustomizedTableRow key={key.toString()}>
                                            <TableCell className={classes.cell} align='center'>
                                                <Typography className={classes.text}>{id_exhibition_sponsor}</Typography>
                                            </TableCell>
                                            <TableCell className={classes.cell}>
                                                <Grid container alignItems='center' spacing={2}>
                                                    <Grid item>
                                                        <AvatarPicture src={sponsor_logo} size={42} />
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography className={classes.text}>{sponsor}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell className={classes.cell} >
                                                {statusData &&
                                                    <StatusData
                                                        icon={'square'}
                                                        data={statusData.label}
                                                        color={statusData.color}
                                                        size={10}
                                                        style={{ color: 'black', fontWeight: 500, fontSize: 14 }}
                                                    />
                                                }
                                            </TableCell>
                                            <TableCell className={classes.cell}><IconButton onClick={() => onRemoveItem(id_exhibition_sponsor)}><SVGIcon src={deleteIcon} /></IconButton></TableCell>
                                        </CustomizedTableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </div>
                    <div className={classes.actions}>
                        <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter} />
                    </div>
                </>
                :
                data.length === 0 && !loading && (
                    <div className={classes.nodata}>
                        <Grid container justifyContent='center' alignItems='center' direction='column'>
                            <Grid item>
                                <Typography>{noData}</Typography>
                            </Grid>
                        </Grid>
                    </div>

                )}

        </div >
    )
}

export default SimpleTable