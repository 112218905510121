import { Grid, Icon, Input, makeStyles, OutlinedInput, Typography } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import SVGIcon from '../Structure/DisplayData/SVGIcon';
import SimpleModal from './SimpleModal';
import searchIcon from '../../assets/icons/search.svg';
import {countries} from '../../texts/eng/catalogs';

const CountryCodesModal = ({ open, onClose, onPick }) => {
	const classes = useStyles();

	const [countries_array, setCountries] = useState(countries);
	const [search, setSearch] = useState('');

	const timerRef = useRef();

	const handleSearch = (e) => {
		setSearch(e.target.value);
	};

	const updateCountries = () => {
		let _countries = [];
		_countries = countries.filter((item) => item.label.toLowerCase().includes(search.toLowerCase()));
		setCountries(_countries);
	};

	const onStartCountDown = () => {
		if (timerRef.current) clearTimeout(timerRef.current);
		timerRef.current = setTimeout(updateCountries, 300);
	};

	const onStopCountDown = () => {
		if (timerRef.current) clearTimeout(timerRef.current);
	};

	return (
		<SimpleModal padding='medium' open={open} onClose={onClose} maxWidth={'sm'}>
			<Typography variant='h6'>Pick country code</Typography>
			<div className={classes.input_container}>
				<OutlinedInput
					fullWidth
					className={classes.input}
					value={search}
					onChange={handleSearch}
					endAdornment={<SVGIcon src={searchIcon} size={22} color='grey' />}
					placeholder='Search'
					onKeyUp={onStartCountDown}
					onKeyDown={onStopCountDown}
				/>
			</div>
			<Grid container className={classes.data_container} alignItems='flex-start'>
				{countries_array.map((item) => (
					<Grid item container xs={12} alignItems={'center'} key={item.id} className={classes.row} onClick={()=>onPick(item.mobile_code)}>
						<Grid item xs={1}>
                            <img src={`https://countryflagsapi.com/svg/${item.abbreviation.toLowerCase()}`} className={classes.image}/>
                        </Grid>
						<Grid item xs={9} style={{paddingLeft: 10}}>
							<Typography className={classes.text} variant='subtitle1'>
								{item.label}
							</Typography>
						</Grid>
						<Grid item xs={2}>
							<Typography className={classes.text} variant='subtitle1'>
								(+{item.mobile_code})
							</Typography>
						</Grid>
					</Grid>
				))}
			</Grid>
		</SimpleModal>
	);
};

const useStyles = makeStyles((theme) => ({
	input_container: {
		width: 354,
		margin: '45px auto',
	},
	input: {
		fontWeight: 600,
		fontSize: 16,
		background: '#FCFAF7',
	},
	text: {
		fontSize: 16,
		color: '#6D6D6D',
	},
	data_container: {
		height: 400,
		overflowY: 'scroll',
		margin: '0px 15px',
		marginBottom: 20,
	},
	row: {
		minHeight: 70,
		borderBottom: '1px solid #DCDCDC',
        cursor: 'pointer',
        padding: '0px 10px',
        transition: 'all 0.2s',
        '&:hover':{
            background: 'lightgrey',
            transition: 'all 0.15s',
        }
	},
    image:{
        height: 30,
        width: 45,
    }
}));

export default CountryCodesModal;
