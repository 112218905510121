import { Button, FormControlLabel, Grid, makeStyles, styled, Switch, Typography } from "@material-ui/core"
import SimpleModal from "../../../../../../components/Modals/SimpleModal"
import system_translations from "../../../../../../texts/system_translations"
import ImagesMenu from "../../../../../../components/Structure/Nav/ImagesMenu"
import InputForm from "../../../../../../components/Forms/InputForm"
import LoadingButton from "../../../../../../components/Actions/LoadingButton"
import { ColorPicker } from 'material-ui-color';
import ImageContainer from "../../../../../../components/Ecommerce/ImageContainer"
import ChangeImageModal from "./ChangeImageModal"
import useEditBannerModal from "./useEditBannerModal"
import { red } from "@material-ui/core/colors"


const EditBannerModal = ({ open, onClose, language, selected, onCompletedBanners }) => {

    const classes = useStyles()
    const content = system_translations[language].modals.banner
    const { system, form, actions, view_data, upload_image } = useEditBannerModal({ open, language, content, selected, onCompletedBanners, onClose })
    const { banner } = view_data


    let contentView = null

    switch (view_data.img_view) {
        case 1:
            contentView = <ImageContainer ratio='16/9' src={banner ? banner.web_image : null} onEdit={() => actions.onChangeModal(true)} />
            break
        case 2:
            contentView = <ImageContainer ratio='16/9' src={banner ? banner.mobile_image : null} onEdit={() => actions.onChangeModal(true)} />
            break
        default:
            break
    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm' >
            <ChangeImageModal open={upload_image} onClose={() => actions.onChangeModal(false)} language={language}
                type={view_data.img_view === 1 ? 'web' : 'mobile'} onCompleted={actions.onCompleted}
                id={selected ? selected.id_banner : null} />
            <div className={classes.titleContainer}><Typography className={classes.title}>{content.edit.title}</Typography></div>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container justifyContent='space-between' alignItems='center'>
                            <Grid item>
                                <ImagesMenu menu={content.imgs_menu} selected={view_data.img_view} onChange={(id) => actions.onChangeImgView(id)} />
                            </Grid>
                            <Grid item>
                                <FormControlLabel  
                                    control={<CostumSwitch checked={view_data.enable} onChange={actions.onChangeEnable} />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {contentView}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent='space-between'>
                            <Grid item>
                                <div className={classes.color_container}  style={{marginBottom: 15}}>
                                    <Typography className={classes.text_color} variant='body2' >{content.text_color}</Typography>
                                    <div className={classes.color_picker}>
                                        <ColorPicker value={view_data.text_color} hideTextfield disableAlpha onChange={actions.onChangeTextColor} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item>
                                <div className={classes.color_container}>
                                    <Typography className={classes.text_color} variant='body2'>{content.back_color}</Typography>
                                    <div className={classes.color_picker}>
                                        <ColorPicker value={view_data.back_color} hideTextfield disableAlpha onChange={actions.onChangeBackColor} />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <InputForm data={form.date} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputForm data={form.title} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputForm data={form.subtitle} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputForm data={form.description} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputForm data={form.web_link} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputForm data={form.app_link} onChange={actions.onChangeForm} />
                    </Grid>
                </Grid>
            </div>
            <div className={classes.bottom_section}>
                <Grid container spacing={2} justifyContent='center'>
                    <Grid item xs={12}>
                        <Typography style={{ color: red[700] }} variant='subtitle1'>
                            {system.error && system.error.message ? system.error.message : system.error}
                        </Typography>
                    </Grid>
                    {/* <Grid item><LoadingButton loading={system.deleting} onClick={actions.onDelete} variant='outlined'>{content.edit.delete_button}</LoadingButton></Grid> */}
                    <Grid item><LoadingButton loading={system.sending} onClick={actions.onSubmit}>{content.edit.save_button}</LoadingButton></Grid>
                </Grid>
            </div>
        </SimpleModal>
    )

}

export default EditBannerModal

const useStyles = makeStyles(theme => ({
    titleContainer: {
        marginBottom: 40
    },
    title: {
        fontSize: 20,
        fontWeight: 700
    },
    bottom_section: {
        textAlign: 'center',
        margin: '50px 0px 12px'
    },
    color_circle: {
        borderRadius: '50%',
        border: '2px solid #CBCBCB',
        boxSizing: 'border-box',
        width: 20,
        height: 20,
        marginLeft: 8,
        marginTop: 2
    },
    color_container: {
        display: 'flex'
    },
    color_picker: {

    },
    text_color: {
        marginTop: 6
    }

}))

const CostumSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                //backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                backgroundColor: theme.palette.primary.dark,
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#C9C9C9',
        //backgroundColor: theme.palette.primary.dark,
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));