import noImage from '../../assets/noimagegold.png'
import featuredIcon from '../../assets/icons/featured.svg'

import noMagazines from '../../assets/icons/magazines.svg'

export const translations = {
    title: 'Magazines',
    artworks: 'Artworks',
    noMagazines: {
        title: 'No magazines to show',
        subtitle: 'Magazines will show here.',
        icon: noMagazines
    },
    filter: [
        { id: 'all', value: 0, label: 'All' }
    ],
    form: {
        search: {
            placeholder: 'Search by title...'
        },
        title: {
            label: 'Title',
            placeholder: 'Title',
            helperText: 'Required field'
        },
        url: {
            label: 'URL',
            placeholder: 'URL',
            helperText: 'Required field'
        },
        magazine_type_id: {
            label: 'Type',
            placeholder: 'Type',
            helperText: 'Select a valid option'
        },
    },
    add_button: 'Add',
    add_title: 'Add Magazine',
    noImage: noImage,
    noContent: 'There are not coincidences with the filters selected.',
    data: {
        title: 'Title',
        start_date: 'Date'
    },
    featured: featuredIcon
}