import React from 'react'
import { Grid } from '@material-ui/core'
import SimpleModal from '../../../components/Modals/SimpleModal'
import RoundedButton from '../../../components/Actions/RoundedButton'
import LineInputForm from '../../../components/Forms/LineInputForm'
import LineSelectForm from '../../../components/Forms/LineSelectForm'
import SwitchForm from '../../../components/Forms/SwitchForm'

const EditVirtualModal = ({ open, form, onChange, onSubmit, onClose, content }) => {

    return (
        <SimpleModal maxWidth='sm' open={open} onClose={onClose}>
            <Grid container alignItems='center' spacing={4}>
                <Grid item xs={12}>
                    <LineInputForm data={form.iframe} onChange={onChange} />
                </Grid>
                <Grid item xs></Grid>
                <Grid item>
                    <RoundedButton size='small' onClick={() => onSubmit('virtual_exhibitions')}>
                        {content.save_button}
                    </RoundedButton>
                </Grid>
            </Grid>
        </SimpleModal>
    )
}

export default EditVirtualModal