import React from 'react';
import { withStyles, Grid, Icon, IconButton, LinearProgress, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import ActionBar from './ActionBar';
import { catalogs } from '../../../texts/eng/catalogs';
import { onGetDateFormat } from '../../../shared/utility';
import { useTheme } from '@material-ui/styles';
import AvatarPicture from '../../../components/Structure/DisplayData/AvatarPicture';
import StatusData from '../../../components/Structure/DisplayData/StatusData';

const CustomizedTableRow = withStyles((theme) => ({
	root: {
		background: 'white',
		boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.07)',
		borderRadius: 15,
		height: 90,
		'& td:first-child': {
			borderRadius: '15px 0px 0px 15px',
		},
		'& td:last-child': {
			borderRadius: '0px 15px 15px 0px',
		},
		'&:hover': {
			cursor: 'pointer',
			background: grey[100],
		},
	},
}))(TableRow);

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 40,
	},
	addIcon: {
		background: theme.palette.secondary.main,
		borderRadius: '100%',
		color: 'white',
		'&:hover': {
			background: theme.palette.secondary.dark,
		},
	},
	paper: {
		marginTop: 4,
		borderRadius: 8,
	},
	cellHeader: {
		paddingTop: 16,
		paddingBottom: 16,
		borderBottom: 'none',
	},
	cell: {
		borderBottom: 'none',
		position: 'relative',
	},
	text: {
		fontWeight: 500,
		fontSize: 14,
	},
	country: {
		width: 'fit-content',
		margin: 'auto',
		fontWeight: 500,
		fontSize: 14,
		border: '1px solid black',
		borderRadius: 4,
		padding: '0px 10px',
	},
	button: {
		background: theme.palette.primary.main,
		padding: '6px 12px',
		borderRadius: 32,
		color: 'white',
		'&:hover': {
			background: theme.palette.primary.dark,
		},
	},
	toolbar: {
		marginBottom: 16,
	},
	actions: {
		marginTop: 16,
	},
	table: {
		//minWidth: 1100,
		borderSpacing: '0px 3px',
		borderCollapse: 'separate',
	},
	tableWrapper: {
		overflowX: 'auto',
	},
	nodata: {
		marginTop: 40,
	},
	colorTag: {
		borderRadius: '15px 0px 0px 15px',
		position: 'absolute',
		width: 8,
		height: 90,
		top: 0,
		left: 0,
	},
	contacted: {
		color: 'white',
		fontWeight: 500,
		fontSize: 15,
		borderRadius: 4,
		textAlign: 'center',
		width: 'fit-content',
		padding: '4px 20px',
	},
}));

const SimpleTable = (props) => {
	const classes = useStyles();
	const theme = useTheme();

	const { data, header, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, onRemoveItem, noData } = props;

	return (
		<div className={classes.root}>
			{loading ? (
				<Grid container justifyContent='center'>
					<Grid item xs={12}>
						<LinearProgress color='secondary' />
					</Grid>
				</Grid>
			) : null}
			{data && data.length > 0 ? (
				<div className={classes.tableWrapper}>
					<Table className={classes.table}>
						{header ? (
							<TableHead>
								<TableRow>
									{header.map((item) => {
										return (
											<TableCell padding={'normal'} className={classes.cellHeader} key={item.id.toString()} align={item.align}>
												{item.label === 'Name' ? (
													<div style={{ width: 250, margin: '0px auto' }}>
														<Typography variant='body2'>{item.label}</Typography>
													</div>
												) : (
													<Typography variant='body2'>{item.label}</Typography>
												)}
											</TableCell>
										);
									})}
								</TableRow>
							</TableHead>
						) : null}
						<TableBody>
							{data.map((item, key) => {
								const { id_sponsor, name, logo, enabled } = item;
								const status = catalogs.sponsor_statuses.find((status) => status.id === enabled);

								return (
									<CustomizedTableRow key={key.toString()}>
										<TableCell className={classes.cell} onClick={() => onSelectedItem(item)} align='center'>
											<Typography className={classes.text}>{id_sponsor}</Typography>
										</TableCell>
										<TableCell align='center' className={classes.cell} onClick={() => onSelectedItem(item)}>
											<div style={{ width: 250, margin: '0px auto' }}>
												<Grid container alignItems='center' spacing={2}>
													<Grid item>
														<AvatarPicture src={logo} size={42} />
													</Grid>
													<Grid item>
														<Typography className={classes.text}>{name}</Typography>
													</Grid>
												</Grid>
											</div>
										</TableCell>
										<TableCell className={classes.cell} onClick={() => onSelectedItem(item)}>
											{status && <StatusData icon={'square'} data={status.label} color={status.color} size={10} style={{ color: 'black', fontWeight: 500, fontSize: 14 }} />}
										</TableCell>
										{onRemoveItem && (
											<TableCell className={classes.cell} onClick={() => onRemoveItem(id_sponsor)}>
												<IconButton size='small'>
													<Icon>delete</Icon>
												</IconButton>
											</TableCell>
										)}
									</CustomizedTableRow>
								);
							})}
						</TableBody>
					</Table>
				</div>
			) : null}

			{data && data.length ? (
				<div className={classes.actions}>
					<ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter} />
				</div>
			) : null}
			{data.length === 0 && !loading ? (
				<div className={classes.nodata}>
					<Grid container justifyContent='center' alignItems='center' direction='column'>
						<Grid item>
							<Typography>{noData}</Typography>
						</Grid>
					</Grid>
				</div>
			) : null}
		</div>
	);
};

export default SimpleTable;
