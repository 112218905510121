import React from 'react'
import { Grid, makeStyles, Typography } from "@material-ui/core"
import LineInputForm from '../../../components/Forms/LineInputForm'
import SimpleModal from '../../../components/Modals/SimpleModal'
import RoundedButton from '../../../components/Actions/RoundedButton'

const styles = makeStyles(theme => ({
    content: {
        position: 'relative'
    },
    words: {
        position: 'absolute',
        fontSize: 11,
        fontWeight: 300,
        right: 0
    },
}))

const EditAboutModal = ({ open, form, onChange, onSubmit, onClose, content }) => {

    const classes = styles()

    return (
        <SimpleModal maxWidth='sm' open={open} onClose={onClose}>
            <Grid container alignItems='center' spacing={4}>
                {Object.keys(content.sections.about.form).map((key,k) => (
                    <Grid item xs={12} key={`about-input-${k.toString()}`}>
                        <div className={classes.content}>
                            <Typography variant='caption' align='right' className={classes.words}>
                                (max. {form[key].rules.max} {form[key].config.words ? content.words : content.characters})
                            </Typography>
                            <LineInputForm
                                data={form[key]}
                                onChange={onChange}
                            />
                        </div>
                    </Grid>
                )
                )}
                <Grid item xs></Grid>
                <Grid item>
                    <RoundedButton size='small' onClick={onSubmit}>
                        {content.save_button}
                    </RoundedButton>
                </Grid>
            </Grid>
        </SimpleModal>
    )
}

export default EditAboutModal