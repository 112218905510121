import React from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Divider, Grid, IconButton, Typography, useMediaQuery } from '@material-ui/core'
import RoundedButton from '../../../components/Actions/RoundedButton'
import GenericImage from '../../../components/Structure/DisplayData/GenericImage'
import SimpleCard from '../../../components/Structure/Cards/SimpleCard'
import FilledDisplayData from '../../../components/Structure/DisplayData/FilledDisplayData'
import ReactPlayer from 'react-player'

const styles = makeStyles(theme => ({
    root: {
        marginTop: 80
    },
    title: {
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        height: 2
    },
    deleteButton: {
        background: theme.palette.primary.main,
        padding: 8,
        '&:hover': {
            background: theme.palette.primary.dark,
        }
    },
    video: {
        width: '100%',
        margin: 'auto',
        borderRadius: 8,
        height: 0,
        textAlign: 'center',
        position: 'relative',
        paddingBottom: "56.25%",
        overflow: 'hidden',
        filter: 'drop-shadow(0px 11px 15px rgba(0, 0, 0, 0.31))'
    },
    iframe: {
        position: 'absolute',
        borderRadius: 12,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%',
        border: 'none',
        '& video': {
            width: '100%',
            height: '100%',
            objectFit: 'fill',
        }
    },
}))

const Video = ({ data, content, form, submitButton, onEdit, onDelete }) => {

    const classes = styles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.only('xs'))

    return (
        <div className={classes.root}>
            <Grid container justifyContent='flex-end' spacing={4}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.title}>
                                {content.title}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <RoundedButton
                        color='black'
                        size='small'
                        style={submitButton}
                        onClick={onEdit}
                    >
                        {content.button}
                    </RoundedButton>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <div className={classes.video}>
                                <ReactPlayer
                                    url={data.video}
                                    //playing={play}
                                    className={classes.iframe}
                                    //light
                                    c ontrols
                                    width='100%' height='100%'
                                //onClickPreview={() => setPlay(true)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <SimpleCard>
                                <FilledDisplayData data={form.video} value={data.video} />
                            </SimpleCard>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div >
    )
}

export default Video