import general_es from './esp/general-translations'
import front_end_errors_es from './esp/frontend-erros'
import users_translations_es from './esp/users-translations'
import user_translations_es from './esp/user-translations'
import product_translations_es from './esp/product-translations'
import catalogs_es from './esp/catalogs'
import modals_es from './esp/modals-translations'
import products_translations_es from './esp/products-translations'
import brands_translations_es from './esp/brands-translations'
import providers_translations_es from './esp/providers-translations'
import sale_groups_translations_es from './esp/sale-groups-translations'
import sale_group_translations_es from './esp/sale-group-translations'
import clients_translations_es from './esp/clients-translations'
import analyticals_translations_es from './esp/analyticals-translations'
import purchases_translations_es from './esp/purchases-translations'
import purchase_translations_es from './esp/purchase-translations'
import sales_translations_es from './esp/sales-translations'
import sale_translations_es from './esp/sale-translations'
import missing_translations_es from './esp/missing-translations'
import order_translations_es from './esp/order-translations'

//english
import modals_eng from './eng/modals-translations'
import catalogs_eng from './eng/catalogs'
import signin_translations_eng from './eng/signin-translations'

import home_translations_eng from './eng/home-translations'
import applications_translations_eng from './eng/applications-translations'
import artworks_translations_eng from './eng/artworks-translations'
import artwork_translations_eng from './eng/artworks-details-translations'


const system_translations = {
    es:{
        errors: front_end_errors_es,
        general:general_es,
        views:{
            users:users_translations_es,
            user:user_translations_es,
            product:product_translations_es,
            products:products_translations_es,
            brands:brands_translations_es,
            providers:providers_translations_es,
            sale_groups:sale_groups_translations_es,
            sale_group:sale_group_translations_es,
            clients:clients_translations_es,
            analyticals:analyticals_translations_es,
            purchases:purchases_translations_es,
            purchase:purchase_translations_es,
            sales:sales_translations_es,
            sale:sale_translations_es,
            missing:missing_translations_es,
            order:order_translations_es
        },
        modals:{
            brand_modal:modals_es.brand_modal,
            equivalence_modal:modals_es.equivalence_modal,
            picture_modal:modals_es.picture_modal,
            change_image_modal:modals_es.change_image_modal,
            warning_modal:modals_es.warning_modal,
            provider_modal:modals_es.provider_modal,
            client_modal:modals_es.client_modal,
            payment_method:modals_es.payment_method,
            receipt:modals_es.receipt,
            select_client:modals_es.select_client,
            download_doc:modals_es.download_doc
        },
        catalogs:catalogs_es
    },
    eng:{
        errors: front_end_errors_es,
        general:general_es,
        signin:signin_translations_eng,
        catalogs:catalogs_eng,
        views:{
            home:home_translations_eng,
            applications:applications_translations_eng,
            artworks:artworks_translations_eng,
            artwork:artwork_translations_eng
        },
        modals:{
            change_image_modal:modals_eng.change_image_modal,
            upload_image_modal:modals_eng.upload_image_modal,
            warning_modal:modals_eng.warning_modal,
            picture_modal:modals_eng.picture_modal,
            banner:modals_eng.banner,
            section:modals_eng.section
        }
    }
}

export default system_translations