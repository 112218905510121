import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { isFormValid, isValidDocument, onClearTokenSession, onGetErrorMessage, onGetFileExtension, onGetFormData, onGetSelectedFormData, onGetUserCatalogs, onInitForm, onSetErrorsToForm } from '../../shared/utility'
import { CONFIG_SETTINGS, private_server } from '../../config'
import { catalogs } from '../../texts/eng/catalogs'
import axios from 'axios'
import FileSaver from 'file-saver'
import queryString from 'query-string'
import { ERROR_CODES } from '../../texts/eng/errorCodes'
import { generic_errors } from '../../texts/eng/genericErrors'

const styles = makeStyles(theme => ({
    content: {
        backgroundColor: 'white',
        minHeight: '100vh',
    },
    loadingContainer: {
        backgroundColor: 'black',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center'
    },
    loading: {
        transform: 'translateY(50vh)'
    },
    arrow: {
        mixBlendMode: 'difference',
        zIndex: 1,
        position: 'fixed',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(40px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        }
    },
    vertical: {
        color: 'white',
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
}))

const useUserProfileView = ({ is_auth, currentUser, language, history, match, content, onUpdateUserData, onUpdateAuthStatus }) => {

    const classes = styles()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)

    /* ENQUIRIES */
    const [loadingEnquiries, setLoadingEnquiries] = useState(false)
    const [enquiries, setEnquiries] = useState([])
    const [filter, setFilter] = useState(0)
    const [tableFilter, setTableFilter] = useState({ limit: 10, offset: 0, order_by: 'id_artwork_purchase_request', order: 0 })
    const [total, setTotal] = useState(0)
    const [enquiry, setEnquiry] = useState(null)
    const [users, setUsers] = useState([])

    /* USER */
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [type, setType] = useState(1)
    const [user, setUser] = useState(null)

    //MODALS
    const [profile_picture_modal, setProfilePictureModal] = useState(false)
    const [edit_modal, setEditModal] = useState(false)
    const [edit_enquiry_modal, setEditEnquiryModal] = useState(false)
    const [change_password_modal, setChangePasswordModal] = useState(false)

    const userID = match.params.id

    const isUserProfile = currentUser && currentUser.id_user ? currentUser.id_user == userID : false

    useEffect(() => {
        window.scrollTo(0, 0)
        if (is_auth)
            actions.initModule()
    }, [is_auth])

    useEffect(() => {
        let _form = JSON.parse(JSON.stringify(formData))

        Object.keys(content.sections).map(section => {
            if (content.sections[section].form)
                _form = onInitForm(_form, user, content.sections[section].form)
        })
        setForm(_form)

    }, [user, edit_modal, change_password_modal])

    useEffect(() => {
        //Initial state for table filter (rows & page)
        if (is_auth) {
            setTableFilter({ limit: 10, offset: 0, order_by: 'id_artwork_purchase_request', order: 0 })
            actions.onFilterEnquiries()
        }
    }, [is_auth, filter])

    useEffect(() => {
        if (enquiry) {
            let _form = JSON.parse(JSON.stringify(formData))
            _form = onInitForm(_form, enquiry, content.sections.enquiries.form)
            _form.consultant_id.options = [...users]
            setForm(_form)
        }
    }, [enquiry, edit_enquiry_modal])

    const actions = {
        initModule: async () => {
            try {
                setLoading(true)
                let _user = await private_server.get(`user/${userID}`)
                _user = _user.data.data.user
                setUser({ ..._user })
                setType(_user.user_type_id)

                let _filterUser = {
                    OR: [
                        { field: "user_type_id", operator: "=", value: 1 },
                        { field: "user_type_id", operator: "=", value: 2 }
                    ]
                }
                
                _filterUser = encodeURIComponent(JSON.stringify(_filterUser))
                let _users = await private_server.get(`user/all/?filter=${_filterUser}`)
                _users = _users.data.data.users
                setUsers(onGetUserCatalogs(_users))

                let _filter = {
                    AND: [
                        { field: "consultant_id", operator: "=", value: userID }
                    ]
                }
                _filter = encodeURIComponent(JSON.stringify(_filter))
                const _enquiries = await private_server.get(`artworkpurchaserequest/all?filter=${_filter}`)
                setEnquiries([..._enquiries.data.data.artwork_purchase_requests])
                setTotal(_enquiries.data.data.count)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onUpdateEnquiriesFilter: (value) => setFilter(value),
        onUpdateTableFilter: (data) => setTableFilter(data),
        onFilterEnquiries: async () => {
            try {
                setLoadingEnquiries(true)
                let _filter = {
                    AND: [
                        { field: "consultant_id", operator: "=", value: userID }
                    ]
                }
                if (filter > 0) _filter.AND.push({ field: "artwork_purchase_request_status_id", operator: "=", value: filter })
                console.log(filter)
                _filter = encodeURIComponent(JSON.stringify(_filter))
                let params = `?${queryString.stringify(tableFilter)}&filter=${_filter}`
                const _enquiries = await private_server.get(`artworkpurchaserequest/all${params}`)
                setEnquiries([..._enquiries.data.data.artwork_purchase_requests])
                setTotal(_enquiries.data.data.count)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoadingEnquiries(false)
        },
        onCloseSuccess: () => setSuccess(false),
        onCloseError: () => setError(null),
        onChange: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if (error) setError(null)
            setForm(temp)
        },
        onSetUserType: (value) => {
            setType(value)
            actions.onSaveUser(value)
        },
        //PROFILE
        onOpenProfileModal: () => setProfilePictureModal(true),
        onCloseProfileModal: () => setProfilePictureModal(false),
        //GENERAL INFO
        onOpenEditModal: () => setEditModal(true),
        onCloseEditModal: () => setEditModal(false),
        onOpenChangePasswordModal: () => setChangePasswordModal(true),
        onCloseChangePasswordModal: () => setChangePasswordModal(false),
        /**** */
        onDeleteUser: async () => {
            try {
                setSending(true)
                //== due to userID being a string
                if (currentUser.id_user == userID) {
                    //We can delete the user we represent
                    setError(ERROR_CODES['504'])
                    return
                } else {
                    await private_server.delete(`user/${userID}`)
                    history.push('/users')
                }
                //LOG OUT?
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onSaveUser: async (value) => {
            try {
                setSending(true)
                const generalInfoKeys = Object.keys(content.sections.general_info.form)

                const errors = isFormValid(form, generalInfoKeys)

                // Error handler
                if (errors && errors.length) {
                    const _form = onSetErrorsToForm(form, errors)
                    setForm(_form)
                    setError(generic_errors[language].INCOMPLETE_FORM)
                    setSending(false)
                    console.log(errors)
                    return
                }

                let data2send = {}
                data2send = onGetSelectedFormData(form, user)
                
                if (Object.keys(data2send).length > 0 || value && typeof value === 'number') {
                    data2send['user_type_id'] = value && typeof value === 'number' ? value : type
                    let _user = await private_server.patch(`user/${userID}`, data2send)
                    _user = _user.data.data.user
                    setUser(_user)
                }

                setError(null)
                setEditModal(false)
                setSending(false)
            } catch (error) {
                console.log(error)
                setSending(false)
                setError(onGetErrorMessage(error))
            }
        },
        onUpdateUser: async () => {
            try {
                setSending(true)

                let _user = await private_server.get(`user/${userID}`)
                _user = _user.data.data.user
                setUser({ ..._user })
                let _form = JSON.parse(JSON.stringify(formData))

                Object.keys(content.sections).map(section => {
                    _form = onInitForm(_form, _user, content.sections[section].form)
                })

                setForm(_form)

                setError(null)
                setProfilePictureModal(false)
                setSending(false)
            } catch (error) {
                console.log(error)
                setSending(false)
                setError(onGetErrorMessage(error))
            }
        },
        onUpdateEnquiry: async () => {
            try {
                setSending(true)
                const enquiryKeys = Object.keys(content.sections.enquiries.form)

                const errors = isFormValid(form, enquiryKeys)
                // Error handler
                if (errors && errors.length) {
                    const _form = onSetErrorsToForm(form, errors)
                    setForm(_form)
                    setError(generic_errors[language].INCOMPLETE_FORM)
                    setSending(false)
                    return
                }

                const data2send = onGetSelectedFormData(form, enquiryKeys)

                const enquiryID = enquiry.id_artwork_purchase_request
                await private_server.patch(`artworkpurchaserequest/${enquiryID}`, data2send)
                await actions.onFilterEnquiries()

                setEditEnquiryModal(false)
                setError(null)
                setEditModal(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onChangePassword: async () => {
            try {
                setSending(true)
                const keys = Object.keys(content.sections.change_password.form)

                //delete 'previous_password' if user is not on them profile
                if(!isUserProfile)
                    keys.shift()

                const errors = isFormValid(form, keys)
                // Error handler
                if (errors && errors.length) {
                    const _form = onSetErrorsToForm(form, errors)
                    setForm(_form)
                    setError(generic_errors[language].INCOMPLETE_FORM)
                    setSending(false)
                    return
                }

                const data2send = onGetFormData(form, keys)

                if(data2send.new_password !== data2send.new_password_confirm){
                    setError(generic_errors[language].PSW_DONT_MATCH)
                    setSending(false)
                    return
                    
                }

                delete data2send.new_password_confirm

                if(isUserProfile)
                    await private_server.post(`user/me/passwordchange`, data2send)
                else
                    await private_server.patch(`user/${userID}`, {password: data2send.new_password})
                

                setChangePasswordModal(false)
                setError(null)

                if(isUserProfile){
                    await onClearTokenSession()
                    await onUpdateAuthStatus(false)
                    await onUpdateUserData(null)
                    history.push('/signin')
                }

            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onSelectEnquiry: (data) => {
            setEnquiry(data)
            actions.onOpenEnquiryModal()
        },
        onOpenEnquiryModal: () => setEditEnquiryModal(true),
        onCloseEnquiryModal: () => setEditEnquiryModal(false),
    }

    const system = { loading, sending, success, error }
    const view_data = {
        form, type, user, userID, enquiries, loadingEnquiries, tableFilter,
        filter, total, profile_picture_modal, edit_modal, edit_enquiry_modal, change_password_modal, isUserProfile
    }

    return { system, view_data, actions }
}

export default useUserProfileView

const formData = {
    user_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options: [...catalogs.user_types],
        config: {
            id: 'user_type_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none',
        }
    },
    referenced_by: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        image: null,
        config: {
            id: 'referenced_by',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none'
        }
    },
    first_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'last_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    gender_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options: [...catalogs.user_genders],
        config: {
            id: 'gender_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none',
        }
    },
    country_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.countries],
        config: {
            id: 'country_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none',
        }
    },
    birth_date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'birth_date',
            type: 'date',
            fullWidth: true,
        },
        rules: { type: 'date' }
    },
    country_code: {
        value: '+49',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        options: [...catalogs.country_codes],
        config: {
            id: 'country_code',
            type: 'select',
            fullWidth: true,
        },
        rules: { type: 'select' }
    },
    mobile: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'mobile',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'phone_number'
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    /* ENQUIRY */
    consultant_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        image: true,
        config: {
            id: 'consultant_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none'
        }
    },
    artwork_purchase_request_status_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        icon: 'square',
        options: [...catalogs.application_statuses],
        config: {
            id: 'artwork_purchase_request_status_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none'
        }
    },
    comments: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'comments',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 4
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    //Change password
    previous_password: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'previous_password',
            type: 'password',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 8,
            max: 16
        }
    },
    new_password: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'new_password',
            type: 'password',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 8,
            max: 16
        }
    },
    new_password_confirm: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'new_password_confirm',
            type: 'password',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 8,
            max: 16
        }
    },
}