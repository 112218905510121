import file from '../../assets/file.png'

export const translations = {
    picture_modal:{
        selected_image:'Selected image',
        cropped_area:'Select the area you want to crop',
        dropzone:{
            text:'Drag or click to upload an image'
        }
    },
    download_doc:{
        title:'¡Listo!',
        message:'Archivo generado exitosamente.',
        button:'Descargar',
        image:file
    },
    change_image_modal:{
        title:'Change image',
        button:'Save',
        error_msg:'You must attach / select an image to continue'
    },
    upload_image_modal:{
        title:'Upload image',
        button:'Save',
        error_msg:'You must attach / select an image to continue'
    },
    warning_modal: {
        title:'Are you sure?',
        button:'Delete',
        message:'Do you really want to delete this record? This process cannot be reversed'
    },

    //
    banner:{
        add:{
            title:'Add banner',
            button:'Add'
        },
        edit:{
            title:'Edit banner',
            save_button:'Edit',
            delete_button:'Delete'
        },
        imgs_menu:[{id:1, label:'Web'}, {id:2, label:'Mobile'}],
        text_color:'Text color:',
        back_color:'Back color:',
        form:{
            date:{placeholder:'Date', label:'Date', helperText:'Field required'},
            title:{placeholder:'Title', label:'Title', helperText:'Field required'},
            subtitle:{placeholder:'Subtitle', label:'Subtitle', helperText:'Field required'},
            description:{placeholder:'Description', label:'Description', helperText:'Field required'},
            web_link:{placeholder:'Web_link', label:'Web_link', helperText:'Field required'},
            app_link:{placeholder:'App_link', label:'App_link', helperText:'Field required'},
        }
    },
    section:{
        edit:{
            title:'Edit section',
            button:'Edit',
        },
        imgs_menu:[{id:1, label:'Web'}, {id:2, label:'Mobile'}],
        text_color:'Text color:',
        back_color:'Back color:',
        form:{
            title:{placeholder:'Title', label:'Title', helperText:'Field required'},
            link:{placeholder:'Link', label:'Link', helperText:'Field required'},
            description:{placeholder:'Description', label:'Description', helperText:'Field required'},
        }
    }
}

export default translations