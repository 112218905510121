import logo from '../../assets/logo_login.png'
import image from '../../assets/banners/signin.png'
import icon_fb from '../../assets/icons/facebook.svg'
import icon_tw from '../../assets/icons/twitter.svg'
import icon_ig from '../../assets/icons/instagram.svg'



export const translations = {
    logo:logo,
    image:image,
    forgot_password:'Forgot password?',
    button:'Log in',
    title: 'Welcome!',
    form:{
        email:{placeholder:'Email Address', label:'Email Address', helperText:'Incorrect email'},
        password:{placeholder:'Password', label:'Password', helperText:'Field required'},
    },
    icons:{
        facebook: icon_fb,
        twitter: icon_tw,
        instagram: icon_ig
    }
    
}

export default translations