import React from 'react';
import { withStyles, Grid, Icon, IconButton, LinearProgress, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import ActionBar from './ActionBar';
import StatusData from '../../../../../components/Structure/DisplayData/StatusData';
import { catalogs } from '../../../../../texts/eng/catalogs';

const CustomizedTableRow = withStyles((theme) => ({
	root: {
		background: 'white',
		boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.07)',
		borderRadius: 15,
		height: 90,
		'& td:first-child': {
			borderRadius: '15px 0px 0px 15px',
		},
		'& td:last-child': {
			borderRadius: '0px 15px 15px 0px',
		},
		'&:hover': {
			cursor: 'pointer',
			background: grey[100],
		},
	},
}))(TableRow);

const useStyles = makeStyles((theme) => ({
	root: {},
	addIcon: {
		background: theme.palette.secondary.main,
		borderRadius: '100%',
		color: 'white',
		'&:hover': {
			background: theme.palette.secondary.dark,
		},
	},
	paper: {
		marginTop: 4,
		borderRadius: 8,
	},
	cellHeader: {
		paddingTop: 16,
		paddingBottom: 16,
		borderBottom: 'none',
	},
	cell: {
		borderBottom: 'none',
		position: 'relative',
	},
	text: {
		fontWeight: 500,
		fontSize: 14,
	},
	button: {
		background: theme.palette.primary.main,
		padding: '6px 12px',
		borderRadius: 32,
		color: 'white',
		'&:hover': {
			background: theme.palette.primary.dark,
		},
	},
	toolbar: {
		marginBottom: 16,
	},
	actions: {
		marginTop: 16,
	},
	table: {
		//minWidth: 1100,
		borderSpacing: '0px 3px',
		borderCollapse: 'separate',
	},
	tableWrapper: {
		overflowX: 'auto',
	},
	nodata: {
		marginTop: 40,
	},
	colorTag: {
		borderRadius: '15px 0px 0px 15px',
		position: 'absolute',
		width: 8,
		height: 90,
		top: 0,
		left: 0,
	},
}));

const SimpleTable = (props) => {
	const classes = useStyles();
	const { data, header, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, onRemoveItem, onAddRegister, noData } = props;

	return (
		<div style={{marginTop: 30}}>
			{loading ? (
				<Grid container justifyContent='center'>
					<Grid item xs={12}>
						<LinearProgress color='secondary' />
					</Grid>
				</Grid>
			) : null}

			{data && data.length > 0 ? (
				<div className={classes.tableWrapper}>
					<Table className={classes.table}>
						{header ? (
							<TableHead>
								<TableRow>
									{header.map((item) => {
										return (
											<TableCell padding={'normal'} className={classes.cellHeader} key={item.id.toString()} align={item.align}>
												<Typography variant='body2'>{item.label}</Typography>
											</TableCell>
										);
									})}
								</TableRow>
							</TableHead>
						) : null}
						<TableBody>
							{data.map((item, key) => {
								const { id_magazine_application, magazine, user, artworks_count, application_status_id, payment_status_id } = item;
								const app_status = catalogs.application_statuses.find((status) => status.value === application_status_id);
								const payment_status = catalogs.payment_statuses.find((status) => status.value === payment_status_id);

								return (
									<CustomizedTableRow key={key.toString()}>
										<TableCell className={classes.cell} onClick={() => onSelectedItem(item)} align='center'>
											<div className={classes.colorTag} style={{ background: app_status.color }}></div>
											<Typography className={classes.text}>{id_magazine_application}</Typography>
										</TableCell>
										<TableCell className={classes.cell} onClick={() => onSelectedItem(item)}>
											<Typography className={classes.text}>{magazine}</Typography>
										</TableCell>
										<TableCell className={classes.cell} onClick={() => onSelectedItem(item)}>
											<Typography className={classes.text}>{user}</Typography>
										</TableCell>
										<TableCell className={classes.cell} onClick={() => onSelectedItem(item)}>
											<Typography align='center' className={classes.text}>
												{artworks_count}
											</Typography>
										</TableCell>
										<TableCell className={classes.cell} onClick={() => onSelectedItem(item)}>
											{application_status_id ? (
												<StatusData icon={'square'} data={app_status.label} color={app_status.color} size={10} style={{ color: 'black', fontWeight: 500, fontSize: 14 }} />
											) : (
												'-'
											)}
										</TableCell>
										<TableCell className={classes.cell} onClick={() => onSelectedItem(item)}>
											{payment_status_id ? (
												<StatusData icon={'square'} data={payment_status.label} color={payment_status.color} size={10} style={{ color: 'black', fontWeight: 500, fontSize: 14 }} />
											) : (
												'-'
											)}
										</TableCell>
										{/* {onRemoveItem && <TableCell className={classes.cell} onClick={() => onRemoveItem(id_user)}><IconButton size='small'><Icon>delete</Icon></IconButton></TableCell>} */}
									</CustomizedTableRow>
								);
							})}
						</TableBody>
					</Table>
				</div>
			) : null}

			{data && data.length ? (
				<div className={classes.actions}>
					<ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter} />
				</div>
			) : null}
			{data.length === 0 && !loading ? (
				<div className={classes.nodata}>
					<Grid container justifyContent='center' alignItems='center' direction='column'>
						<Grid item>
							<Typography>{noData}</Typography>
						</Grid>
					</Grid>
				</div>
			) : null}
		</div>
	);
};

export default SimpleTable;
