import React from 'react'
import { translations as translations_esp } from '../../texts/eng/user-profile-translations'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import LoadingContainer from '../../components/Structure/Layouts/LoadingContainer'
import TitleContainer from '../../components/Structure/Layouts/TitleContainer'
import useUserProfileView from './useUserProfileView'
import GeneralInfo from './sections/GeneralInfo'
import EditUserModal from './modals/EditUserModal'
import { connect } from 'react-redux'
import ProfileModal from './modals/ProfileModal/ProfileModal'
import Enquiries from './sections/Enquiries/Enquiries'
import EditEnquiryModal from './modals/EditEnquiryModal'
import ChangePasswordModal from './modals/ChangePasswordModal'
import { actionTypes } from '../../store/actions'

const styles = makeStyles(theme => ({
    root: {
        padding: 80,
        paddingBottom: 0,
    },
}))

const submitButton = {
    fontSize: 14,
    fontWeight: 700,
    padding: '5px 20px',
    textTransform: 'uppercase'
}

const UserProfileView = ({ is_auth, language, currentUser, history, match, onUpdateUserData, onUpdateAuthStatus }) => {

    const classes = styles()
    const content = translations_esp

    const { system, view_data, actions } = useUserProfileView({
        is_auth, language, currentUser, history, match, content, onUpdateUserData, onUpdateAuthStatus
    })


    return (
			<LoadingContainer
				loading={system.loading}
				sending={system.sending}
				success={system.success}
				error={system.error}
				onCloseSuccess={actions.onCloseSuccess}
				onCloseError={actions.onCloseError}
			>
				<TitleContainer title={content.title} detail={content.detail}>
					<ProfileModal
						id={view_data.userID}
						open={view_data.profile_picture_modal}
						content={content.modals.picture}
						onClose={actions.onCloseProfileModal}
						onUpdateUser={actions.onUpdateUser}
					/>
					<EditUserModal
						open={view_data.edit_modal}
						form={view_data.form}
						onChange={actions.onChange}
						onSubmit={actions.onSaveUser}
						onClose={actions.onCloseEditModal}
						content={content}
					/>
					<ChangePasswordModal
						open={view_data.change_password_modal}
						form={view_data.form}
						onChange={actions.onChange}
						onSubmit={actions.onChangePassword}
						onClose={actions.onCloseChangePasswordModal}
						content={content.modals.change_password}
                        isUserProfile={view_data.isUserProfile}
					/>
					<EditEnquiryModal
						open={view_data.edit_enquiry_modal}
						form={view_data.form}
						onChange={actions.onChange}
						onSubmit={actions.onUpdateEnquiry}
						onClose={actions.onCloseEnquiryModal}
						content={content.modals.edit_enquiry}
					/>
					<Grid container spacing={4}>
						{view_data.user && (
							<>
								<Grid item xs={12}>
									<GeneralInfo
										type={view_data.type}
										user={view_data.user}
										form={view_data.form}
										submitButton={submitButton}
										content={content.sections.general_info}
										onEditPicture={actions.onOpenProfileModal}
										onOpenEditModal={actions.onOpenEditModal}
										onOpenChangePasswordModal={actions.onOpenChangePasswordModal}
										onSetUserType={actions.onSetUserType}
										onDeleteUser={actions.onDeleteUser}
                                        isUserProfile={view_data.isUserProfile}
									/>
								</Grid>
								<Grid item xs={12}>
									<Enquiries
										content={content.sections.enquiries}
										data={view_data.enquiries}
										filter={view_data.filter}
										tableFilter={view_data.tableFilter}
										total={view_data.total}
										loading={view_data.loadingEnquiries}
										onUpdateEnquiriesFilter={actions.onUpdateEnquiriesFilter}
										onUpdateTableFilter={actions.onUpdateTableFilter}
										onSelectEnquiry={actions.onSelectEnquiry}
									/>
								</Grid>
							</>
						)}
					</Grid>
				</TitleContainer>
			</LoadingContainer>
		);
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.user,
        is_auth: state.is_auth,
        language: state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({ type: actionTypes.AUTH_UPDATE_USER, user }),
        onUpdateAuthStatus: (is_auth) => dispatch({ type: actionTypes.AUTH_UPDATE_STATUS, is_auth }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileView)