import { useEffect, useRef, useState } from "react"
import { private_server } from "../../../../../../config"
import { isFormValid, onGetDateFormat, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm, onSetValueInForm, updateAuthorizationHeader } from "../../../../../../shared/utility"
import { generic_errors } from "../../../../../../texts/esp/genericErrors"


const useEditBannerModal = ({open, language, content, selected, onCompletedBanners, onClose}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))

    const [banner, setBanner] = useState(null)
    const [img_view, setImgView] = useState(1)
    const [text_color, setTextColor] = useState('black')
    const [text_color_hex, setTextColorHex] = useState('black')
    const [back_color, setBackColor] = useState('white')
    const [back_color_hex, setBackColorHex] = useState('white')
    const [enable, setEnable] = useState(false)

    const [upload_image, setUploadImage] = useState(false)


    useEffect(() => {
        if(open){
            actions.onInitModule()
        }
    }, [open])
    
    const actions = {
        onInitModule: async() => {
            try{
                setError(null)
                console.log(selected)
                updateAuthorizationHeader(private_server)
                const request = await private_server.get(`/banner/${selected.id_banner}`)
                console.log(request.data.data.banner)
                const _banner = request.data.data.banner
                setBanner(_banner)
                
                let _form = onInitForm(form, _banner, content.form)
                _form.date.value = onGetDateFormat(_banner.date)
                setForm(_form)
                setBackColor(_banner.background_color)
                setTextColor(_banner.text_color)
                setEnable(Boolean(_banner.enabled))
            }catch(error){
                setError(onGetErrorMessage(error))
            }      
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onChangeImgView: (view) =>{
            setImgView(view)
        },
        onChangeTextColor: (value) => {
            setTextColor(value)
            setTextColorHex(value.css.backgroundColor)
        },
        onChangeBackColor: (value) => {
            setBackColor(value)
            setBackColorHex(value.css.backgroundColor)
        },
        onChangeModal: (value) =>{
            setUploadImage(value)
        },
        onChangeEnable: (event) =>{
            setEnable(event.target.checked);
        },
        onCompleted: async() =>{
            try{
                setSending(true)
                updateAuthorizationHeader(private_server)
                const request = await private_server.get(`/banner/${selected.id_banner}`)
                const _banner = request.data.data.banner
                //console.log(_banner)
                await setBanner(_banner)
                onCompletedBanners()
                actions.onChangeModal(false)
                setSending(false)
            }catch(error){
                setSending(false)
                setError(onGetErrorMessage(error))
            }
        },
        onSubmit: async() => {

            const errors = isFormValid(form)
            // Error handler
            if(errors && errors.length){
                const _form = onSetErrorsToForm(form, errors)
                setForm(_form)
                setError(generic_errors[language].INCOMPLETE_FORM)
                return
            }

            // Clean data
            const data2send = onGetSelectedFormData(form, banner)
            data2send.enabled = enable ? 1 : 0
            data2send.text_color = text_color_hex
            data2send.background_color = back_color_hex
            data2send.banner_type_id = 1
            //console.log(data2send)

            try {
                setSending(true)
                updateAuthorizationHeader(private_server)
                await private_server.patch(`/banner/${selected.id_banner}`, data2send)
                onCompletedBanners()
                setError(null)
                setSending(false)
                onClose()
            } catch (error) {
                setSending(false)
                const _error = onGetErrorMessage(error)
                if(_error.details){
                    console.log('Actualizar datos')
                    const _error_keys = Object.keys(_error.details).map(item => item)
                    const _form = onSetErrorsToForm(form, _error_keys)
                    console.log(_form)
                    setForm(_form)
                }
                setError(_error ? _error.message : null)
            }
            
        },
        onDelete: async() =>{
            try{
                setDeleting(true)
                updateAuthorizationHeader(private_server)
                await private_server.delete(`/banner/${selected.id_banner}`)
                onCompletedBanners()
                onClose()
                setDeleting(false)
            }catch(error){
                setDeleting(false)
                setError(onGetErrorMessage(error))
            }
        }
    }

    const system = {loading, sending, error, deleting}
    const view_data ={img_view, text_color, text_color_hex, back_color, back_color_hex, enable, banner}

    return { system, form, actions, view_data, upload_image} 
}


export default useEditBannerModal

const form_data = {
    date: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'date',
            type: 'date',
            fullWidth: true,
        },
        rules: {type: 'date'}
    },
    title: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'title',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'distance', min:1, max:50}
    },
    subtitle: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'subtitle',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'distance'}
    },
    description: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            multiline:true,
            rows:4
        },
        rules: {type: 'distance'}
    },
    web_link: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'web_link',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'distance'}
    },
    app_link: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'app_link',
            type: 'text',
            fullWidth: true,
        },
        rules: {type: 'distance'}
    },

}