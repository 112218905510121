import { Grid, Typography, useMediaQuery } from '@material-ui/core'
import { green, grey, red } from '@material-ui/core/colors'
import { makeStyles, useTheme } from '@material-ui/styles'
import React from 'react'
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon'
import SimpleCard from '../../../components/Structure/Cards/SimpleCard'
import FilledDisplayData from '../../../components/Structure/DisplayData/FilledDisplayData'
import FilterSelect from '../../../components/Forms/FilterSelect'
import RoundedButton from '../../../components/Actions/RoundedButton'
import { catalogs } from '../../../texts/eng/catalogs'
import ImageContainer from '../../../components/Ecommerce/ImageContainer'

const styles = makeStyles(theme => ({
    root: {

    },
    topBar: {
        [theme.breakpoints.up('md')]: {
            marginTop: -50
        }
    },
    content: {
        marginTop: 30
    },
    deleteButton: {
        background: theme.palette.primary.main,
        padding: 8,
        '&:hover': {
            background: theme.palette.primary.dark,
        }
    },
    country_code: {
        fontSize: 16,
        color: '#151515',
        marginRight: 5,
        fontWeight: 700
    },
    topData: {
        top: 0,
        right: 0,
        position: 'absolute',
        display: 'inline-flex'
    },
    referenceCode: {
        //marginTop: 10,
        //marginRight: 10,
        textTransform: 'uppercase',
        fontSize: 12,
        color: '#A6A6A6',
        '& span': {
            fontSize: 14,
            color: theme.palette.secondary.main
        }
    },
    marginBottom: {
        marginBottom: 20
    },
    sideImage: {
        height: '100%',
        display: 'grid',
        alignItems: 'stretch',
    }
}))

const GeneralInfo = ({ artist, status, form, content, onEditPicture, onOpenEditModal, onSetArtistStatus, submitButton, onClickSmallCard }) => {

    const classes = styles()
    const theme = useTheme()
    const isMD = useMediaQuery(theme.breakpoints.only('xs'))

    let image = artist && artist.image ? artist.image : null

    const country_code = (
        <div className={classes.country_code}>
            ({artist.country_code})
        </div>
    )
    return (
        <div className={classes.root}>
            <div className={classes.topBar}>
                <Grid container justifyContent='flex-end' alignItems='center' spacing={2}>
                    <Grid item>
                        <FilterSelect
                            value={status}
                            menuFilter={catalogs.artist_statuses}
                            onFilter={onSetArtistStatus}
                        />
                    </Grid>
                    <Grid item>
                        <RoundedButton
                            color='black'
                            size='small'
                            style={submitButton}
                            onClick={onOpenEditModal}
                        >
                            {content.edit_button}
                        </RoundedButton>
                    </Grid>
                    {/* <Grid item>
                            <IconButton
                                className={classes.deleteButton}
                                onClick={actions.onDeleteArtist}
                            >
                                <SVGIcon src={content.delete} size={22} color='white' />
                            </IconButton>
                       </Grid>*/}
                </Grid>
            </div>
            <div className={classes.content}>
                <Grid container alignItems='stretch' spacing={4}>
                    <Grid item xs={12} md={4} lg={3}>
                        <div className={classes.sideImage}>
                            <div className={classes.marginBottom}>
                                <ImageContainer src={image} ratio='4/5' variant='cover' onEdit={() => onEditPicture('image')} />
                            </div>
                            <div className={classes.marginBottom}>
                                <SimpleCard padding='small' fullHeight>
                                    <FilledDisplayData data={form.referenced_by} value={artist.referer} />
                                </SimpleCard>
                            </div>
                            <SimpleCard padding='small' fullHeight>
                                <FilledDisplayData data={form.approved_by} value={artist.approver} />
                            </SimpleCard>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={8} lg={9}>
                        <SimpleCard fullHeight>
                            <div style={{ position: 'relative' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <div style={{padding: artist.featured ? 15 : 0}}></div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FilledDisplayData data={form.first_name} value={artist.first_name} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FilledDisplayData data={form.last_name} value={artist.last_name} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FilledDisplayData data={form.professional_name} value={artist.professional_name} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FilledDisplayData data={form.gender_id} value={artist.gender} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FilledDisplayData data={form.country_id} value={artist.country} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FilledDisplayData data={form.discipline_id} value={artist.discipline} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FilledDisplayData data={form.birth_date} value={artist.birth_date} format={'DD/MMMM/YYYY'} number />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FilledDisplayData data={form.mobile} value={artist.mobile} startAdornment={country_code} number/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FilledDisplayData data={form.email} value={artist.email} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FilledDisplayData data={form.url} value={artist.url} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FilledDisplayData data={form.order} value={artist.order} number/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FilledDisplayData data={form.artist_level_id} value={artist.artist_level} />
                                    </Grid>
                                </Grid>
                                <div className={classes.topData}>
                                    {artist.reference_code &&
                                        <Typography className={classes.referenceCode}>
                                            {content.referenceCode} - <span>{artist.reference_code}</span>
                                        </Typography>
                                    }
                                    {artist.featured > 0 &&
                                        <SVGIcon src={content.featuredIcon} color={theme.palette.secondary.main} size={42} />
                                    }
                                </div>
                            </div>
                        </SimpleCard>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            {content.smallCards.map((card, key) => (
                                <Grid item xs={4} key={`small-card-${key.toString()}`}>
                                    <SmallCard onClick={onClickSmallCard} value={artist[card.id]} {...card} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid >
            </div>
        </div >
    )
}

const SmallCard = ({ icon, label, value, secondaryColor, primaryColor, onClick, id }) => {

    const classes= smallCardStyles()

    const _primaryColor = primaryColor ? primaryColor : green[500]
    const _secondaryColor = secondaryColor ? secondaryColor : grey[500]
    const styleElement = { fontSize: 15, color: value ? _primaryColor : _secondaryColor }

    return (
        <SimpleCard padding='small' onClick={() => onClick(id, value)} style={!value ? {opacity: 0.9, boxShadow: 'none'} : {}}>
            <Grid className={classes.root} container justifyContent='center' alignItems='center' spacing={1}>
                <Grid item>
                    {React.cloneElement(icon, { style: styleElement })}
                </Grid>
                <Grid item>
                    <Typography style={{ color: '#636363', fontSize: 16, fontWeight: 600 }}>
                        {label}
                    </Typography>
                </Grid>
            </Grid>
        </SimpleCard>
    )
}

const smallCardStyles = makeStyles(theme => ({
    root: {
    },
}))

export default GeneralInfo