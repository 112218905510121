import React from 'react'
import { Grid, Icon, makeStyles, Typography } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import SimpleModal from './SimpleModal'
import ActionModalBar from '../Actions/ActionModalBar'
import system_translations from '../../texts/system_translations'


const useStyles = makeStyles(theme => ({
    icon:{
        color:red[700],
        fontSize:54,
        border:`2px solid ${red[700]}`,
        padding:8,
        borderRadius:'50%'
    },
}))

const WarningModal = props => {

    const { open, onClose, onSubmit, loading, error, language } = props
    const content = system_translations[language].modals.warning_modal
    const classes = useStyles()
   
    return(
        <SimpleModal open={open} onClose={onClose}>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container direction='column' alignItems='center' spacing={2}>
                            <Grid item>
                                <Icon fontSize='large' className={classes.icon}>clear</Icon>
                            </Grid>
                            <Grid item>
                                <Typography variant='h6'  align='center'>{content.title}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body1' align='center'>{content.message}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ActionModalBar loading={loading} error={error} btnLabel={content.button} btnColor='red' onSubmit={onSubmit}/>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
    )
}

export default WarningModal