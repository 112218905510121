import React, { useEffect, useState } from 'react';
import { private_server } from '../../config';
import { isFormValid, onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../shared/utility';
import queryString from 'query-string';
import catalogs from '../../texts/eng/catalogs';
import { generic_errors } from '../../texts/eng/genericErrors';

const useUsersView = ({ history, is_auth, content, search_params, onUpdateSearchParams }) => {

	//Check if its the last page visited and has a saved search and filter
	let savedSearch = null
	let savedFilterValue = 0
	if(search_params && search_params.last_visited)
		if(search_params.last_visited === 'users'){
			savedSearch = search_params.search ? search_params.search : null;
			savedFilterValue = search_params.filter_value ? search_params.filter_value : 0;
		}

	const [loading, setLoading] = useState(false);
	const [sending, setSending] = useState(false);
	const [error, setError] = useState(null);

	const [total, setTotal] = useState(0);
	const [search, setSearch] = useState(savedSearch);
	const [filter, setFilter] = useState([]);
	const [filterValue, setFilterValue] = useState(savedFilterValue);
	const [tableFilter, setTableFilter] = useState({ limit: 10, offset: 0, order_by: 'id_user', order: 0 });
	const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)));
	//DATA
	const [users, setUsers] = useState([]);
	const [add_modal, setAddModal] = useState(false);

	useEffect(() => {
		if (is_auth) actions.onInitModule();
	}, [is_auth]);

	useEffect(() => {
		if (is_auth) actions.onFilterUsers();
	}, [is_auth, tableFilter]);

	useEffect(() => {
		if (is_auth) actions.onResetTableFilter();
	}, [is_auth, filterValue]);

	const errors_txt = generic_errors.eng

	const actions = {
		onInitModule: async () => {
            setLoading(true);
			try {
				updateAuthorizationHeader(private_server);
				let _form = JSON.parse(JSON.stringify(formData));
				_form = onInitForm(_form, null, content.form);
				_form.user_type_id.options = catalogs.user_types.filter(item => item.id !== 3)
				_form.gender_id.options = catalogs.user_genders

				if(savedSearch)
					_form.search.value = savedSearch

				setForm(_form);

				/* FILTER */
				const _filter = [...content.filter, ...catalogs.user_types];
				setFilter(_filter);
                setLoading(false);
			} catch (error) {
				console.log(error);
				setLoading(false);
			}
		},
		onResetTableFilter: () => setTableFilter({ limit: 10, offset: 0, order_by: 'id_user', order: 0 }),
		onUpdateTableFilter: (data) => setTableFilter(data),
		onUpdateFilterValue: (id) => setFilterValue(id),
		onChangeModalStatus: (status) => setAddModal(status),
		onSearchUsers: async (data) => {
			try {
				// setSending(true)
				let temp = { ...form };
				temp.search = { ...data };
				if (error) setError(null);
				setForm(temp);
				setSearch(data.value);
			} catch (error) {
				setError(onGetErrorMessage(error));
			}
			//setSending(false)
		},
		onFilterUsers: async () => {
            setLoading(true)
			try {
				setSending(true);
				let filter = {};
				if (filterValue > 0) filter.AND = [{ field: 'user_type_id', operator: '=', value: filterValue }];
				if (search && search.length > 0)
					if (filterValue > 0)
						filter.AND.push({
							OR: [
								{ field: 'first_name', operator: 'LIKE', value: `%%${search}%%` },
								{ field: 'last_name', operator: 'LIKE', value: `%%${search}%%` },
							],
						});
					else
						filter.OR = [
							{ field: 'first_name', operator: 'LIKE', value: `%%${search}%%` },
							{ field: 'last_name', operator: 'LIKE', value: `%%${search}%%` },
						];
				let _filter = encodeURIComponent(JSON.stringify(filter));
				let isValidFilter = Object.keys(filter).length !== 0 || Object.getPrototypeOf(filter) !== Object.prototype;
				let params = isValidFilter ? `?${queryString.stringify(tableFilter)}&filter=${_filter}` : `?${queryString.stringify(tableFilter)}`;
				const _users = await private_server.get(`user/all${params}`);
				setUsers([..._users.data.data.users]);
				setTotal(_users.data.data.count);
                setLoading(false)
			} catch (error) {
				setError(onGetErrorMessage(error));
                setLoading(false)
			}
			setSending(false);
		},
		onSelectUser: (user) => {
			onUpdateSearchParams({search: search, filter_value: filterValue, last_visited: 'users'})
			history.push(`users/${user.id_user}`);
		},
		onChange: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if (error) setError(null)
            setForm(temp)
        },
		onSaveUser: async () => {
            const errors = isFormValid(form)
            if (errors && errors.length) {
				console.log(errors);
				const _form = onSetErrorsToForm(form, errors);
				setForm(_form);
				setError(errors_txt.INCOMPLETE_FORM);
				return;
			}

            try {
                setSending(true)

                const data2send = onGetFormData(form)
                delete data2send.search

                let _user = await private_server.post(`user`, data2send)
                _user = _user.data.data.user
                setUsers([...users, _user])

                setError(null)
                setAddModal(false)
                setSending(false)

				history.push(`users/${_user.id_user}`);
            } catch (error) {
                console.log(error)
                setSending(false)
                setError(onGetErrorMessage(error))
            }
        }
	};

	const system = { loading, sending, error };
	const view_data = { total, filter, filterValue, users, form, tableFilter };

	return { system, add_modal, view_data, actions };
};

export default useUsersView;

const formData = {
	search: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: false,
		isValid: false,
		config: {
			id: 'search',
			type: 'text',
			fullWidth: true,
		},
		rules: {
			type: 'distance',
			min: 1,
			max: 200,
		},
	},
	email: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'email',
			type: 'email',
			fullWidth: true,
		},
		rules: {
			type: 'email'
		},
	},
	password: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'password',
			type: 'password',
			fullWidth: true,
		},
		rules: {
			type: 'distance',
			min: 8,
			max: 16,
		},
	},
	user_type_id: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'user_type_id',
			type: 'select',
			fullWidth: true,
		},
		rules: {
			type: 'select'
		}

	},
	first_name: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'first_name',
			type: 'text',
			fullWidth: true,
		},
		rules: {
			type: 'distance',
			min: 1,
			max: 200,
		},
	},
	last_name: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: false,
		isValid: false,
		config: {
			id: 'last_name',
			type: 'text',
			fullWidth: true,
		},
		rules: {
			type: 'distance',
			min: 1,
			max: 200,
		},
	},
	gender_id: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'gender_id',
			type: 'select',
			fullWidth: true,
		},
		rules: {
			type: 'select'
		},
	},
};
