import noUsers from '../../assets/icons/profile.svg'

export const translations = {
    title: 'Users',
    noData: 'No users to show.',
    noUsers: {
        title: 'No users to show',
        subtitle: 'Users will show here.',
        image: noUsers
    },
    filter: [
        { id: 'all', value: 0, label: 'All' }
    ],
    form: {
        search: {
            placeholder: 'Search'
        },
        email:{
            label: 'Email',
            placeholder: 'Email',
            helperText: 'Insert a valid email',
        },
        password:{
            label: 'Password',
            placeholder: 'Password',
            helperText: 'Insert a valid password',
        },
        user_type_id:{
            label: 'User type',
            helperText: 'Select a valid option',
        },
        first_name:{
            label: 'First name',
            placeholder: 'First Name',
            helperText: 'Required field',
        },
        last_name:{
            label: 'Last name',
            placeholder: 'Last Name',
            helperText: 'Incorrect field',
        },
        gender_id:{
            label: 'Gender',
            helperText: 'Select a valid option',
        },
    },
    header: [
        { id: 1, label: 'ID', align: 'center' },
        { id: 2, label: 'Users' },
        { id: 3, label: 'Level' },
        { id: 4, label: 'Mobile number' },
        { id: 5, label: 'Email' },
    ],
    modals:{
        add:{
            title: 'Add User',
            button: 'Add'
        }
    }

}

export default translations