import { Grid, makeStyles, Typography } from "@material-ui/core"
import SimpleModal from "../../../../../../components/Modals/SimpleModal"
import system_translations from "../../../../../../texts/system_translations"
import ImagesMenu from "../../../../../../components/Structure/Nav/ImagesMenu"
import InputForm from "../../../../../../components/Forms/InputForm"
import LoadingButton from "../../../../../../components/Actions/LoadingButton"
import ImageContainer from "../../../../../../components/Ecommerce/ImageContainer"
import ChangeImageModal from "./ChangeImageModal"
import { red } from "@material-ui/core/colors"
import useEditSectionModal from "./useEditSectionModal"


const EditSectionModal = ({open, onClose, language, selected, onCompletedSections}) => {

    const classes = useStyles()
    const content = system_translations[language].modals.section
    const {system, form, actions, view_data, upload_image } = useEditSectionModal({open, language, content, selected, onCompletedSections, onClose})
    const {section, img_id} = view_data

    let contentView = null

    switch(view_data.img_view){
        case 1:
            contentView = <Grid container spacing={2}>
                                <Grid item md={4} xs={6}>{section && section.web_image_1 ? <ImageContainer ratio='2/3' variant='cover' noborder src={section.web_image_1} onEdit={()=>actions.onChangeModal(true, 1)} /> : <ImageContainer ratio='2/3' onUpload={()=>actions.onChangeModal(true, 1)} /> }</Grid>
                                <Grid item md={4} xs={6}>{section && section.web_image_2 ? <ImageContainer ratio='2/3' variant='cover' noborder src={section.web_image_2} onEdit={()=>actions.onChangeModal(true, 2)} /> : <ImageContainer ratio='2/3' onUpload={()=>actions.onChangeModal(true, 2)} /> }</Grid>
                                <Grid item md={4} xs={6}>{section && section.web_image_3 ? <ImageContainer ratio='2/3' variant='cover' noborder src={section.web_image_3} onEdit={()=>actions.onChangeModal(true, 3)} /> : <ImageContainer ratio='2/3' onUpload={()=>actions.onChangeModal(true, 3)} /> }</Grid>
                            </Grid>
            break
        case 2:
            contentView = <Grid container spacing={2}>
                                <Grid item md={4} xs={6}>{section && section.mobile_image_1 ? <ImageContainer ratio='2/3' variant='cover' noborder src={section.mobile_image_1} onEdit={()=>actions.onChangeModal(true, 1)} /> : <ImageContainer ratio='2/3' onUpload={()=>actions.onChangeModal(true, 1)} /> }</Grid>
                                <Grid item md={4} xs={6}>{section && section.mobile_image_2 ? <ImageContainer ratio='2/3' variant='cover' noborder src={section.mobile_image_2} onEdit={()=>actions.onChangeModal(true, 2)} /> : <ImageContainer ratio='2/3' onUpload={()=>actions.onChangeModal(true, 2)} /> }</Grid>
                                <Grid item md={4} xs={6}>{section && section.mobile_image_3 ? <ImageContainer ratio='2/3' variant='cover' noborder src={section.mobile_image_3} onEdit={()=>actions.onChangeModal(true, 3)} /> : <ImageContainer ratio='2/3' onUpload={()=>actions.onChangeModal(true, 3)} /> }</Grid>
                            </Grid>
            break
        default:
            break
    }

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='md' >
            <ChangeImageModal open={upload_image} onClose={()=>actions.onChangeModal(false)} language={language} 
               type={view_data.img_view === 1 ? 'web' : 'mobile'} onCompleted={actions.onCompleted}
               id={selected ? selected.id_home_section : null}  id_image={img_id}/>
            <div className={classes.title}><Typography variant='h6'>{content.edit.title}</Typography></div>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <ImagesMenu menu={content.imgs_menu} selected={view_data.img_view} onChange={(id)=>actions.onChangeImgView(id)} />                                  
                    </Grid>
                    <Grid item xs={12}>
                        {contentView}
                    </Grid>
                </Grid>
            </div>
            <div className={classes.form}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <InputForm data={form.title} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputForm data={form.link} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputForm data={form.description} onChange={actions.onChangeForm} />
                    </Grid>
                </Grid>
            </div>
            <div className={classes.bottom_section}>
                <Grid container spacing={2} justifyContent='center'>
                    <Grid item xs={12}><Typography style={{color:red[700]}} variant='subtitle1'>{system.error}</Typography></Grid>
                    <Grid item><LoadingButton loading={system.sending} onClick={actions.onSubmit}>{content.edit.button}</LoadingButton></Grid>
                </Grid>  
            </div>
        </SimpleModal>
    )

}

export default EditSectionModal

const useStyles = makeStyles(theme => ({
    title:{
        marginBottom:40
    },
    bottom_section:{
        textAlign:'center',
        margin:'50px 0px 12px'
    },
    form:{
        marginTop:40
    }
}))

