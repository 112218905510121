import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import LineInputForm from '../../../components/Forms/LineInputForm';
import SimpleModal from '../../../components/Modals/SimpleModal';
import RoundedButton from '../../../components/Actions/RoundedButton';
import LineSelectForm from '../../../components/Forms/LineSelectForm';
import CountryCodesModal from '../../../components/Modals/CountryCodesModal';
import CountryCodeButton from '../../../components/Forms/CountryCodeButton';

const EditArtistModal = ({ open, form, onChange, onSubmit, onClose, content }) => {

	return (
		<>
			<SimpleModal maxWidth='sm' open={open} onClose={onClose}>
				<Grid container alignItems='center' spacing={4}>
					<Grid item xs={12} sm={6}>
						<LineInputForm data={form.first_name} onChange={onChange} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<LineInputForm data={form.last_name} onChange={onChange} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<LineInputForm data={form.professional_name} onChange={onChange} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<LineSelectForm data={form.gender_id} onChange={onChange} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<LineSelectForm data={form.country_id} onChange={onChange} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<LineSelectForm data={form.discipline_id} onChange={onChange} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<LineInputForm data={form.birth_date} onChange={onChange} disableFuture />
					</Grid>
					<Grid item xs={3} sm={2}>
						<CountryCodeButton data={form.country_code} onChange={onChange}  />
					</Grid>
					<Grid item xs={9} sm={4}>
						<LineInputForm data={form.mobile} onChange={onChange} />
					</Grid>
					<Grid item xs={12} sm={4}>
						<LineInputForm data={form.url} onChange={onChange} />
					</Grid>
					<Grid item xs={12} sm={4}>
						<LineInputForm data={form.order} onChange={onChange} />
					</Grid>
					<Grid item xs={12} sm={4}>
						<LineSelectForm data={form.artist_level_id} onChange={onChange} />
					</Grid>
					<Grid item xs></Grid>
					<Grid item>
						<RoundedButton size='small' onClick={onSubmit}>
							{content.save_button}
						</RoundedButton>
					</Grid>
				</Grid>
			</SimpleModal>
		</>
	);
};

export default EditArtistModal;
