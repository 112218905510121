import { connect } from 'react-redux';
import SelectFilter from '../../components/Forms/SelectFilter';
import TitleContainer from '../../components/Structure/Layouts/TitleContainer';
import ActionTopBar from '../../components/Structure/Table/ActionTopBar';
import catalogs from '../../texts/eng/catalogs';
import system_translations from '../../texts/system_translations';
import ArtistApplications from './sections/Artists/ArtistApplications';
import AddArtistModal from './sections/Artists/modals/AddArtistModal';
import ExhibitionApplications from './sections/Exhibitions/ExhibitionApplications';
import AddExhibitionApplicationModal from './sections/Exhibitions/modals/AddExhibitionApplicationModal';
import EditExhibitionApplicationModal from './sections/Exhibitions/modals/EditExhibitionApplicationModal';
import EditMagazineApplicationModal from './sections/Magazines/modals/EditMagazineApplicationModal';
import MagazineApplications from './sections/Magazines/MagazineApplications';
import AddMagazineApplicationModal from './sections/Magazines/modals/AddMagazineApplicationModal';
import useApplicationsView from './useApplicationsView';

const ApplicationsView = ({ history, language }) => {
	const content = system_translations[language].views.applications;
	const { system, view_data, actions, modals } = useApplicationsView({ language, content });

	let contentView = null;
	const label = view_data.mainFilter === 1 ? 'artist' : view_data.mainFilter === 2 ? 'exhibition' : 'magazine';

	switch (view_data.mainFilter) {
		case 1:
			contentView = (
				<ArtistApplications
					content={content.sections.artists}
					applications={view_data.applications.artist}
					search={view_data.search}
					onChangeSearch={actions.onChangeSearch}
					total={view_data.total.artist}
					tableFilter={view_data.tableFilter.artist}
					loading={system.loading}
					onUpdateTableFilter={actions.onUpdateTableFilter}
					onSelectItem={(item) => history.push(`artists/${item.url}`)}
				/>
			);
			break;
		case 2:
			contentView = (
				<ExhibitionApplications
					content={content.sections.exhibitions}
					applications={view_data.applications.exhibition}
					search={view_data.search}
					onChangeSearch={actions.onChangeSearch}
					total={view_data.total.exhibition}
					tableFilter={view_data.tableFilter.exhibition}
					loading={system.loading}
					onUpdateTableFilter={actions.onUpdateTableFilter}
					onSelectItem={actions.onInitEditForm}
				/>
			);
			break;
		case 3:
			contentView = (
				<MagazineApplications
					content={content.sections.magazines}
					applications={view_data.applications.magazine}
					search={view_data.search}
					onChangeSearch={actions.onChangeSearch}
					total={view_data.total.magazine}
					tableFilter={view_data.tableFilter.magazine}
					loading={system.loading}
					onUpdateTableFilter={actions.onUpdateTableFilter}
					onSelectItem={actions.onInitEditForm}
				/>
			);
			break;
	}
	return (
		<>
			{/* MODALS */}

			{/* ARTISTS */}
			<AddArtistModal
				content={content.modals.add_artist}
				form={view_data.form}
				onChange={actions.onChange}
				onSubmit={actions.onAddRegister}
				onClose={() => actions.onChangeAddModalStatus('artist', false)}
				open={modals.add_modal.artist}
			/>

			{/* EXHIBITIONS */}
			<AddExhibitionApplicationModal
				open={modals.add_modal.exhibition}
				form={view_data.form}
				onChange={actions.onChange}
				onSubmit={actions.onAddRegister}
				onClose={() => actions.onChangeAddModalStatus('exhibition', false)}
				content={content.modals.add_exhibition}
			/>
			<EditExhibitionApplicationModal
				open={modals.edit_modal.exhibition}
				form={view_data.form}
				onChange={actions.onChange}
				onSubmit={actions.onEditRegister}
				onClose={() => actions.onChangeEditModalStatus('exhibition', false)}
				content={content.modals.edit_exhibition}
				artworks={view_data.applicationArtworks}
				setArtistArtworks={actions.updateArtistArtworks}
				onAddArtwork={actions.onAddArtwork}
				onDeleteArtwork={actions.onDeleteArtwork}
			/>

			{/* MAGAZINES */}
			<AddMagazineApplicationModal
				open={modals.add_modal.magazine}
				form={view_data.form}
				onChange={actions.onChange}
				onSubmit={actions.onAddRegister}
				onClose={() => actions.onChangeAddModalStatus('magazine', false)}
				content={content.modals.add_magazine}
			/>
			<EditMagazineApplicationModal
				open={modals.edit_modal.magazine}
				form={view_data.form}
				onChange={actions.onChange}
				onSubmit={actions.onEditRegister}
				onClose={() => actions.onChangeEditModalStatus('magazine', false)}
				content={content.modals.edit_magazine}
				artworks={view_data.applicationArtworks}
				setArtistArtworks={actions.updateArtistArtworks}
				onAddArtwork={actions.onAddArtwork}
				onDeleteArtwork={actions.onDeleteArtwork}
			/>

			<TitleContainer title={content.title} filters={content.mainFilter} filterValue={view_data.mainFilter} onFilter={actions.onChangeMainFilter}>
				<ActionTopBar
					filter={view_data.mainFilter !== 1 ? [...content.filter, ...catalogs.application_statuses] : [...content.filter, ...catalogs.artist_applications_statuses]}
					filterValue={view_data.filterValue[label]}
					onChangeFilter={actions.onUpdateFilterValue}
					search
					searchData={view_data.form.search}
					onSearch={actions.onSearch}
					onUpdateSearch={actions.onResetTableFilter}
					add
					onAdd={() => actions.onChangeAddModalStatus(label, true)}
				/>
				{contentView}
			</TitleContainer>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		language: state.language,
	};
};

export default connect(mapStateToProps)(ApplicationsView);
