import { useEffect, useState } from "react"
import WarningModal from "../../../../../components/Modals/WarningModal"
import { private_server } from "../../../../../config"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../../../shared/utility"


const DeleteBannerModal = (props) => {

    const {open, onClose, selected, onCompleted, language} = props

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        if(!open){
            setError(null)
        }
    }, [open])

    const onDelete = async() => {
        setLoading(true)
        try {
            updateAuthorizationHeader(private_server)
            await private_server.delete(`/banner/${selected.id_banner}`)
            onCompleted()
            onClose()
            setLoading(false)
        } catch (error) {
            console.log(error)
            const _error = onGetErrorMessage(error)
            setError(_error ? _error.message : null)
            setLoading(false)
        }
    }

    return(
        <WarningModal open={open} onClose={onClose} error={error} loading={loading} onSubmit={onDelete} language={language}/>
    )

}

export default DeleteBannerModal