export const translations = {
    title: 'Newsletter',
    noData: 'No newsletters to show.',
    filter: [
        { id: -1, value: -1, label: 'All' },
        { id: 0, value: 0, label: 'No contacted' },
        { id: 1, value: 1, label: 'Contacted' },
    ],
    form: {
        search: {
            placeholder: 'Search'
        },
        name: {
            label: 'Name',
            placeholder: 'Name',
            helperText:'Name is required'
        },
        email: {
            label: 'Email',
            placeholder: 'Email',
            helperText:'Introduce a valid email'
        },
        tags: {
            label: 'Tags',
            placeholder: 'Tags',
            helperText:'Tag is required'
        },
        date: {
            label: 'Date',
            placeholder: 'Date',
            helperText:'Date is required'
        },
        contacted: {
            label: 'Contacted',
            placeholder: 'Contacted',
        },
        contacted_at: {
            label: 'Contacted at',
            placeholder: 'Contacted at',
            helperText:'Select a date'
        }
    },
    header: [
        { id: 1, label: 'ID', align: 'center' },
        { id: 2, label: 'Name' },
        { id: 3, label: 'Email' },
        { id: 4, label: 'Date' },
        { id: 5, label: 'Contacted' },
        { id: 6, label: 'Tags' }
    ],
    modals: {
        add_modal: {
            title: 'Add newsletter',
            save_button: 'Add'
        },
        edit_modal: {
            title: 'Edit newsletter',
            save_button: 'Save'
        }
    }
}

export default translations