import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import SimpleModal from '../../../../../components/Modals/SimpleModal'
import RoundedButton from '../../../../../components/Actions/RoundedButton'
import LineAutocompleteForm from '../../../../../components/Forms/LineAutocompleteForm'

const AddMagazineApplicationModal = ({ open, form, onChange, onSubmit, onClose, content }) => {

    return (
        <SimpleModal maxWidth='xs' open={open} onClose={onClose} borderRadius={5}>
            <Grid container alignItems='center' spacing={4}>
                <Grid item xs={12}>
                    <Typography variant='h6'>{content.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <LineAutocompleteForm data={form.user_id} onChange={onChange} />
                </Grid>
                <Grid item xs={12}>
                    <LineAutocompleteForm data={form.magazine_id} onChange={onChange} />
                </Grid>   
                <Grid item xs></Grid>
                <Grid item>
                    <RoundedButton size='small' onClick={() => onSubmit('magazine')}>
                        {content.save_button}
                    </RoundedButton>
                </Grid>
            </Grid>
        </SimpleModal>
    )
}

export default AddMagazineApplicationModal
