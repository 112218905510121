import { Grid, makeStyles, Typography } from "@material-ui/core"
import SectionCard from "./components/SectionCard"
import EditSectionModal from "./modals/EditSectionModal/EditSectionModal"


const Sections = ({content, data, actions, modals, language, selected}) => {

    const classes = useStyles()

    //console.log(data)

    return(
        <div className={classes.root} >
            <EditSectionModal open={modals.edit_section} onClose={()=>actions.onChangeModalStatus('edit_section', false)} language={language} 
             selected={selected} onCompletedSections={actions.onCompletedSections} />
            <div className={classes.title}><Typography variant='caption'>{content.title}</Typography></div>
            <Grid container spacing={5}>
                {data.map((item, index) =>{
                    return(
                        <Grid item xs={12} key={index.toString()}>
                            <SectionCard {...item} content={content.card} onEdit={()=>actions.onEditSection(item)} />
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    )

}

export default Sections

const useStyles = makeStyles(theme => ({
    root:{
        margin:'20px 0px',
        padding:'60px 0px',
        borderTop:'2px solid #CFCFCF',
        position:'relative'
    },
    title:{
        position:'absolute',
        padding:'0px 24px',
        background:'#FCFCFC',
        textTransform:'uppercase',
        left:'50%',
        top:-14,
        transform:'translateX(-50%)',
        letterSpacing:'0.16rem'
    }
}))