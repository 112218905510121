import { Grid, makeStyles, Typography } from "@material-ui/core"
import { grey } from "@material-ui/core/colors"
import DeleteButton from "../../../../../components/Actions/DeleteButton"
import EditButton from "../../../../../components/Actions/EditButton"
import ImageContainer from "../../../../../components/Ecommerce/ImageContainer"
import SimpleCard from "../../../../../components/Structure/Cards/SimpleCard"
import { onGetDateFormat } from "../../../../../shared/utility"

const SectionCard = ({ content, title, link, description, web_image_1, onEdit }) => {

    const classes = useStyles()

    return (
        <SimpleCard padding='small'>
            <Grid container alignItems='stretch' spacing={6}>
                <Grid item md={3} xs={12}>
                    <div className={classes.image_container}>
                        <ImageContainer src={web_image_1} variant='cover' />
                    </div>
                </Grid>
                <Grid item md={9} xs={12}>
                    <div className={classes.container}>
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <Typography className={classes.title}>{content.title}</Typography>
                                <Typography className={classes.text}>{title}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.title}>{content.link}</Typography>
                                <Typography className={classes.text}>{link}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.title}>{content.description}</Typography>
                                <Typography className={classes.text}>{description}</Typography>
                            </Grid>
                        </Grid>
                        <div className={classes.edit}><EditButton onClick={onEdit} /></div>
                    </div>
                </Grid>
            </Grid>
        </SimpleCard>
    )
}

export default SectionCard


const useStyles = makeStyles(theme => ({
    container: {
        //padding: 40,
        marginTop: 15,
        boxSizing: 'border-box',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: 20
        }
    },
    title: {
        fontSize: 14,
        fontWeight: 600
    },
    text: {
        overflowX: 'hidden',
        overflowY: 'auto',
        overflowWrap: 'anywhere',
        fontSize: 14,
        color: grey[500],
        whiteSpace: 'pre',
        maxHeight: 80,
    },
    edit: {
        position: 'absolute',
        top: 12,
        right: 12,
        zIndex: 5,
    },
    image_container: {
        [theme.breakpoints.down('sm')]: {
            width: 300,
            height: 300,
            marginBottom: 40,
        }
    },
    center: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center'
        }
    }

}))


const DataItem = ({ title, data }) => {

    return (
        <Grid container spacing={3} alignItems='center' >
            <Grid item><Typography variant='body2'>{title}</Typography></Grid>
            <Grid item><Typography style={{ fontSize: '1rem', color: '#848484' }}>{data}</Typography></Grid>
        </Grid>
    )
}

