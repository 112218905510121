import React from 'react';
import { Checkbox, Divider, Grid, IconButton, Paper, Typography, useMediaQuery } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles, useTheme } from '@material-ui/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon';

const styles = makeStyles((theme) => ({
	root: {
		marginTop: 60,
	},
	title: {
		fontSize: 12,
		fontWeight: 600,
		textTransform: 'uppercase',
		letterSpacing: '0.165em',
	},
	divider: {
		height: 2,
	},
}));

const AdditionalImages = ({ content, images, onUpdateImage, onDeleteImage, onSetApproved }) => {
	const classes = styles();

	return (
		<div className={classes.root}>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<Grid container alignItems='center' spacing={2}>
						<Grid item xs>
							<Divider className={classes.divider} />
						</Grid>
						<Grid item>
							<Typography className={classes.title}>{content.title}</Typography>
						</Grid>
						<Grid item xs>
							<Divider className={classes.divider} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container alignItems='stretch' spacing={4}>
						{images.map((artwork, key) => (
							<Grid item xs={12} sm={3} key={`additional-image-item-${key.toString()}`}>
								<ImageCard onSetApproved={onSetApproved} artwork={artwork} onDelete={onDeleteImage} content={content} updateGallery={onUpdateImage} />
							</Grid>
						))}
						{images.length < 12 && (
							<Grid item xs sm={3}>
								<ImageCard add updateGallery={onUpdateImage} content={content} />
							</Grid>
						)}
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

const imageCardStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '100%',
		borderRadius: 5,
	},
	add: {
		width: '100%',
		paddingTop: '100%',
		background: '#E3E3E3',
		borderRadius: 5,
		boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.07)',
		position: 'relative',
		'&:hover': {
			background: 'rgb(0,0,0,0.2)',
		},
	},
	addButton: {
		top: 0,
		position: 'absolute',
		color: 'black',
		margin: 'auto',
		width: '100%',
		height: '100%',
		boxSizing: 'border-box',
		'&:hover': {
			background: 'transparent',
		},
	},
	addIcon: {
		width: 50,
		height: 50,
	},
	topSpace: {
		height: '100%',
		paddingTop: 40,
		boxSizing: 'border-box',
	},
	deleteIcon: {
		background: 'white',
		boxShadow: '0px 4.44444px 5.55556px rgba(0, 0, 0, 0.17)',
		padding: 5,
	},
	title: {
		color: 'white',
		fontSize: 16,
		fontWeight: 400,
	},
	imageContainer: {
		paddingTop: '100%',
		position: 'relative',
	},
	image: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		top: 0,
	},
	approved_text: {
		fontSize: 16,
		fontWeight: 600,
		color: '#636363',
	},
}));

const ImageCard = ({ artwork, content, add, updateGallery, onDelete, onSetApproved }) => {

	const classes = imageCardStyles();
	const theme = useTheme();
	const ismobile = useMediaQuery(theme.breakpoints.only('xs'));

	let selectedImage = artwork ? (ismobile ? artwork.mobile_image : artwork.web_image) : null;
	selectedImage = selectedImage ? selectedImage : content.noImage;

	const approved = artwork && artwork.approved ? Boolean(artwork.approved) : null;
	const id = artwork && artwork.id ? artwork.id : null;

	const addButton = (
		<div className={classes.topSpace}>
			<Paper className={classes.add}>
				<IconButton className={classes.addButton} onClick={updateGallery} component='span'>
					<AddCircleIcon className={classes.addIcon} />
				</IconButton>
			</Paper>
		</div>
	);

	const contentView = (
		<Grid container alignItems='center' spacing={1}>
			<Grid item container alignItems='center' xs={10}>
				<Grid item>
					<Typography className={classes.approved_text}>{content.approved}</Typography>
				</Grid>
				<Grid item>
					<Checkbox checked={approved} onChange={() => onSetApproved(id, approved ? 0 : 1)}/>
				</Grid>
			</Grid>
			<Grid item container xs={2} justifyContent='flex-end'>
				<IconButton className={classes.deleteIcon} component='span' onClick={() => onDelete(artwork)}>
					<SVGIcon src={content.deleteIcon} size={20} colorStroke={theme.palette.primary.main} />
				</IconButton>
			</Grid>
			<Grid item xs={12}>
				<Paper className={classes.root} onClick={() => updateGallery(artwork)}>
					<div className={classes.imageContainer}>
						<img src={selectedImage} className={classes.image} />
					</div>
				</Paper>
			</Grid>
		</Grid>
	);

	return add ? addButton : contentView;
};

export default AdditionalImages;
