import React from 'react'
import { translations as translations_esp } from '../../texts/eng/exhibitions-translations'
import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import LoadingContainer from '../../components/Structure/Layouts/LoadingContainer'
import TitleContainer from '../../components/Structure/Layouts/TitleContainer'
import ActionTopBar from '../../components/Structure/Table/ActionTopBar'
import useExhibitionsView from './useExhibitionsView'
import { CONFIG_SETTINGS } from '../../config'
import { isValidDocument, onGetDateFormat } from '../../shared/utility'
import { catalogs } from '../../texts/eng/catalogs'
import StopIcon from '@material-ui/icons/Stop'
import { grey } from '@material-ui/core/colors'
import SVGIcon from '../../components/Structure/DisplayData/SVGIcon'
import AddExhibitionModal from './modals/AddExhibitionModal'
import { connect } from 'react-redux'
import cx from 'classnames'
import ActionBar from './components/ActionBar'
import { actionTypes } from '../../store/actions'

const styles = makeStyles(theme => ({
    root: {
        padding: 80,
        paddingBottom: 0,
    },
    content: {
        marginTop: 30
    },
    noExhibitions: {
        marginTop: 100
    },
    noExhibitionsTitle: {
        fontSize: 24,
        fontWeight: 700,
        color: '#494949'
    },
    noExhibitionsSubtitle: {
        fontSize: 16,
        color: '#7F7F7F'
    }
}))

const ExhibitionsView = ({ is_auth, user, history, search_params, onUpdateSearchParams }) => {

    const classes = styles()
    const content = translations_esp

    const { system, view_data, actions } = useExhibitionsView({
        is_auth, user, history, content, search_params, onUpdateSearchParams
    })


    return (
        <TitleContainer title={content.title}>
            <AddExhibitionModal
                open={view_data.add_modal}
                form={view_data.form}
                onChange={actions.onChange}
                onSubmit={actions.onSaveExhibition}
                onClose={actions.onCloseAddModal}
                content={content}
            />
            <ActionTopBar
                filter={[...content.filter, ...catalogs.exhibition_statuses]}
                filterValue={view_data.filterValue}
                onChangeFilter={actions.onUpdateFilterValue}
                search
                searchData={view_data.form.search}
                onSearch={actions.onSearchExhibition}
                onUpdateSearch={actions.onResetTableFilter}
                add
                onAdd={actions.onOpenAddModal}
            />
            <LoadingContainer
                loading={system.loading}
                sending={system.sending}
                success={system.success}
                error={system.error}
                onCloseSuccess={actions.onCloseSuccess}
                onCloseError={actions.onCloseError}
            >
                <div className={classes.content}>
                    <Grid container alignItems='stretch' spacing={4}>
                        {view_data.exhibitions.length > 0 ?
                            view_data.exhibitions.map((exhibition, key) => (
                                <Grid item xs={12} sm={6} lg={4} key={`exhibition-general-card-${key.toString()}`}>
                                    <ExhibitionCard exhibition={exhibition} content={content} onClick={actions.onVisitUrl} />
                                </Grid>
                            ))
                            :
                            <Grid item xs={12}>
                                <div className={classes.noExhibitions}>
                                    <Grid container justifyContent='center' spacing={3}>
                                        <Grid item>
                                            <SVGIcon src={content.noExhibitions.icon} size={64} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography align='center' className={classes.noExhibitionsTitle}>
                                                {content.noExhibitions.title}
                                            </Typography>
                                            <Typography align='center' className={classes.noExhibitionsSubtitle}>
                                                {content.noExhibitions.subtitle}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        }
                    </Grid>
                    {view_data.exhibitions.length > 0 &&
                        <div className={classes.actions}>
                            <ActionBar total={view_data.total} tableFilter={view_data.tableFilter} onUpdateTableFilter={actions.onUpdateTableFilter} />
                        </div>
                    }
                </div>
            </LoadingContainer>
        </TitleContainer>
    )
}

const stylesCard = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        //height: "calc(100% * (4/3))",
        maxWidth: 400,
        borderRadius: 5,
        padding: 0,
        boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.07)',
        '&:hover': {
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
            cursor: 'pointer'
        }
    },
    cardActionArea: {
        position: 'relative',
        padding: 10,
        width: '100%',
        height: '100%',
        cursor: 'inherit',
        '& span': {
            '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.1)',
                //cursor: 'pointer'
            }
        }
    },
    media: {
        width: '100%',
        background: 'white',
        paddingTop: "56.25%",
        borderRadius: 2,
        display: 'block',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        border: '1px solid #EAEAEA',
    },
    mediaNoImage: {
        backgroundSize: '40%',
        backgroundRepeat: 'no-repeat'
    },
    cardContent: {
        padding: 0,
        paddingTop: 16,
        paddingBottom: 16,
        background: 'transparent',
        position: 'relative',
        '&:hover': {
            background: 'white'
        }
    },
    typeButton: {
        width: 'fit-content',
        borderRadius: 2,
        padding: '5px 20px',
        background: 'black',
        color: 'white',
        fontSize: 12,
        textTransform: 'uppercase'
    },
    statusContainer: {
        display: 'inline-flex',
        alignItems: 'center'
    },
    status: {
        fontSize: 14,
        fontWeight: 600,
        color: grey[800]
    },
    text: {
        fontSize: 14,
        fontWeight: 400,
        color: grey[500],
        '& span': {
            fontSize: 14,
            fontWeight: 600,
            color: grey[800],
        }
    },
    featuredContent: {
        position: 'absolute',
        top: 10,
        right: 15,
    },
    likes: {
        fontSize: 14,
        color: 'white'
    }
}))

const ExhibitionCard = ({ exhibition, content, onClick }) => {

    const classes = stylesCard()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    const { exhibition_type, exhibition_status_id, featured, url, web_image, mobile_image,
        start_date, end_date, title, artwork_number, max_artwork_number } = exhibition

    const _image = mobile ? mobile_image : web_image
    const isValid = isValidDocument(_image)
    const selectedImage = isValid ? `${CONFIG_SETTINGS.S3_SERVER_URL}${_image}` : content.noImage

    const exhibitionStatus = catalogs.exhibition_statuses.find(exhibition => exhibition.id === exhibition_status_id)

    const _start_date = start_date ? onGetDateFormat(start_date, 'DD MMM YYYY') : null
    const _end_date = end_date ? onGetDateFormat(end_date, 'DD MMM YYYY') : null

    return (
        <Card className={classes.root} onClick={() => onClick(url)}>
            <CardActionArea className={classes.cardActionArea}>
                <CardMedia
                    className={cx({
                        [classes.media]: true,
                        [classes.mediaNoImage]: !isValid
                    })}
                    image={selectedImage}
                />
                <CardContent className={classes.cardContent}>
                    <Grid container alignItems='center' spacing={2}>
                        <Grid item xs>
                            <div color='black' size='small'>
                                <Typography className={classes.typeButton}>
                                    {exhibition_type}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className={classes.statusContainer}>
                                <Typography className={classes.status}>
                                    {exhibitionStatus.label}
                                </Typography>
                                <StopIcon style={{ width: 15, height: 15, color: exhibitionStatus.color }} />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.text}>
                                <span style={{marginRight: 10}}>{content.data.title}:</span> {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.text}>
                                <span style={{marginRight: 10}}>{content.data.start_date}: </span>
                                {_start_date ? _start_date : '-'} - {_end_date ? _end_date : '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.text} align='right'>
                                <span style={{fontFamily: 'Montserrat', fontSize: 14, marginRight: 10 }}>{`${artwork_number} / ${max_artwork_number}`}</span> {content.data.artworks}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                {Boolean(featured) &&
                    <div className={classes.featuredContent}>
                        <SVGIcon src={content.featured} color={theme.palette.secondary.main} />
                    </div>
                }
            </CardActionArea>
        </Card >
    )
}


const mapStateToProps = (state) => {
    return {
        is_auth: state.is_auth,
        user: state.user,
		search_params: state.search_params
    }
}

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateSearchParams: (search_params) => dispatch({ type: actionTypes.SYS_SET_SEARCH_PARAMS, search_params }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ExhibitionsView)