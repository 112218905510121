import { useEffect, useState } from 'react';
import { onGetErrorMessage, onGetFormData, onInitForm, onSetErrorsToForm, onSetTokenSession, updateAuthorizationHeader } from '../../shared/utility';
import { isFormValid } from '../../components/Forms/customFunctions';
import { private_server, public_server } from '../../config'

const useSignInView = ({ history, is_auth, onUpdateUserData, onUpdateAuthStatus, content }) => {

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        actions.onInitModule()
        if (is_auth) history.push('/home')
    }, [is_auth])

    const actions = {
        onInitModule: () => {
            let _form = {...formData}
            _form = onInitForm(_form, null, content.form)
            setForm(_form)
        },
        onCloseError: () => setError(null),
        onSetDisabled: () => setDisabled(true),
        onChange: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            setForm(temp)

            const isValid = isFormValid(temp)
            /// Check if the form is valid
            if (isValid) {
                setDisabled(false)
            } else {
                setDisabled(true)
            }
        },
        onSubmit: async () => {

            const errors = isFormValid(form)
            /// Check if the form is valid
            if (errors && errors.length) {
                const new_form = onSetErrorsToForm(form, errors)
                setForm(new_form)
                return
            }

            let data2send = onGetFormData(form)
            data2send.device = 'web'

            setLoading(true)
            try {
                const response = await public_server.post('/login', data2send)
                //console.log(response)  
                const token_data = response.data.data
                await onSetTokenSession(token_data)
                updateAuthorizationHeader(private_server)
                const user_request = await private_server.get('/user/me')
                const _user = user_request.data.data.user

                if (_user.user_type_id === 1 || _user.user_type_id === 2) {
                    await onUpdateUserData(_user)
                    await onUpdateAuthStatus(true)
                    history.push('/home')
                } else
                    setError('User not authorized for log in.')
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setLoading(false)

        },
        onEnter: (e)=>{
			if (e.key === 'Enter') if (!disabled) actions.onSubmit();
		}
    }

    const system = { loading, error }
    const view_data = { form, disabled }

    return { system, view_data, actions }
}

export default useSignInView

const formData = {
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
        },
        rules: {
            type: 'email',
        }
    },
    password: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'password',
            type: 'password',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 100
        }
    },
}