import React from 'react';
import { Grid, Icon, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const StatusData = ({ data, icon, color, bold, variant, size, style }) => {


    return (
        <Grid container alignItems='center' spacing={1} wrap='nowrap'>
            <Grid item>
                <Icon
                    style={{
                        marginTop: 6,
                        fontSize: size ? size : 24,
                        color: color ? color : grey[300],
                    }}
                >
                    {icon ? icon : 'brightness_1'}
                </Icon>
            </Grid>
            <Grid item>
                <Typography
                    style={{ color: color ? color : grey[300], ...style }}
                    variant={bold ? 'subtitle1' : (variant ? variant : 'body1')}>
                    {data}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default StatusData;