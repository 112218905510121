import React from 'react'
import { Dialog, Grow, Icon, IconButton, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import cx from 'classnames'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} timeout={500} />;
});

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative'
    },
    paper: {
        borderRadius: 10,
        padding: theme.spacing(7),
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
            padding: theme.spacing(3),
            paddingTop: theme.spacing(7)
        }
    },
    paper_sharp: {
        borderRadius: 4,
    },
    buttonContainer: {
        position: 'absolute',
        top: 16,
        right: 16
    },
    container: {
        //padding:theme.spacing(4),
        boxSizing: 'border-box'
    },
    small_padding: { padding: 16 },
    medium_padding: { padding: 24 }
}))



const SimpleModal = ({ open, maxWidth, children, onClose, padding, sharp, borderRadius }) => {

    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const _styles = { borderRadius: borderRadius }

    return (
        <Dialog open={typeof open === 'undefined' ? false : open} maxWidth={maxWidth ? maxWidth : 'xs'} scroll='body' fullScreen={isMobile}
            fullWidth classes={{
                paper: cx({
                    [classes.paper]: true,
                    [classes.paper_sharp]: sharp,
                    [classes.small_padding]: padding === 'small',
                    [classes.medium_padding]: padding === 'medium'
                })
            }} className={classes.root}
            PaperProps={{ style: _styles }}
            TransitionComponent={Transition} >
            <div className={classes.buttonContainer}><IconButton onClick={onClose}><Icon >close</Icon></IconButton></div>
            <div className={classes.container}>
                {children}
            </div>

        </Dialog >
    )
}

export default SimpleModal