import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { grey } from '@material-ui/core/colors'
import { onGetDateFormat } from "../../../shared/utility"
import AvatarPicture from './AvatarPicture'
import nouser from '../../../assets/nouser.png'

const styles = makeStyles(theme => ({
  label: {
    color: '#A0A0A0',
    fontSize: 12,
    fontWeight: 700
  },
  content: {
    background: 'rgba(189, 139, 64, 0.04)',
    padding: '14px 10px',
    borderRadius: 4,
    '& span': {
      fontSize: 16,
      color: '#9D9D9D'
    }
  },
  text: {
    whiteSpace: "pre-wrap",
    fontSize: 16,
    fontWeight: 500,
    color: grey[800],
  },
  unit: {
    fontSize: 14,
    color: '#A0A0A0',
    //fontWeight: 700
  },
  textArea: {
    overflow: 'auto'
  },
  line: {
    width: '100%',
    maxWidth: 20,
    paddingLeft: 2,
    paddingRight: 2,
    boxSizing: 'border-box',
    height: 1,
    background: 'grey',
    magin: 'auto',
    marginTop: '50%',
  }
}))

const FilledDisplayData = props => {

  const classes = styles()
  const { data, value, value2, unit1, unit2, startAdornment, endAdornment, format, number } = props

  const { config } = data
  const { id, label, label2, image, multiline, rows, type } = config

  let range = type === 'range'
  let _value = value ? value : '-'
  let _value2 = value2 ? value2 : '-'

  if (format) {
    _value = value ? onGetDateFormat(value, format) : '-'
    _value = _value ? _value : '-'
    _value2 = value2 ? onGetDateFormat(value2, format) : '-'
    _value2 = _value2 ? _value2 : '-'
  }

  let textHeight = {}
  if (multiline)
    textHeight = { height: 20 * rows }

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={range && label2 ? 7 : 12}>
          <Typography className={classes.label}>
            {label}
          </Typography>
        </Grid>
        {range && label2 &&
          <Grid item>
            <Typography className={classes.label}>
              {label2}
            </Typography>
          </Grid>
        }
        <Grid item xs={range ? 5 : 12}>
          <div className={classes.content}>
            <Grid container>
              {startAdornment &&
                <Grid item>
                  {startAdornment}
                </Grid>
              }
              {image &&
                <AvatarPicture size={125} src={image} nopicture={nouser} />
              }
              <Grid item xs>
                <div className={classes.textArea} style={textHeight}>
                  <Typography className={classes.text} style={number ? {fontFamily: 'Montserrat'} : {}}>
                    {_value}
                  </Typography>
                </div>
              </Grid>
              {range &&
                <Grid item>
                  <Typography align='right' className={classes.unit} component='span'>
                    {unit1}
                  </Typography>
                </Grid>
              }
              {endAdornment &&
                <Grid item>
                  {endAdornment}
                </Grid>
              }
            </Grid>
          </div>
        </Grid >
        {range &&
          <>
            <Grid item xs={2}>
              <div className={classes.line}></div>
            </Grid>
            <Grid item xs={5}>
              <div className={classes.content}>
                <Grid container>
                  <Grid item xs>
                    <div className={classes.textArea} style={textHeight}>
                      <Typography className={classes.text} style={number ? {fontFamily: 'Montserrat'} : {}}>
                        {_value2}
                      </Typography>
                    </div>
                  </Grid>
                  {range &&
                    <Grid item>
                      <Typography align='right' className={classes.unit} component='span'>
                        {unit2}
                      </Typography>
                    </Grid>
                  }
                </Grid>
              </div>
            </Grid>
          </>
        }
      </Grid >
    </div >
  )

}

export default FilledDisplayData