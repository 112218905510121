import moment from "moment"
import { useEffect, useState } from "react"
import { private_server } from "../../config"
import { updateAuthorizationHeader } from "../../shared/utility"

const useHomeView = ({ history }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const [banners, setBanners] = useState([])
    const [banner_selected, setBannerSelected] = useState(null)
    const [sections, setSections] = useState([])
    const [section_selected, setSectionSelected] = useState(null)
    

    const [modals, setModals] = useState({
        add_banner:false,
        edit_banner:false,
        delete_banner:false,
        edit_section:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const banners_request = await private_server.get('/banner/all')
                setBanners(banners_request.data.data.banners)
                const sections_request = await private_server.get('/homesection/all')
                setSections(sections_request.data.data.home_sections) 
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        },
        onChangeModalStatus: (key, status) => {
            console.log(key)
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onCompletedBanners: async () => {
            actions.onChangeModalStatus('add_banner', false)
            try{
                const banners_request = await private_server.get('/banner/all')
                setBanners(banners_request.data.data.banners)
            }catch(error){
                console.log(error)
            }
        }, 
        onEditBanner: (banner) => {
            setBannerSelected(banner)
            actions.onChangeModalStatus('edit_banner', true)
        },
        onDeleteBanner: (banner) => {
            setBannerSelected(banner)
            actions.onChangeModalStatus('delete_banner', true)
        },
        onEditSection: (section) => {
            setSectionSelected(section)
            actions.onChangeModalStatus('edit_section', true)
        },
        onCompletedSections: async () => {
            //actions.onChangeModalStatus('edit_section', false)
            try{
                const sections_request = await private_server.get('/homesection/all')
                setSections(sections_request.data.data.home_sections)
            }catch(error){
                console.log(error)
            }
        },
    }

    const system = {loading, sending, error}
    const view_data = {banners, banner_selected, sections, section_selected}

    return {system, modals, view_data, actions}
}

export default useHomeView