import React from 'react';
import { Grid, makeStyles, Button, CircularProgress, Typography, Hidden } from '@material-ui/core';
import InputText from '../../components/Forms/InputForm';
import { actionTypes } from '../../store/actions';
import { connect } from 'react-redux';
import LoadingContainer from '../../components/Structure/Layouts/LoadingContainer';
import system_translations from '../../texts/system_translations';
import useSignInView from './useSignInView';

const SignInView = ({ language, history, is_auth, onUpdateUserData, onUpdateAuthStatus }) => {
	const classes = useStyles();
	const content = system_translations[language].signin;

	const { system, view_data, actions } = useSignInView({
		language,
		history,
		is_auth,
		onUpdateUserData,
		onUpdateAuthStatus,
        content
	});

	return (
		<div className={classes.root}>
			<LoadingContainer error={system.error} onCloseError={actions.onCloseError}>
				<div className={classes.subroot}>
					<Hidden smDown>
						<div className={classes.image_container}>
							<img src={content.image} className={classes.img} />
						</div>
					</Hidden>

					<div className={classes.form_container}>
						<div>
							<div style={{ display: 'flex', justifyContent: 'center', marginBottom: 50 }}>
								<div className={classes.logo_container}>
									<img src={content.logo} className={classes.logo} />
								</div>
							</div>
							<Typography variant='h3'>{content.title}</Typography>
							<div className={classes.form_section}>
								<div className={classes.form}>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<InputText data={view_data.form.email} onChange={actions.onChange} onKeyDown={actions.onEnter} />
										</Grid>
										<Grid item xs={12}>
											<InputText data={view_data.form.password} onChange={actions.onChange}  onKeyDown={actions.onEnter} />
										</Grid>
										<Grid item xs></Grid>
										<Grid item>
											<Typography variant='body2' className={classes.forgot_psw}>
												{content.forgot_password}
											</Typography>
										</Grid>
									</Grid>
								</div>
								<div className={classes.action}>
									<Grid container>
										<Grid item xs={12}>
											<Button onClick={actions.onSubmit} disabled={view_data.disabled} className={classes.button}>
												{content.button}
												{system.loading ? <CircularProgress style={{ width: 24, height: 24, marginLeft: 8, color: 'white' }} /> : null}
											</Button>
										</Grid>
									</Grid>
								</div>
								<div className={classes.social_divider} />
								<Grid container justifyContent='center' alignItems='center' spacing={3} className={classes.social_container}>
									<Grid item>
										<img src={content.icons.facebook} />
									</Grid>
									<Grid item>
										<img src={content.icons.instagram} />
									</Grid>
									<Grid item>
										<img src={content.icons.twitter} />
									</Grid>
								</Grid>
							</div>
						</div>
					</div>
				</div>
			</LoadingContainer>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '100vh',
		overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            height: '100%',
        }
	},
	subroot: {
		display: 'flex',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
		    alignItems: 'center',
            height: '100%',
        }
	},
	image_container: {
		width: '55%',
		height: '100vh',
        borderWidth: 0
	},
	form_container: {
		width: '45%',
		height: '100vh',
		textAlign: 'center',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: 60,
            height: '100%',
            width: '80%'
        }
	},
	form_section: {
		width: 400,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
	},
	form: {
		marginTop: 40,
	},
	action: {
		marginTop: 40,
	},
	logo_container: {},
	logo: {
		width: 86,
	},
	line: {
		background: '#D7D7D7',
		width: '100%',
		height: 0.5,
	},
	img: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		clipPath: 'polygon(100% 0%, 85% 50%, 100% 100%, 0 100%, 0% 50%, 0 0)',
	},
	button: {
		padding: '12px 54px',
		borderRadius: 5,
		background: theme.palette.primary.dark,
		color: 'white',
		textTransform: 'none',
		'&:disabled': {
			opacity: '53%',
			color: 'white',
		},
		'&:hover': {
			background: theme.palette.primary.main,
		},
	},
	network: {
		position: 'absolute',
		right: 32,
		top: 32,
	},
	forgot_psw: {
		cursor: 'pointer',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	social_divider: {
		width: 55,
		height: 0,
		margin: '100px auto 30px auto',
		border: '1px solid #B0B0B0',
	},
}));

const mapStateToProps = (state) => {
	return {
		ip_address: state.ip_address,
		language: state.language,
		is_auth: state.is_auth,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateURL: (ip_address) => dispatch({ type: actionTypes.SYS_UPDATE_IP_ADDRESS, ip_address }),
		onUpdateUserData: (user) => dispatch({ type: actionTypes.AUTH_UPDATE_USER, user }),
		onUpdateAuthStatus: (is_auth) => dispatch({ type: actionTypes.AUTH_UPDATE_STATUS, is_auth }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInView);
