import noproduct from '../../assets/noimage.png'
import file from '../../assets/file.png'

export const translations = {
    provider_modal:{
        title:'Crear proveedor',
        name:'Nombre',
        relationship_date:'Inicio de relación',
        description:'Descripción',
        button:'Crear',
        nopicture:noproduct,
        contact_info:{
            title:'Contacto',
            name:'Nombre',
            phone:'Teléfono',
            email:'Email',
            address:'Dirección'
        }
    },
    brand_modal:{
        title:'Crear marca',
        brand:'Marca',
        description:'Descripción',
        button:'Crear',
        nopicture:noproduct
    },
    equivalence_modal:{
        title:'Agregar equivalencia',
        equivalence:'Equivalencia',
        button:'Agregar'
    },
    client_modal:{
        title:'Detalle de cliente',
        name:'Nombre',
        email:'Email',
        rfc:'RFC',
        invoice_usage:'Uso de factura',
        button:'Listo'
    },
    picture_modal:{
        selected_image:'Imagen seleccionada',
        cropped_area:'Seleccione el área que desea recortar',
        dropzone:{
            text:'Arrastre o de clic para subir una imagen'
        }
    },
    payment_method:{
        title:'Método de pago',
        subtitle:'Cobrar:',
        options:[
            'Efectivo',
            'Tarjeta'
        ],
        button:'Pagado'
    },
    select_client:{
        title:'Elegir cliente',
        client:'Cliente',
        button:'Continuar'
    },
    receipt:{
        title:'Elegir recibo',
        options:[
            'Foliado',
            'Sin folio',
            'Factura'
        ],
        button:'Continuar'
    },
    download_doc:{
        title:'¡Listo!',
        message:'Archivo generado exitosamente.',
        button:'Descargar',
        image:file
    },
    change_image_modal:{
        title:'Cambiar imagen',
        button:'Guardar',
        error_msg:'Debe adjuntar/seleccionar una imagen para continuar'
    },
    warning_modal: {
        title:'¿Estás seguro?',
        button:'Eliminar',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido'
    },
}

export default translations