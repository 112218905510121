import { Grid, makeStyles, Typography } from "@material-ui/core"
import { grey } from "@material-ui/core/colors"
import DeleteButton from "../../../../../components/Actions/DeleteButton"
import EditButton from "../../../../../components/Actions/EditButton"
import ImageContainer from "../../../../../components/Ecommerce/ImageContainer"
import SimpleCard from "../../../../../components/Structure/Cards/SimpleCard"
import { onGetDateFormat } from "../../../../../shared/utility"

const BannerCard = ({ content, title, subtitle, web_image, description, date, web_link, onEdit, onDelete }) => {

    const classes = styles()

    return (
        <SimpleCard padding='small' fullHeight>
            <ImageContainer ratio='4/3' src={web_image} variant='cover' />
            <div className={classes.container}>
                <div className={classes.buttons}>
                    <Grid container spacing={1}>
                        <Grid item><EditButton onClick={onEdit} /></Grid>
                        <Grid item><DeleteButton onClick={onDelete} /></Grid>
                    </Grid>
                </div>
                <Grid container alignItems='stretch' spacing={2}>
                    <Grid item xs={12}>
                        <DataItem title={content.date} data={onGetDateFormat(date, 'DD / MMM / YYYY')} />
                    </Grid>
                    <Grid item xs={12}>
                        <DataItem title={content.title} data={title} />
                    </Grid>
                    <Grid item xs={12}>
                        <DataItem title={content.subtitle} data={subtitle} />
                    </Grid>
                    <Grid item xs={12}>
                        <DataItem title={content.link} data={web_link} />
                    </Grid>
                </Grid>
            </div>
        </SimpleCard>
    )
}

export default BannerCard

const styles = makeStyles(theme => ({
    container: {
        position: 'relative',
        marginTop: 24
    },
    buttons: {
        position: 'absolute',
        top: 0,
        right: 0
    }
}))


const DataItem = ({ title, data }) => {

    const classes = itemStyles()

    return (
        <Grid container spacing={2} >
            <Grid item><Typography className={classes.title}>{title}</Typography></Grid>
            <Grid item xs><Typography className={classes.text}>{data}</Typography></Grid>
        </Grid>
    )
}

const itemStyles = makeStyles(theme => ({
    title: {
        fontSize: 14,
        fontWeight: 600
    },
    text: {
        overflowX: 'auto',
        overflowY: 'hidden',
        overflowWrap: 'anywhere',
        fontSize: 14,
        color: grey[500],
    }
}))
