import StopIcon from '@material-ui/icons/Stop'
import StarIcon from '@material-ui/icons/Star'
import { grey } from '@material-ui/core/colors'

import noImage from '../../assets/noimagegold.png'
import nftIcon from '../../assets/icons/NFT.svg'
import likeIcon from '../../assets/icons/full_heart.svg'
import trashIcon from '../../assets/icons/trash.svg'
import deleteIcon from '../../assets/icons/delete_trash.svg'

import viewsIcon from '../../assets/icons/views.svg'
import exhibitionsIcon from '../../assets/icons/exhibitions_filled.svg'
import timeIcon from '../../assets/icons/wall-clock-white-clockhands.svg'
import enquiresIcon from '../../assets/icons/file_filled.svg'
import featuredIcon from '../../assets/icons/featured.svg'

import uploadIcon from '../../assets/icons/upload.svg'
import downloadIcon from '../../assets/icons/download.svg'
import removeIcon from '../../assets/icons/remove.svg'

export const translations = {
    title: 'Exhibition',
    detail: 'Detail',
    nft: nftIcon,
    like: likeIcon,
    save_button: 'Save',
    words: 'words',
    characters: 'characters',
    filter: [
        { id: 'all', value: 0, label: 'All' }
    ],
    files: {
        brochure: {
            label: 'Brochure'
        },
    },
    sections: {
        general_info: {
            delete: trashIcon,
            editable: true,
            edit_button: 'Edit',
            referenceCode: 'refcode',
            featuredIcon: featuredIcon,
            image_options: [
                { id: 'web_image', value: 1, label: 'web' },
                { id: 'mobile_image', value: 2, label: 'mobile' },
            ],
            form: {
                title: {
                    label: 'Title',
                    placeholder: 'Title'
                },
                artworks: {
                    label: 'Artworks',
                    placeholder: 'Artworks'
                },
                min_artwork_number: {
                    unit: 'min.',
                    label: 'Min. Artworks',
                    placeholder: 'Min. Artworks'
                },
                max_artwork_number: {
                    unit: 'max.',
                    label: 'Max. Artworks',
                    placeholder: 'Max. Artworks'
                },
                created_by: {
                    label: 'Created by',
                    placeholder: 'Created by'
                },
                updated_by: {
                    label: 'Updated by',
                    placeholder: 'Updated by'
                },
                short_description: {
                    label: 'Phrase',
                    placeholder: 'Phrase'
                },
                medium_description: {
                    label: 'Medium text',
                    placeholder: 'Medium text'
                },
                description: {
                    label: 'Description',
                    placeholder: 'Description'
                },
                exhibition_type_id: {
                    label: 'Type',
                    placeholder: 'Type',
                },
                featured: {
                    label: 'Featured',
                    placeholder: 'Featured'
                }
            },
            fileData: {
                upload: {
                    icon: uploadIcon,
                    label: 'Upload'
                },
                download: {
                    icon: downloadIcon,
                    label: 'Download'
                },
                remove: {
                    icon: removeIcon,
                    label: 'Remove'
                }
            }
        },
        dates: {
            title: 'Dates',
            edit_button: 'Edit',
            editable: true,
            cardLabels: {
                period: 'Period',
                application_deadline: 'Application deadline',
                application_documents_limit_date: 'Final decision date',
            },
            form: {
                period: {
                    label: 'From',
                    label2: 'To'
                },
                start_date: {
                    label: 'Start date'
                },
                end_date: {
                    label: 'End date'
                },
                date: {
                    label: 'Date'
                },
                application_limit_date: {
                    label: 'Application deadline',
                    placeholder: 'Application deadline'
                },
                application_documents_limit_date: {
                    label: 'Final decision date',
                    placeholder: 'Final decision date'
                }
            }
        },
        applications: {
            title: 'Applications',
            noData: 'No applications to show.',
            header: [
                { id: 1, label: 'ID', align: 'center' },
                { id: 2, label: 'Artist' },
                { id: 3, label: 'Artworks', align: 'center' },
                { id: 4, label: 'Status' },
                { id: 5, label: 'Payment status' },
            ],
            form: {
                user_id: {
                    label: 'Artist',
                    placeholder: 'Artist',
                    helperText: 'Select an artist',
                },
            }
        },
        sponsors: {
            title: 'Sponsors',
            noData: 'No sponsors to show.',
            header: [
                { id: 1, label: 'ID', align: 'center' },
                { id: 2, label: 'Name' },
                { id: 4, label: 'Status' },
            ],
            form: {
                sponsor_id:{
                    label:'Sponsor',
                    placeholder:'Sponsor'
                },
            },
        },
        artworks: {
            title: 'Artworks',
            noData: 'No artworks to show.',
            form: {
                artwork_id: {
                    label: 'Artwork',
                    placeholder: 'Artwork',
                    helperText: 'Select an artwork'
                },
            },
            noImage: noImage,
            noContent: 'There are not coincidences with the filters selected.',
            sold: 'Sold',
            data: [
                {
                    id: 'artwork_artist',
                    label: 'Name'
                },
                {
                    id: 'artwork',
                    label: 'Title'
                },
            ],
            price: 'Price',
            like: likeIcon,
            delete: deleteIcon,
            deleteH: trashIcon,
        },
        artists: {
            title: 'Artists',
            noData: 'No artists to show.',
            header: [
                { id: 1, label: 'ID', align: 'center' },
                { id: 2, label: 'Artist' },
                { id: 3, label: 'Discipline' },
                { id: 4, label: 'Nationality', align: 'center' },
                { id: 5, label: 'Member' },
                { id: 6, label: 'New', align: 'center' },
                { id: 7, label: 'Featured', align: 'center' },
            ],
            form: {
                artist_id: {
                    label: 'Artist',
                    placeholder: 'Artist',
                    helperText: 'Select an artist'
                }
            }
        },
        virtual_exhibitions: {
            title: 'Virtual exhibition',
            button: 'Edit',
            iframe: 'Iframe',
            preview: 'Preview',
            editable: true,
            form: {
                iframe: {
                    label: 'Iframe',
                    placeholder: 'Iframe'
                }
            }
        },
        magazines: {
            title: 'Select magazine',
            button: 'Select',
            editable: true,
            delete: trashIcon,
            form: {
                magazine_id: {
                    label: 'Magazine',
                    placeholder: 'Select a magazine'
                },
            }
        },
        video: {
            title: 'Video url',
            button: 'Edit',
            editable: true,
            delete: trashIcon,
            form: {
                video: {
                    label: 'Video URL',
                    placeholder: 'Video URL'
                },
            }
        }
    },
    modals: {
        picture: {
            title: 'Update Picture',
            placeholder: '',
            image_selected: '',
            save_button: 'Save',
            mobile: 'Save for mobile view'
        },
        professional_image: {
            title: 'Update Additional Image',
            placeholder: '',
            image_selected: '',
            save_button: 'Save',
            //mobile: 'Save for mobile view',
        },
        add_application_modal: {
            title: 'Create application',
            button: 'Add'
        },
        add_artist_modal: {
            title: 'Add artist',
            button: 'Add'
        },
        add_sponsor_modal: {
            title: 'Add sponsor',
            button: 'Add'
        },
        add_artwork_modal: {
            title: 'Add artwork',
            button: 'Add'
        },
        documents: {
            title: 'Upload document',
            label: 'Select the document',
            button: 'Save'
        },
        add_modal: {
            add_button: 'Add',
        },
    }
}