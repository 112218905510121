import { CircularProgress, Fade, Grid, Icon, makeStyles, Snackbar, Typography } from '@material-ui/core';
import React from 'react';
import ErrorModal from '../../Modals/ErrorModal';
import { blue, green } from '@material-ui/core/colors';

const LoadingContainer = ({ children, loading, error, success, sending, onCloseError, onCloseSuccess }) => {

    const classes = useStyles()

    const successContent = <Snackbar ContentProps={{ className: classes.success }} open={Boolean(success)} onClose={onCloseSuccess}
        autoHideDuration={1500} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        message={<Grid container alignItems='center' spacing={1}>
            <Grid item><Icon>done</Icon></Grid>
            <Grid item><Typography style={{ color: 'white' }}>{'Successful action'}</Typography></Grid>
        </Grid>} />

    const sendingContent = <Snackbar ContentProps={{ className: classes.sending }} open={Boolean(sending)}
        message={<Grid container alignItems='center' spacing={1}>
            <Grid item><CircularProgress size={18} style={{ color: 'white' }} /></Grid>
            <Grid item><Typography style={{ color: 'white' }}>Sending...</Typography></Grid>
        </Grid>} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} />


    const errorModal = <ErrorModal open={Boolean(error)} message={error} onClose={onCloseError} />

    return (
        <div>
            {sendingContent}
            {successContent}
            {errorModal}
            {loading ? (
                <Grid container alignItems='center' direction='column' style={{marginTop:200}}><Grid item><CircularProgress size={45} /></Grid></Grid>
            ) : null}
            <Fade in={!loading}>{children}</Fade>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: 32,
        [theme.breakpoints.down('sm')]: {
            padding: 16,
        }
    },
    container: {
        padding: '32px 0px'
    },
    success: {
        background: theme.palette.secondary.main,
        zIndex: 3
    },
    sending: {
        background: theme.palette.primary.main
    }
}))


export default LoadingContainer;