import React from 'react'
import { TextField, withStyles } from '@material-ui/core'
import { ruleValidation } from './customFunctions'

const CssTextField = withStyles({
  root: {
    //paddingTop:6,
    '& label.Mui-focused': {
      //color: 'green',
      //display:'none'
    },
    '& .MuiInput-underline:after': {
      //borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      //borderRadius: 0,
      //border:'none',
      //borderBottom:'1px solid #AEAEAE',
      // '& fieldset': {
      //   //borderColor: 'red',
      //   border: 'none',
      //   borderBottom: '1px solid #AEAEAE',
      // },
      // '&:hover fieldset': {
      //   borderColor: '#BD8B40',
      // },
      // '&.Mui-focused fieldset': {
      //   borderColor: '#BD8B40',
      // },
    },
    /* '& .MuiFormLabel-root':{
      color:'#858585'
    } */
  },
})(TextField);

const InputForm = props => {

  const { data, onChange, startAdornment, endAdornment, onKeyUp, onKeyDown } = props

  const onInnerChange = event => {
    const response = event.target.value;
    let temp = { ...data };
    temp.value = response;
    temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
    temp.isVisited = true;
    onChange(temp);
  }

  const { config, value, isValid, isVisited } = data

  const isError = isVisited && !isValid
  let interConfig = { ...config }
  if (!isError) interConfig.helperText = ''


  return (
    <div>
      <CssTextField value={value} variant='outlined' color='secondary'
        error={isError} {...interConfig} onChange={onInnerChange}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={
          {
            startAdornment: startAdornment ? startAdornment : undefined,
            endAdornment: endAdornment ? endAdornment : undefined,
            onKeyUp: onKeyUp ? onKeyUp : undefined,
            onKeyDown: onKeyDown ? onKeyDown : undefined,
          }}
      />
    </div>
  )
}

export default InputForm