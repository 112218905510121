import React, { useEffect, useState } from "react"
import { private_server } from "../../config"
import { isFormValid, onGetErrorMessage, onGetSelectedFormData, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../shared/utility"
import queryString from 'query-string'
import { generic_errors } from "../../texts/eng/genericErrors"
import catalogs from "../../texts/eng/catalogs"

const useNewsletterView = ({ language, history, is_auth, content }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const [total, setTotal] = useState(0)
    const [search, setSearch] = useState(null)
    const [filterValue, setFilterValue] = useState(-1)
    const [tableFilter, setTableFilter] = useState({ limit: 10, offset: 0, order_by: 'id_newsletter_request', order: 1 })
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    //DATA
    const [newsletters, setNewsletters] = useState([])
    const [newsletter, setNewsletter] = useState(null)
    const [newsletterID, setNewsletterID] = useState(null)
    const [add_modal, setAddModal] = useState(false)
    const [edit_modal, setEditModal] = useState(false)

    useEffect(() => {
        if (is_auth)
            actions.onInitModule()
    }, [is_auth])

    useEffect(() => {
        if (is_auth)
            actions.onFilterNewsletters()
    }, [is_auth, tableFilter])

    useEffect(() => {
        if (is_auth)
            actions.onResetTableFilter()
    }, [is_auth, filterValue])

    const actions = {
        onInitModule: () => {
            let _form = JSON.parse(JSON.stringify(formData))
            _form = onInitForm(_form, null, content.form)
            setForm(_form)
        },
        onResetTableFilter: () => setTableFilter({ limit: 10, offset: 0, order_by: 'id_newsletter_request', order: 1 }),
        onCloseError: () => setError(null),
        onUpdateTableFilter: (data) => setTableFilter(data),
        onUpdateFilterValue: (id) => setFilterValue(id),
        onOpenAddModal: () => {
            let _form = JSON.parse(JSON.stringify(formData))
            _form = onInitForm(_form, null, content.form)
            setForm(_form)
            setAddModal(true)
        },
        onCloseAddModal: () => setAddModal(false),
        onChange: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if (error) setError(null)
            setForm(temp)
        },
        onSearchNewsletter: (data) => {
            setSending(true)
            let temp = { ...form }
            temp.search = { ...data }
            if (error) setError(null)
            setForm(temp)
            setSearch(data.value)
        },
        onFilterNewsletters: async () => {
            try {
                setSending(true)

                let filter = { AND: [] }
                if (filterValue > -1) filter.AND.push({ field: "contacted", operator: "=", value: filterValue })
                if (search && search.length > 0) filter.AND.push({ field: "name", operator: "LIKE", value: `%%${search}%%` })
                let _filter = encodeURIComponent(JSON.stringify(filter))
                let params = filter.AND.length > 0 ? `?${queryString.stringify(tableFilter)}&filter=${_filter}` : `?${queryString.stringify(tableFilter)}`
                const _newsletters = await private_server.get(`newsletterrequest/all${params}`)
                setNewsletters([..._newsletters.data.data.newsletter_requests])
                setTotal(_newsletters.data.data.count)
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onSelectNewsletter: (_newsletter) => {
            setNewsletter(_newsletter)
            setNewsletterID(_newsletter.id_newsletter_request)
            const _form = onInitForm(form, _newsletter, content.form)
            setForm(_form)
            setEditModal(true)
        },
        onCloseEditModal: () => setEditModal(false),
        onAddNewsletter: async () => {
            try {
                setSending(true)

                const errors = isFormValid(form)

                // Error handler
                if (errors && errors.length) {
                    const _form = onSetErrorsToForm(form, errors)
                    setForm(_form)
                    setError(generic_errors[language].INCOMPLETE_FORM)
                    setSending(false)
                    return
                }

                const data2send = onGetSelectedFormData(form, newsletters)

                await private_server.post(`newsletterrequest`, data2send)
                let params = `?${queryString.stringify(tableFilter)}`
                const _newsletters = await private_server.get(`/newsletterrequest/all${params}`)
                setNewsletters(_newsletters.data.data.newsletter_requests)
                setTotal(_newsletters.data.data.count)
                setFilterValue(-1)
                setTableFilter({ limit: 10, offset: 0, order_by: 'id_newsletter_request', order: 1 })

                setError(null)
                setAddModal(false)
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onUpdateNewsletter: async () => {
            try {
                setSending(true)

                const errors = isFormValid(form)

                // Error handler
                if (errors && errors.length) {
                    const _form = onSetErrorsToForm(form, errors)
                    setForm(_form)
                    setError(generic_errors[language].INCOMPLETE_FORM)
                    setSending(false)
                    return
                }

                const data2send = onGetSelectedFormData(form, newsletters)

                await private_server.patch(`newsletterrequest/${newsletterID}`, data2send)
                await actions.onFilterNewsletters()
              
                setError(null)
                setEditModal(false)
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
    }

    const system = { loading, sending, error }
    const view_data = { total, filterValue, newsletters, form, tableFilter, add_modal, edit_modal }

    return { system, view_data, actions }
}

export default useNewsletterView

const formData = {
    search: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'search',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    name: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    email: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'email'
        }
    },
    tags: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'tags',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
        }
    },
    date: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'date',
            type: 'date',
            fullWidth: true,
        },
        rules: {
            type: 'date'
        }
    },
    contacted: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'contacted'
        }
    },
    contacted_at: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'contacted_at',
            type: 'date',
            fullWidth: true,
        },
        rules: {
            type: 'date'
        }
    },
}