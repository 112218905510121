import shipping_icon from '../../assets/icons/shipping.svg'
import center_call_icon from '../../assets/icons/center-call.svg'
import offers_icon from '../../assets/icons/offers.svg'

import baby_icon from '../../assets/icons/baby.png'
import drugs_icon from '../../assets/icons/pill.png'
import aids_icon from '../../assets/icons/first-aids.png'
import nutrition_icon from '../../assets/icons/nutrition.png'
import skin_icon from '../../assets/icons/skin.png'
import vision_icon from '../../assets/icons/vision.png'

import offer_a from '../../assets/banners/offer-drink.jpg'
import offer_b from '../../assets/banners/offer-nutrition.jpg'
import offer_c from '../../assets/banners/offer-covid.jpg'

import blog_asset_1 from '../../assets/blogs/blog-1.png'
import blog_asset_2 from '../../assets/blogs/blog-2.png'
import blog_asset_3 from '../../assets/blogs/blog-3.png'



import appointment_banner from '../../assets/banners/banner-appointment.png'

export const translations = {
    title:'Detalle de venta',
    general:{
        button:'Editar',
        start_date:'Fecha inicio',
        sale_date:'Fecha venta',
        billing_date:'Fecha facturación',
        cancel_date:'Fecha cancelación',
        price:'Precio',
        status:'Status',
        payment_method:'Método de pago',
        document:'Documento',
        vendor:'Vendedor',
        receipt:'Recibo',
        generate_receipt_button:'Generar recibo',
        cancel_receipt_button:'Cancelar recibo'
    },
    client:{
        title:'Cliente',
        button:'Editar',
        header:[
            'Cliente',
            'Usuario',
            'RFC',
            'Uso de factura'
        ]

    },
    products:{
        title:'Productos',
        header:[
            'Código',
            'Descripción',
            'Cantidad',
            'Precio',
            'Total'
        ]
    }
    
}

export default translations