import noImage from '../../assets/noimagegold.png'

export const translations = {
	title: 'Applications',
	noData: 'No applications found.',
	mainFilter: [
		{ id: 1, value: 1, label: 'Artists' },
		{ id: 2, value: 2, label: 'Exhibitions' },
		{ id: 3, value: 3, label: 'Magazines' },
	],
	filter: [{ id: 0, value: 0, label: 'All' }],
	search: {
		placeholder: 'Search',
	},
	sections: {
		artists: {
			title: 'Artist',
			header: [
				{ id: 1, label: 'ID', align: 'center' },
				{ id: 2, label: 'Artist' },
				{ id: 3, label: 'Email' },
				{ id: 4, label: 'Discipline' },
				{ id: 5, label: 'Nationality', align: 'center' },
				{ id: 6, label: 'Sponsor' },
				{ id: 7, label: 'Application Date' },
			],
			add_form: {
				email:{
					label: 'Email',
					placeholder: 'Email',
					helperText: 'Insert a valid email',
				},
				password:{
					label: 'Password',
					placeholder: 'Password',
					helperText: 'Insert a valid password',
				},
				first_name:{
					label: 'First name',
					placeholder: 'First Name',
					helperText: 'Required field',
				},
				last_name:{
					label: 'Last name',
					placeholder: 'Last Name',
					helperText: 'Incorrect field',
				},
				gender_id:{
					label: 'Gender',
					helperText: 'Select a valid option',
				},
			},
		},
		exhibitions: {
			title: 'Exhibitions',
			header: [
				{ id: 1, label: 'ID', align: 'center' },
				{ id: 2, label: 'Exhibition' },
				{ id: 3, label: 'Artist' },
				{ id: 4, label: 'Artworks', align: 'center' },
				{ id: 5, label: 'Status' },
				{ id: 6, label: 'Payment status' },
				//{ id: 7, label: 'Application Date' },
			],
			add_form: {
				exhibition_id: {
					label: 'Exhibition',
					placeholder: 'Exhibition',
					helperText: 'An exhibition for the application is required',
				},
				user_id: {
					label: 'Artist',
					placeholder: 'Artist',
					helperText: 'An artist for the application is required',
				},
			},
			form: {
				user_id: {
					label: 'Artist',
					placeholder: 'Artist',
					helperText: 'Select an artist',
				},
				payment_status_id: {
					label: 'Payment status',
					placeholder: 'Payment status',
					helperText: 'Select a payment status',
				},
				application_status_id: {
					label: 'Application status',
					placeholder: 'Application status',
					helperText: 'Select an application status',
				},
			},
		},
		magazines: {
			title: 'Magazines',
			header: [
				{ id: 1, label: 'ID', align: 'center' },
				{ id: 2, label: 'Magazine' },
				{ id: 3, label: 'Artist' },
				{ id: 4, label: 'Artworks', align: 'center' },
				{ id: 5, label: 'Status' },
				{ id: 6, label: 'Payment status' },
			],
			form: {
				user_id: {
					label: 'Artist',
					placeholder: 'Artist',
					helperText: 'Select an artist',
				},
				payment_status_id: {
					label: 'Payment status',
					placeholder: 'Payment status',
					helperText: 'Select a payment status',
				},
				application_status_id: {
					label: 'Application status',
					placeholder: 'Application status',
					helperText: 'Select an application status',
				},
			},
            add_form: {
				magazine_id: {
					label: 'Magazine',
					placeholder: 'Magazine',
					helperText: 'A magazine for the application is required',
				},
				user_id: {
					label: 'Artist',
					placeholder: 'Artist',
					helperText: 'An artist for the application is required',
				},
			},
		},
	},
	modals: {
		add_artist: {
			title: 'Add Artist Application',
			save_button: 'Add',
		},
		add_exhibition: {
			title: 'Add Exhibition Application',
			save_button: 'Add',
		},
		edit_exhibition: {
			title: 'Exhibition Application',
			save_button: 'Save',
			artworks: 'Artworks',
			noImage: noImage,
			add_artwork:{
				title: 'Add exposition artwork',
				save_button: 'Save',
			}
		},
		add_magazine: {
			title: 'Add Magazine Application',
			save_button: 'Add',
		},
		edit_magazine: {
			title: 'Magazine Application',
			save_button: 'Save',
			artworks: 'Artworks',
			noImage: noImage,
			add_artwork:{
				title: 'Add magazine artwork',
				save_button: 'Save',
			}
		},
	},
};

export default translations