import { makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import WebsiteRouter from '../../routes/website-router';
import { actionTypes } from '../../store/actions';
import Topbar from './components/Topbar';
import cx from 'classnames'
import Sidebar from './components/Sidebar';
import useWebsiteLayout from './useWebsiteLayout';
import ErrorModal from '../../components/Modals/ErrorModal';
import { DASHBOARD_SETTINGS, TOKEN_STATUSES } from '../../config';
import { onGetTokenFromMemory, onValidateToken } from '../../shared/utility'


const WebsiteLayout = ({ history, onUpdateURL, blur, user, is_auth, error, drawer_status,
    onUpdateUserData, onUpdateAuthStatus, onUpdateCart, onUpdateError, search_params, onUpdateSearchParams }) => {

    const classes = useStyles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))

    const { loading, sending, actions } = useWebsiteLayout({
        is_auth, history, onUpdateUserData, onUpdateAuthStatus, onUpdateCart, search_params, onUpdateSearchParams
    })


    const session_data = onGetTokenFromMemory()
    const isAuth = onValidateToken(session_data)


    return (
        <div className={cx({
            [classes.root]: true,
            [classes.root_blur]: blur
        })}>
            <ErrorModal open={Boolean(error)} message={error ? error.message : null}
                onClose={() => onUpdateError(null)} />
            <Topbar showSidebar={drawer_status} onItemSelected={actions.onItemSelected} onShowApplicationForm={actions.onShowApplicationForm} />
            <Sidebar history={history} ismobile={ismobile} onItemSelected={actions.onItemSelected} user={user} />
            <div className={cx({
                [classes.subroot]: true,
                [classes.subroot_shift]: !drawer_status,
            })}>
                {isAuth === TOKEN_STATUSES.VALID || isAuth === TOKEN_STATUSES.REAUTH ? <WebsiteRouter /> : <Redirect to='/signin' />}
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        background: '#FCFCFC',
        display: 'flex',
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        filter: 'none',
        position: 'relative',
        zIndex: 1,
    },
    root_blur: {
        filter: 'blur(3px)'
    },
    main: {
        //marginLeft:150,
        //padding:16,
        overflowY: 'auto'

    },
    subroot: {
        height: '100vh',
        marginTop: 100,
        boxSizing: 'border-box',
        overflowY: 'auto',
        overflowX: 'hidden',
        margin: 'auto',
        width: '100%',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        //: DASHBOARD_SETTINGS.SIDEBAR.WIDTH,
        /*[theme.breakpoints.down('sm')]: {
            marginLeft: 0
        },*/
    },
    subroot_shift: {
        marginLeft: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),

    },
}))

const mapStateToProps = state => {
    return {
        is_auth: state.is_auth,
        ip_address: state.ip_address,
        blur: state.blur,
        user: state.user,
        error: state.error,
        drawer_status: state.drawer_status,
		search_params: state.search_params
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({ type: actionTypes.AUTH_UPDATE_USER, user }),
        onUpdateAuthStatus: (is_auth) => dispatch({ type: actionTypes.AUTH_UPDATE_STATUS, is_auth }),
        onUpdateError: (error) => dispatch({ type: actionTypes.SYS_UPDATE_ERROR, error }),
        onUpdateSearchParams: (search_params) => dispatch({ type: actionTypes.SYS_SET_SEARCH_PARAMS, search_params })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteLayout);

