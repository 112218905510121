import React from 'react'
import { makeStyles, useTheme, withStyles } from '@material-ui/styles'
import { Divider, Grid, IconButton, Typography } from '@material-ui/core'
import SimpleCard from '../../../components/Structure/Cards/SimpleCard'
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon'
import { grey } from '@material-ui/core/colors'
import RoundedButton from '../../../components/Actions/RoundedButton'
import FilledDisplayData from '../../../components/Structure/DisplayData/FilledDisplayData'

const styles = makeStyles(theme => ({
    root: {
        marginTop: 80
    },
    title: {
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.165em'
    },
    divider: {
        height: 2
    },
    labelButton: {
        fontSize: 14,
        fontWeight: 600,
        color: '#6D6D6D',
        marginTop: 10
    },
    label: {
        color: '#494949',
        fontSize: 16,
        fontWeight: 700,
    }
}))

const Dates = ({ exhibition, content, form, submitButton, onOpenEditModal }) => {

    const classes = styles()
    const theme = useTheme()

    return (
        <div className={classes.root}>
            <Grid container justifyContent='flex-end' spacing={4}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.title}>
                                {content.title}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Divider className={classes.divider} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <RoundedButton
                        color='black'
                        size='small'
                        style={submitButton}
                        onClick={onOpenEditModal}
                    >
                        {content.edit_button}
                    </RoundedButton>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems='center' spacing={4}>
                        <Grid item xs={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className={classes.label}>
                                        {content.cardLabels.period}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <SimpleCard>
                                        <FilledDisplayData
                                            data={form.period}
                                            value={exhibition.start_date}
                                            value2={exhibition.end_date}
                                            format='DD/MM/YYYY'
                                        />
                                    </SimpleCard>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className={classes.label}>
                                        {content.cardLabels.application_deadline}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <SimpleCard>
                                        <FilledDisplayData data={form.date} value={exhibition.application_limit_date} format='DD/MM/YYYY' />
                                    </SimpleCard>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className={classes.label}>
                                        {content.cardLabels.application_documents_limit_date}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <SimpleCard>
                                        <FilledDisplayData data={form.date} value={exhibition.application_documents_limit_date} format='DD/MM/YYYY' />
                                    </SimpleCard>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const DocumentButton = withStyles(theme => ({
    root: {
        background: 'black',
        padding: 10,
        margin: 'auto',
        display: 'flex',
        boxShadow: '0px 4.19564px 19.9293px rgba(0, 0, 0, 0.15)',
        '&:hover': {
            background: theme.palette.secondary.dark
        }
    },
    disabled: {
        color: 'white',
        backgroundColor: `${grey[500]} !important`
    }
}), { withTheme: true })(IconButton)

const RedDocumentButton = withStyles(theme => ({
    root: {
        background: theme.palette.primary.main,
        padding: 10,
        margin: 'auto',
        boxShadow: '0px 4.19564px 19.9293px rgba(0, 0, 0, 0.15)',
        display: 'flex',
        '&:hover': {
            background: theme.palette.primary.light
        }
    },
    disabled: {
        color: 'white',
        backgroundColor: `${grey[500]} !important`
    }
}), { withTheme: true })(IconButton)

export default Dates