import noImage from '../../assets/noimagegold.png'
import nftIcon from '../../assets/icons/NFT.svg'
import likeIcon from '../../assets/icons/full_heart.svg'

import noArtworks from '../../assets/icons/no_artworks.svg'

export const translations = {
    title: 'Artworks',
    noArtworks: {
        title: 'No artworks to show',
        subtitle: 'Artworks will show here.',
        icon: noArtworks
    },
    filter: [
        { id: 'all', value: 0, label: 'All' }
    ],
    form: {
        search: {
            placeholder: 'Search by title...'
        },
        artist_id:{
            label: 'Artist',
            placeholder: 'Artist',
            helperText: 'Select a valid option'
        },
        title: {
            label: 'Title',
            placeholder: 'Title',
            helperText: 'Required field'
        },
        dimensions: {
            label: 'Dimensions',
            placeholder: 'Dimensions',
            helperText: 'Required field'
        },
        technique: {
            label: 'Technique',
            placeholder: 'Technique',
            helperText: 'Required field'
        },
        price: {
            label: 'Price',
            placeholder: 'Price',
            helperText: 'Field must be numeric'
        },
        description: {
            label: 'Description',
            placeholder: 'Description',
            helperText: 'Not valid field'
        },
        date: {
            label: 'Date',
            helperText: 'Choose a valid date'
        },
        sold: {
            label: 'Sold'
        },
        nft: {
            label: 'NFT'
        },
        external_url: {
            label: 'NFT Link',
            placeholder: 'NFT Link',
            helperText: 'Field must be between 0 and 200 characters long'
        },
    },
    add_button: 'Add',
    add_title: 'Add Artwork',
    noImage: noImage,
    noContent: 'There are not coincidences with the filters selected.',
    sold: 'Sold',
    data: [
        {
            id: 'id_artwork',
            label: 'ID'
        },
        {
            id: 'artist_professional_name',
            label: 'Artist'
        },
        {
            id: 'title',
            label: 'Title'
        },
    ],
    price: 'Price',
    nft: nftIcon,
    like: likeIcon
}

export default translations