import { Icon, IconButton, makeStyles, useTheme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import cx from 'classnames';
import editIcon from '../../assets/icons/edit.svg';
import SVGIcon from '../Structure/DisplayData/SVGIcon';

const EditButton = ({ size, onClick, variant }) => {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<IconButton
			onClick={onClick}
			size={size ? size : 'medium'}
			className={cx({
				[classes.root]: true,
				[classes.modal]: variant === 'modal',
			})}
		>
			{variant === 'modal' ? <SVGIcon size={24} src={editIcon} colorStroke={theme.palette.primary.main} /> : <Icon>edit</Icon>}
		</IconButton>
	);
};

export default EditButton;

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.secondary.main,
		padding: 6,
		border: '1px solid #EAEAEA',
		color: 'white',
		'&:hover': {
			background: theme.palette.secondary.main,
		},
	},
	modal: {
		background: 'white',
		color: theme.palette.primary.dark,
		padding: 10,
		'&:hover': {
			background: grey[200],
		},
	},
}));
