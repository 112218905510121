import { Grid, Icon, IconButton, Typography, useMediaQuery } from '@material-ui/core'
import { green, grey, red } from '@material-ui/core/colors'
import { makeStyles, useTheme } from '@material-ui/styles'
import React from 'react'
import GenericImage from '../../../components/Structure/DisplayData/GenericImage'
import SVGIcon from '../../../components/Structure/DisplayData/SVGIcon'
import SimpleCard from '../../../components/Structure/Cards/SimpleCard'
import FilledDisplayData from '../../../components/Structure/DisplayData/FilledDisplayData'
import SwitchForm from '../../../components/Forms/SwitchForm'
import cx from 'classnames'

const styles = makeStyles(theme => ({
    root: {

    },
    content: {
        width: '100%',
        display: 'flex',
        [theme.breakpoints.between(0, 900)]: {
            display: 'block'
        }
    },
    imageContainer: {
        width: '100%',
        position: 'relative',
        //background: grey[700],
        borderRadius: 5,
        maxWidth: 380,
        marginRight: 20,
        boxSizing: 'border-box',
        [theme.breakpoints.between(0, 900)]: {
            maxWidth: 400,
            padding: 0,
            paddingBottom: 20,
            margin: 'auto'
        }
    },
    editButton: {
        top: 0,
        right: 0,
        position: 'absolute',
        background: 'white',
        padding: 10,
        margin: 10,
        '&:hover': {
            background: grey[200]
        }
    },
    imageData: {

    },
    tabContainer: {
        width: '100%',
        boxSizing: 'border-box'
    },
    labelTab: {
        width: '100%',
        boxSizing: 'border-box',
        fontSize: 16,
        fontWeight: 600,
        fontFamily: theme.typography.fontFamily,
        textTransform: 'uppercase',
        letterSpacing: '0.165em',
        padding: '4px 0px',
        marginBottom: 10,
        cursor: 'pointer',
        color: '#858585',
    },
    labelTabSelected: {
        color: theme.palette.secondary.main,
        borderBottom: `solid 2px ${theme.palette.secondary.main}`,

    },
    priceIcon: {
        color: grey[500],
        fontWeight: 600,
        paddingRight: 10
    },
    url_adornment:{
        fontSize: 16,
        fontWeight: 700,
        marginRight: 10,
        color: '#9e9e9e'
    }
}))

const GeneralInfo = ({ data, imageType, form, content, onEditPicture, onUpdateImageType, onClickSmallCard }) => {

    const classes = styles()
    const theme = useTheme()

    const image = data ? data[imageType.id] : null

    return (
        <div className={classes.root}>
            <Grid container alignItems='stretch' spacing={4}>
                <Grid item xs={12}>
                    <div className={classes.tabContainer}>
                        <Grid container spacing={1}>
                            {content.image_options.map((tab, key) => (
                                <Grid item key={`artwork-image-type-item-${key.toString()}`} style={{paddingRight: 20}}>
                                    <div onClick={() => onUpdateImageType(tab)} 
                                        className={cx({
                                            [classes.labelTab]: true,
                                            [classes.labelTabSelected]: imageType.value === tab.value
                                        })}>
                                        {tab.label}
                                    </div>

                                </Grid>
                            ))}
                        </Grid>
                    </div>
                    <div className={classes.content}>
                        <div className={classes.imageContainer}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <GenericImage src={image} borderRadius={5} ratio='3/4' />
                                    <IconButton className={classes.editButton} onClick={onEditPicture}>
                                        <SVGIcon
                                            size={24}
                                            src={content.editIcon}
                                            colorStroke={theme.palette.primary.main}
                                        />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12}>
                                    <SimpleCard>
                                        <Grid container justifyContent='center' spacing={2}>
                                            <Grid item xs={12} sm={5}>
                                                <FilledDisplayData
                                                    startAdornment={<Typography className={classes.priceIcon}>{'#'}</Typography>}
                                                    data={form.order}
                                                    value={data.order} number
                                                />
                                            </Grid>
                                            <Grid item xs/>
                                            <Grid item xs={12} sm={5}>
                                                <FilledDisplayData data={form.quantity} value={data.quantity} number/>
                                            </Grid>
                                        </Grid>
                                    </SimpleCard>
                                </Grid>
                            </Grid>
                        </div>
                        <SimpleCard style={{ paddingTop: 50 }}>
                            <Grid container spacing={4}>
                                <Grid xs={12}>
                                    <Typography variant='h6' style={{padding: 16}}>{data.artist_professional_name}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FilledDisplayData data={form.title} value={data.title} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FilledDisplayData data={form.dimensions} value={data.dimensions} number/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FilledDisplayData data={form.technique} value={data.technique} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FilledDisplayData data={form.price} value={data.price} number/>
                                </Grid>
                                <Grid item xs={12}>
                                    <FilledDisplayData data={form.description} value={data.description} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FilledDisplayData data={form.date} value={data.date} format={'DD/MMM/YYYY'} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FilledDisplayData startAdornment={<Typography className={classes.url_adornment}>/ </Typography>} data={form.url} value={data.url} />
                                </Grid>
                            </Grid>
                        </SimpleCard>
                    </div>
                </Grid>
                <Grid item xs={12}>
                        <Grid container spacing={4}>
                            {content.smallCards.map((card, key) => (
                                <Grid item xs={4} key={`small-card-${key.toString()}`}>
                                    <SmallCard value={data[card.id]} {...card} onClick={onClickSmallCard} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                <Grid item xs={12}>
                    <SimpleCard>
                        <Grid container spacing={2}>
                            <Grid item xs={4} sm={1}>
                                <SwitchForm disabled data={form.nft} value={data.nft} />
                            </Grid>
                            <Grid item xs={4} sm={1}>
                                <SwitchForm disabled data={form.nft_sold} value={data.nft_sold} />
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <FilledDisplayData data={form.nft_price} value={data.nft_price} number />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FilledDisplayData data={form.external_url} value={data.external_url} />
                            </Grid>
                        </Grid>
                    </SimpleCard>
                </Grid>
            </Grid>
        </div>
    )
}


const SmallCard = ({ icon, label, value, secondaryColor, primaryColor, onClick, id  }) => {

    const _primaryColor = primaryColor ? primaryColor : green[500]
    const _secondaryColor = secondaryColor ? secondaryColor : grey[500]
    const styleElement = { fontSize: 15, color: value ? _primaryColor : _secondaryColor }

    return (
        <SimpleCard padding='small' onClick={() => onClick(id, value)} style={!value ? {opacity: 0.9, boxShadow: 'none'} : {}}>
            <Grid container justifyContent='center' alignItems='center' spacing={1}>
                <Grid item>
                    {React.cloneElement(icon, { style: styleElement })}
                </Grid>
                <Grid item>
                    <Typography style={{ color: '#636363', fontSize: 16, fontWeight: 600 }}>
                        {label}
                    </Typography>
                </Grid>
            </Grid>
        </SimpleCard>
    )
}

export default GeneralInfo