import { connect } from 'react-redux';
import SelectFilter from '../../components/Forms/SelectFilter';
import TitleContainer from '../../components/Structure/Layouts/TitleContainer';
import ActionTopBar from '../../components/Structure/Table/ActionTopBar';
import { actionTypes } from '../../store/actions';
import { translations as translations_eng } from '../../texts/eng/artists-translations';
import catalogs from '../../texts/eng/catalogs';
import SimpleTable from './components/SimpleTable';
import AddArtistModal from './modals/AddArtistModal';
import useArtistsView from './useArtistsView';

const ArtistsView = ({ history, is_auth, search_params, onUpdateSearchParams }) => {
	const content = translations_eng;
	const { system, view_data, actions, add_modal } = useArtistsView({ history, is_auth, content, search_params, onUpdateSearchParams });

	return (
		<TitleContainer title={content.title}>
			<AddArtistModal
				content={content.modals.add}
				form={view_data.form}
				onChange={actions.onChange}
				onSubmit={actions.onSaveArtist}
				onClose={() => actions.onChangeModalStatus(false)}
				open={add_modal}
			/>
			<ActionTopBar
				filter={[...content.filter, ...catalogs.artist_statuses]}
				filterValue={view_data.filterValue}
				onChangeFilter={actions.onUpdateFilterValue}
				search
				searchData={view_data.form.search}
				onSearch={actions.onSearchArtists}
				onUpdateSearch={actions.onResetTableFilter}
				add
				onAdd={() => actions.onChangeModalStatus(true)}
			/>
			<SimpleTable
				//onSelectedItem={(data) => history.push(`/clients/details/${data.id}`)}
				onSelectedItem={actions.onSelectArtist}
				data={view_data.artists}
				tableFilter={view_data.tableFilter}
				onUpdateTableFilter={actions.onUpdateTableFilter}
				total={view_data.total}
				loading={system.loading}
				onRemoveItem={actions.onPreDeleteRegister}
				noData={content.noArtists}
				header={content.header}
			/>
		</TitleContainer>
	);
};

const mapStateToProps = (state) => {
	return {
		language: state.language,
		is_auth: state.is_auth,
		search_params: state.search_params
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateSearchParams: (search_params) => dispatch({ type: actionTypes.SYS_SET_SEARCH_PARAMS, search_params }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtistsView);
