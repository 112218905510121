import { connect } from 'react-redux';
import SelectFilter from '../../components/Forms/SelectFilter';
import TitleContainer from '../../components/Structure/Layouts/TitleContainer';
import ActionTopBar from '../../components/Structure/Table/ActionTopBar';
import { translations as translations_eng } from '../../texts/eng/users-translations'
import catalogs from '../../texts/eng/catalogs';
import SimpleTable from './components/SimpleTable';
import useUsersView from './useUsersView';
import AddUserModal from './modals/AddUserModal';
import { Redirect } from 'react-router';
import { actionTypes } from '../../store/actions';


const UsersView = ({ history, is_auth, user, search_params, onUpdateSearchParams }) => {

    const content = translations_eng
    const { system, view_data, actions, add_modal } = useUsersView({ history, is_auth, content, search_params, onUpdateSearchParams })

    return (
			<>
				<TitleContainer title={content.title}>
					<AddUserModal
						content={content.modals.add}
						form={view_data.form}
						onChange={actions.onChange}
						onSubmit={actions.onSaveUser}
						onClose={() => actions.onChangeModalStatus(false)}
						open={add_modal}
					/>
					<ActionTopBar
						filter={[...content.filter, ...catalogs.user_types]}
						filterValue={view_data.filterValue}
						onChangeFilter={actions.onUpdateFilterValue}
						search
						searchData={view_data.form.search}
						onSearch={actions.onSearchUsers}
						onUpdateSearch={actions.onResetTableFilter}
						add
						onAdd={() => actions.onChangeModalStatus(true)}
					/>
					<SimpleTable
						onSelectedItem={actions.onSelectUser}
						data={view_data.users}
						tableFilter={view_data.tableFilter}
						onUpdateTableFilter={actions.onUpdateTableFilter}
						total={view_data.total}
						loading={system.loading}
						onRemoveItem={actions.onPreDeleteRegister}
						noData={content.noUsers}
						header={content.header}
					/>
				</TitleContainer>
			</>
		);
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        is_auth: state.is_auth,
        user: state.user,
		search_params: state.search_params
    }
}

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateSearchParams: (search_params) => dispatch({ type: actionTypes.SYS_SET_SEARCH_PARAMS, search_params }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersView) 