import logo from '../../assets/logo.png'

/// Celudoc searchbar icons
import calendar_icon from '../../assets/icons/calendar.svg'
import timer_icon from '../../assets/icons/clock.svg'
import moment from 'moment'

import logoTextIcon from '../../assets/logo-text.svg'

// Social media icons
import facebook_icon from '../../assets/icons/facebook.png'
import instagram_icon from '../../assets/icons/instagram.png'
import twitter_icon from '../../assets/icons/twitter.png'

import home from '../../assets/icons/home.svg'
import profile from '../../assets/icons/profile.svg'
import applications from '../../assets/icons/applications.svg'
import artists from '../../assets/icons/artist.svg'
import artworks from '../../assets/icons/artworks.svg'
import exhibitions from '../../assets/icons/exhibitions.svg'
import magazines from '../../assets/icons/magazines.svg'
import masterclasses from '../../assets/icons/master_classes.svg'
import audience from '../../assets/icons/audience.svg'
import stadistics from '../../assets/icons/stadistics.svg'
import logout from '../../assets/icons/logout.svg'

const general_translations = {
    sidebar: {
        logo: logo,
        title: 'Monitoring System',
        list: [
            {
                name: 'Home',
                path: '/home',
                icon: home,
            },
            {
                name: 'Applications',
                path: '/applications',
                icon: applications,
            },
            {
                name: 'Users',
                path: '/users',
                icon: profile,
            },
            {
                name: 'Artists',
                path: '/artists',
                icon: artists,
            },
            {
                name: 'Artworks',
                path: '/artworks',
                icon: artworks,
            },
            {
                name: 'Exhibitions',
                path: '/exhibitions',
                icon: exhibitions,
            },
            {
                name: 'Magazines',
                path: '/magazines',
                icon: magazines,
            },
            {
                name: 'Masterclasses',
                path: '/masterclasses',
                icon: masterclasses,
            },
            {
                name: 'Audience',
                path: '/audience',
                icon: audience,
                options: [
                    { name: 'Newsletters', path: '/newsletters' },
                    { name: 'Contact', path: '/contact' },
                    { name: 'Enquiries', path: '/enquiries' }
                ]
            },
            {
                name: 'Statistics',
                path: '/statistics',
                icon: stadistics,
            },
        ],
        logout: {
            name: 'Log out',
            path: '/log-out',
            icon: logout,
        },
    },
    topbar: {
        titles: [
            {
                url: '/',
                title: 'Welcome!'
            },
            {
                url: '/devices',
                title: 'Devices'
            },
            {
                url: '/locations',
                title: 'Locations'
            },
        ],
        cart_button: {
            remove: 'Eliminar',
            subtotal: 'Subtotal',
            view_button: 'Ver carrito',
            pay_button: 'Pagar'
        },
        profile_menu: [
            {
                url: '/kuvik-website',
                label: 'Kuvik Website'
            },
            {
                url: '/change-password',
                label: 'Change password'
            },
            {
                url: '/log-out',
                label: 'Log out'
            }
        ],
    },
    footer: {
        links: [
            { id: 1, label: 'Celufarma', link: '/' },
            { id: 2, label: 'Celudoc', link: '/' },
            { id: 3, label: 'Celuhistoria', link: '/' },
        ],
        legal_links: [
            { id: 2, label: `Aviso de Privacidad ${moment().format('YYYY')}`, url: '/privacy', target: '_blank' },
            { id: 1, label: 'Términos y condiciones', url: '/terms-conditions', target: '_blank' },
        ],
        logo: logoTextIcon,
        social_media: {
            title: 'Contáctanos',
            media: [
                { id: 1, icon: facebook_icon, url: '/', target: '_blank' },
                { id: 2, icon: instagram_icon, url: '/', target: '_blank' },
                { id: 3, icon: twitter_icon, url: '/', target: '_blank' },
            ],
        }
    },

}

export default general_translations