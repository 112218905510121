import React, {useCallback, useState} from 'react'
import { alpha, makeStyles, Typography } from '@material-ui/core'
import { grey, teal } from '@material-ui/core/colors'
import {useDropzone} from 'react-dropzone'
import cx from 'classnames'

const useStyles = makeStyles(theme => ({
    root:{},
    container:{
        background:grey[100],
        borderRadius:12,
        padding:32,
        transition: theme.transitions.create(["background"], {
            easing: theme.transitions.easing.sharp, 
            duration: 500,
        })
    },
    containerIn:{
        background:alpha(theme.palette.primary.light,0.7),
        cursor:'pointer'
    }
}))

const CustomDropzone = props => {

    const classes = useStyles()
    const {onDropFile, content} = props

    const [enter, setEnter] = useState(false)

    const onDrop = useCallback((files) => {
        onDropFile(files[0])
        setEnter(false)
    })

    const onDragEnter = useCallback((files) => {
        setEnter(true)
    })

    const onDragLeave = useCallback((files) => {
        setEnter(false)
    })

    const {getRootProps, getInputProps} = useDropzone({onDrop,onDragEnter, onDragLeave})

    return(
        <div {...getRootProps()}
        className={cx({
            [classes.container]: true,
            [classes.containerIn]:enter
        })}>
            <input {...getInputProps()} />
            <Typography align='center' variant='body2'>
                {content.text}
            </Typography>
        </div>
    )
}

export default CustomDropzone