import nftIcon from '../../assets/icons/NFT.svg'
import likeIcon from '../../assets/icons/full_heart.svg'
import trashIcon from '../../assets/icons/trash.svg'

import featuredIcon from '../../assets/icons/featured.svg'

export const translations = {
	title: 'User',
	detail: 'Profile',
	nft: nftIcon,
	like: likeIcon,
	save_button: 'Save',
	words: 'words',
	characters: 'characters',
	sections: {
		general_info: {
			delete: trashIcon,
			add_button: 'Add',
			edit_button: 'Edit',
			change_psw_button: 'Change Password',
			referenceCode: 'refcode',
			featuredIcon: featuredIcon,
			form: {
				referenced_by: {
					label: 'Refered by',
					placeholder: 'Refered by',
				},
				first_name: {
					label: 'First name',
					placeholder: 'First name',
				},
				last_name: {
					label: 'Last name',
					placeholder: 'Last name',
				},
				gender_id: {
					label: 'Gender',
					placeholder: 'Gender',
				},
				country_id: {
					label: 'Country',
					placeholder: 'Country',
				},
				birth_date: {
					label: 'Birth Date',
					//placeholder: 'Discipline',
				},
				country_code: {
					label: 'Code',
				},
				mobile: {
					label: 'Mobile',
					placeholder: 'Mobile',
				},
				email: {
					label: 'Email',
					placeholder: 'Email',
				},
				user_type_id: {
					label: 'Level',
					placeholder: 'Level',
				},
			},
		},
		enquiries: {
			title: 'Enquiries',
			noData: 'No enquiries to show.',
			filter: [{ id: 'all', value: 0, label: 'All' }],
			header: [
				{ id: 1, label: 'ID', align: 'center' },
				{ id: 2, label: 'Name' },
				{ id: 3, label: 'Email' },
				{ id: 4, label: 'Artwork' },
				{ id: 5, label: 'Date' },
				{ id: 6, label: 'Status' },
			],
			form: {
				consultant_id: {
					label: 'Consultant',
					placeholder: 'Consultant',
					helperText: 'Select an option',
				},
				artwork_purchase_request_status_id: {
					label: 'Status',
					placeholder: 'Status',
					helperText: 'Select an option',
				},
				comments: {
					label: 'Comments',
					placeholder: 'Comemts',
					helperText: 'Incorrect field',
				},
			},
		},
		change_password: {
			form: {
				previous_password: {
					label: 'Previous password',
					placeholder: 'Previous password',
					helperText: 'Password must be between 8 and 16 characters long',
				},
				new_password: {
					label: 'New Password',
					placeholder: 'New password',
					helperText: 'Password must be between 8 and 16 characters long',
				},
				new_password_confirm: {
					label: 'Confirm new Password',
					placeholder: 'Confirm new password',
					helperText: 'Password must be between 8 and 16 characters long',
				},
			},
		},
	},
	modals: {
		picture: {
			title: 'Update Picture',
			placeholder: '',
			image_selected: '',
			save_button: 'Save',
			mobile: 'Save for mobile view',
		},
		professional_image: {
			title: 'Update Additional Image',
			placeholder: '',
			image_selected: '',
			save_button: 'Save',
			//mobile: 'Save for mobile view',
		},
		documents: {
			title: 'Upload document',
			label: 'Select the document',
			button: 'Save',
		},
		edit_enquiry: {
			title: 'Edit enquiry',
			save_button: 'Save',
		},
		change_password: {
			title: 'Change password',
			save_button: 'Save',
		},
	},
};