import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { isFormValid, isValidDocument, onGetArtworksCatalogs, onGetErrorMessage, onGetFileExtension, onGetFormData, onGetMagazineCatalogs, onGetSelectedFormData, onGetSponsorsCatalogs, onGetUserCatalogs, onInitForm, onSetErrorsToForm } from '../../shared/utility'
import { CONFIG_SETTINGS, private_server } from '../../config'
import { catalogs } from '../../texts/eng/catalogs'
import axios from 'axios'
import FileSaver from 'file-saver'
import queryString from 'query-string'
import { generic_errors } from '../../texts/eng/genericErrors'

const styles = makeStyles(theme => ({
    content: {
        backgroundColor: 'white',
        minHeight: '100vh',
    },
    loadingContainer: {
        backgroundColor: 'black',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center'
    },
    loading: {
        transform: 'translateY(50vh)'
    },
    arrow: {
        mixBlendMode: 'difference',
        zIndex: 1,
        position: 'fixed',
        width: 'fit-content',
        display: 'flex',
        transform: 'translate(40px,80vh)',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        }
    },
    vertical: {
        color: 'white',
        fontSize: 11,
        paddingLeft: 10,
        letterSpacing: 8,
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateY(10px)'
    },
}))

const useExhibitionDetailsView = ({ is_auth, history, language, match, content }) => {

    const classes = styles()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [files, setFiles] = useState(JSON.parse(JSON.stringify(filesData)))
    const [status, setStatus] = useState(0)
    const [users, setUsers] = useState([])
    const [exhibitionID, setExhibitionID] = useState(null)
    const [exhibition, setExhibition] = useState(null)
    const [exhibitions, setExhibitions] = useState([]) //For application add
    const [applications, setApplications] = useState([])
    const [sponsors, setSponsors] = useState([])
    const [artworks, setArtworks] = useState([])
    const [allArtworks, setAllArtworks] = useState([])
    const [allSponsors, setAllSponsors] = useState([])
    const [artists, setArtists] = useState([])
    const [magazines, setMagazines] = useState([])

    //TABLES 
    const [currentTable, setCurrentTable] = useState(null)
    const [currentTableSearch, setCurrentTableSearch] = useState(null)
    const [loadingTable, setLoadingTable] = useState({
        applications: false,
        sponsors: false,
        artworks: false,
        artists: false,
    })
    const [search, setSearch] = useState({
        applications: '',
        artworks: '',
        artists: '',
        sponsors: '',
    })

    const [total, setTotal] = useState({
        applications: 0,
        sponsors: 0,
        artworks: 0,
        artists: 0
    })

    const [filters, setFilters] = useState({
        applications: 0,
        artists: 0
    })

    const [tableFilter, setTableFilter] = useState({
        applications: { limit: 10, offset: 0, order: 0, order_by: 'id_exhibition_application' },
        sponsors: { limit: 10, offset: 0, order: 0, order_by: 'id_exhibition_sponsor' },
        artworks: { limit: 12, offset: 0, order: 0, order_by: 'id_exhibition_artwork' },
        artists: { limit: 10, offset: 0, order: 0, order_by: 'id_exhibition_artist' }
    })


    //FILES & IMGS
    const [selectedDocument, setSelectedDocument] = useState(null)
    const [selectedProfessionalImage, setSelectedProfessionalImage] = useState(null)
    const [professionalImages, setProfessionalImages] = useState([])
    const [imageType, setImageType] = useState({ id: 'web_image', value: 1, label: 'web' })

    //MODALS
    const [document_modal, setDocumentModal] = useState(false)
    const [picture_modal, setPictureModal] = useState(false)
    const [professional_image_modal, setProfessionalImageModal] = useState(false)
    const [add_modal, setAddModal] = useState({
        sponsors: false,
        artworks: false,
        artists: false,
        applications: false,
    })
    const [edit_modal, setEditModal] = useState(false)
    const [edit_magazine_modal, setEditMagazineModal] = useState(false)
    const [edit_video_modal, setEditVideoModal] = useState(false)
    const [edit_virtual_modal, setEditVirtualModal] = useState(false)
    const [edit_date_modal, setEditDateModal] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
        if (is_auth)
            actions.initModule()
    }, [is_auth])

    useEffect(() => {
        actions.onChangeSearch()
    }, [currentTableSearch, search[currentTableSearch]])


    useEffect(() => {
        actions.onFilterDataTable()
    }, [currentTable, tableFilter[currentTable]])

    useEffect(() => {
        actions.onFilter()
    }, [currentTable, filters[currentTable]])

    useEffect(() => {
        let _form = JSON.parse(JSON.stringify(formData))
        let _users = onGetUserCatalogs(users)
        _form.created_by.options = [..._users]
        _form.updated_by.options = [..._users]

        let _artists = users.filter(user => user.user_type_id === 3)
        _form.artist_id.options = onGetUserCatalogs(_artists)
        _form.user_id.options = onGetUserCatalogs(_artists)

        _form.magazine_id.options = onGetMagazineCatalogs(magazines)

        _form.artwork_id.options = onGetArtworksCatalogs(allArtworks)

        _form.sponsor_id.options = onGetSponsorsCatalogs(allSponsors)

        _form.exhibition_type_id.options = catalogs.exhibition_types

        Object.keys(content.sections).map(section => {
            if (content.sections[section].form) {
                let data = content.sections[section].editable ? exhibition : null
                _form = onInitForm(_form, data, content.sections[section].form)
            }
        })

        setForm(_form)

        let _files = JSON.parse(JSON.stringify(filesData))
        Object.keys(content.files).map(fileID => {
            _files[fileID] = { ..._files[fileID], ...content.files[fileID] }
            _files[fileID].status = exhibition && isValidDocument(exhibition[fileID])
        })

        setFiles(_files)

    }, [users, exhibition, add_modal, edit_modal, magazines, edit_magazine_modal])

    const actions = {
        initModule: async () => {
            const exhibitionURL = match.params.url
            try {
                setLoading(true)
                let _exhibition = await private_server.get(`exhibition/url?url=${exhibitionURL}`)
                _exhibition = _exhibition.data.data.exhibition
                setExhibition({ ..._exhibition })
                setExhibitionID(_exhibition.id_exhibition)
                setStatus(_exhibition.exhibition_status_id)

                let _users = await private_server.get(`user/all`)
                _users = _users.data.data.users
                setUsers(_users)


                let _magazines = await private_server.get(`magazine/all`)
                _magazines = _magazines.data.data.magazines
                setMagazines(_magazines)

                let _all_artworks = await private_server.get(`artwork/all`)
                _all_artworks = _all_artworks.data.data.artworks
                setAllArtworks(_all_artworks)

                let _all_sponsors = await private_server.get(`sponsor/all`)
                _all_sponsors = _all_sponsors.data.data.sponsors
                setAllSponsors(_all_sponsors)

                /*TABLES DATA */
                let _total = { ...total }
                //Applications
                let filter = {
                    AND: [
                        { field: "exhibition_id", operator: "=", value: _exhibition.id_exhibition },
                    ]
                }
                filter = encodeURIComponent(JSON.stringify(filter))

                let params = `?${queryString.stringify(tableFilter.applications)}&filter=${filter}`

                let _applications = await private_server.get(`exhibitionapplication/all${params}`)
                _applications = _applications.data.data
                setApplications(_applications.exhibition_applications)
                _total.applications = _applications.count

                //Sponsors
                params = `?${queryString.stringify(tableFilter.sponsors)}&filter=${filter}`

                let _sponsors = await private_server.get(`exhibitionsponsor/all${params}`)
                _sponsors = _sponsors.data.data
                setSponsors(_sponsors.exhibition_sponsors)
                _total.sponsors = _sponsors.count

                //Artworks
                params = `?${queryString.stringify(tableFilter.artworks)}&filter=${filter}`

                let _artworks = await private_server.get(`exhibitionartwork/all${params}`)
                _artworks = _artworks.data.data
                setArtworks(_artworks.exhibition_artworks)
                _total.artworks = _artworks.count

                //Artists
                params = `?${queryString.stringify(tableFilter.artists)}&filter=${filter}`

                let _artists = await private_server.get(`exhibitionartist/all${params}`)
                _artists = _artists.data.data
                setArtists(_artists.exhibition_artists)
                _total.artists = _artists.count


                setTotal(_total)
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onCloseSuccess: () => setSuccess(false),
        onCloseError: () => setError(null),
        onUpdateImageType: (step) => setImageType(step),
        onChange: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if (error) setError(null)
            setForm(temp)
        },
        onSetExhibitionStatus: async (value) => {
            setStatus(value)
            try {
                setSending(true)

                const data2send = { exhibition_status_id: value }

                let _exhibition = await private_server.patch(`exhibition/${exhibitionID}`, data2send)
                _exhibition = _exhibition.data.data.exhibition
                setExhibition({ ..._exhibition })

                setError(null)
                setSending(false)
            } catch (error) {
                setSending(false)
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        //GENERAL INFO
        onOpenEditModal: () => setEditModal(true),
        onCloseEditModal: () => setEditModal(false),
        //BG BANNER
        onOpenPictureModal: () => setPictureModal(true),
        onClosePictureModal: () => setPictureModal(false),
        /**** */
        onDeleteExhibition: async () => {
            try {
                setSending(true)
                await private_server.delete(`exhibition/${exhibitionID}`)
                history.push('/exhibitions')
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onSaveExhibition: async (section) => {
            try {
                setSending(true)
                let _form = {}

                Object.keys(content.sections[section].form).map(item => {
                    _form[item] = form[item]
                })

                const data2send = onGetSelectedFormData(_form, exhibition)
                //data2send['exhibition_status_id'] = status

                if (Object.keys(data2send).length > 0) {
                    let _exhibition = await private_server.patch(`exhibition/${exhibitionID}`, data2send)
                    _exhibition = _exhibition.data.data.exhibition
                    setExhibition({ ..._exhibition })
                }

                setError(null)
                setEditModal(false)
                setEditMagazineModal(false)
                setEditVideoModal(false)
                setEditVirtualModal(false)
                setEditDateModal(false)
                setSending(false)
            } catch (error) {
                setSending(false)
                setError(onGetErrorMessage(error))
            }
        },
        onUpdateExhibition: async () => {
            try {
                setSending(true)

                let _exhibition = await private_server.get(`exhibition/${exhibitionID}`)
                _exhibition = _exhibition.data.data.exhibition
                setExhibition({ ..._exhibition })

                let _form = JSON.parse(JSON.stringify(formData))

                Object.keys(content.sections).map(section => {
                    _form = onInitForm(_form, _exhibition, content.sections[section].form)
                })

                setForm(_form)

                setError(null)
                setPictureModal(false)
                setDocumentModal(false)
                setSending(false)
            } catch (error) {
                setSending(false)
                setError(onGetErrorMessage(error))
            }
        },
        //TABLES
        onUpdateSearch: (id, data) => {
            let _search = { ...search }
            _search[id] = data
            setSearch(_search)
            setCurrentTableSearch(id)
        },
        onUpdateTableFilter: (id, data) => {
            let _tableFilter = { ...tableFilter }
            _tableFilter[id] = { ...data }
            setTableFilter(_tableFilter)
            setCurrentTable(id)
        },
        onUpdateFilter: (id, data) => {
            let _filter = { ...filters }
            _filter[id] = data
            setFilters(_filter)
            setCurrentTable(id)
        },
        onFilterDataTable: async () => {
            let _loading = { ...loadingTable }

            try {
                _loading[currentTable] = true
                setLoadingTable(_loading)

                let filter = {
                    AND: [
                        { field: "exhibition_id", operator: "=", value: exhibitionID },
                    ]
                }
                filter = encodeURIComponent(JSON.stringify(filter))
                let params = null

                switch (currentTable) {
                    case 'applications':
                        params = `?${queryString.stringify(tableFilter.applications)}&filter=${filter}`
                        const _applications = await private_server.get(`exhibitionapplication/all${params}`)
                        setApplications([..._applications.data.data.exhibition_applications])
                        break;
                    case 'sponsors':
                        params = `?${queryString.stringify(tableFilter.sponsors)}&filter=${filter}`
                        const _sponsors = await private_server.get(`exhibitionsponsor/all${params}`)
                        setSponsors([..._sponsors.data.data.exhibition_sponsors])
                        break;
                    case 'artworks':
                        params = `?${queryString.stringify(tableFilter.artworks)}&filter=${filter}`
                        const _artworks = await private_server.get(`exhibitionartwork/all${params}`)
                        setArtworks([..._artworks.data.data.exhibition_artworks])
                        break;
                    case 'artists':
                        params = `?${queryString.stringify(tableFilter.artists)}&filter=${filter}`
                        const _artists = await private_server.get(`exhibitionartist/all${params}`)
                        setArtworks([..._artists.data.data.exhibition_artists])
                        break;
                }
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            _loading = { ...loadingTable }
            _loading[currentTable] = false
            setLoadingTable(_loading)
        },
        onChangeSearch: async () => {
            try {
                const data = search[currentTableSearch]
                const isValid = data && data.length > 0

                let params = null

                let filter = {
                    AND: [
                        { field: "exhibition_id", operator: "=", value: exhibitionID }
                    ]
                }

                switch (currentTableSearch) {
                    case 'applications':
                        if (isValid) filter.AND.push({ field: "user", operator: "LIKE", value: `%%${data}%%` })
                        filter = encodeURIComponent(JSON.stringify(filter))
                        params = `?${queryString.stringify(tableFilter.applications)}&filter=${filter}`
                        const _applications = await private_server.get(`exhibitionapplication/all${params}`)
                        setApplications([..._applications.data.data.exhibition_applications])
                        break
                    case 'artworks':
                        if (isValid)
							filter.AND.push({
								OR: [
									{ field: 'artwork', operator: 'LIKE', value: `%%${data}%%` },
									{ field: 'artwork_artist', operator: 'LIKE', value: `%%${data}%%` },
								],
							});
                        filter = encodeURIComponent(JSON.stringify(filter))
                        params = `?${queryString.stringify(tableFilter.artworks)}&filter=${filter}`
                        const _artworks = await private_server.get(`exhibitionartwork/all${params}`)
                        setArtworks([..._artworks.data.data.exhibition_artworks])
                        break
                    case 'artists':
                        if (isValid) filter.AND.push({ field: "artist", operator: "LIKE", value: `%%${data}%%` })
                        filter = encodeURIComponent(JSON.stringify(filter))
                        params = `?${queryString.stringify(tableFilter.artists)}&filter=${filter}`
                        const _artists = await private_server.get(`exhibitionartist/all${params}`)
                        setArtists([..._artists.data.data.exhibition_artists])
                        break
                    case 'sponsors':
                        if (isValid) filter.AND.push({ field: "sponsor", operator: "LIKE", value: `%%${data}%%` })
                        filter = encodeURIComponent(JSON.stringify(filter))
                        params = `?${queryString.stringify(tableFilter.sponsors)}&filter=${filter}`
                        const _sponsors = await private_server.get(`exhibitionsponsor/all${params}`)
                        setSponsors([..._sponsors.data.data.exhibition_sponsors])
                        break
                }

            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            //setSending(false)
        },
        onFilter: async () => {
            try {
                const data = filters[currentTable]
                let params = null

                let filter = {
                    AND: [
                        { field: "exhibition_id", operator: "=", value: exhibitionID }
                    ]
                }

                switch (currentTable) {
                    case 'applications':
                        if (data > 0) filter.AND.push({ field: "application_status_id", operator: "=", value: data })
                        filter = encodeURIComponent(JSON.stringify(filter))
                        params = `?${queryString.stringify(tableFilter.applications)}&filter=${filter}`
                        const _applications = await private_server.get(`exhibitionapplication/all${params}`)
                        setApplications([..._applications.data.data.exhibition_applications])
                        break
                    case 'artists':
                        if (data > 0) filter.AND.push({ field: "artist_status_id", operator: "=", value: data })
                        filter = encodeURIComponent(JSON.stringify(filter))
                        params = `?${queryString.stringify(tableFilter.artists)}&filter=${filter}`
                        const _artists = await private_server.get(`exhibitionartist/all${params}`)
                        setArtists([..._artists.data.data.exhibition_artists])
                        break
                }

            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            //setSending(false)
        },
        onOpenAddModal: (label) => {
            let _add_modal = { ...add_modal }
            _add_modal[label] = true
            setAddModal(_add_modal)
        },
        onOpenEditVirtualModal: () => setEditVirtualModal(true),
        onCloseEditVirtualModal: () => setEditVirtualModal(false),
        onOpenEditMagazineModal: () => setEditMagazineModal(true),
        onCloseEditMagazineModal: () => setEditMagazineModal(false),
        onOpenEditVideoModal: () => setEditVideoModal(true),
        onCloseEditVideoModal: () => setEditVideoModal(false),
        onOpenEditDateModal: () => setEditDateModal(true),
        onCloseEditDateModal: () => setEditDateModal(false),
        onAddItem: async (labelID) => {
            try {
                
                setSending(true)
                const keys = Object.keys(content.sections[`${labelID}s`].form)
                
                const errors = isFormValid(form, keys)
                console.log(errors)
                // Error handler
                if (errors && errors.length) {
                    const _form = onSetErrorsToForm(form, errors)
                    setForm(_form)
                    setError(generic_errors[language].INCOMPLETE_FORM)
                    return
                }
                
                const data2send = onGetFormData(form, keys)

                switch (labelID) {
                    case 'artwork':
                        const artworkData = {
                            artwork_id: data2send.artwork_id,
                            exhibition_id: exhibitionID,
                            order: 0,
                            enabled: 1
                        }
                        console.log(artworkData)
                        let _artwork = await private_server.post(`exhibitionartwork`, artworkData)
                        _artwork = _artwork.data.data.exhibition_artwork
                        setArtworks([...artworks, _artwork])
                        break
                    case 'application':
                        const applicationData = {
                            exhibition_id: exhibitionID,
                            user_id: data2send.user_id,
                            payment_id: 1,
                            application_status_id: 1
                        }
                        let _application = await private_server.post(`exhibitionapplication`, applicationData)
                        _application = _application.data.data.exhibition_application
                        setApplications([...applications, _application])
                        break
                    case 'artist':
                        const artistData = {
                            exhibition_id: exhibitionID,
                            artist_id: data2send.artist_id,
                            order: 0,
                            enabled: 0
                        }
                        let _artist = await private_server.post(`exhibitionartist`, artistData)
                        _artist = _artist.data.data.exhibition_artist
                        setArtists([...artists, _artist])
                        break
                    case 'sponsor':
                        const sponsorData = {
                            sponsor_id: data2send.sponsor_id,
                            exhibition_id: exhibitionID,
                        }
                        let _sponsor = await private_server.post(`exhibitionsponsor`, sponsorData)
                        _sponsor = _sponsor.data.data.exhibition_sponsor
                        setSponsors([...sponsors, _sponsor])
                        break
                }

                actions.onCloseAddModal(`${labelID}s`)
                setError(null)
            } catch (error) {
                setError(onGetErrorMessage(error))
                console.log('catch')
            }
            setSending(false)
        },
        onDeleteItem: async (labelID, id) => {
            try {
                setSending(true)
                let url = ""
                let path = ""
                let setState = null

                switch (labelID) {
                    case 'magazines':
                        let _form = { ...form }
                        _form.magazine.value = null
                        _form.magazine_description.value = null
                        console.log(_form)
                        setForm(_form)
                        actions.onSaveExhibition(labelID)
                        return
                        break
                    case 'sponsors':
                        url = 'exhibitionsponsor'
                        path = 'exhibition_sponsors'
                        setState = (data) => setSponsors(data)
                        break
                    case 'artworks':
                        url = 'exhibitionartwork'
                        path = 'exhibition_artworks'
                        setState = (data) => setArtworks(data)
                        break
                    case 'artists':
                        url = 'exhibitionartist'
                        path = 'exhibition_artists'
                        setState = (data) => setArtists(data)
                        break
                }

                //eliminamos
                await private_server.delete(`${url}/${id}`)
                //Actualizamos la info

                let _total = { ...total }
                let filter = {
                    AND: [
                        { field: "exhibition_id", operator: "=", value: exhibitionID },
                    ]
                }
                filter = encodeURIComponent(JSON.stringify(filter))

                let params = `?${queryString.stringify(tableFilter[labelID])}&filter=${filter}`

                let response = await private_server.get(`${url}/all${params}`)
                response = response.data.data
                setState(response[path])
                _total[labelID] = response.count
                setTotal(_total)

            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onVisitUrl: (url) => {
            if (url && url.length > 0)
                history.push(`/artworks/${url}`)
        },
        onCloseAddModal: (label) => {
            let _add_modal = { ...add_modal }
            _add_modal[label] = false
            setAddModal(_add_modal)
        },
        //DOCUMENTS
        onOpenDocumentModal: document => {
            setSelectedDocument(document)
            setDocumentModal(true)
        },
        onCloseDocumentModal: () => {
            setSelectedDocument(null)
            setDocumentModal(false)
        },
        onUploadDocument: async (file) => {
            try {
                if (!file) {
                    setError('A document must be attached')
                    return
                }

                setSending(true)
                const file2send = { name: selectedDocument.key, ext: onGetFileExtension(file.name) }
                const options = { headers: { 'Content-Type': file.type } }
                const response = await private_server.post(`exhibition/${exhibitionID}/file`, file2send)
                await axios.put(response.data.data.url, file, options)

                await actions.onUpdateExhibition()
                setSuccess(true)
                setSending(false)
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onDownloadDocument: async (document) => {
            try {
                setSending(true)

                let file = exhibition[document.key]

                if (isValidDocument(file)) {
                    file = `${CONFIG_SETTINGS.S3_SERVER_URL}${file}`
                    console.log(file)
                    //let response = await private_server.get()
                    let response = await axios.get(file, { responseType: 'blob' })
                    FileSaver.saveAs(response.data, document.label)
                }
                setSending(false)
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onRemoveDocument: async (document) => {
            try {
                setSending(true)

                const data = { name: document.path }

                await private_server.delete(`exhibition/${exhibitionID}/file`, { params: data })
                await actions.onUpdateExhibition()

                setSending(false)
            } catch (error) {
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
    }

    const system = { loading, sending, success, error }
    const view_data = {
        files, form, status, exhibition, exhibitionID, imageType,
        loadingTable, total, tableFilter, search,
        applications, sponsors, artworks, artists,
        selectedProfessionalImage, selectedDocument, professionalImages,
        picture_modal, professional_image_modal, add_modal, edit_modal,
        edit_magazine_modal, edit_video_modal, edit_virtual_modal, edit_date_modal,
        document_modal, filters
    }

    return { system, view_data, actions }
}

export default useExhibitionDetailsView

const filesData = {
    brochure: { key: 'brochure', path: 'brochure', status: false }
}

const formData = {
    title: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'title',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    artworks: {
        config: {
            id: 'artworks',
            type: 'range',
            fullWidth: true,
        }
    },
    min_artwork_number: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'min_artwork_number',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'numeric',
        }
    },
    max_artwork_number: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'max_artwork_number',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'numeric',
        }
    },
    created_by: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        image: null,
        config: {
            id: 'created_by',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none'
        }
    },
    updated_by: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        image: null,
        config: {
            id: 'updated_by',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none'
        }
    },
    short_description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'short_description',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 2
        },
        rules: {
            type: 'none'
        }
    },
    medium_description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'medium_description',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 4
        },
        rules: {
            type: 'none'
        }
    },
    description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 6
        },
        rules: {
            type: 'none'
        }
    },
    exhibition_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        image: null,
        config: {
            id: 'exhibition_type_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none'
        }
    },
    featured: {
        value: 0,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config: {
            id: 'featured'
        }
    },
    period: {
        config: {
            id: 'period',
            type: 'range',
            fullWidth: true,
        }
    },
    start_date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'start_date',
            type: 'date',
            fullWidth: true,
        },
        rules: { type: 'date' }
    },
    end_date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'end_date',
            type: 'date',
            fullWidth: true,
        },
        rules: { type: 'date' }
    },
    artwork_date: {
        config: {
            id: 'date',
            type: 'date',
            fullWidth: true,
        }
    },
    application_limit_date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'application_limit_date',
            type: 'date',
            fullWidth: true,
        },
        rules: { type: 'date' }
    },
    application_documents_limit_date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'application_documents_limit_date',
            type: 'date',
            fullWidth: true,
        },
        rules: { type: 'date' }
    },
    /* APPLICATIONS */
    user_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        image: true,
        config: {
            id: 'user_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none'
        }
    },
    /*ARTWORKS*/
    artist_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        image: true,
        config: {
            id: 'artist_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none'
        }
    },
    artwork_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        image: true,
        config: {
            id: 'artwork_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none'
        }
    },
    artwork_title: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        image: true,
        config: {
            id: 'title',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    dimensions: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'dimensions',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    technique: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'technique',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    price: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'price',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'numeric',
        }
    },
    artwork_description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 4
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'date',
            type: 'date',
            fullWidth: true,
        },
        rules: { type: 'date' }
    },
    sold: {
        value: 0,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config: {
            id: 'sold'
        }
    },
    nft: {
        value: 0,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config: {
            id: 'nft'
        }
    },
    external_url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'external_url',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    /* SPONSOR */
    sponsor_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        image: true,
        config: {
            id: 'sponsor_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none'
        }
    },
    /* MAGAZINES */
    magazine_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        image: true,
        config: {
            id: 'magazine_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'none'
        }
    },

    magazine: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'magazine',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    magazine_description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'magazine_description',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 4
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    /* VIDEO URL */
    video: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'video',
            type: 'text',
            fullWidth: true
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 200
        }
    },
    /* IFRAME */
    iframe: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'iframe',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows: 8
        },
        rules: {
            type: 'distance',
            min: 1
        }
    }
}