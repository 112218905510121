import { Grid, Typography } from '@material-ui/core'
import LineInputForm from '../../../components/Forms/LineInputForm'
import SimpleModal from '../../../components/Modals/SimpleModal'
import RoundedButton from '../../../components/Actions/RoundedButton'
import LineSelectForm from '../../../components/Forms/LineSelectForm'

const AddUserModal = ({ open, form, onChange, onSubmit, onClose, content }) => {

    return (
        <SimpleModal maxWidth='sm' open={open} onClose={onClose}>
            <Typography variant='h6' style={{marginBottom: 24}}>{content.title}</Typography>
            <Grid container alignItems='center' spacing={4}>
                <Grid item xs={12} sm={6}>
                    <LineInputForm data={form.email} onChange={onChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LineInputForm data={form.password} onChange={onChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LineSelectForm data={form.user_type_id} onChange={onChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LineInputForm data={form.first_name} onChange={onChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LineInputForm data={form.last_name} onChange={onChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LineSelectForm data={form.gender_id} onChange={onChange} />
                </Grid>
                <Grid item xs></Grid>
                <Grid item>
                    <RoundedButton size='small' onClick={onSubmit}>
                        {content.button}
                    </RoundedButton>
                </Grid>
            </Grid>
        </SimpleModal>
    )
}

export default AddUserModal