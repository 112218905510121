import logo from '../../assets/logo.png'
import nouser from '../../assets/nousergold.png'

//SIDEBAR ICONS
import home from '../../assets/icons/home.svg'
import profile from '../../assets/icons/profile.svg'
import applications from '../../assets/icons/applications.svg'
import artists from '../../assets/icons/artist.svg'
import artworks from '../../assets/icons/artworks.svg'
import exhibitions from '../../assets/icons/exhibitions.svg'
import magazines from '../../assets/icons/magazines.svg'
import masterclasses from '../../assets/icons/master_classes.svg'
import audience from '../../assets/icons/audience.svg'
import stadistics from '../../assets/icons/stadistics.svg'
import logout from '../../assets/icons/logout.svg'
import sponsors from '../../assets/icons/sponsors.svg'

import arrow from '../../assets/icons/arrow.svg'

const general_translations = {
    sidebar: {
        logo: logo,
        list: [
            {
                name: 'Home',
                path: '/home',
                icon: home,
            },
            {
                name: 'Applications',
                path: '/applications',
                icon: applications,
            },
            {
                name: 'Users',
                path: '/users',
                icon: profile,
                adminOnly: true
            },
            {
                name: 'Sponsors',
                path: '/sponsors',
                icon: sponsors,
            },
            {
                name: 'Artists',
                path: '/artists',
                icon: artists,
            },
            {
                name: 'Artworks',
                path: '/artworks',
                icon: artworks,
            },
            {
                name: 'Exhibitions',
                path: '/exhibitions',
                icon: exhibitions,
            },
            {
                name: 'Magazines',
                path: '/magazines',
                icon: magazines,
            },
            {
                name: 'Masterclasses',
                path: '/masterclasses',
                icon: masterclasses,
            },
            {
                name: 'Audience',
                path: '/audience',
                icon: audience,
                options: [
                    { name: 'Newsletters', path: '/newsletters' },
                    { name: 'Contact', path: '/contact' },
                    { name: 'Enquiries', path: '/enquiries' }
                ]
            },
            {
                name: 'Statistics',
                path: '/statistics',
                icon: stadistics,
            },
        ],
        logout: {
            name: 'Log out',
            path: '/log-out',
            icon: logout,
        },
        arrow: arrow
    },
    topbar: {
        titles: [
            {
                url: '/',
                title: 'Welcome!'
            },
            {
                url: '/devices',
                title: 'Devices'
            },
            {
                url: '/locations',
                title: 'Locations'
            },
        ],
        cart_button: {
            remove: 'Eliminar',
            subtotal: 'Subtotal',
            view_button: 'Ver carrito',
            pay_button: 'Pagar'
        },
        profile_menu: [
            /*{
                url: '/kuvik-website',
                label: 'Kuvik Website'
            },*/
            {
                id: 'profile',
                url: '/users',
                label: 'Profile'
            },
            {
                id: 'logout',
                url: '/log-out',
                label: 'Log out'
            }
        ],
        noimage: nouser
    },
    errors:{
        incomplete_form: 'Incorrect fields'
    }
}

export default general_translations