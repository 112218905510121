import { Grid, Typography } from '@material-ui/core';
import SimpleModal from '../../../components/Modals/SimpleModal';
import RoundedButton from '../../../components/Actions/RoundedButton';
import LineAutocompleteForm from '../../../components/Forms/LineAutocompleteForm';

const AddArtworkModal = ({ open, form, onChange, onSubmit, onClose, content }) => {
	return (
		<SimpleModal maxWidth='sm' open={open} onClose={onClose}>
			<Grid container alignItems='center' spacing={4}>
				<Grid item xs={12}>
					<Typography variant='h6'>{content.title}</Typography>
				</Grid>
				<Grid item xs={12}>
					<LineAutocompleteForm data={form.artwork_id} onChange={onChange} />
				</Grid>
				<Grid item xs></Grid>
				<Grid item>
					<RoundedButton size='small' onClick={() => onSubmit('artwork')}>
						{content.button}
					</RoundedButton>
				</Grid>
			</Grid>
		</SimpleModal>
	);
};

export default AddArtworkModal;
