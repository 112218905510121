import { amber, blue, cyan, green, indigo, orange, purple, red, teal, pink, lime } from "@material-ui/core/colors";
import paypal from '../../assets/payments/paypal.png'
import mercadopago from '../../assets/payments/mercadopago.png'
import card from '../../assets/cards/mastercard.png'

import baby_icon from '../../assets/icons/baby.png'
import drugs_icon from '../../assets/icons/pill.png'
import aids_icon from '../../assets/icons/first-aids.png'
import nutrition_icon from '../../assets/icons/nutrition.png'
import skin_icon from '../../assets/icons/skin.png'
import vision_icon from '../../assets/icons/vision.png'

export const catalogs = {
    brands:[
        {value:1, label:'Marca 1', image:baby_icon},
        {value:2, label:'Marca 2', image:drugs_icon},
        {value:3, label:'Marca 3', image:aids_icon},
        {value:4, label:'Marca 4', image:skin_icon},
    ],
    sale_groups:[
        {value:1, label:'Grupo 1'},
        {value:2, label:'Grupo 2'},
        {value:3, label:'Grupo 3'},
        {value:4, label:'Grupo 4'},
        {value:5, label:'Grupo 5'},
    ],
    equivalences:[
        {value:1, label:'Equivalencia 1'},
        {value:2, label:'Equivalencia 2'},
        {value:3, label:'Equivalencia 3'},
        {value:4, label:'Equivalencia 4'},
        {value:5, label:'Equivalencia 5'},
    ]
}

export default catalogs