import React from 'react'
import { connect } from 'react-redux';
import TitleContainer from '../../components/Structure/Layouts/TitleContainer';
import system_translations from '../../texts/system_translations'
import Banners from './sections/Banners/Banners';
import Sections from './sections/Sections/Sections';
import useHomeView from './useHomeView';


const HomeView = ({ language }) => {

    const content = system_translations[language].views.home
    const { system, view_data, actions, modals } = useHomeView({ language })

    return (
        <TitleContainer title={content.title}>
            <Banners content={content.sections.banners} data={view_data.banners} actions={actions} modals={modals}
                language={language} selected={view_data.banner_selected} />
            <Sections content={content.sections.sections} data={view_data.sections} actions={actions} modals={modals}
                language={language} selected={view_data.section_selected} />
        </TitleContainer>
    )
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
    }
}

export default connect(mapStateToProps)(HomeView) 