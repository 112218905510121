import { connect } from 'react-redux';
import SelectFilter from '../../components/Forms/SelectFilter';
import LoadingContainer from '../../components/Structure/Layouts/LoadingContainer';
import TitleContainer from '../../components/Structure/Layouts/TitleContainer';
import ActionTopBar from '../../components/Structure/Table/ActionTopBar';
import catalogs from '../../texts/eng/catalogs';
import { translations as translations_eng } from '../../texts/eng/enquiries-translations'
import SimpleTable from './components/SimpleTable';
import AddEnquirieModal from './modals/AddEnquirieModal';
import EditEnquirieModal from './modals/EditEnquirieModal';
import useEnquiriesView from './useEnquiriesView';


const EnquiriesView = ({ language, history, is_auth }) => {

    const content = translations_eng
    const { system, view_data, actions } = useEnquiriesView({ language, history, is_auth, content })


    return (
        <LoadingContainer
            loading={system.loading}
            error={system.error}
            onCloseError={actions.onCloseError}
        >
            <TitleContainer title={content.title}>
                <AddEnquirieModal
                    open={view_data.add_modal}
                    form={view_data.form}
                    onChange={actions.onChange}
                    onSubmit={actions.onAddEnquirie}
                    onClose={actions.onCloseAddModal}
                    content={content.modals.add_modal}
                    artworks={view_data.artworks}
                />
                <EditEnquirieModal
                    open={view_data.edit_modal}
                    form={view_data.form}
                    onChange={actions.onChange}
                    onSubmit={actions.onUpdateEnquiry}
                    onClose={actions.onCloseEditModal}
                    content={content.modals.edit_modal}                    
                    artworks={view_data.artworks}

                />
                <ActionTopBar
                    filter={[...content.filter, ...catalogs.application_statuses]}
                    filterValue={view_data.filterValue}
                    onChangeFilter={actions.onUpdateFilterValue}
                    search
                    searchData={view_data.form.search}
                    onSearch={actions.onSearchEnquirie}
                    add
                    onAdd={actions.onOpenAddModal}
                    onUpdateSearch={actions.onResetTableFilter}
                />
                <SimpleTable
                    onSelectedItem={actions.onSelectEnquirie}
                    data={view_data.enquiries}
                    tableFilter={view_data.tableFilter}
                    onUpdateTableFilter={actions.onUpdateTableFilter}
                    total={view_data.total}
                    loading={system.sending}
                    onRemoveItem={actions.onPreDeleteRegister}
                    noData={content.noData}
                    header={content.header}
                />
            </TitleContainer>
        </LoadingContainer>
    )
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        is_auth: state.is_auth
    }
}

export default connect(mapStateToProps)(EnquiriesView) 