import React, { useEffect, useState } from 'react'
import { Chip, FormControlLabel, IconButton, Switch, TextField, Typography } from "@material-ui/core"
import { makeStyles, withStyles } from '@material-ui/styles'
import AddIcon from '@material-ui/icons/Add'
import InputForm from './InputForm'

const CssTextField = withStyles({
    root: {
        //paddingTop:6,
        '& label.Mui-focused': {
            //color: 'green',
            //display:'none'
        },
        '& .MuiInput-underline:after': {
            //borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            //border:'none',
            //borderBottom:'1px solid #AEAEAE',
            '& fieldset': {
                //borderColor: 'red',
                border: 'none',
                borderBottom: '1px solid #AEAEAE',
            },
            '&:hover fieldset': {
                borderColor: '#BD8B40',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#BD8B40',
            },
        },
        /* '& .MuiFormLabel-root':{
          color:'#858585'
        } */
    },
})(TextField)

const styles = makeStyles(theme => ({
    container: {
        width: '100%',
    },
    label: {
        color: '#A0A0A0',
        fontWeight: 700,
        fontSize: 12
    },
    root: {
        width: 45,
        height: 26,
        padding: 0,
        margin: '18px 10px 10px 0px',
        overflow: 'initial'
    },
    tagContainer: {
        display: 'inline-flex',
        marginTop: 12
    },
    tag: {
        marginRight: 5
    }
}))

const TagsForm = props => {

    const classes = styles()
    const { data, onChange, disabled, size } = props
    const { config, value, isRequired, isVisited, isValid } = data
    const { id, label } = config

    const [tags, setTags] = useState([])
    const [currentTag, setCurrentTag] = useState('')
    const [interConfig, setInterConfig] = useState({ ...config, label: '' })
    const [isError, setIsError] = useState(false)

    const onInnerChange = () => {
        let temp = { ...data }
        temp.isVisited = true

        if (currentTag && currentTag.length > 0) {
            let _value = value && value.length > 0 ? `${value},${currentTag}` : currentTag
            temp.value = _value
            temp.isValid = true
            setCurrentTag('')
            let _tags = [...tags]
            _tags.push({ id: _tags.length, label: currentTag })
            setTags(_tags)
            setIsError(false)
        } else {
            temp.isValid = false
            let _interConfig = { ...interConfig }
            _interConfig.helperText = 'Write at least one character'
            setInterConfig(_interConfig)
        }
        onChange(temp)
    }

    const onDeleteTag = tag => {
        let _tags = tags.filter(_tag => _tag.id !== tag.id && _tag.label !== tag.label)
        let temp = { ...data }
        temp.value = _tags.join(',')
        temp.isVisited = true
        temp.isValid = true
        onChange(temp)
        setTags(_tags)

        if (isRequired && temp.value.length === 0) {
            setIsError(true)
            let _interConfig = { ...interConfig }
            _interConfig.helperText = 'Should be at least 1 tag'
            setInterConfig(_interConfig)
        }
    }

    const onChangeCurrentTag = event => setCurrentTag(event.target.value)

    const handleKeyPress = event => {
        if (event.key === 'Enter')
            onInnerChange()
    }

    useEffect(() => {
        let _tags = value ? value.split(",").map((tag, key) => { return { id: key, label: tag } }) : []
        setTags(_tags)

        let _interConfig = { ...config, label: '' }

        const _isError = isVisited && !isValid
        setIsError(_isError)
        if (!_isError) _interConfig.helperText = ''
        if (isRequired) _interConfig.placeholder = `${_interConfig.placeholder} *`

        setInterConfig(_interConfig)

    }, [isVisited])

    return (
        <div className={classes.container}>
            <Typography className={classes.label}>
                {label}
            </Typography>
            <CssTextField value={currentTag} variant='outlined' color='secondary'
                error={isError} {...interConfig} onChange={onChangeCurrentTag}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: <IconButton onClick={onInnerChange}><AddIcon /></IconButton>,
                    onKeyPress: handleKeyPress
                }}
            />
            <div className={classes.tagContainer}>
                {tags.map(tag => (
                    <div className={classes.tag} key={`tags-form-${tag.id.toString()}`}>
                        <Chip
                            color="secondary"
                            label={tag.label}
                            onDelete={() => onDeleteTag(tag)}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TagsForm