import { makeStyles } from '@material-ui/core';
import React from 'react';
import { ReactSVG } from 'react-svg'

const SVGIcon = ({ src, size, color, colorStroke, width, height, style }) => {

    const classes = useStyles()
    const _size = 32
    const icon_size = size ? size : _size

    return (
        <div style={{ width: width ? width : icon_size, height: height ? height : icon_size, ...style }}>
            <div className={classes.root}>
                {src && src !== null &&
                    <ReactSVG
                        src={src}
                        beforeInjection={(svg) => {
                            svg.setAttribute('style', `fill: ${color ? color : 'none'}; stroke: ${colorStroke ? colorStroke : 'none'}; height:${icon_size}px; width:${icon_size}px; position:absolute; top:0; left:0;`)
                        }}
                        afterInjection={(error, svg) => {
                            if (error) {
                              //  console.log('Hubo un error svg')
                                //console.log(error)
                                return
                            }
                           // console.log(svg)
                        }}
                    />}
            </div>
        </div>

    );
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        textAlign: 'center',
        borderRadius: 8,
        position: 'relative'
    }
}))

export default SVGIcon;